@value 1300: (min-width: 1300px);
@value 1200: (min-width: 1200px);
@value 1100: (min-width: 1100px);
@value 1000: (min-width: 1000px);
@value 900: (min-width: 900px);
@value 850: (min-width: 850px);
@value 800: (min-width: 800px);
@value 700: (min-width: 700px);
@value 600: (min-width: 600px);
@value 500: (min-width: 500px);

@value width-navbar: 1280px;


:root {
  --pri-01:  #ffffff;
  --sec-01:  #e5e5e5;

  --pri-02:  #28AAE1;
  --sec-02:  #1D94C7;

  --pri-03:  #4CB847;
  --sec-03:  #2DA127;

  --pri-04:  #E12E40;
  --sec-04:  #CC1B2F;

  --pri-05:  #76d1ff;
  --sec-05:  #28aae1;

  --acc-01:  #535353;
  --acc-02:  #1d2e55;
  --acc-03:  #020f23;

  --gray-xxl: #FAFAFA;
  --gray-xl:  #f2f2f2;
  --gray-l:   #d3d3d3;
  --gray-m:   #ADADAD;
  --gray:     #bdbdbd;
  --gray-d:   #626262;
  --gray-bg: hsla(0, 0%, 66.2%, 0.04);

  --black-l: #4c4c4c;
  --black:   #323232;
  --black-d: #000000;

  --blue-m:  #aee0f5;
  --blue-l:  #E5F5FC;
  --blue-xl: #f8fafe;

  --greenish-white: #e3f9e8;
  --reddish-white: #fde5e5;

  --azure: #6288aa;
  --trolley: #808080;

  --font-helv: 'Helvetica Neue';
  --font-reg: 'HarmoniaSans W01';
  --font-semibold: 'HarmoniaSans W01 SemiBd';
  --font-bold: 'HarmoniaSans W01 Bold';
  --font-01: var(--font-helv);
  --font-02: var(--font-semibold);
  --font-03: var(--font-bold);

  --navbar-height: 60px;
  --navbar-width: width-navbar;
  --footer-height: 58px;
  --top-offset: 60px;

  --z-index-toast: 9;
  --z-index-modal: 8;
  --z-index-popper: 7;
  --z-index-chat-widget: 6;
  --z-index-navbar: 6;

  --hyperlink: #1271c4;

  --shadow: 1px -1px 1px -1px rgba(0, 0, 0, 0.2),
            0px 1px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  --box-shadow: 0 2px 6px 2px rgba(60,64,67,.15);
}
