.redacted {
  position: relative;
  white-space: pre;
  font-size: 0;
  vertical-align: top; /* Needed so that the height doesn't get all wonky */
}

.redacted>span {
  display: none;
}

.redacted:after {
  font-family: var(--font-semibold);
  content: "[Redacted]";
  width: 100%;
  height: 27.5px;
  left: 0;
  font-size: 14px;
  vertical-align: middle;
}