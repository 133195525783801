.header {
  composes: header from './Messages.css';
}

.contain {
  composes: header-wrap from './Messages.css';
  padding: 30px;
}

.title {
  color: var(--black);
  font-size: 24px;
  font-family: var(--font-semibold);
}

.edit {
  composes: edit from './Messages.css';
}

.btn {
  composes: btn from './Messages.css';
}

.main {
  composes: main from './Messages.css';
}

.content {
  composes: content from './Messages.css';
}

.scroll {
  composes: scroll from './Messages.css';
}

.busy {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 30px 0;
}

.spinner {
  color: var(--pri-02);
}

.items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0;
  width: 100%;
}

.item {
  position: relative;
  border-bottom: 1px solid var(--gray-l);
  cursor: pointer;
  width: 100%;
  user-select: none;
}

@media 1100 {
  .item:hover {
    background-color: var(--blue-l);
  }
}

.user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 45px;
  padding: 20px 10px;
}

.name {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  font-size: 15px;
}

.avatar {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-family: var(--font-bold);
  color: var(--gray-d);
}

.info {
  flex: 1;
  overflow: hidden;
  font-size: 14px;
  line-height: 24px;
  padding-left: 10px;
}

.message {
  font-family: var(--font-reg);
  color: var(--gray-d);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.date {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 60px;
  height: 100%;
}

.time {
  font-size: 14px;
  font-family: var(--font-reg);
  color: var(--gray-d);
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 40px;
  bottom: 0;
  width: 100%;
}

.icon {
  width:  50%;
  height:  50%;
  color: var(--sec-01);
  stroke-width: .4;
}

.wrap {
  text-align: center;
}

.subtitle {
  color: var(--black);
  font-family: var(--font-reg);
  font-size: 17px;
  line-height: 35px;
}

.unread {
  composes: item;
  background-color: var(--pri-01);
}

.read {
  composes: item;
  background-color: var(--gray-xl);
}

.active {
  composes: read;
  background-color: var(--blue-l);
}

.unread .name,
.unread .message {
  color: var(--black);
  font-family: var(--font-bold);
}

.active .indicator {
  position: absolute;
  left: 0;
  height: 100%;
  width: 7px;
  background-color: var(--pri-02);
}