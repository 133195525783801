.text {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.name {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.count {
  margin-left: 5px;
  color: var(--gray);
}