.wrap {
  display: none;
}

.title {
  color: var(--gray-d);
  font-family: var(--font-bold);
  font-size: 14px;
  margin: 0 0 12px 0;
}

.item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px 0;
  margin: 0 0 6px 2px;
  font-size: 14px;
  user-select: none;
}

.checkbox {
  cursor: pointer;
  margin: 0 6px 0 0;
  padding: 0;
}

.img {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}

@media(min-width: 800px) {
  .wrap {
    display: block;
    padding: 0 5px;
    margin: 50px 0 0 0;
    width: 140px;
  }
}