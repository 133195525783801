.root {

}

.wrap {

}

.main {

}

.header {
  margin: 30px 0;
}

.cover {
  position: relative;
}

.box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9px;
  overflow: hidden;
}

.text {
  margin-bottom: 5px;
}

.paragraph {
  margin-bottom: 15px;
}