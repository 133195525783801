.root {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.input {
  border: 1px solid var(--sec-01);
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-content: space-between;
}

.editor {
  width: 100%;
  line-height: 25px;
}

.btn {
  background: var(--pri-02);
  border-radius: 5px;
  color: white;
  font-size: 12px;
  line-height: 15px;
  padding: 5px 15px;
  height: 25px;
  font-weight: bold;
}

.btn[disabled], .comment:disabled {
  cursor: pointer;
  background: var(--gray);
}

.comment:not(:first-child) {
  border-top: 1px solid var(--sec-01);
}

.avatar {
  margin-right: 10px;
}