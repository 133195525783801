.root {

}

.wrap {

}

.main {
  margin: 40px 0;
}

.check-cell {
  max-width: 60px;
}

.buttons {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.buttons>button {
  flex-grow: 1;
}

.checkbox-row {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.subheader {
  margin-bottom: 20px;
}

.btn {
  padding: 0 10px;
  height: 40px;
  width: 100%;
  border-radius: 4px;
}

.footer {
  padding: 40px 0;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  border: 1px solid rgb(211, 211, 211);
  height: 35px;
  line-height: 23px;
  color: rgb(76, 76, 76);
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  font-family: var(--font-reg);
}

.modal {
  width: 850px;
}

.header {
  background-color: var(--sec-01);
  border-bottom: 1px solid var(--gray-l);
  border-top: 2px solid var(--gray-l);
  font-size: 15px;
  font-family: var(--font-semibold);
}

.cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header .tr {
  min-height: 30px;
}

.notify {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 5px;
  height: 24px;
}

.input {
  width: 100%;
  padding: 0 2px;
}

.email {
  font-size: 15px;
}

.email {
  padding: 0 5px;
}

.last,
.first {
  max-width: 150px;
}

.checkbox {
  width: 30px;
}

.user-select-menu {
  z-index: 99 !important;
}

.label {
  font-size: 14px;
  font-family: var(--font-bold);
  margin-bottom: 10px;
}

.search {
  margin-bottom: 20px;
}

.table {
  min-height: 150px;
}

.tbody {
  height: auto;
  overflow-y: hidden;
}

.nomatch {
  color: var(--pri-04);
  font-size: 13px;
  font-family: var(--font-semibold);
}

.account .select {
  max-width: 140px;
  color: var(--black-d);
}

.account .disabled {
  color: var(--black-d);
  background-color: var(--gray-xxl);
}

.tr {
  max-width: calc(100% - 20px);
}

.td {
  height: 45px;
}

.loader {
  height: 300px;
}