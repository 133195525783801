.m-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 45px;
}

.m-title {
  color: var(--gray-d);
  font-size: 26px;
  text-align: center;
  margin-bottom: 20px;
}

.m-desc {
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-reg);
  margin: 0 0 30px 0;
}

.m-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.p {
  max-width: 80%;
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-reg);
}

.m-line {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  text-align: left;
}

.m-check {
  color: var(--pri-03);
  margin: 0 15px;
  height: 40px;
  width: 31px;
}

.footer {
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-reg);
  text-align: center;
  max-width: 300px;
  margin-bottom: 10px;
}