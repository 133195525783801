.root {

}

.wrap {
  padding: 10px;
}

.main {
  max-width: 350px;
  color: var(--black);
  font-family: var(--font-reg);
  font-size: 16px;
  margin: 0 auto;
  padding: 0 10px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  border-radius: 25px;
  background: linear-gradient(90deg, rgba(92,93,167,1) 0%, rgba(40,170,225,1) 100%);
  text-transform: uppercase;
  font-family: var(--font-bold);
  font-size: 16px;
  letter-spacing: 3px;
  color: var(--pri-01);
  margin-bottom: 20px;
}

.addr {
  margin-bottom: 20px;
}

.label {
  font-family: var(--font-bold);
  font-size: 16px;
  margin-bottom: 3px;
}

.email {

}

.link {
  color: var(--hyperlink);
  text-decoration: underline;
}

.info {
  font-size: 16px;
}

.list {
  margin: 0 0 20px;
}

.root ul {
  padding-left: 20px;
}

.list > li {
  margin-bottom: 15px;
}

ul.dash {
  list-style-type: none;
}

ul.dash > li:before {
  content: '\2013';
  position: absolute;
  margin-left: -18px;
  color: var(--sec-02);
}

ul.sm li {
  list-style-type: disc;
}

li.item,
li.dash {
  margin: 2.5px 0;
}

li.sm {
  margin-bottom: 2.5px;
}

.bp {
  font-size: 16px;
  font-family: var(--font-reg);
}

li.dash > .bp {
  font-size: 14px;
  color: var(--sec-02);
}

li.sm .bp {
  font-size: 13px;
}

.italic {
  font-style: italic;
}

.em {
  font-family: var(--font-bold);
  text-decoration: underline;
}