.root {
  position: relative;
}

.details {
  padding-right: 40px;
}

.row {
  display: flex;
  align-items: flex-start;

  margin-bottom: 15px;
}

.title {
  color: var(--gray-d);
  font-family: var(--font-semibold);
  width: 50px;
  margin-right: 10px;
}

.name {
  margin-left: 10px;
  margin-right: 50px;
}

.popper {
  position: absolute;
  top: 0;
  right: 0;
}

.conditions {
  margin-left: 15px;
}