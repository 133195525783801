@value bar-width: 300px;
@value bar-z-index: 6;

.root {
  width: bar-width;
  box-shadow: var(--shadow);
  border-radius: 6px;
  box-sizing: border-box;
  height: calc(100vh - (var(--root-padding, 0px) * 2));
  background-color: white;
  z-index: bar-z-index;

  position: fixed;
  display: flex;
  flex-direction: column;

  transition: 1s;
}

.spacer {
  width: bar-width;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
}

.section {
  padding: 10px;
}

.section:not(:last-child) {
  border-bottom: 1px solid var(--gray-l);
}

.settings-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.items-section {
  flex-grow: 1;
  overflow-y: auto;
}

.title {
  font-family: var(--font-bold);
  color: var(--gray-d);
  display: flex;
  align-items: center;
}

.item {
  display: flex;
  gap: 5px;
  padding: 5px 5px;
  cursor: pointer;
}

.item-value {
  flex-grow: 1;
  padding: 5px;
  border-radius: 4px;
}

.toggle-collapsed {
  cursor: pointer;
}

.collapsed {
  z-index: bar-z-index;

  padding: 5px;

  box-shadow: var(--shadow);
  border-radius: 6px;

  height: 34px;
  box-sizing: border-box;

  background-color: white;
}

.zoom-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.zoom-row {
  display: inline-flex;
  align-items: center;
  border: 2px solid var(--gray-l);
  width: auto;
  border-collapse: collapse;
  border-radius: 4px;
  height: 30px;
}

.zoom-input-root {
  width: 50px;
  padding-left: 5px;
}

.zoom-btn {
  height: 100%;
  border: 2px solid var(--gray-d);
  margin-top: -2px;
  margin-bottom: -2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: var(--gray-l);
  cursor: pointer;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.zoom-btn:hover {
  background-color: var(--gray-d);
}

.zoom-btn:last-child {
  margin-right: -2px;
}

.zoom-btn>svg {
  color: white;
}

.focusable {
  display: none;
}

.popout {
  margin-left: 10px;
  cursor: pointer;
  color: var(--hyperlink);
}

.icon {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.completed-icon {
  composes: icon;
  background-color: var(--pri-03);
}

.active-icon {
  composes: icon;
  background-color: var(--pri-02);
}

.empty-icon {
  composes: icon;
  background-color: inherit;
}