.icon-end {
  composes: icon from './Shared.css';
  color: var(--pri-01);
  background-color: var(--pri-04);
  border: 1px solid var(--pri-04);
}

.icon-end svg {
  height: 32px;
  width: 32px;
}

@media (min-width: 800px) {
  .icon-end:hover {
    background-color: var(--sec-04);
  }
}