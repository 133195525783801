.root {
  padding: 15px;
  width: 700px;
}

.title {
  font-size: 24px;
  font-family: var(--font-semibold);
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  margin-bottom: 25px;
}

.header-left {

}

.created-on {
  color: var(--gray-d);
  font-size: 14px;
}

.toggle {

}

.section {
  margin-bottom: 30px;
}

.label {
  font-family: var(--font-bold);
  font-size: 15px;
  margin-bottom: 5px;
  color: var(--black);
}

.section-title {
  font-size: 20px;
  color: var(--black);
  font-family: var(--font-semibold);
  line-height: 40px;
  margin: 30px 0;
  border-bottom: 1px solid var(--gray-l);
}

.content {

}

.content ~ .content {
  margin-bottom: 10px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.grid > .section {
  width: 50%;
}

.input {
  margin-right: 20px;
  margin-bottom: 5px;
}

.date {
  composes: input;
  width: 100%;
}

.btn {
  width: 160px;
}

.downloader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  height: 40px;
}

.spinner {
  padding: 10px;
}

.downloader .export {
  color: var(--black-l);
}

.downloader .export:hover {
  background-color: var(--blue-l);
  color: var(--black-d);
}

.downloader .export:hover svg {
  color: var(--black-d);
}

.downloader .export:disabled,
.downloader .export:disabled:hover {
  background-color: var(--sec-01);
  opacity: 0.7;
  color: var(--black-l);
}

button.export {
  padding-right: 15px;
}

.link {
  color: var(--hyperlink);
  cursor: pointer;
}

.url {
  display: flex;
  align-items: center;
}

.protocol {
  display: flex;
  align-items: center;
  line-height: 32px;
  margin-bottom: 5px;
  color: var(--gray-m);
}

.error {
  color: var(--pri-04);
  font-size: 14px;
  font-family: var(--font-semibold);
}

.domains {

}

.domain {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.ipt {
  max-width: 200px;
}

.iptt {
  line-height: 16px;
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px 0;
}

.add {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--pri-02);
  font-family: var(--font-bold);
  height: 32px;
  cursor: pointer;
}

.add:disabled {
  color: var(--gray);
}

.item {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}