.name {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.author a:hover {
  color: var(--pri-02);
  text-decoration: underline;
  cursor: pointer;
}

.respondent {
  display: flex;
  min-height: 50px;
  border-bottom: 1px solid var(--gray-xl);
  font-size: 15px;
  color: var(--gray-d);
}

.response {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 25px;
}

.link {
  width: 100%;
}

.link:hover {
  color: var(--black-l);
  font-weight: 500;
  background-color: var(--blue-xl);
}

.author:before {
  content: '-';
  margin-right: 5px;
}

.author {
  font-size: 14px;
  color: var(--black);
  display: flex;
}

.quote {
  word-break: break-word;
  white-space: pre-wrap;
  margin-bottom: 10px;
}

.quote:before {
  content: '"';
}

.quote:after {
  content: '"';
}

.textblock {	
  display: flex;	
  justify-content: space-between;	
  align-items: center;	
  padding: 15px 0;	
}	

.comment {	

}

.baseballContainer {
  width: 100%;
}