.root {

}

.wrap {

}

.main {

}

.header {

}

.empty {
  padding: 25px 0;
  text-align: center;
  color: var(--gray-m);
  font-family: var(--font-semibold);
  font-size: 18px;
}

.thread .item:not(:first-child) {
  border-top: 1px solid var(--sec-01);
}