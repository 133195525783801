.root {
  padding: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray-xl);
  padding: 20px;
}

.title {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  font-size: 18px;
}

.x {
  color: var(--gray);
  font-size: 22px;
  cursor: pointer;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid var(--gray-xl);
  min-height: 75px;
  padding: 15px 20px;
  box-sizing: border-box;
}