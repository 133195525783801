.badgeText {
  font-size: 14px;
  font-family: var(--font-semibold);
}

.badge-base {
  composes: badgeText;
  background-color: var(--gray-l);
  border-radius: 12px;
  width: auto;
  justify-content: normal;
  border-bottom: none;
  display: inline-flex;
  padding: 4px 12px;
  vertical-align: middle;
  align-items: center;
}

.input-value {
  composes: badgeText;
}

.option {
  padding: 4px 8px;
  font-size: 12px;
}

.arrow {
  padding-left: 10px;
}