.root {
  color: var(--pri-02);
  vertical-align: baseline;
}

.read-only {
  composes: root;
  display: inline-block;
  border-radius: 4px;
  background-color: var(--blue-l);
  padding: 3px 3px 2px;
  margin: 0 1px;
  font-size: 0.9em;
}

.editing {
  composes: root;
  font-family: var(--font-semibold);
  font-size: 15px;
}

.focused {

}