.draggable-option {
  position: relative;
  background: white;
  border: 1px solid var(--gray-m);
  border-radius: 8px;
  padding: 2px;
  cursor: grab;
}

.dragging-option {
  composes: draggable-option;
  cursor: grabbing;
}

.option-action {
  position: absolute;
  top: 5px;
  right: 5px;
}

.action {
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.remove-action {
  composes: action;
  color: var(--pri-04);
}

.add-action {
  composes: action;
  color: var(--pri-03);
}

.draggable-option img {
  max-height: calc(40vh);
}

.removed-options {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 10px;
  border: 1px solid var(--pri-04);
  background-color: var(--reddish-white);
  margin-top: 20px;
}