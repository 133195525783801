@value 800, 900, 1200 from 'vars';

.root {
  display: none;
}


@media 800 {
  .root:nth-child(-n+2) {
    display: block;
  }
}

@media 900 {
  .root:nth-child(-n+3) {
    display: block;
  }
}

@media (min-width: 1265px) {
  .root:nth-child(-n+4) {
    display: block;
  }
}

.wrap {
  border: 1px solid var(--gray-l);
  border-radius: 9px;
  height: 260px;
  width: 280px;
}

.wrap:hover {
  background-color: rgba(229, 229, 229, 0.3);
}

.main {
  padding: 20px 15px;
}

.user {
  margin-bottom: 15px;
}

.avatar {
  border-radius: 50%;
  height: 56px;
  width: 56px;
}

.info {

}

.header {
  composes: header from './Discovery.css';
}

.details:first-child {

}

.details {
  height: 70px;
}

.name {
  font-family: var(--font-bold);
  font-size: 18px;
  color: var(--black);
  line-height: 22px;
  margin-bottom: 5px;
}

.subheader {
  font-family: var(--font-semibold);
  font-size: 14px;
  margin-bottom: 5px;
}

.text {
  font-size: 14px;
  color: var(--gray-d);
  overflow: hidden;
  text-overflow: ellipsis;
}

.items {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 25px;
}

@media 900 {
  .items {
    justify-content: space-around;
  }
}

@media (min-width: 1265px) {
  .items {
    justify-content: space-between;
  }
}

.row {

}

.phantom {
  display: none;
}

@media (min-width: 1265px) {
  .phantom {
    display: block;
    visibility: hidden;
  }
}
