.root {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--gray-l);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 4px -1px,
              rgba(0, 0, 0, 0.14) 0px 0px 1px 1px,
              rgba(0, 0, 0, 0.12) 0px 1px 19px 0px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.icon:hover {
  background-color: var(--gray);
}

.open {
  background-color: var(--gray-l);
}

.tagging.active {
  color: var(--pri-02);
  fill: var(--pri-05);
}

.notes {
  position: absolute;
  top: 9px;
  left: 10px;
  height: 31px;
  width: 31px;
  color: var(--black-l);
}

.edit {
  color: var(--black-l);
  position: absolute;
  bottom: 6px;
  right: 0px;
  height: 20px;
}

.tagging {
  color: var(--black-l);
  fill: none;
  height: 33px;
  width: 33px;
}

.badge {
  position: absolute;
  top: -2px;
  right: -10px;
  border-radius: 50%;
  min-width: 14px;
  padding: 3px;
  background-color: var(--pri-05);
  color: var(--pri-01);
  border: 2px solid var(--pri-02);
  font-size: 12px;
  text-align: center;
  font-family: var(--font-bold);
  line-height: 14px;
}