.root {
  background-color: white;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  border-left: 1px solid var(--gray-l);
}

.header {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  border-bottom: 1px solid var(--gray-l);
}

.btns {
  display: flex;
  gap: 10px;
}

.highlights {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  flex-grow: 1;
}

.snippet {
  background-color: var(--gray-xl);
  border-radius: 8px;
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid white;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.snippet.active {
  background-color: var(--blue-l);
  border: 1px solid var(--pri-02);
}

.snippet :global(.term) {
  background-color: yellow;
}

.highlights-msg {
  font-size: 20px;
  padding: 20px;
}

.pagination {
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: var(--font-semibold);
}

.page-btn {
  height: 20px;
  width: 20px;
  padding: 2px;
  border-radius: 12px;
  color: var(--gray-d);
  cursor: pointer;
}

.page-btn:not(.disabled):hover {
  background-color: var(--gray-l);
}

.page-btn.disabled {
  cursor: not-allowed;
  color: var(--gray-l);
}

.highlight-row {
  display: flex;
  gap: 10px;
  padding: 5px 10px;
}

.transcript-name {
  font-size: 14px;
  font-family: var(--font-semibold);
  margin-bottom: 5px;
  background-color: var(--gray-xl);
  padding: 10px;
  margin-bottom: 5px;
}

.transcript-name a {
  display: block;
}

.skeleton-container {
  padding: 10px;
}

.skeleton-row {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.skeleton-snippet {
  flex-grow: 1;
  border-radius: 8px;
}

.highlight-link {
  color: var(--pri-02);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.replace-input-root {
  max-width: 250px;
}

.spacer {
  flex-grow: 1;
}

.edit {
  flex-shrink: 0;
  cursor: pointer;
}

.replace-all-confirmation {
  max-width: 500px;
}

.replace-all-warning {
  display: flex;
  flex-direction: column;
  gap: 20px;
}