@value 800, 1000 from 'vars';

.root {

}

.wrap {
  padding: 40px 20px;
}

.main {
  max-width: 400px;
  margin: 0 auto;
}

@media 800 {
  .main {
    max-width: 1100px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.header {
  font-size: 28px;
  font-family: var(--font-bold);
  color: var(--black-d);
  max-width: 400px;
  margin: 0 auto 40px;
  text-align: center;
}

@media 1000 {
  .header {
    font-size: var(--font-size-header);
    max-width: 700px;
    margin: 0 auto 70px;
  }
}

.item {
  max-width: 280px;
  margin: 0 auto 40px;
}

.title {
  font-size: 18px;
  color: var(--black-d);
  font-family: var(--font-semibold);
  margin: 0 auto 25px;
}


.text {
  font-size: 18px;
  color: var(--gray-d);
  margin: 0 auto;
}

.img {
  width: auto;
  height: 60px;
  margin-bottom: 10px;
}

.box {
}