.root {
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.root > div {
  display: flex;
  margin: 0 4px 0 0;
}

.root > div:last-child {
  margin: 0;
}