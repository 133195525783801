.root {

}

.wrap {

}

.main {
  margin: 0 auto;
  max-width: 500px;
  padding: 20px 0 40px;
}

.subtitle {
  margin-bottom: 25px;
}

.footer {
  padding: 20px 0;
}

.btn {
  min-width: 180px;
  border-radius: 4px;
}

.option {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 40px;
}

.name {

}