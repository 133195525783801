.root {
  margin-bottom: 15px;
}

.root:last-of-type {
  margin-bottom: 0;
}

.textarea:disabled {
  background-color: var(--pri-01);
}

.rt-toggle {
  margin-left: 15px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px;
  cursor: pointer;
}

.rt-enabled {
  background-color: var(--gray-l);
}

.action {
  margin-left: 15px;
  width: 35px;
  height: 35px;
}

.item {
  display: flex;
  align-items: center;
}

.item:nth-last-child() {
  margin-bottom: 0px;
}

.toolbar {
  margin-bottom: 5px;
}