.star {
  color: gray;
}

.clickable {
  cursor: pointer;
}

.root:hover>.star {
  color: var(--pri-02);
  fill: #28aae133;
}

.root>.star:hover~.star {
  color: gray;
  fill: none;
}

.star.filled {
  color: var(--pri-02) !important;
  fill: var(--pri-02) !important;
}

.star.preview {
  color: var(--pri-02) !important;
}

.preview-root {
  display: flex;
}

.mini-root {
  display: flex;
  align-items: center;
  color: var(--gray);
}

.mini-star {
  color: var(--gray);
}

.mini-text {
  margin-left: 2px;
}