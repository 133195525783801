.root {
  max-width: 520px;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.textarea {
  border: 2px solid var(--gray-l);
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;
  resize: none;
  font-family: var(--font-reg);
  font-size: 16px;
  outline: none;
  padding: 5px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.title {
  font-size: 23px;
  font-family: var(--font-bold);
}

.row {
  margin-bottom: 20px;
}

.subtitle {
  font-family: var(--font-reg);
  font-size: 18px;
  color: var(--gray-d);
}

.btn {
  font-size: 18px;
  width: 100%;
  height: 50px;
  border-radius: 3px;
}

.stretch {
  width: 450px;
  padding-bottom: 20px;
}

.footer {
  max-width: 450px;
  margin: 0 auto 20px;
  width: 100%;
}