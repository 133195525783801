.root {
  color: var(--black-l);
  background-color: var(--gray-xl);
  border-radius: 6px;
  box-sizing: border-box;
  height: 42px;

  width: 300px;

  margin-left: 235px;
}

.wrap {
  display: flex;
  align-items: center;
  padding: 0 10px 0 7px;
  height: 100%;
}

.left {
  display: flex;
  align-items: center;
  width: 100%;
}

.x {
  color: var(--black);
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  padding-left: 5px;
}

.icon {
  color: var(--black);
}

.input {
  font-family: var(--font-reg);
  background-color: transparent;
  border: none;
  font-size: 16px;
  /* line-height: 22px; */
  margin-left: 6px;
  text-overflow: ellipsis;
  transition: all 750ms ease;
  height: 100%;
  width: 100%;
}

.input::placeholder {
  color: var(--black);
  opacity: 1;
}
