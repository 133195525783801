.root {
  max-width: 800px;
  margin: 0 auto;
}

.nav {
  display: flex;
  align-items: center;
  height: var(--navbar-height);
  box-sizing: border-box;
  padding: 0 10px;
  margin: 0 auto;
}

@media 800 {
  .nav {
    padding: 0 15px;
    max-width: var(--navbar-width);
  }
}

.message {
  margin-top: 50px;
  text-align: center;
  font-family: var(--font-bold);
  font-size: 24px;
}