.root {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* height: 100%; */
  /* margin: 50px 0 25px 0; */
  margin: max(calc(20% - 80px), 80px) 0 25px 0;
}

.wrap {
  text-align: center;
}

.icon {
  stroke-width: .8px;
  color: var(--gray-l);
  width: 125px;
  height: auto;
}

.text {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  /* font-size: 22px; */
  margin-top: 30px;
}