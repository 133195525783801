.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  height: 32px;
}

.title {
  color: var(--black-l);
  font-size: 19px;
  font-family: var(--font-semibold);
}