@value 1100 from 'vars';

.root {
  width: 100%;
}

@media 1100 {
  .root {
    /* max-width: 885px; */
  }
}

.wrap {

}
