.root {
  --header-height: 70px;
  --vertical-padding: 20px;
  --table-height: calc(100vh - var(--navbar-height) - var(--header-height) - var(--vertical-padding));

  --list-width: 350px;
  --content-width: calc(100% - var(--list-width));
}

.wrap {
  padding: var(--vertical-padding) 0;
}

.body {
  display: flex;
  border-top: 1px solid var(--gray-l);
  height: calc(100% - var(--navbar-height));
}

.list {
  height: 100%;
  width: var(--list-width);
  box-sizing: border-box;
}

.header {
  height: var(--header-height);
}

.table {
  min-height: var(--table-height);
  height: var(--table-height);
  box-sizing: border-box;
}

.content {
  height: 100%;
  width: var(--content-width);
  box-sizing: border-box;
}