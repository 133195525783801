.title {
  margin: 20px 0 25px 0;
  font-size: 20px;
  font-family: var(--font-bold);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 40px 0 20px 0;
}

.body {

}

.submit {

}

.review {
  margin-left: 20px;
}

@media (min-width: 800px) {
  .title {
    width: 400px;
    margin: 20px auto 25px auto;
  }

  .body {
    width: 400px;
    margin: 0 auto;
  }
}