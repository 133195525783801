.btn {
  padding: 5px 10px;
  font-size: 14px;
  width: 130px;
  min-width: 90px;
}

.cancel {
  composes: btn;
  border: 1px solid var(--sec-01);
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}