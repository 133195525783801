.root {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.13);
  border-radius: 3px;
}

.wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.main {

}

.icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5px;
}

.divider {
  width: 1px;
  height: 30px;
  background-color: var(--sec-01);
  margin: 0 15px 0 10px;
}