.row {
  display: flex;
  margin-bottom: 15px;
}

.label {
  font-size: 16px;
}

.tooltip {
  margin-left: 10px;
}

.input {
  --border-width: 2px;
  --padding-horizontal: 15px;
  font-family: var(--font-reg);
  font-size: 16px;
  padding: 10px var(--padding-horizontal);
  border: var(--border-width) solid var(--gray-l);
  border-radius: 5px;
  width: calc(100% - ((var(--border-width) * 2) + (var(--padding-horizontal) * 2)));
}

.input:focus {
  border: var(--border-width) solid var(--pri-02);
}

.input::placeholder {
  color: var(--gray);
}

.required {
  color: var(--pri-04);
}

.invalid {
  border: var(--border-width) solid var(--pri-04);
}