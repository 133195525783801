.table {
  display: grid;
  border-collapse: collapse;
  border: 1px solid var(--gray-xl);
  border-radius: 4px;
  min-width: 100%;
  grid-template-columns:
    minmax(150px, 4fr) 
    minmax(100px, 1fr) 
    minmax(100px, 1fr) 
    minmax(100px, 1fr)
}

.thead,
.tbody,
.tr {
  display: contents;
}

.td {
  padding: 10px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.th {
  color: var(--black);
  font-family: var(--font-semibold);
  font-weight: normal;
  border-bottom: 1px solid var(--gray-xl);
  padding: 10px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.tr:nth-child(even) .td {
  background: var(--gray-xl);
}