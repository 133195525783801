@value 800 from 'vars';

.root {

}

.wrap {
  padding: 40px 0;
}

.main {
  margin: 0 auto;
  max-width: 825px;
}

.header {
  margin-bottom: 25px;
}

.title {
  text-align: left;
}

.content {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 60px;
  padding: 40px 0;
}

@media 800 {
  .content {
    display: flex;
    flex-direction: row;
  }

  .content:nth-child(even) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.info {

}

.graphic {

}

.img {
  width: 375px;
}

.copy {
  max-width: 370px;
  padding-bottom: 15px;
}

.btn {

}

.paragraph {
  margin-bottom: 25px;
  line-height: 24px;
}

.em {
  font-family: var(--font-bold);
}