.title {
  margin-top: 30px;
}

.subtitle {
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

.btn {
  border-radius: 4px;
  padding: 5px;
  font-size: 14px;

  margin-left: 10px;
}