.number-modal {
  width: 440px;
}

.body {
  min-height: 100px;
}

.footer {
  display: flex;
  justify-content: center;
}

.submit {
  margin-left: 10px;
}

.question-dropdown {
  width: 200px;
  margin-right: 25px;
}

.row-dropdown {
  width: 200px;
  margin-right: 15px;
}

.operator-dropdown {
  width: 60px;
  margin-right: 25px;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.label {
  font-family: var(--font-semibold);
  width: 85px;
}