.root {
  --credit-size: 5px;
  display: inline-block;
}

.container {
  display: inline-block;
  margin-right: 2px;
  width: 20px;
  height: 20px;
}

.container:last-child {
  margin-right: 0;
}

.rotated {
  transform: translateZ(0) perspective(999px) rotate(45deg);
  border: 2px solid var(--azure);
  border-radius: 2px;
  display: inline-block;
  position: relative;
  left: 3px;
  top: 1px;
 }
 
 .credit-1 {
  border-right: var(--credit-size) solid transparent;
  border-top: var(--credit-size) solid transparent;
  border-left: var(--credit-size) solid var(--azure);
  border-bottom: var(--credit-size) solid transparent;
 }

 .credit-2 {
   composes: credit-1;
   border-bottom: var(--credit-size) solid var(--azure);
 }

 .credit-3 {
  composes: credit-2;
  border-right: var(--credit-size) solid var(--azure);
 }

  .credit-4 {
  composes: credit-3;
  border-top: var(--credit-size) solid var(--azure);
 }

 .label {
   color: var(--azure);
   font-family: var(--font-semibold);
   font-size: 16px;
 }