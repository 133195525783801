.icon {
  background-color: var(--gray-l);
  border: 1px solid var(--gray-m);
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 50px;
  width: 50px;
}

.icon-off {
  background-color: var(--pri-01);
  border: 1px solid var(--gray-l);
}

.disabled {
  composes: icon-off;
  cursor: not-allowed;
  opacity: .8;
}