.root {

}

.wrap {

}

.main {

}

.add,
.add:hover {
  background-color: var(--hyperlink);
  border-color: var(--hyperlink);
  height: 30px;
  width: 130px;
  color: var(--pri-01);
  font-family: var(--font-semibold);
  padding: 0;
}

.schedule,
.schedule:hover {
  background-color: var(--pri-02);
  border-color: var(--pri-02);
  height: 30px;
  width: 130px;
  color: var(--pri-01);
  font-family: var(--font-semibold);
  padding: 0;
}

.accordion {
  min-height: 50px;
}

.accordion {
  border-bottom: 1px solid var(--sec-01);
}

.label {
  font-family: var(--font-reg);
  color: var(--black-l);
  padding: 0 10px;
}

.value {
  color: var(--black-l);
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  font-family: var(--font-reg);
  color: var(--black-l);
  padding: 0 10px;
}

.item {
  border-bottom: 1px solid var(--gray-l);
}

.range {
  width: 200px;
}