.root {

}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 40px;
}

.icon {
  color: var(--gray-l);

}

.icon svg {
  height: 75px;
  width: 75px;
}

.copy {
  color: var(--gray-d);
  font-size: 24px;
  font-family: var(--font-semibold);
  line-height: 40px;
}

.back {
  margin: 20px 0 0 0;
}