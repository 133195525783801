.root {
  max-width: 880px;
  margin: 0 auto;
  margin-top: 15px;
  padding: 0 10px 40px;
}

.header {
  font-family: var(--font-semibold);
  font-size: 28px;
  color: var(--black-l);
  margin-bottom: 25px;
  text-align: center;
}

.embed {
  height: 700px;
  width: 100%;
  margin: 20px auto;
}