.root {
  max-width: 600px;
  margin: 0 auto;
}

.wrap {
  padding: 25px 15px;
}

.card {
  border-radius: 4px;
  border: 1px solid var(--sec-01);
  padding: 30px 40px;
}

.title {
  font-family: var(--font-semibold);
  font-size: 20px;
  margin-bottom: 15px;
}

.message {
  margin-bottom: 20px;
  font-size: 14px;
}

.link {
  color: var(--pri-02);
  cursor: pointer;
}

.input {

}

.btn {
  width: 100%;
  margin-top: 15px;
}

.error {
  color: var(--pri-04);
  height: 20px;
  margin-top: 5px;
  font-size: 15px;
}