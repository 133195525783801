.root {
  display: flex;
  align-items: center;
  height: calc(100vh - var(--navbar-height));
}

.wrap {
  height: 500px;
  width: 350px;
}

.calendar {
  display: flex;
  justify-content: center;
  margin: 0 0 40px 0;
  height: 109px;
}

.text {
  color: var(--black);
  font-family: var(--font-semibold);
  text-align: center;
  line-height: 22px;
  margin: 0 0 80px 0;
}

.actions {
  display: flex;
  justify-content: center;
}

.btn {
  width: 150px;
}