.root {

}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
}

.main {

}

.header {
  text-align: left;
}

.btn {
  padding: 0 10px;
  height: 40px;
  min-width: 130px;
  border-radius: 4px;
}

.footer {
  padding-bottom: 30px;
}

.backfill-container {
  max-height: 400px;
  overflow-y: auto;
}

.backfill-table {
  text-align: left;
}

.backfill-table th, .backfill-table td {
  padding: 7px;
}

.modal {
  width: 100%;
  max-width: 750px;
}