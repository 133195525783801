.root {

}

.empty {
  text-align: center;
  padding: 35px 0;
}

.calendar {
  width: 100px;
  margin-bottom: 10px;
}

.text {
  font-family: var(--font-semibold);
  color: var(--gray-d);
}

.connect {
  margin-bottom: 20px;
}
