.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 10px;
  box-sizing: border-box;
}

.wrap {

}

.back {
  margin: 20px 0 10px 0;
}

@media (min-width: 850px) {
  .root {
    border: 1px solid var(--sec-01);
    border-radius: 20px;
    padding: 25px;
    width: 100%;
  }

  .wrap {
    max-width: 700px;
  }
}