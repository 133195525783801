.header {
  display: flex;
  justify-content: space-between;

  font-size: 20px;
  font-family: var(--font-semibold);
  color: var(--black);

  padding-bottom: 10px;
  line-height: 28px;
}