.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body {
  height: 225px;
  margin-bottom: 20px;
  text-align: center;
}

.image {
  height: 150px;
  margin-bottom: 15px;
}

.copy {
  max-width: 400px;
}

.footer {
  display: flex;
  justify-content: center;
}