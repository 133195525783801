.root {
  position: absolute;
  z-index: 4;
  background-color: var(--pri-01);
  background-clip: padding-box;
  border: 1px solid var(--gray-l);
  border-radius: 2px;
  box-shadow: 1px 1px 2px 1px rgba(238, 238, 238, 1);
}

.open {
  composes: root;
}

.closed {
  composes: root;
  display: none;
}

.arrow {
  position: absolute;
  top: -10px;
  right: 0;
  content: ' ';
  height: 0;
  width: 0;
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-bottom: solid var(--gray-l) 10px;
}

.arrow:after {
  position: absolute;
  top: 2px;
  right: -10px;
  content: ' ';
  height: 0;
  width: 0;
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-bottom: solid var(--pri-01) 11px;
}

.wrapper {
  overflow: hidden;
  border-radius: inherit;
}

.menu {
  background-color: var(--pri-01);
  border: 1px solid var(--gray-l);
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 6px 2px rgba(60,64,67,.15);
  background-clip: padding-box;

  margin-top: 5px;
}

.item {
  cursor: pointer;
  color: var(--black-l);
  padding: 10px 20px;
  white-space: nowrap;
  height: 20px;
  background-color: var(--pri-01);
}

.item:hover {
  color: var(--hyperlink);
  background-color: var(--blue-xl);
}

.item.disabled {
  color: var(--gray);
  background-color: var(--pri-01);
  cursor: not-allowed;
}

.item.parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px 10px 20px;
}

.chevron {
  color: var(--black-l);
}

.item:hover .chevron {
  color: var(--hyperlink);
}