@value 800 from 'vars';

.profile {
  border: 1px solid var(--sec-01);
  border-radius: 10px;
}

@media 800 {
  .profile {
    width: 268px;
  }
}

.profile:not(:last-child) {
  margin: 0 0 20px 0;
}

@media 800 {
  .profile:not(:last-child) {
    margin: 0 20px 0 0;
  }
}

.btns {
  display: flex;
}

.see-more {
  border: none;
}

.carousel {
  overflow-x: hidden;
}

.carousel-items {
  display: flex;
  align-items: center;
  transition: transform .2s ease-out;
}

.post {
  margin-right: 20px;
}

.post:last-of-type {
  margin-right: 0;
}