.info {
  position: relative;
  padding: 20px 0;
  margin-bottom: 15px;
}

.paper {
    display: flex;
    margin-right: 20px;
    color: var(--gray-d);
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  composes: border from '../style.css';
}

.subtitle {
  display: flex;
  composes: title from '../style.css';
}

.info-icon {
    margin: 6px 0 0 10px;
}

.row {
   composes: root from '../Fields/style.css';
}

.leftRow {
    composes: row;
    justify-content: flex-start;
}

.publicSubRow {
    display: flex;
}

.text {
    color: var(--acc-01);
    font-family: var(--font-reg);
    display: flex;
    font-size: 16px;
}

.subText {
    color: var(--acc-01);
    font-family: var(--font-01);
    display: flex;
    font-size: 16px;
    padding-left: 20px;
}

.dropdownText {
    font-size: 16px;
}

.dropdownContainer {
    margin-left: 10px;
    width: 130px;
}

.companyContainer {

}

.companyRow {

}

.companyRow:first-child {
    border-top: 1px solid var(--sec-01);
}

.company {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--sec-01);
    padding: 13px;
    font-family: var(--font-reg);
    font-size: 16px;
    color: var(--acc-01);
}

.editCompany {
    composes: company;
    display: flex;
    height: 30px;
}

.company:first-child {

}

.remove {
    display: flex;
    color: var(--black-l);
    font-family: var(--font-semibold);
    font-size: 14px;
    margin-right: 10px;
    cursor: pointer;
    padding: 10px;
    border-radius: 20px;
}

.remove:hover {
    background-color: var(--sec-01);
}

.autocomplete {
    width: 250px;
    margin-bottom: 20px;
}

.headerBtn {
    height: 35px;
    width: 35px;
    border-radius: 35px;
}

.editBtn {
    composes: headerBtn;
    border: 2px solid var(--pri-02);
    color: var(--pri-02);
}

.editBtn:hover {
    background-color: var(--pri-02);
    color: var(--pri-01);
}

.x {
    padding-top: 4px;
    padding-left: 1px;
}

.edit {
    margin-top: 2px;
    margin-left: 1px;
}

.headerRowLeft {
    display: flex;
    align-items: center;
}

.headerRowRight {
    display: flex;
    align-items: center;
}