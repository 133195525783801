.root {
  margin: 30px 0 0 0;
  min-height: 200px;
}

.row {
  composes: row from './Shared.css';
}

.dial-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dial-title svg {
  margin: 0 8px 0 0;
  height: 20px;
  width: 20px;
}