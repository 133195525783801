@value 800 from 'vars';

.field {
  margin-top: 40px;
  width: 90%;
  position: relative;
}

@media 800 {
  .field {
    width: 100%;
    width: 320px;
  }
}

.field:first-child {
  margin-top: 10px;
}

.input {
  composes: input from 'css/input.css';
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.inline {
  width: 47%;
}

.invalid {
  background-color: var(--sec-04);
}

.company-input {
  composes: input;
  width: 90%;
}

@media(max-width: 425px) {
  .company-input {
    width: 100%;
  }
}

.company-info-wrap {
  position: absolute;
  right: 2px;
  bottom: 0;
}

@media(max-width: 425px) {
  .company-info-wrap {
    display: none;
  }
}

.company-info {
  fill: #F5F5F5;
  stroke: var(--gray);
}