.root {
  border-bottom: 1px solid var(--sec-01);
}

.call {
  display: flex;
  padding: 20px;
}

.call:last-child {
  border-bottom: none;
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.name {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--black-l);
  font-size: 16px;
  font-family: var(--font-reg);
  margin-left: 15px;
}

.name a:hover {
  color: var(--pri-02);
  text-decoration: underline;
}

.date {
  font-size: 13px;
  font-family: var(--font-reg);
  color: var(--gray-d);
  margin-bottom: 8px;

  display: flex;
  align-items: center;
  height: 20px;
}

.notes {
  font-family: var(--font-reg);
  color: var(--gray-d);
  font-size: 14px;
  white-space: pre-wrap;
  padding: 15px 10px;
  border-top: 1px solid var(--gray-xl);
}

@media (max-width: 425px) {
  .notes {
    padding: 0 0 20px 0;
  }
}

.link {
  margin-bottom: 8px;
}

.link:hover {
  text-decoration: underline;
}

.arrow {
  cursor: pointer;
  display: flex;
}

.expand {
  padding: 0 20px;
}

@media (min-width: 800px) {
  .expand {
    padding: 0 20px;
  }
}

.notes-title {
  font-size: 14px;
  font-family: var(--font-semibold);

  margin-bottom: 10px;
}

.hide {
  display: none;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.icon {
  margin-right: 10px;
  color: var(--gray);
  width: 20px;
}

.icon-rating {
  margin-right: 10px;
}

.icon-tooltip {
  display: flex;
}

.transcript {
  border-top: 1px solid var(--gray-xl);
  padding: 15px 0;
}

.recording {
  border-top: 1px solid var(--gray-xl);
}

.is-processing {
  display: flex;
  align-items: center;
  color: var(--gray-d);
  font-size: 14px;
}

.spinner {
  margin-right: 6px;
}

.notes-entry {
  padding: 5px 0px;
}

.notes-entry:not(:first-child) {
  border-top: 1px solid var(--gray-xl);
}

.notes-user {
  margin-bottom: 2px;
}

.recording-col {
  align-self: self-start;
}

.downloader {
  color: var(--gray);
  width: 20px;
}

.status {
  width: 180px;
}