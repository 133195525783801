.root {
  position: relative;
}

.menu {
  position: absolute;
  top: 10px;
  right: 0;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.label {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  margin-right: 8px;
  min-width: 52px;
}

.conditions {
  margin: 0 15px;
}

.condition {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid var(--sec-01);
}

.condition:first-of-type {
  padding-top: 10px;
}

.condition:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}