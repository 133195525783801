.root {
  display: inline-block;
  position: relative;
  width: 100%;

  --border-color: #F5F5F5;
  --focused-border-color: #979797;
  --color: #323232;
  --focused-color: #ADADAD;
}

.input {
  width: 100%;
  border: 1.5px solid var(--gray-l);
  box-sizing: border-box;
  border-radius: 4px;

  font-family: var(--font-reg);
  font-size: 16px;
  line-height: 19px;

  padding: 15px 12px;

  color: var(--color);
}

.input:focus {
  border: 1.5px solid var(--focused-border-color);
}

.input::placeholder {
  color: var(--focused-color);
}