.root {
  cursor: pointer;
  display: inline-flex;
}

.icon {
  fill: #f5f5f5;
  stroke: var(--gray);
  height: 20px;
  width: 20px;
  margin-right: 14px;
}

.text {
  color: var(--black);
  font-size: 14px;
}