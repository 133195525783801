.root {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.loader {
  display: inline-block;
  height: 15px;
  width: 15px;
  background-color: var(--gray);
  border-radius: 50%;
  margin: 0px 7px;
  animation: inflate 1.7s infinite ease-in-out both;
}

.first {
  composes: loader;
  animation-delay: -0.16s;
}

.second {
  composes: loader;
}

.third {
  composes: loader;
  animation-delay: 0.16s;
}

@keyframes inflate {
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.spinner {
  animation-name: clockwise;
  animation-iteration-count: infinite;
  animation-duration: 1.1s;
  animation-timing-function: linear;
  color: var(--pri-02);
}

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes clockwise {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}