.root {

}

.wrap {
  padding: 20px 0;
}

.main {

}

.thead {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.thead .tr {
  border-top: none;
  border-bottom: 1px solid var(--gray-l);
}

.td {
  font-size: 14px;
}

.link:hover {
  color: var(--hyperlink);
  text-decoration: underline;
}