.right {
  width: 0;
  opacity: 0;
  overflow: hidden;
  transition: width 0.2s ease-in;
}

.open {
  border-left: 1px solid var(--gray-l);
  opacity: 1;
  transition: width 0.2s ease-in-out,
              opacity 0.4s ease-in-out;
}

.info,
.participants {
  padding: 20px 16px;
  height: calc(100% - 40px);
  overflow-y: auto;
}

.chat {
  padding: 20px 0 0 0;
  height: calc(100% - 20px);
}

/* For Right Content */
.icon {
  color: var(--gray-d);
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.active {
  background-color: var(--gray-l);
}

@media (min-width: 800px) {
  .icon:hover {
    background-color: var(--gray-l);
  }  
}

.badge {
  position: absolute;
  bottom: 2px;
  right: -2px;
  border-radius: 50%;
  min-width: 12px;
  padding: 3px;
  background-color: var(--gray-d);
  color: var(--pri-01);
  border: 2px solid var(--pri-01);
  font-size: 12px;
  text-align: center;
  font-family: var(--font-bold);
  line-height: 12px;
  box-shadow: 2px 2px 3px 0px rgba(238, 238, 238);
}
/* For Right Content */

.header {
  color: var(--black);
  font-size: 20px;
  font-family: var(--font-bold);
  margin: 0 0 8px;
}
