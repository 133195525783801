.root {
  margin: 0 auto;
  width: 100%;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.header {
  font-size: 24px;
  font-family: var(--font-semibold);
  border-bottom: 1px solid var(--gray-l);
  width: 100%;
}

.title {
  padding: 30px;
}