.active {
  composes: active from '../style.css';
}

.icon {
  composes: icon from '../style.css';
  height: 40px;
  width: 40px;
}

.icon svg {
  height: 32px;
  width: 32px;
}