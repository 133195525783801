.root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
}

.content {
  margin: auto;
}

.strip-participant {
  height: 100%;
  width: auto;
}