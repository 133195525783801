.root {

}

.wrap {
  width: 300px;
  padding: 15px;
}

.popper {
  background-color: var(--pri-01);
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 1px 1px 8px 1px var(--gray-l);
  z-index: 4;
}

.trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  padding: 6px;
}

.trigger:hover {
  background-color: var(--gray-xl);
}

.select {
  width: 100%;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.row:last-child {
  margin-bottom: 0;
}

.label {
  color: var(--gray-d);
  font-family: var(--font-semibold);
  min-width: 80px;
}

.readonly {
  color: var(--gray-d);
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn {
  width: 100%;
  border-radius: 4px;
  height: 45px;
  transition: background-color 120ms ease-in-out,
              border 120ms ease-in,
              border-color 120ms ease-in,
              color 120ms ease-in;
}

.field {
  width: 175px;
}

.amount {
  line-height: 32px;
  padding-bottom: 0;
}

.clipboard {
  composes: readonly;
  border: none;
  font-size: 16px;
}

.check {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-right: 20px;
}