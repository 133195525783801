.wrap {
  padding: 0 40px;
}

.header {
  text-align: left;
}

.textarea {
  border: 2px solid var(--gray-l);
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;
  resize: none;
  font-family: var(--font-reg);
  font-size: 16px;
  outline: none;
  padding: 5px;

  appearance: none;
}

.msg {
  margin: 20px 0 25px 0;
}

.text {
  color: var(--gray-d);
}

.btn {
  width: 150px;
}

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.paid {
  color: var(--gray-d);
  font-family: var(--font-reg);
  font-size: 15px;
  margin: 0 0 0 6px;
}

.setup {
  cursor: pointer;
  color: var(--black);
  font-family: var(--font-reg);
  font-size: 15px;
  margin: 4px 0 0 0;
}

.setup:hover {
  color: var(--pri-02);
  text-decoration: underline;
}