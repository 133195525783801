.readonly p {
  margin: 0;
}

.icon {
  color: var(--gray);
}

.active {
  color: var(--black);
}

.blockQuote,
.bold,
.code,
.italic,
.bulleted-list,
.numbered-list,
.h1,
.h2,
.blockQuote svg,
.bold svg,
.code svg,
.italic svg,
.bulleted-list svg,
.numbered-list svg,
.h1 svg,
.h2 svg {
  height: 24px;
  width: 24px;
}

.underline,
.underline svg {
  height: 22px;
  width: 22px;
}