.root {
  padding: 15px;
}

.buttonset {

}

.btn {
  composes: brick from 'css/button.css';
}

.cancel {
  composes: btn;
  color: var(--gray-d);
  border: 2px solid var(--gray-d);
}

.end {
  composes: btn;
  color: var(--pri-01);
  background-color: var(--pri-04);
  border: 1px solid var(--pri-04);
}

.end:hover {
  background-color: var(--sec-04);
  border: 1px solid var(--sec-04);
}