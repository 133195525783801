.root {
  position: relative;
}

.wrap {
  position: relative;
  width: 100%;
}

.clear {
  position: absolute;
  top: 12px;
  right: 50px;
  color: hsl(0, 0%, 80%);
  transition: all 200ms;
}

.clear:hover {
  color: hsl(0, 0%, 60%);
}

.clear svg {
  width: 20px;
  height: 20px;
}

.clear:disabled {
  color: transparent;
  cursor: default;
}

.t {
  position: absolute;
  bottom: 0;
  left: 2px;
  width: 100%;
  opacity: 0;
  z-index: -1;
  transition: z-index 400ms ease 200ms;
  height: 100%;
}

.cover {
  z-index: 1;
  opacity: 1;
  background-color: var(--pri-01);
}

.i {
  display: flex;
  align-items: center;
  height: 100%;
}

.cover.value {
  transition: opacity 400ms ease 200ms;
}

.cover.placeholder {
  transition: opacity 500ms cubic-bezier(0, 0, 0.2, 1) 5000ms;
}

.cover.placeholder .i {
  color: var(--gray);
}