.item {
  margin-bottom: 10px;
}

.item:last-child {
  margin-bottom: 0;
}

.attribute {
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

.level {
  margin-bottom: 10px;
}

.level:last-child {
  margin-bottom: 0;
}

.nota {
  margin-top: 10px;
  font-family: var(--font-semibold);
}