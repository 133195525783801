@value 1100 from 'vars';

.hidden {
  composes: hidden from './Messages.css';
}

.visible {
  composes: visible from './Messages.css';
}

@media 1100 {
  .root {
    composes: shadow from './Messages.css';
    border-left: none;
    border-top: none;
    border-bottom: none;
    max-width: 350px;
  }
}

@media (min-width: 1260px) {
  .root {
    border-left: 1px solid var(--sec-01);
  }
}