@value 800, 1200 from 'vars';

.root {

}

.player {

}

.title {
  font-size: 20px;
  font-family: var(--font-semibold);
  margin: 10px 0;
  color: var(--black-l);
}

.video {
  background-color: var(--black-d);
  border-radius: 4px;
  object-fit: cover;
}

@media 1200 {
  .video {
    width: 100%;
    max-width: 100%;
    height: 319.5px;
  }
}