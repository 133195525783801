.root {
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.drop-down {
  max-width: 200px;
}

.inline-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.inline-row .settings-title {
  margin-bottom: 0px;
}