.row {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 10px;
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.submit {
  margin-left: 10px;
}

.images {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.image {
  height: 80px;
}