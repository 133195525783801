@value 1200 from 'vars';

.root {
  max-width: var(--navbar-width);
  width: 100%;
  margin: 0 auto;
}

.wrap {
  padding: 20px 10px;
}

.main {
  display: flex;
  gap: 40px;
}

.table {
  width: 100%;
}

.box {
  border-color: var(--sec-01);
  border-radius: 4px;
}

.sidebar {
  display: none;
}

@media 1200 {
  .sidebar {
    display: block;
    min-width: 300px;
    flex: 1;
  }
}

.body {
  max-height: calc(100vh - (var(--navbar-height) + 40px + 60px + 40px + 50px));
  height: calc(100vh - (var(--navbar-height) + 40px + 60px + 40px + 50px));
}

.td {
  height: 55px;
  font-size: 14px;
}

.sortable {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title {
  margin-right: 10px;
}

.btn {
  width: 150px;
  height: 40px;
  padding: 0;
}

.workspaces {
  margin-bottom: 20px;
}