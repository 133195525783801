.root {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.avatar {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  transition: opacity .15s ease-in;
}

.default {
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-link {
  display: flex;
}

.hidden {
  opacity: 0;
}

.editable {
  position: relative;
  display: inline-block;
}

.edit {
  position: absolute;
  right: 0;
  bottom: 0;
}