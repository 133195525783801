
.feedback {
  display: flex;
  align-items: center;
}

.ratings {
  display: flex;
  align-items: center;
}

.notes {
  margin-right: 10px;
  cursor: pointer;
  color: var(--gray-d);
}

.notes:hover {
  color: var(--pri-02);
}

.active {

}

.rotate {
  transform: rotate(90deg);
}

.active {
  color: var(--pri-02);
  fill: var(--blue-l);
}

.inactive {
  cursor: pointer;
}

.inactive:hover {
  color: var(--pri-02);
}

.users {
  color: var(--gray-d);
  cursor: pointer;
}

.users:hover {
  color: var(--pri-02);
}

.view-feedback {
  cursor: pointer;
  color: var(--pri-02);
  font-size: 15px;
}

.divider {
  min-height: 100%;
  width: 4px;
  background-color: var(--gray-l);
  margin: 0 2px;
}