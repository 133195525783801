@value 800, 900, 1100 from 'vars';

.root {
  border-top: 1px solid var(--gray-xl);
}

.wrap {

}

.content {
  max-width: 1280px;
  margin: 0 auto;
  padding: 40px 20px;
}

@media 800 {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 600px;
  }
}

.main {
  max-width: 400px;
  margin: 40px auto;
}

@media 800 {
  .main {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin: unset;
    max-width: unset;
  }
}

.items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto 40px;
  max-width: 450px;
}

@media 800 {
  .items {
    max-width: unset;
    margin: 0;
  }
}

.header {

}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
  height: 100px;
  margin-bottom: 20px;
}

@media 800 {
  .item {
    margin-bottom: 40px;
    justify-content: flex-start;
  }
}

@media 1100 {
  .item {
    margin-bottom: 60px;
  }
}

.icon {
  margin-bottom: 10px;
}

@media 1100 {
  .icon {
    width: 80px;
  }
}

.text {
  font-family: var(--font-semibold);
  color: var(--black-l);
}

@media 900 {
  .text {
    font-size: 18px;
  }
}

.info {

}

@media 800 {
  .info {
    min-width: 425px;
  }
}

.title {
  font-size: 30px;
  color: var(--black-d);
  font-family: var(--font-bold);
  margin-bottom: 30px;
}

@media 800 {
  .title {
    font-size: var(--font-size-header);
    max-width: 410px;
  }
}

@media 1100 {
  .title {
    max-width: 400px;
  }
}

.subtitle {
  font-size: 20px;
  color: var(--black-l);
  margin-bottom: 50px;
  max-width: 370px;
}

@media 800 {
  .subtitle {
    max-width: 360px;
  }
}

@media 1100 {
  .subtitle {
    max-width: 340px;
  }
}