@value 1100 from 'vars';

.root {
  width: 100%;
  overflow-y: scroll;
}

.items {
  margin: 0 auto;
  max-width: 885px;
}

@media 1100 {
  .items {
    margin: 0;
  }
}

.item {
  width: 100%;
  height: 200px;
  border-bottom: 1px solid var(--gray-xl);
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}