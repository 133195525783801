@value 800, 1100, 1200 from 'vars';

.root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 0 20px;
}

@media 1100 {
  .root {
    padding-bottom: 40px;
  }
}

.wrap {
  background-image: linear-gradient(180deg, var(--blue-xl) 0%, var(--blue-xl) 63%, var(--pri-01) 100%);
}

@media 800 {
  .wrap {
    background-image: none;
    padding: 20px;
    position: relative;
  }
}

@media 1100 {
  .wrap {
    padding: 40px 20px;
  }
}

.bg {
  background-image: url('static/images/website/collaboration-tools/bg-dots-blue-horizontal-s.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 40px 20px 50px;
}

@media 800 {
  .bg {
    background-color: var(--blue-xl);
    background-image: none;
    padding: 20px 40px;
    border-radius: 10px;
  }
}

@media 1100 {
  .bg {
    max-width: 1060px;
    margin: 0 auto;
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media 1200 {
  .bg {
    max-width: 1110px;
  }
}

@media 800 {
  .fill {
    position: absolute;
    background-image: url('static/images/website/collaboration-tools/bg-dots-blue-horizontal-m.png');
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 2;
  }
}

@media 1100 {
  .fill {
    background-image: url('static/images/website/collaboration-tools/bg-dots-blue-horizontal-l.png');
    background-size: contain;
  }
}

.main {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  z-index: 3;
}

.header {
  font-size: 28px;
  font-family: var(--font-bold);
  color: var(--black-d);
  max-width: 400px;
  margin: 0 auto 40px;
  text-align: center;
}

@media 800 {
  .header {
    max-width: 610px;
  }
}

@media 1100 {
  .header {
    font-size: 30px;
  }
}

.btn {
  width: 150px;
}