.root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap {
  padding: 20px;
}

.content {
  display: flex;
  justify-content: flex-start;
  font-family: var(--font-reg);
  color: var(--black);
}