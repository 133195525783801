.label {
  /* font-family: var(--font-semibold); */
  margin-bottom: 10px;
  margin-top: 25px;
  padding-top: 20px;
  color: var(--black-l);
  border-top: 1px solid var(--gray-l);
}

.input {
  max-width: 550px;
  height: auto;
}