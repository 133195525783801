.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body {
  height: 225px;
  margin-bottom: 20px;
  overflow-y: auto;
}

.table {
  border-collapse: collapse;
}

.table th {
  background-color: var(--sec-01);
  font-family: var(--font-semibold);
  color: var(--black-l);
  font-size: 14px;
}

.name {
  text-align: left;
  width: 425px;
  box-sizing: border-box;
  padding: 6px;
}

.transcribe {
  text-align: center;
  width: 55px;
  box-sizing: border-box;
  padding: 6px;
}

.checkbox {

}

.footer {
  display: flex;
  justify-content: center;
}