.root {
  background-color: var(--pri-01);

  max-height: 250px;
  overflow-y: auto;

  box-sizing: border-box;
  border: 1px solid var(--gray-xl);
}

.open-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.open-bottom {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.option {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--pri-02);
  font-family: var(--font-bold);
  cursor: pointer;

  height: 50px;
  box-sizing: border-box;
  padding: 0 20px;
}

.option:hover {
  background-color: var(--blue-l);
}