.video {
  pointer-events: none;
  visibility: hidden;
}

.video::-webkit-media-controls-enclosure {
  display: none;
}

.video::-webkit-media-controls {
  visibility: hidden;
}

.img {
  width: 100%;
  height: auto;
}

.hidden {
  visibility: hidden;
}