.root {
  position: fixed;
  right: 20px;
}

.wrap {
  height: 100px;
  width: 300px;
  background-color: var(--pri-01);
  border: 1px solid var(--sec-01);
  border-radius: 5px;
  overflow: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.info {
  width: 100%;
}

.title {
  font-family: var(--font-reg);
  color: var(--gray-m);
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

.percent {
  font-family: var(--font-reg);
  font-size: 21px;
  color: var(--pri-02);
}

.bar {
  position: relative;
  height: 9px;
  width: 100%;
}

.progress {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--pri-02);
  transition: width 0.3s ease;
}