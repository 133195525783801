.root {

}

.wrap {

}

.main {

}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.field {
  --width: 260px;
  --padding-x: 13px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.outline {
  width: var(--width);
  height: 35px;
  outline: 1px solid var(--gray-l);
  border-radius: 4px;
}

.outline:focus-within {
  outline: 1px solid var(--pri-02);
}

.input {
  border: none;
  padding: 0 var(--padding-x);
  width: calc(var(--width) - (2 * var(--padding-x)));
  font-family: var(--font-reg);
  height: 100%;
  font-size: 16px;
}

input::placeholder {
  color: var(--black);
}

.select {
  width: var(--width);
}

.label {
  font-size: 15px;
  font-family: var(--font-reg);
  color: var(--gray-d);
  display: flex;
  align-items: center;
}

.btn {
  border-radius: 4px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.snippet .actions,
.snippet .main {
  display: flex;
  justify-content: center;
}

.snippet .main {
  padding-bottom: 30px;
}

.tooltip-icon {
  color: var(--pri-03);
  height: 14px;
}