.root {
  display: flex;
  justify-content: flex-end;

  padding: 10px;
}

.pagination {

}

.page-size {
  display: flex;
  margin-right: 25px;
}