.root {

}

.wrap {
  max-width: 700px;
  margin: 0 auto;
}

.main {
  padding-bottom: 40px;
}

.header {
  padding-bottom: 25px;
}

.title {
  color: var(--black-l);
  font-family: var(--font-bold);
  font-size: 24px;
  margin-bottom: 40px;
}

.subtitle {
  font-size: 20px;
  color: var(--black-l);
  font-family: var(--font-reg);
  margin-bottom: 40px;
}

.email {
  display: flex;
  margin-bottom: 10px;
}

.message {
  margin-bottom: 10px;
}

.break {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 0;
  margin: 0 0 15px 0;
}

.line {
  height: 1px;
  width: 100%;
  background-color: var(--sec-01);
}

.sub {
  color: var(--black-l);
  font-family: var(--font-semibold);
  font-size: 15px;
  margin: 0 10px;
}

.label {
  font-family: var(--font-bold);
  color: var(--black-l);
  font-size: 15px;
}

.import {
  display: flex;
}

.import .label {
  font-family: var(--font-reg);
  margin-right: 10px;
  font-size: 14px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.navigation {
  display: flex;
  justify-content: space-between;
  padding: 60px 0 50px;
}

.back {
  color: var(--black-l);
}

.link {
  font-family: var(--font-semibold);
  color: var(--hyperlink);
}

.link::after {
  margin-left: 5px;
  content: '>';
}