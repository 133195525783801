@value 800 from 'vars';

.root {
  padding: 10px 15px 20px 15px;
  margin: 0 15px 0 15px;
}

@media 800 {
  .root {
    padding: 0;
    margin: 0 0 20px 0;
  }
}

.wrap {
  max-width: 600px;
}

.see-more {
  border: none;
}

.create {
  padding: 0;
}

.post,
.article {
  padding: 0;
}

.empty {
  padding: 25px 0;
  text-align: center;
  color: var(--gray-m);
  font-family: var(--font-semibold);
  font-size: 18px;
}