.root {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--pri-03);
  cursor: pointer;
}

.root:hover .check {
  background-color: var(--pri-03);
  color: var(--pri-01);
}

.check {
  padding: 5px;
  border-radius: 50%;
  transition: all 0.2s ease;
  color: var(--pri-03);
  border: 2px solid var(--pri-03);
}

.disabled {
  color: var(--gray-d);
  cursor: not-allowed;
  opacity: 0.5;
}

.disabled:hover .check {
  background-color: var(--gray-d);
  color: var(--pri-01);
}

.disabled .check {
  border: 2px solid var(--gray-d);
  color: var(--gray-d);
}