.root {

}

.wrap {

}

.main {
  margin: 0 auto;
  max-width: 500px;
  padding: 20px 0 40px;
  min-height: 300px;
}

.subtitle {
  margin-bottom: 25px;
}

.input {
  margin-bottom: 30px;
}

.label {
  margin-bottom: 10px;
  font-family: var(--font-bold);
  color: var(--font-black-l);
}

.footer {
  padding: 20px 0;
}

.btn {
  min-width: 180px;
  border-radius: 4px;
}

.option {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 40px;
}

.name {

}

.loader {
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
}