.root {
  max-width: var(--navbar-width);
  margin: 0 auto;
}

.wrap {
  padding: 25px 15px;
}

.nav {
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 0 auto;
}

@media 800 {
  .nav {
    padding: 0 15px;
    max-width: var(--navbar-width);
  }
}