.placeholder {
  color: var(--gray-d);
  min-height: 100px;
  padding-top: 25px;

  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.row-header, .option {
  display: flex;
  align-items: center;
  padding: 15px 25px;
  border-bottom: 1px solid var(--gray-xl);
  justify-content: space-between;
}

.option {
  cursor: pointer;
  padding: 10px 25px;
}

.option:hover {
  background-color: var(--blue-xl);
}

.option:hover .view-all {
  color: var(--pri-02);
}

.row-header .left, .option .left {
  display: flex;
  align-items: center;
}

.row-header .label, .option-label {
  font-family: var(--font-semibold);
  color: var(--gray-d);
}

.row-header .subtitle, .option .subtitle {
  color: var(--gray-m);
  margin-left: 5px;
}