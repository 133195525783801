.buttons {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
}

.term-row {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 5px;
}