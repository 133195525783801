.bold {
  font-family: var(--font-bold);
}

.section {
  font-family: var(--font-bold);
  color: var(--gray-d);
}

.bordered {
  width: 160px;
  border: 1px solid var(--gray-l);
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
}

.aql {
  color: var(--gray-d);
  width: 180px;
}