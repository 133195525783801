.root {
  --rmr-color-border: transparent;
  --rmr-color-outline: transparent;
  --rmr-space-3: 0;
  height: 100%;
  --max-width: 775px;
  --padding-x: 20px;
}

.wrap {
  padding: var(--padding-x) var(--padding-x) 40px;
  height: 100%;
}

.main {
  max-width: var(--max-width);
  margin: 0 auto;
  height: 100%;
}

.header {

}

.root a {
  color: var(--pri-02);
}

.root p[data-placeholder]:first-of-type::before {
  font-style: inherit;
}

.root :global(.remirror-theme) {
  height: calc(100vh - var(--navbar-height));
}

.root :global(.remirror-editor-wrapper),
.root :global(.ProseMirror.remirror-editor) {
  min-height: 450px;
}