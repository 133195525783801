.root {
  max-width: 460px;
  padding: 0 20px 20px;
}

.wrap {
  max-width: 370px;
  margin: 0 auto;
}

.body {
  color: var(--gray-d);
  margin-bottom: 20px;
}

.btn {
  width: 100%;
}

.submitted {
  padding: 20px 0;
}