.root {

}

.wrap {

}

.main {

}

.commands {
  display: flex;
}

.publish {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 90px;
}

.submit {
  padding: 0;
  min-width: 75px;
  border-radius: 4px;
  font-size: 14px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 38px;
  height: 40px;
  overflow: hidden;
  color: var(--gray-d);
}

.enabled {
  background-color: var(--gray-d);
  color: var(--pri-01);
}

.btn:hover {
  background-color: var(--gray-xl);
  color: var(--black-l);
}

.btn:active {
  background-color: var(--pri-02);
}

.btn:disabled,
.btn:disabled:hover {
  color: var(--gray-l);
  background-color: var(--sec-01);
  cursor: default;
}

.icon {

}

.menu {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 80px;
  box-sizing: border-box;
  border: 1px solid var(--gray-l);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 5px 1px inset;
  overflow: hidden;
  background-color: var(--pri-01);
  z-index: 1;
  width: 100%;
  max-width: calc(100% - (var(--padding-x) * 2));
}

@media (min-width: 815px) {
  .menu {
    max-width: var(--max-width);
  }
}

.input {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.heading {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 150px;
  transition: background-color 180ms ease-in-out,
              border 120ms ease-in,
              border-color 120ms ease-in,
              color 120ms ease-in;
}

.heading :global(.MuiListItem-button):hover {
  background-color: initial;
}

.heading:hover {
  background-color: var(--gray-xl);
}

.btn:hover {
  background-color: var(--gray-xl);
}

.indicator {
  display: flex;
  align-items: center;
}

.h1 {
  font-family: var(--font-bold);
}

.h2 {
  font-family: var(--font-semibold);
}