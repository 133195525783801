.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  font-size: 14px;

  box-sizing: border-box;
}

.file {
  display: flex;
  align-items: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  margin-right: 10px;
}

.content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file .name {
  color: var(--gray-d);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitle {
  font-size: 12px;
  margin-top: 2px;
  color: var(--pri-04);
  font-style: italic;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.icon {
  margin-right: 10px;
  display: flex;
  flex-shrink: 0;
}

.complete {
  composes: circle;
  background-color: var(--pri-03);
}

.check {
  color: var(--pri-01);
  height: 20px;
  width: 20px;
}

.abort {
  composes: circle;
  cursor: pointer;
  background-color: var(--gray-m);
  display: none;
}

.x {
  color: var(--pri-01);
  height: 18px;
  width: 18px;
}

.failed {
  composes: circle;
  box-sizing: border-box;
  border: 3px solid var(--pri-04);
  color: var(--pri-04);
  font-family: var(--font-bold);
}

.aborted {
  composes: failed;
  border: 3px solid gold;
  color: gold;
  cursor: default;
}

.progress {
  
}

.status:hover .abort {
  display: flex;
}

.status:hover .progress {
  display: none;
}