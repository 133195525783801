.autocomplete {
  position: relative;
  height: 40px;
  width: 350px;
  margin-bottom: 20px;
}

.team {
  display: flex;
  align-items: center;

  margin-bottom: 20px;
}

.team .name {
  font-family: var(--font-semibold);
  /* font-size: 18px; */
  margin-right: 15px;
}