.root {
  max-width: var(--navbar-width);
  width: 100%;
  margin: 0 auto;
}

.wrap {
  padding: 20px 10px;
}

.main {
  display: flex;
  gap: 40px;
}

.table {
  width: 100%;
}

.header {
  border-bottom: 1px solid var(--gray-l);
  border-radius: 4px;
}

.group {
  max-width: 225px;
}

.link {
}