.select {
  padding-top: 30px;
  width: 300px;
  min-height: 360px;
}

@media (max-width: 425px){
  .select {
    padding-top: 50px;
  }
}