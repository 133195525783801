.player {
  /* width: 400px; */
  margin-bottom: 20px;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.footer > *:first-child {
  margin-right: 10px;
}