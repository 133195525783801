
.entities {
  padding: 15px 0 0;
}

.entities .row {
  display: flex;
  justify-content: space-between;
}

.entities .row .col {
  width: 30%;
}

.highlights {
  padding: 25px 0 0;
}

.highlights .row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 0 0 20px;
}

.highlights .row .timestamp {
  min-width: 130px;
}

.highlights .row .tag {
  min-width: 130px;
}

.highlights .row .text {
  width: 100%;
}

.summary {

}

.summary .row {
  padding: 0 0 15px;
}

.transcript {
  padding: 15px 0 0;
}

.transcript .row {
  padding: 0 0 15px;
}

.project.entities {
  margin-top: 100px;
}

.project.entities .row .col:nth-child(-n+2) {
  width: 25%;
}

.project.entities .row .col:nth-child(n+3):not(:last-child) {
  width: 20%;
}

.project.entities .row .col:last-child {
  width: 10%;
  display: flex;
  justify-content: center;
}