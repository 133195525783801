.root {

}

.title {
  composes: title from '../style.css';
  composes: border from '../style.css';
}

.main {
  margin: 20px 0;
}