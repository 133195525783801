.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid var(--gray-l);
  font-family: var(--font-semibold);

  box-sizing: border-box;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.close:hover {
  background-color: var(--gray-xl);
}