@value 800, 1000 from 'vars';

.root {

}

.wrap {
  padding: 40px 20px;
}

.main {
  max-width: 400px;
  margin: 0 auto;
}

@media 800 {
  .main {
    max-width: 700px;
    background-image: url('static/images/website/q2q/bg-dots-grey-bottom-right-l.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 410px;
  }
}

@media 1000 {
  .main {
    max-width: 1000px;
    width: 100%;
    background-size: 490px;
  }
}

.title {
  font-size: 22px;
  color: var(--black-d);
  font-family: var(--font-semibold);
  margin: 0 auto 25px;
  max-width: 280px;
}

@media 800 {
  .title {
    max-width: 100%;
  }
}

@media 1000 {
  .title {
    font-size: 28px;
  }
}

.text {
  font-size: 20px;
  color: var(--gray-d);
  margin: 0 auto;
  max-width: 280px;
}

@media 800 {
  .text {
    max-width: 100%;
  }
}

.item {

}

.row {
  width: 100%;
}

@media 800 {
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 900px;
    margin: 0 auto 30px;
  }

  .row:nth-child(odd) .item {
    flex-direction: row-reverse;
  }
}

.img {
  width: 100%;
  height: auto;
}

.box {

}

@media 800 {
  .box {
    width: 330px;
  }
}

@media 1000 {
  .box {
    width: 370px;
  }

  .row:last-child {
    background-image: url('static/images/website/q2q/bg-blob-dots-l.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 40%;
  }
}