@value 1100 from 'vars';

.root {
  border-bottom: 1px solid var(--gray-xl);
  font-size: 17px;
  padding: 0 25px;
}

.wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 60px;
  margin: 0 auto;
  max-width: 885px;
}

@media 1100 {
  .wrap {
    margin: 0;
  }
}

@media 1100 {
  .btn {
    display: none;
  }
}

.banner {
  background-color: var(--blue-l);
  font-family: var(--font-semibold);
  color: var(--black);
}