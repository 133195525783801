.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--black);
  font-size: 14px;
  line-height: 22px;
  width: 200px;
  position: relative;
}

.wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.icon {
  margin-right: 10px;
  width: 22px;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}