.root {

}

.body {
  margin-bottom: 15px;
}

.input {
  width: 600px;
}

.section {
  margin-bottom: 15px;
}

.field {
  composes: field from '@/access/admin/shared/Label/style.css';
}

.invalid {
  background-color: var(--sec-04);
}