.root {
  background: linear-gradient(90deg, rgba(40,170,225,1) 0%, rgba(92,93,167,1) 100%);
  width: 100%;
  padding: 30px 0;
}

.wrap {

}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  margin-bottom: 25px;
}

.img {
  width: 225px;
}

.title {
  color: var(--pri-01);
  margin-bottom: 0;
}

.message {
  font-family: var(--font-semibold);
  color: var(--pri-01);
  max-width: 600px;
  text-align: center;
  line-height: 24px;
  margin-bottom: 25px;
}

.cta {

}