.root {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 80px 0;
}

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 200px;
}

.message {
  margin: 0;
}

.btn {
  padding: 0;
  margin: 50px 0;
  color: var(--pri-02);
  font-size: 24px;
}

.text {
  font-family: var(--font-bold);
  color: var(--black-l);
  font-size: 24px;
}