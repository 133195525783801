.root {
  height: 100%;
}

.wrap {
  margin: 0 auto;
  max-width: var(--navbar-width);
  padding: 40px 10px;
  box-sizing: border-box;
}

.row {
  margin-bottom: 15px;
}

.label {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 26px;
  margin-bottom: 15px;
}

.label.editing {
  justify-content: space-between;
}

.text {
  font-family: var(--font-bold);
  color: var(--font-black);
}

.actions {

}

.title {
  font-size: 18px;
  color: var(--black);
  font-family: var(--font-semibold);
  line-height: 34px;
}

.edit {
  display: flex;
  justify-content: center;
}

.btn {
  color: var(--pri-02);
  cursor: pointer;
  width: 16px;
}