.root {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.title {
  font-family: var(--font-semibold);
  color: var(--gray-d);
}

.root > *:nth-child(2) {
  margin-left: 10px;
}