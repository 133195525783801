.file-input {
  display: none;
}

.upload {
  padding: 10px;
  border-radius: 5px;

  border: 2px solid var(--pri-02);
  background-color: var(--pri-02);
  color: var(--pri-01);

  font-family: var(--font-bold);
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  min-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: background-color 180ms ease-in-out, border 120ms ease-in, border-color 120ms ease-in, color 120ms ease-in;
}

.upload:hover {
  border: 2px solid var(--sec-02);
  background-color: var(--sec-02);
}