.notification {
  border: 1px solid var(--pri-04);
  background-color: var(--reddish-white);
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.notification-text {
  justify-self: left;
  flex-grow: 1;
}

.view-drafts {
  color: var(--pri-02);
  cursor: pointer;
}

.view-drafts:hover {
  text-decoration: underline;
}

.dismiss {
  cursor: pointer;
  color: var(--gray-m);
}

.dismiss:hover {
  color: black;
}

.draft-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.draft-table tr {
  border-bottom: 1px solid var(--gray-m);
}

.draft-table td {
  padding: 8px 4px;
}

.draft-row {
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.questions-cell {
  text-align: right;
  padding-right: 5px;
}

.button-cell {
  text-align: right;
}

.load-btn {
  margin-right: 5px;
}