.root {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.link {
  border:2px solid var(--gray-l);
  border-radius: 3px;
  width: calc(100% - 16px);
  padding: 8px;
  margin-bottom: 10px;

  font-size: 14px;
  color: var(--black);
  font-family: var(--font-reg);
}

.link::selection {
  background: transparent;
}

.btn {
  border-radius: 3px;
  width: 100%;
  padding: 0;
  height: 38px;
}


@media (min-width: 600px) {
  .root {
    flex-direction: row;
  }

  .link {
    width: 100%;
    margin: 0 10px 0 0;
  }

  .btn {
    font-size: 14px;
    width: 100px;
  }
}