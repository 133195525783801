.root {
  padding: 2px;
  border-radius: 4px;
  cursor: pointer;
}

.disabled {
  cursor: unset;
}


.color-one {
  background-color: #CCECF9;
}

.color-two {
  background-color: #F7C0CD;
}

.color-three {
  background-color: #E4F1CF;
}

.color-four {
  background-color: var(--reddish-white);
}

.color-five {
  background-color: var(--greenish-white);
}

.color-six {
  background-color: #DCDCF8;
}

.color-seven {
  background-color: #C6F5F5;
}