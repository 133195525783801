.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.root.editable {
  padding-right: 35px;
}

.add-good-quote, .add-tags {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-semibold);
}

.star, .plus {
  color: var(--gray-d);
}

.form {
  width: 275px;
}

.form-buttons {
  margin-top: 10px;
}

.tags-select {
  width: 100%;
}

.tag {
  cursor: pointer;
}