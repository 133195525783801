.root {

}

.wrap {

}

.main {

}

.checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 40px;
}

.message {

}

.footer {
  padding: 20px 0 0;
}

.btn {
  min-width: 140px;
}

.modal {
  width: 100%;
  max-width: 750px;
}