@value 800, 1100, 1200, 1300 from 'vars';

.root {
  display: none;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

@media 800 {
  .root {
    display: flex;
  }
}

@media 1100 {
  .root {
    align-items: flex-start;
  }
}

.row {
  display: flex;
}

.main {

}

.cell {
  margin-right: 15px;
  width: 150px;
}

@media 1100 {
  .cell {
    margin-right: 20px;
    width: 240px;
  }
}

@media 1200 {
  .cell {
    width: 260px;
  }
}

@media 1300 {
  .cell {
    width: 290px;
  }
}

.pad {
  padding: 5px 15px;
}

@media 1100 {
  .pad {
    padding: 5px 25px;
  }
}