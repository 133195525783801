.u {
  text-decoration: underline;
}

.b {
  font-family: var(--font-bold);
  font-size: 18px;
}

.i {
  font-style: italic;
}

.p {
  margin-bottom: 20px;
}

.in {
  margin-left: 20px;
}

.ul {
  padding-inline-start: 15px;
}

.li {
  margin-bottom: 4px;
}

.header {
  border-bottom: 2px solid var(--gray-xl);
  margin-bottom: 20px;
}

.table {
  border: 2px solid var(--sec-05);
  border-spacing: 0;
  margin-bottom: 20px;
}

.table thead th {
  background-color: var(--blue-xl);
  font-family: var(--font-bold);
}

.table tbody > tr:nth-of-type(even) {
  background-color: var(--blue-l);
}

.table tbody > tr td:first-child {
  width: 150px;
}

.table th:not(:first-child),
.table td:not(:first-child) {
  border-left: 1px solid var(--sec-02);
}

.table th,
.table td {
  padding: 8px 10px;
  border-spacing: 0;
}

.table tbody td {
  border-top: 1px solid var(--sec-02);
}

.a {
  color: var(--hyperlink);
}