.root {
  max-width: 800px;
}

.row {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.new {
  composes: row;
  border-bottom: 1px solid var(--gray-xl);
  padding: 10px 0;
  margin-bottom: 10px;
}

.active {
  display: flex;
  flex-direction: column;
}

.active .row {
  padding: 4px 0;
}

.select {
  width: max-content;
  min-width: 100%;
}

.filter-op {
  flex: 0 1;
  min-width: 51px;
}

.field-name {
  flex: 0 1;
  min-width: 200px;
}

.field-qualifier {
  flex: 0 1;
}

.field-op {
  flex: 0 1;
}

.field-value {
  flex: 1 1;
  min-width: 200px;
}

.match {

}

.match .item {
  height: 38px;
}

.match .field {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  padding: 0 8px;
  line-height: 38px;
}

.match .btn {
  flex: 1 1
}

.match .remove {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.field-value.covered {
  max-width: 100px;
  min-width: 100px;
}