.root {
  position: relative;
  box-sizing: border-box;
  height: calc(100% * 0.5);
  border-radius: 9px;
  width: 100%;
}

.editable {
  cursor: pointer;
}

.image {
  display: flex;
  overflow: hidden;
  padding-top: 50%;
  border-radius: 9px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.remove {
  position: absolute;
  top: -12px;
  right: -18px;
  background-color: var(--blue-xl);
  color: var(--gray-l);
  display: flex;
  align-items: center;
  font-size: 13px;
  margin: 0 5px;
  transition: color 150ms ease-in-out,
              box-shadow 150ms ease-in-out;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
}

.remove:hover {
  color: var(--gray);
  box-shadow: 0px 3px 5px 1px  rgba(55,70,70,.15);
}