.content {

}

.info {
  composes: info from '../style/AccountSettings.css';
  margin-bottom: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  composes: border from '../style.css';
}


.subtitle {
  composes: title from '../style.css';
}

.info-icon {
  margin: 6px 0 0 10px;
}

.row {
  composes: root from '../Fields/style.css';
}

.subrow {
  display: flex;
}

.text {
  composes: text from '../style.css';
}

.subtext {
  composes: text from '../style.css';
  margin-left: 20px;
}

.dropdown-text {
  font-size: 16px;
}

.dropdown {
  margin-left: 10px;
  width: 130px;
}

.review-dropdown {
  margin-left: 10px;
  width: 325px;
}

.title {
  display: flex;
  align-items: center;
}