.root {
  display: flex;
  gap: 10px;
  align-items: center;
}

.label {
  min-width: 200px;
}

.color-input {
  width: 64px;
}

.color-root {
  height: 28px !important;
  border-width: 2px !important;
  display: flex;
  align-items: center;
  border-radius: 6px !important;
}