.root {

}

.title {
  font-family: var(--font-bold);
  color: var(--gray-d);
  font-size: 16px;
  margin-bottom: 5px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.required::after {
  content: "*";
  color: var(--pri-02);
  margin-left: 5px;
  font-size: 16px;
}