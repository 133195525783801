@value 800 from 'vars';

.header {
  margin: 0 25px 20px 25px;
  min-height: 84px;
}

@media 800 {
  .header {
    margin: 0 0 20px 0;
  }
}

.nav {
  margin: 0 25px 10px 25px;
}

@media 800 {
  .nav {
    margin: 0 0 20px 0;
  }
}

.tabview {
  margin-top: 20px;
}