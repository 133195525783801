.row {
  margin-bottom: 25px;  
}

.label-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.editor {
  width: 100%;
}

.editor-btn {
  margin-left: 5px;
}

.label {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  font-size: 15px;
  margin-right: 8px;
  width: 82px;
  min-width: 82px;
}

.settings {
  margin-top: 25px;
}

.attributes,
.sets {
  cursor: pointer;
  width: 75px;
}
