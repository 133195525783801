.root {

}

.root .item {
  margin-bottom: 15px;
}

.root .item:last-of-type {
  margin-bottom: 0;
}