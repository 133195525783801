.dropdown-container {
  padding: var(--gutter-width);
  padding-bottom: 0px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}