.root {
}

.wrap {
  padding: 0 20px;
}

.header {
  background: url('static/images/website/bg-faq.png') no-repeat bottom 0 right 0/200px 142px;
  border-bottom: 1px solid var(--black);
  text-align: left;
  padding: 0 0 50px 0;
  margin: 0 0 20px 0;
}

.title {
  color: var(--black-l);
  font-family: var(--font-semibold);
  font-size: 38px;
  letter-spacing: -1.1px;
  line-height: 2;
}

.subtitle {
  color: var(--black);
  font-family: var(--font-semibold);
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: 0.2px;
  max-width: 300px;
}

.body {
  text-align: left;
}

@media (min-width: 600px) {
  .wrap {
    max-width: 1280px;
    padding: 0 30px;
    margin: 0 auto;
  }

  .header {
    background: url('static/images/website/bg-faq.png') no-repeat bottom -1px right 0/600px 430px;
    padding: 60px 0 90px 0;
  }

  .title {
    font-size: 46px;
  }

  .subtitle {
    font-size: 20px;
    max-width: 500px;
  }
}