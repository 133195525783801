.section {
  margin-bottom: 20px;
}

.section-header {
  font-family: var(--font-semibold);
  margin-bottom: 10px;
  font-size: 18px;
}

.summary {
  margin-bottom: 25px;
}

.ta {
  height: 175px;
}

.tags {
  width: 300px;
}

.tag {
  cursor: pointer;
  padding: 6px;
  margin-right: 10px;
  margin-bottom: 10px; 
}

.tag:hover {
  background-color: var(--blue-l);
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 15px;
}