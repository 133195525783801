.main {
  position: relative;
  border-top: 1px solid var(--gray-xl);
}

.main :global(.fc-view-container) {
  overflow: unset;
}

.main :global(.fc-time-grid .fc-slats .fc-minor) td {
  border-color: transparent;
}

.main :global(.fc-unthemed) thead,
.main :global(.fc-unthemed) td,
.main :global(.fc-unthemed) th,
.main :global(.fc-time-grid .fc-slats) td {
  border-color: var(--gray-xl);
}

.main :global(.fc-toolbar.fc-header-toolbar .fc-left h2) {
  font-size: 14px;
  color: var(--gray-d);
  margin-left: 15px;
  font-family: var(--font-semibold);
}

.main :global(.fc-head-container.fc-widget-header) {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: var(--pri-01);
  border: none;
}

.main :global(.fc-row.fc-widget-header) {
  border-bottom: 1px solid var(--gray-xl);
}

.main :global(.fc-day-header.fc-widget-header) {
  border-color: transparent;
}

.main :global(.fc-unthemed) thead:global(.fc-head) {
  background-color: var(--pri-01);
  border-bottom: 1px solid var(--gray-xl);
}

.main :global(.fc-button-primary .fc-icon-chevron-left),
.main :global(.fc-button-primary .fc-icon-chevron-right) {
  font-size: 22px;
}

.main :global(.fc-axis.fc-time.fc-widget-content) {
  font-family: var(--font-reg);
  font-size: 11px;
  color: var(--gray-d);
}

.btn {
  position: fixed;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  padding: 5px 30px;
  color: var(--pri-01);
  font-family: var(--font-semibold);
  transition: all 0.2s ease;
  width: 100px;
  height: auto;
}
/*
.main :global(.fc-view.fc-timeGridThreeDay-view.fc-timeGrid-view) {
}

.main :global(.fc-scroller.fc-time-grid-container) {
}

.main :global(.fc-body) {
}
*/