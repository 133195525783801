.row {
  display: flex;
  gap: 40px;
  margin-bottom: 20px;
}

.column {
  min-width: 300px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  font-family: var(--font-bold);
  color: var(--black-l);
}

.context-menu {
  align-self: center;
}