.card {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
}

@media (min-width: 425px) {
  .card {
    margin-top: 20px;
    /* margin-bottom: 100px; */
    /* min-height: calc(100vh - var(--navbar-height) - 120px);
    height: calc(100vh - var(--navbar-height) - 120px); */
  }
}

@media (max-width: 425px) {
  .card {
    border-bottom: none;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.count {
  color: var(--gray);
  font-size: 16px;
  font-family: var(--font-reg);
  margin-left: 6px;
}

.body {
  /* margin-bottom: 45px; */
}

.td {
  min-height: 70px;
}

.tr:last-of-type {
  border-bottom: none;
}

.footer {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: var(--pri-01);

  border-top: 1px solid var(--sec-01);
}

.rows {
  display: none;
}

@media (min-width: 425px) {
  .footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    position: unset;
    width: unset;
  }

  .rows {
    display: flex;
  }
}

.loading {
  margin: 20px auto 40px auto;
}