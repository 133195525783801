.root {

}

.editor {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.editor[contenteditable="true"] {
  border-radius: 4px;
  border: 2px solid var(--sec-01);
  padding: 5px;

  min-height: 22px;
}

.editor p {
  margin: 0;
  line-height: 22px;
}

.editor[contenteditable="true"] p {
  font-size: 15px;
}

.editor[contenteditable="false"] p {
  color: var(--gray-d);
}

.root :global(.ProseMirror-focused) {
  outline: unset;
  border: 2px solid var(--pri-02);
}

.root :global(.remirror-is-empty:first-of-type::before) {
  position: absolute;
  color: var(--gray-m);
  pointer-events: none;
  height: 0;
  content: attr(data-placeholder);
  font-size: 15px;
}

/* Mentions */

.root :global(.remirror-mention-atom) {
  color: var(--pri-02);
  font-family: var(--font-semibold);
  font-size: 15px;
}

.root :global(.remirror-mention-atom)::before {
  content: "@";
}