.root {

}

.editor {
  white-space: pre-wrap;
  overflow-wrap: break-word;

  font-size: 16px;
  line-height: var(--line-height);
  color: var(--black-l);
}

.editor[contenteditable="true"] {
  border: 2px solid var(--gray-l);
  border-radius: 7px;
  padding: 8px;

  /* min-height: calc(2 * var(--line-height));
  max-height: calc(15 * var(--line-height)); */
}

.root :global(.ProseMirror-focused) {
  outline: unset;
  border: 2px solid var(--pri-02);
}

.editor p {
  margin: 0;
}

/* Placeholder */
.root :global(.remirror-is-empty:first-of-type::before) {
  position: absolute;
  color: var(--gray-m);
  pointer-events: none;
  height: 0;
  content: attr(data-placeholder);
  font-size: 16px;
}