.picker {
  width: 100%;
}

.tooltip {
  display: none;
}

@media (min-width: 600px) {
  .tooltip {
    display: flex;
    margin-left: 15px;
    height: 24px;
  }
}

.type {
  width: 100%;
}

.type input {
  font-family: var(--font-reg);
  font-size: 16px;
  color: var(--gray-d);
}

.picker input {
  width: 100%;
  padding-bottom: 4px;
}

.field {
  min-height: 80px;
}

.inline {
  display: flex;
  flex-direction: column;
}

.inline .field {
  width: 100%;
}

@media (min-width: 600px) {
  .inline {
    flex-direction: row;
  }

  .inline .field {
    width: 195px;
  }

  .inline .field:nth-child(even) {
    margin-left: 35px;
  }
}

.header {
  display: flex;
  max-width: 450px;
  min-height: 50px;
}

.label {
  font-size: 16px;
  font-family: var(--font-semibold);
  line-height: 24px;
}

.alert {
  color: var(--pri-04);
  margin-left: 5px;
}

.row {

}

@media (min-width: 800px) {
  .row {
    display: flex;
    justify-content: space-between;
  }
}

.po {

}

.account {

}

@media (min-width: 800px) {
  .po {
    max-width: 300px;
  }

  .account {
    max-width: 380px;
  }
}

.half {
  width: 100%;
}

.numeric {
  line-height: 32px;
}