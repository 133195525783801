@value 800 from 'vars';

.root {
}

.item {
  display: flex;
  align-items: center;

  color: var(--gray-d);
  overflow: hidden;
}

.item:not(:last-child) {
  margin-bottom: 15px;
}

.icon {
  margin-right: 10px;
  color: var(--gray-d);
  display: flex;
  flex-shrink: 0;
}

.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14px;
}

.link:hover {
  text-decoration: underline;
  color: var(--hyperlink);
}

.empty {
  text-align: center;
}

.subtitle {
  margin-bottom: 10px;
  color: var(--trolley);
}

.footer {
  padding-top: 20px;
  width: 100%;
  text-align: center;
}

.footer .link {
  color: var(--hyperlink);
}

.footer .link:hover {
  text-decoration: none;
}