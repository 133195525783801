@value 1100, 800 from 'vars';

.root {
  min-height: 100vh;
  height: 100%;
}

.header {
  padding-top: 10px;
  text-align: center;
}

@media (min-width: 800px) {
  .header {
    padding: 10px 0;
  }
}

@media (min-width: 800px) {
  .main {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .auth {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: var(--font-bold);
  font-size: 34px;
  color: var(--black-l);
  margin-bottom: 20px;
}

.h2 {
  font-family: var(--font-bold);
  color: var(--black-l);
  font-size: 23px;
  max-width: 360px;
  margin: 0 auto 20px;
}

.h3 {
  font-family: var(--font-bold);
  color: var(--black-l);
  font-size: 23px;
  margin-bottom: 40px;
  margin-top: 10px;
  max-width: 480px;
}

.wrap {
  display: flex;
  width: 100%;
  height: 100%;
}

@media 1100 {
  .wrap {
    background-color: #F5F5F5;
  }
}

.signup {
  margin: 0 auto;
  background: var(--pri-01);
}

.survey {
  width: 100%;
  background: var(--pri-01);
}

.survey .form {
  margin: 0 auto;
  max-width: 390px;
  padding-bottom: 30px;
}

@media 1100 {
  .survey,
  .signup {
    width: 45%;
    margin: unset;
  }
}

@media(max-width: 450px) {
  .signup {
    max-width: 420px;
    width: 100%;
    padding: 0 25px;
  }

  .survey {
    max-width: 420px;
    width: 100%;
  }
}

.logo {
  height: 40px;
  width: auto;
  margin: 10px 0;
  padding-left: 25px;
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 50px 0 20px 0;
}

.tabs {
  display: flex;
}

.tabbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 30px;
}

@media 800 {
  .tabbar {
    margin: 0 auto 20px;
    display: block;
    max-width: 320px;
  }
}

.branding {
  display: flex;
  justify-content: center;
  align-items: center;
}

.branding .logo {
  height: 37px;
  width: auto;
  margin: 50px 0 30px;
}

.footer {
  margin-top: 20px;
}

.check {
  width: 18px;
  margin-right: 20px;
}

.login {
  margin-top: 42px;
}

.field {
  margin-top: 50px;
  width: 90%;
}

@media (min-width: 800px) {
  .field {
    width: 100%;
    width: 320px;
  }
}

.general {
  margin-top: 50px;
}