.root {
  /* overflow: hidden; */
}

.wrap {
  display: flex;
  position: relative;
  background-color: var(--pri-01);
}

.scrollable {
  flex: 1 1 auto;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
}

.scrollable::-webkit-scrollbar {
  display: none;
}

.tabs {
  display: inline-block;
  position: relative;
}

.tab {
  display: inline-flex;
  width: 150px;
  font-size: 16px;
  line-height: 45px;
  min-height: 45px;
  text-align: center;
  justify-content: center;
  font-family: var(--font-bold);
  color: var(--gray-d);
  cursor: pointer;
}

.active {
  composes: tab;
  color: var(--hyperlink);
}

.bar {
  height: 3px;
  background-color: var(--sec-01);
  width: 100%;
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
}

.indicator {
  position: absolute;
  height: 4px;
  background-color: var(--hyperlink);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 1;
  bottom: 0;
}

.more-menu {
  background-color: var(--pri-01);
  border: 1px solid var(--gray-l);
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 6px 2px rgba(60, 64, 67, .15);
  background-clip: padding-box;
  margin-top: 5px;

  z-index: var(--z-index-popper);
  min-width: 150px;
}

.menu-item {
  padding: 10px;
  cursor: pointer;
}

.menu-item:hover {
  background-color: var(--gray-xl);
}