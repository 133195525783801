.section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 10px;
}

.header {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.minus {
  cursor: pointer;
  color: var(--pri-04);
}

.add-group {
  margin-top: 10px;
}