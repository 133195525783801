.slider {
  margin-bottom: 25px;
}

.warning {
  margin: 15px 0;
  color: var(--pri-04);
  font-size: 15px;
}

.checkbox {
  margin-top: 15px;
}