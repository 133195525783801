.wrap {
  padding: 5px 0;
  border-bottom: 2px solid var(--gray-xl);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.body {
  font-size: 14px;
}

.row {
  padding: 5px 0;
  height: 38px;
  border-bottom: 1px solid var(--gray-l);
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 5px;
  font-size: 16px;
  font-family: var(--font-semibold);

  overflow: hidden;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
}

.cell {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 5px;

  overflow: hidden;
}

.pagination {
  padding: 20px 0;
  justify-content: flex-end;
}

.pagination-top {
  padding: 0;
  justify-content: flex-end;
}

.filter {
  margin-top: 10px;
}

.checkbox {
  display: flex;
}

.footer .checkbox {
  font-size: 16px;
  font-family: var(--font-semibold);
}

.history-icon {
  line-height: 0;
}

.history {
  padding: 6px 10px;
  font-size: 14px;
}

.history-row {
  display: flex;
}

.history-row:not(:last-child) {
  margin-bottom: 4px;
}

.history-label {
  flex: 1 0;
  margin-right: 8px;
}

.employments {
  padding: 6px 10px;
  font-size: 14px;
}

.employments-row {
  display: flex;
}

.employments-row:not(:last-child) {
  margin-bottom: 4px;
}

.company { 
  font-family: var(--font-semibold);
}

.topics {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: 30px;
}

.topic:not(:last-of-type) {
  margin-right: 6px;
}