.root {
  --line-height: 24px;
}

.editor {
  white-space: pre-wrap;
  overflow-wrap: break-word;

  font-size: 16px;
  line-height: var(--line-height);
  color: var(--black-l);
}

.root :global(.remirror-editor.ProseMirror) {
  overflow-y: auto;
  word-break: break-word;
}

.editor[contenteditable="true"] {
  border: 2px solid var(--gray-l);
  border-radius: 7px;
  padding: 8px;

  /* min-height: calc(2 * var(--line-height));
  max-height: calc(15 * var(--line-height)); */
}

.root :global(.ProseMirror-focused) {
  outline: unset;
  border: 2px solid var(--pri-02);
}

.editor p {
  margin: 0;
}

/* Link */
.editor a {
  color: var(--hyperlink);
  cursor: pointer;
  text-decoration: underline;
}

/* Bold */
.editor[contenteditable="false"] strong {
  font-family: var(--font-semibold);
  font-size: 17px;
}

/* Italics */
.root em {
  padding-right: 2px;
}

/* Placeholder */
.root :global(.remirror-is-empty:first-of-type::before) {
  position: absolute;
  color: var(--gray-m);
  pointer-events: none;
  height: 0;
  content: attr(data-placeholder);
  font-size: 16px;
}

/* Images */

.editor img {
  max-width: 100%;
  object-fit: contain;
}

.root :global(.remirror-image-loader) {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* workaround to hide image resize bars when not editing */
.editor[contenteditable="false"] :global(.remirror-resizable-view) div {
  display: none;
}

/* End Images */