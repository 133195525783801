.alert-modal {
  max-width: none;
}

.title {
  font-size: 18px;
}

.err-msg {
  margin-top: 10px;
}