.left-pad {
  padding-left: 30px;
}

.checkmark {
  margin-right: 5px;
}

.checked {
  padding-left: 5px;
}

.check {
  display: flex;
}