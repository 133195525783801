.root {
  --bottom-height: 80px;
  --right-padding: 10px;
  --left-width: 600px;
  --video-container-margin: 20px 0 0 0;
  --video-height-fudge: 40px;
  --padding-main: 20px;
}

.root-fs {
  composes: root;

  --video-container-margin: 0;
  --video-height-fudge: 0px;
  --navbar-height: 0px;
  --left-width: 100%;

  background-color: var(--pri-01);
  width: 100%;
}

.wrap {
  display: flex;
}

.left {
  display: none;
}

@media (min-width: 600px) {
  .left {
    display: flex;
    height: calc(100vh - var(--navbar-height));
    border-right: 2px solid var(--gray-xl);
    transition: width 1s ease-in-out;
  }
}

.right {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.top {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - (var(--navbar-height) + var(--bottom-height)));
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--bottom-height);
  border-top: 2px solid var(--gray-xl);
  padding: 0 var(--padding-main);
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.interactive {}

.toggles {
  display: flex;
  align-items: center;
  gap: 10px;
}