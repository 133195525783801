.root {
  display: flex;
  align-items: center;
}

.click-btn {
  padding: 2px 2px;
  min-width: 0px;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.showcased-editor {
  position: relative;
  padding-left: 10px;
  margin-left: -10px;
  padding-right: 10px;
  margin-right: -10px;
  background-color: white;
}