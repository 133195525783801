.grid {
  width: 100%;
}

.grid th {
  background-color: var(--blue-l);
}

.radio-cell {
  padding: 5px;
  text-align: center;
}

.option-header {
  text-align: center;
  min-width: 50px;
  max-width: 90px;
  padding: 5px;
}

.left-label-header, .right-label-header {
  font-family: var(--font-semibold);
  min-width: 275px;
  /* width: 275px; */
  padding: 10px;
  box-sizing: border-box;
}

.left-label-header {
  text-align: left;
}

.right-label-header {
  text-align: right;
}

.label-cell {
  padding: 10px;
}



/* .grid td {
  border-left: 1px solid var(--gray-l);
  border-bottom: 1px solid var(--gray-l);
}

.grid th {
  border-left: 1px solid var(--gray-l);
  border-bottom: 1px solid var(--gray-l);
}

.grid tr td:last-child {
  border-right: 1px solid var(--gray-l);
} */

.grid th, .grid td, .grid {
  border-collapse: collapse;
  border: 1px solid var(--gray-l);
  border-width: .1px;
}