.root {

}

.wrap {
  width: 100%;
  box-shadow: var(--shadow);
  border-radius: 3px;
  width: var(--width-panel, 100%);
  min-width: var(--min-width-panel, 300px);
  max-width: var(--max-width-panel, 500px);
}

.main {

}

.item {
  padding: 0 10px;
}

.ongoing:hover {
  background-color: var(--gray-xxl);
}

.separator {
  border-bottom: 1px solid var(--gray-xl);
}

.focus {
  padding: 10px 0;
}

.active {
  padding-bottom: 0;
}

.new {
  height: 40px;
  width: 100%;
  border-bottom: 1px solid var(--gray-xl);
}

.add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--pri-02);
  font-family: var(--font-semibold);
  background-color: var(--pri-01);
}

.add:hover {
  background-color: var(--gray-xxl);
}

.add:active {
  background-color: var(--blue-l);
}

.popup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}

.mini {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid var(--gray-xl);
}