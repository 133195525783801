.settings {
  margin-top: 20px;
}

.input {
  width: 125px;
}

.checkbox {
  margin-bottom: 15px;
}