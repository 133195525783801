.item {
  margin-bottom: 25px;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.title {
  font-family: var(--font-bold);
  margin-bottom: 15px;
  text-decoration: underline;
}

.label {
  font-family: var(--font-semibold);
  width: 100px;
  margin-right: 10px;
  color: var(--gray-d);
}

.input {

}