.tag {
  border-radius: 4px;
  padding: 6px;
  border: 1px solid var(--gray-xl);
  display: inline-flex;
  align-items: center;
  font-family: var(--font-semibold);
  font-size: 14px;
}

.clickable {
  cursor: pointer;
}

.star {
  color: var(--gray);
  height: 18px;
  width: 18px;
  margin-right: 4px;
}