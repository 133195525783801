.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid var(--gray-xl);
  min-height: 75px;
  box-sizing: border-box;
}

.root:last-child {
  border-bottom: 1px solid var(--gray-xl);
}

.left {
  display: flex;
  align-items: center;
}

.ordinal {
  margin-right: 30px;
  width: 58px;
  min-width: 58px;
}

.message {
  padding-right: 10px;
}

.dragging {
  background-color: var(--blue-l);
  border: 2px solid var(--pri-02);
  border-radius: 5px;
}

.dragging:last-child {
  border-bottom: 2px solid var(--pri-02);
}

.handle {
  color: var(--gray-d);
}

.dragging .handle {
  color: var(--pri-02);
}

.open-ended-input {
  width: 350px;
  margin-top: 10px;
}

.editor img {
  max-height: 150px;
}