.row {
  display: flex;
  align-items: center;
}

.exclude-row {
  margin-top: 15px;
}

.action {
  width: 200px;
}

.none {
  margin-left: 15px;
  color: var(--gray-d);
}

.dropdown {
  width: 350px;
}

.choice {
  margin-left: 15px;
}