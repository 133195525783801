@value 800, 1000 from 'vars';

.root {
  margin: 0 auto;
}

.wrap {
  height: calc(100vh - var(--navbar-height));
  min-height: 640px;
  padding: 0 20px 40px;
  margin: 0 auto;
}

@media 800 {
  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media 1000 {
  .wrap {
    max-width: var(--max-width);
  }
}

.main {
  max-width: 400px;
  margin: 0 auto;
}

@media 800 {
  .main {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    max-width: var(--max-width);
  }
}

@media 1000 {
  .wrap {
  }
}

.info {

}

@media 800 {
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 320px;
  }
}

@media 1000 {
  .info {
    max-width: 450px;
  }
}

.title {
  font-size: 30px;
  color: var(--black-d);
  font-family: var(--font-bold);
  margin-bottom: 30px;
}

@media 800 {
  .title {
    font-size: 40px;
  }
}

.subtitle {
  font-size: 20px;
  color: var(--black-l);
  margin-bottom: 50px;
}

.graphic {
  width: 100%;
  max-width: 293px;
  margin: 0 auto;
}

.img-s {
  width: 100%;
  height: auto;
}

.img-m {
  display: none;
  width: 430px;
  animation-name: fade;
  animation-duration: 300ms;
}

.img-l {
  display: none;
  animation-name: fade;
  animation-duration: 300ms;
}

@media 800 {
  .graphic {
    width: 450px;
    max-width: 100%;
    margin: auto;
  }

  .img-s {
    display: none;
  }

  .img-m {
    display: block;
  }
}

@media 1000 {
  .graphic {
    width: 600px;
    height: auto;
  }

  .img-m {
    display: none;
  }

  .img-l {
    width: 600px;
  }

  .img-l.ready {
    display: block;
  }
}

.btnset {
  display: flex;
  flex-direction: column;
}

.btn {
  width: 100%;
}

.btnset .btn:first-child {
  margin-bottom: 10px;
}

@media 800 {
  .btnset {
    flex-direction: row;
  }

  .btn {
    width: 150px;
  }

  .btnset .btn:first-child {
    margin: 0 20px 0 0;
  }

}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}