.root {
  margin-top: 35px;
  margin-bottom: 10px;
}

.main {
  margin: 20px 0;
}

.subtitle {
  font-family: var(--font-reg);
  font-size: 16px;
  color: var(--acc-01);
}

.content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  height: 40px;
}

.spinner {
  padding: 10px;
}

.loading {
  cursor: not-allowed;
  opacity: 0.3;
}

.brick {
  composes: brick from 'css/button.css';
}