.language-section {}

.section-header {
  background-color: black;
  color: white;
  padding: 6px;
  border-radius: 8px 8px 0px 0px;
  font-family: var(--font-semibold);
  min-height: 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-body {
  padding: 10px;
  border: 1px solid var(--gray-l);
  border-top: none;
  border-radius: 0px 0px 8px 8px;
}

.close-icon {
  cursor: pointer;
}