.recipients {
  composes: tags from 'static/css/tags.css';
  margin-bottom: 5px;
}

.recipient {
  font-size: 14px;
  border-radius: 4px;
  padding: 5px 15px;

  background-color: var(--blue-xl);
  color: var(--pri-02);

  cursor: pointer;

  margin-right: 10px;
  margin-bottom: 8px;

  display: flex;
  align-items: center;
}

.recipient:last-child {
  margin-right: 0;
}

.email {
  margin-right: 5px;
}

.x {

}

.input-container {
  position: relative;
}

.add {
  font-size: 10px;
  padding: 1px 8px;
  height: unset;
  width: unset;
  min-width: unset;

  position: absolute;
  right: 0;
  top: 0;
}

.input-root {
  padding-right: 60px;
  width: calc(100% - 60px);
}

@media (min-width: 600px) {
  .add {
    font-size: 12px;
    padding: 3px 10px;
  }

  .input-root {
    padding-right: 70px;
    width: calc(100% - 70px);
  }
}

.input {
  color: var(--gray-d);
  font-family: var(--font-reg);
  font-size: 14px;
}

.textarea {
  composes: input;
  outline: none;
  resize: none;
  min-height: 90px;
  line-height: 22px;
  width: 100%;

  border: 2px solid var(--gray-l);
  border-radius: 3px;
  box-sizing: border-box;
  padding: 8px;

  margin-bottom: 10px;
}

.textarea::placeholder {
  color: var(--gray);
}

.footer {
  text-align: center;
}

.send {
  padding: 0;
  height: 40px;
  font-size: 14px;
  border-radius: 3px;
}

.imports {
  display: flex;
  align-items: center;

  margin-top: 10px;
}

.label {
  margin-right: 15px;
  font-size: 14px;
}