.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  font-family: var(--font-semibold);
  margin-right: 10px;
}

.column-label {
  composes: label;
  width: 150px;
  min-width: 150px;
}

.header {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  margin-bottom: 15px;
  margin-top: 15px;
}

.row-item {
  border-bottom: 1px solid var(--sec-01);
  padding: 12px 0;
}

.row-item:first-of-type {
  padding-top: 0;
}

.row-item:last-of-type {
  border-bottom: none;
}

.row-item .label {
  width: 86px;
  min-width: 86px;
}

.row-item .row:last-of-type {
  margin-bottom: 0;
}

.value {

}

.option-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.bullet {

}