.root {
  border-radius: 4px;
  border: 1px solid var(--gray-l);
}

.column {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.combo {
  display: flex;
}

.column:last-child {
  border-right: none;
}

.cell {
  box-sizing: border-box;
  font-size: 13px;

  display: flex;
}

.details {

}

.details, .limit {
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  background-color: #97979714;
}

.limit {
  align-items: center;
  border-left: 1px solid var(--gray-xl);

  color: var(--gray-d);
  background-color: var(--pri-01);
}

.answer {
  display: flex;
}

.question {
  display: flex;
  margin-bottom: 5px;
  font-family: var(--font-semibold);
  color: var(--black-l);
}

.question .ordinal {
  color: var(--pri-02);
}

.answer .ordinal {
  color: var(--gray);
}
.limit .ordinal {
  color: var(--gray-d);
}

.ordinal {
  width: 30px;
  min-width: 30px;
  font-family: var(--font-semibold);
}

.children {

}