.root {

}

.wrap {
  padding: 20px 0px;
}

.main {
  margin: 0 auto 40px;
  max-width: 550px;
}

.headers {
  display: flex;
  align-items: flex-end;
}

.field {

}

.id {
  width: 70px;
}

.date {
  width: 110px;
}

.time {
  width: 160px;
}

.status {
  width: 120px;
}

.fields {
  display: flex;
  align-items: center;
  height: 45px;
}

.label {
  font-family: var(--font-bold);
  color: var(--font-black-l);
  margin-bottom: 15px;
}

.footer {
  padding: 40px 0 0;
}

.btn {
  min-width: 140px;
}

.link {
  color: var(--hyperlink);
}

.link:hover {
  text-decoration: underline;
}

.call {
  display: flex;
  width: 120px;
}

.skip {
  color: var(--pri-02);
  line-height: 19px;
}