@value 800, 1000 from 'vars';

.root {

}

@media 800 {
  .root {
    margin-bottom: 30px;
    padding: 20px;
  }
}

@media 1000 {
  .root {
    padding: 20px 40px;
  }
}

.wrap {
  background-color: var(--blue-l);
  padding: 30px;
}

@media 800 {
  .wrap {
    margin: 0 auto;
    border-radius: 10px;
    background-image: url('static/images/website/customer-research/bg-bubbles-blue.svg?url');
    background-position: 99% 10px;
    background-repeat: no-repeat;
    padding: 40px;
  }
}

@media 1000 {
  .wrap {
    max-width: 940px;
  }
}

.main {

}


.title {
  font-size: 22px;
  font-family: var(--font-bold);
  color: var(--black);
  margin-bottom: 20px;
}

@media 800 {
  .title {
    margin-bottom: 30px;
  }
}