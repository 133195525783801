.root {
  
}

.secure {
  font-family: var(--font-bold);
  font-size: 16px;
}

.title {
  font-family: var(--font-bold);
  font-size: 16px;
  margin: 0 0 10px 0;
}

.content {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  margin: 0 12px 0 3px;
}

.text {
  font-size: 14px;
}

.lock {
  display: flex;
  margin: 0 0 0 2px;
}

.lock,
.lock svg {
  color: var(--sec-03);
  height: 18px;
  width: 18px;
}