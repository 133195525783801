.root {
  height: 100%;
}

.wrap {
  margin: 0 auto;
  max-width: var(--navbar-width);
  padding: 20px 10px 40px;
  box-sizing: border-box;
}

.main {
  border: 1px solid var(--gray-l);
  border-radius: 8px;
}

.header {
  padding: 20px 10px 0;
}

.title {
  font-size: 24px;
  font-family: var(--font-bold);
  color: var(--black);
}

.table {

}

.filters {
  display: flex;
  gap: 10px;
  padding: 10px;
}