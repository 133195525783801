.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 0 0;
}

.trash {
  margin-left: 40px;
  color: var(--pri-04);
  cursor: pointer
}

.left {
  display: flex;
  align-items: center;
  width: 100%;
}

.number {
  height: 24px;
}

.textarea {
  border-bottom: 2px solid var(--pri-01);
  margin-left: 6px;
}

.textarea:hover {
  border-bottom: 2px solid var(--gray-l);
}

.textarea:focus {
  border-bottom: 2px solid var(--pri-02);
}