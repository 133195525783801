@value 800, 1100, 1200 from 'vars';

.root {
  border-top: 1px solid var(--gray-xl);
  position: relative;
}

@media 1100 {
  .bg {
    width: 100%;
    max-width: 1500px;
    height: 100%;
    margin: 0 auto;
    background-image: url('static/images/website/q2q/bg-dots-globe-l.png');
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center left;
  }
}


.wrap {
  max-width: 1280px;
  margin: 0 auto;
}

.content {
  height: calc(100vh - var(--navbar-height));
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media 800 {
  .content {
    padding: 0 20px 40px;
  }
}

@media 1100 {
  .content {
  }
}

.main {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
}

@media 1100 {
  .main {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.info {
  text-align: center;
  margin: 0 auto;
}

@media 800 {
  .info {
    margin: 0 20px;
  }
}

@media 1100 {
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }
}

.header {
  font-size: var(--font-size-header);
  font-family: var(--font-bold);
  color: var(--black-d);
  margin-bottom: 30px;
}

@media 1100 {
  .header {
    max-width: 380px;
    margin-bottom: 20px;
  }
}

.subtitle {
  font-size: 20px;
  color: var(--black-l);
  margin-bottom: 50px;
  max-width: 500px;
}

@media 800 {
  .subtitle {
    margin: 0 auto 40px;
  }
}

@media 1100 {
  .subtitle {
    max-width: 330px;
    margin: 0 0 40px;
  }
}

.video {
  width: 100%;
  max-width: 400px;
  height: 225px;
  object-fit: contain;
  border-radius: 10px;
  background-color: var(--pri-01);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
}

@media 800 {
  .video {
    max-width: unset;
    width: 600px;
    height: 337.5px;
  }
}

@media 1100 {
  .video {
    width: 725px;
    height: 407.8125px;
  }
}

.video::-webkit-media-controls-enclosure {
  display: none;
}

.video::-webkit-media-controls {
  opacity: 0;
}

.player {
  margin: 0 auto 30px;
  width: 100%;
  text-align: center;
}

@media 800 {
  .player {
    background-image: url('static/images/website/q2q/bg-dots-globe-m.png');
    background-repeat: no-repeat;
    padding: 65px 0;
    background-size: contain;
    background-position: center;
  }
}

@media 1100 {
  .player {
    margin: 0;
    background-image: unset;
    padding: 0;
    margin-bottom: 0;
    width: auto;
  }
}

.player .play {
  position: absolute;
  bottom: calc(50% - 36px);
  left: calc(50% - 36px);
  color: var(--pri-01);
  opacity: 0.8;
  height: 66px;
  width: 66px;
  box-shadow: 0 2px 12px 0 var(--pri-01);
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  z-index: 1;
  background-color: var(--black-l);
}

.play:hover {
  opacity: 1;
}

.box {
  position: relative;
}

.text {
  font-family: var(--font-bold);
  color: var(--pri-02);
  font-size: 20px;
}

.link {
  justify-content: center;
}

@media 1100 {
  .link {
    justify-content: flex-start;
  }
}