.root {
  margin-left: 48px;
}

.message {
  margin-bottom: 15px;
  color: var(--gray-d);
}

.lists {
  display: flex;
}

.start {
  margin-right: 30px;
  width: 50%;
  box-sizing: border-box;
  flex: 1;
}

.destination {
  width: 50%;
  box-sizing: border-box;
  flex: 1;
}