.root {

}

.wrap {
  margin: 0 15px;
}

.main {
  margin: 0 auto 30px;
  padding: 0 30px;
  max-width: 300px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 15px 0;
  height: 30px;
}

.back {
  position: absolute;
  left: 0;
}

.arrow {
  width: 28px;
  height: 28px;
}

.title {
  color: var(--gray-d);
  font-size: 23px;
  font-family: var(--font-bold);
  width: calc(100% - 50px);
  text-align: center;
  overflow: hidden;
}

.subheader {
  padding: 15px 10px;
  border-bottom: 1px solid var(--sec-01);
}

.message {
  font-family: var(--font-reg);
  color: var(--gray-d);
  font-size: 17px;
  margin-bottom: 15px;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.calendar {
  margin-bottom: 25px;
}

.propose {
  font-size: 14px;
  color: var(--pri-02);
  font-family: var(--font-reg);
}

.h2 {
  font-size: 19px;
  font-family: var(--font-bold);
  color: var(--black-l);
  margin: 20px 0 10px 0;
}