@value 800 from 'vars';
@value 1100 from 'vars';

.root {

}

.wrap {
  text-align: center;
  margin: 0 auto;
  padding: 75px 0;
  max-width: 100%;
}


@media 800 {
  .wrap {
    max-width: 475px;
  }
}

@media 1100 {
  .wrap {
    max-width: 520px;
  }
}

.title {
  composes: headline from '@/access/website/screens/Main/style.css';
  margin-bottom: 60px;
  position: relative;
  z-index: 2;
}

.btn {
  width: 100%;
  max-width: 350px;
  font-size: 18px;
}

@media 800 {
  .btn {
    width: 275px;
  }
}