.wrapper {
  color: var(--black);
  font-family: var(--font-reg);
}

.title {
  font-family: var(--font-bold);
  font-size: 26px;
  text-align: center;
  max-width: 500px;
  margin: 10px auto 20px auto;
}

.scroll {
  overflow-y: auto;
  padding: 0 20px 0 0;
  margin: 0 5px 0 20px;
  height: 60vh;
}

.header {
  margin: 40px 0 0 0;
}

.header:first-child {
  margin-top: 0;
}