.root {
  display: flex;
  align-items: center;
}

.label {
  margin: 0 6px 0 0;
}

.duration {
  letter-spacing: 1px;
}