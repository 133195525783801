.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  font-family: var(--font-semibold);
  margin-right: 10px;
  width: 150px;
  min-width: 150px;
}

.entry-label {
  margin-left: 20px;
}