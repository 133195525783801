.root {

}

.wrap {

}

.main {
  display: flex;
}

.header {
  composes: label from './Settings.Branding.Theming.css';
}

.upload {
  margin-right: 25px;
}

.btn {
  font-family: var(--font-reg);
  padding: 5px 16px;
  height: 36px;
}

.display {
  position: relative;
  box-sizing: border-box;
  height: calc(100% * 0.194);
  border-radius: 9px;
  border: 1px solid var(--gray-l);
  width: 100%;
}

.placeholder {
  display: flex;
  overflow: hidden;
  padding-top: 19.4%;
  border-radius: 9px;
  background-color: var(--blue-xl);
  width: 100%;
}

.noimage {
  position: absolute;
  bottom: calc(50% - 15px);
  left: calc(50% - 40px);
  height: 30px;
  width: 80px;
  line-height: 30px;
  text-align: center;
  color: var(--gray-l);
  font-family: var(--font-bold);
  font-size: 18px;
}

.image {
  display: flex;
  overflow: hidden;
  padding-top: 19.4%;
  border-radius: 9px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.remove {
  position: absolute;
  top: -12px;
  right: -18px;
  background-color: var(--blue-xl);
  color: var(--gray-l);
  display: flex;
  align-items: center;
  font-size: 13px;
  margin: 0 5px;
  transition: color 150ms ease-in-out,
              box-shadow 150ms ease-in-out;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
}

.remove:hover {
  color: var(--gray);
  box-shadow: 0px 3px 5px 1px  rgba(55,70,70,.15);
}

.modal {
  overflow-x: hidden;
  max-width: 800px;
  width: 100%;
}

.cropper {
}

.picker {
  text-align: center;
  width: 100%;
}

.original {
  max-width: 1140px;
}

.cropped {
  width: 100%;
  max-height: 110px;
  max-width: 570px;
}