.root {
  padding: 20px 0;
}

.wrap {

}

.main {

}

.input {
  font-size: 30px;
  font-family: var(--font-bold);
  color: var(--black);
  border: none;
  width: 100%;
}

.input::placeholder {
  color: var(--gray);
}