.root {
  width: 100%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  --height-toolbar: 50px;
  --height-header: 60px;
  --top-toolbar: var(--navbar-height);
  --top-header: calc(var(--top-toolbar) + var(--height-toolbar));
}

.wrap {
  width: 100%;
}

.root :global(.fc-toolbar.fc-header-toolbar) {
  position: sticky;
  top: var(--top-toolbar);
  z-index: 2;
  background-color: var(--pri-01);
}

.root :global(.fc-head-container.fc-widget-header) {
  position: sticky;
  top: var(--top-header);
}

.calendar {
  padding-bottom: 80px;
}