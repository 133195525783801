.root {
  padding: 15px;
  max-width: 900px;
}

.ordinal {
  font-family: var(--font-semibold);
  margin-right: 10px;
}

.list {

}

.item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

}

.empty {
  
}