@value 1100 from 'vars';

.root {
  display: flex;
  height: calc(100vh - var(--navbar-height));
}

.wrap {
  width: 100%;
  border-left: 1px solid var(--gray-xl);
}

@media 1100 {
  .wrap {
    max-width: calc(100% - 350px);
  }
}

.main {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

@media 1100 {
  .main {
    padding: 0;
  }
}

.pane {
}

@media 1100 {
  .pane {
    min-width: 350px;
  }
}