@value 800, 1100 from 'vars';

.root {

}

.wrap {
  margin: 0 auto;
  max-width: 1280px;
}

@media 1100 {
  .wrap {
    padding: 0 15px;
  }
}

.main {

}

.header {
  width: 300px;
  margin: 40px auto 50px;
}

@media 800 {
  .header {
    width: 725px;
  }
}

@media 1100 {
  .header {
    width: 890px;
    margin: 40px 0 50px;
  }
}

.title {
  font-size: 24px;
  font-family: var(--font-bold);
  color: var(--black-d);
  margin-bottom: 20px;
}

@media 800 {
  .title {
    font-size: 30px;
  }
}

.subtitle {
  font-size: 14px;
  color: var(--black-l);
}

@media 800 {
  .subtitle {
    font-size: 18px;
  }
}