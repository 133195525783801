.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: wrap;
  max-height: unset;
  line-height: unset;
}

.clickable {
  cursor: pointer;
}

.static {
  cursor: default;
}

.label:after {
  display: inline-block;
  content: '';
}