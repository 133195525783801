@value 800, 1000, 1100 from 'vars';

.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  white-space: nowrap;
}

.wrap {
  background-color: rgba(0, 0, 0, 0.04);
  padding: 15px;
}

.main {
  width: 285px;
}

.circle {
  width: 42px;
  height: 42px;
  margin-right: 10px;
}

.rect {
  margin-bottom: 25px;
}

.contain {
  width: 100%;
}

.info {
  display: flex;
}

.text {
  width: 100%;
}

.title {
  margin-bottom: 5px;
  margin-top: -5px
}

.box {
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.item {
  margin-bottom: 10px;
}

@media 800 {
  .contain {
    display: none;
    width: 32%;
  }

  .contain:nth-child(-n+3) {
    display: block;
  }
}

@media (min-width: 1265px) {
  .contain {
    width: 24%;
  }

  .contain:nth-child(-n+4) {
    display: block;
  }
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}