.label {
  color: var(--gray-d);
  margin-bottom: 5px;
}

.btns {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.section {
  margin-bottom: 10px;
}