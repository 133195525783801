@value 1100 from 'vars';

.root {
  --height-header: 25px;
  --height-footer: 65px;
  font-size: 14px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 1;
}

@media 1100 {
  .root {
    position: unset;
    height: calc(100vh - (var(--navbar-height) + var(--height-header) + var(--height-footer)));
  }
}

.modal {
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
}

@media 1100 {
  .modal {
    height: auto;
    max-height: calc(100vh - var(--navbar-height));
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  touch-action: none;
}

@media 1100 {
  .main {
    padding: unset;
  }
}

.title {
  font-family: var(--font-bold);
  font-size: 18px;
  margin-bottom: 20px;
  line-height: var(--height-header);
  text-align: center;
  padding-top: 20px;
}

@media 1100 {
  .title {
    padding-top: unset;
  }
}

.br {
  display: block;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.scroll {
  overflow-y: scroll;
  margin-bottom: var(--height-footer);
  padding: 0 20px;
}

.scroll::-webkit-scrollbar {
  width: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--gray);
}

@media 1100 {
  .scroll {
    padding: 0;
  }
}

.confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: var(--height-footer);
  bottom: 0px;
  left: 0;
  right: 0;
  background-color: var(--pri-01);
  width: 100%;
  touch-action: none;
}

.btn {
  width: 150px;
}