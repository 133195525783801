.btn {
  font-family: var(--font-bold);
  font-size: 20px;
  border: 2px solid var(--pri-02);
  border-radius: 10px;
  width: 150px;
  padding: 12px;
  margin: 15px;
  transition: all 0.2s ease;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.nofill {
  composes: btn;
  background-color: var(--pri-01);
  color: var(--gray-d);
  border: 2px solid var(--gray-l);
}

.nofill:active {
  background-color: var(--sec-01);
  color: var(--gray-d);
  border: 2px solid var(--gray);
}

.fill {
  composes: btn;
  background-color: var(--pri-02);
  color: var(--pri-01);
}

.fill:active {
  background-color: var(--sec-02);
  color: var(--pri-01);
  border: 2px solid var(--sec-02);
}

@media (min-width: 1100px) {
  .nofill:hover {
    background-color: var(--sec-01);
    color: var(--gray-d);
    border: 2px solid var(--gray);
  }

  .fill:hover {
    background-color: var(--sec-02);
    color: var(--pri-01);
    border: 2px solid var(--sec-02);
  }

  .fill:disabled {
    border: 2px solid var(--gray-l);
    color: var(--pri-01);
    background-color: var(--gray-l);
    cursor: not-allowed;
  }
}

.brick {
  min-width: 140px;
  font-size: 15px;
  padding: 5px 10px;
  height: 40px;
}