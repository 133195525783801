@value 1000 from 'vars';

.root {

}

.root :global(.react-datepicker__day) {
  font-size: 15px;
  font-family: var(--font-reg);
  color: var(--gray-m);
  padding: 0.166rem;
  width: 1.9rem;
  line-height: 1.9rem;
  margin: 0;
}

@media 1000 {
  .root :global(.react-datepicker__day) {
    font-size: 12px;
    padding: 0;
    height: 27px;
    width: 27px;
    line-height: 27px;
  }
}

.root :global(.react-datepicker__day).highlight,
.root :global(.react-datepicker__day)[class*="-selected"]:not([class*="-today"]),
.root :global(.react-datepicker__day)[class*="-range"]:not([class*="-today"])
{
  background-color: var(--blue-l);
  color: var(--pri-02);
  border-radius: 50%;
}

.root :global(.react-datepicker__day)[class*="-today"] {
  color: #fff;
  background-color: var(--pri-02);
  border-radius: 50%;
}

.root :global(.react-datepicker__day:hover) {
  background-color: transparent;
  border-radius: 50%;
}

@media 1000 {
  .root :global(.react-datepicker__day:hover) {
    background-color: var(--gray-xl);
    cursor: pointer;
  }

  .root :global(.react-datepicker__day).highlight:hover,
  .root :global(.react-datepicker__day)[class*="-selected"]:not([class*="-today"]):hover,
  .root :global(.react-datepicker__day)[class*="-range"]:not([class*="-today"]):hover {
    background-color: var(--blue-l);
  }

  .root :global(.react-datepicker__day)[class*="-today"] {
    color: #fff;
    background-color: var(--pri-02);
  }
}

:global(.react-datepicker).root  {
  border: none;
  width: 100%;
}

:global(.react-datepicker__header.react-datepicker__header--custom) {
  background-color: var(--pri-01);
  border-bottom: none;
}

.root :global(.react-datepicker__day-name) {
  color: var(--gray-d);
  font-family: var(--font-semibold);
  text-transform: uppercase;
  font-size: 11px;
}

@media 1000 {
  .root :global(.react-datepicker__day-name) {
    color: var(--black-l);
  }
}

.root :global(.react-datepicker__month) {
  margin: 0;
}

.root :global(.react-datepicker__day-names),
.root :global(.react-datepicker__week) {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.root :global(.react-datepicker__month-container) {
  float: none;
}

.root :global(.react-datepicker__day),
.root :global(.react-datepicker__day--disabled) {
  outline: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--pri-01);
  margin-bottom: 15px;
}

.month {
  font-family: var(--font-semibold);
  font-size: 14px;
  color: var(--gray-d);
}

.btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70px;
}

.btn {
  block-size: 24px;
}

.chevron {
  color: var(--gray-d);
  font-size: 24px;
}
