.root {
  padding: 0 15px
}

.wrap {
  padding: 15px 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.details {
  display: flex;
  align-items: center;
}

.meta {
  margin-left: 10px;
}

.header .name {
  font-size: 14px;
  font-family: var(--font-semibold);
  color: var(--black-l);
}

.age {
  color: #6c6c6c;
  font-size: 12px;
  margin-top: 2px;
}