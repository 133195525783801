.root {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 80vh;
}

.img {
  height: 90px;
  width: 90px;
  margin-bottom: 40px;
}

.redirecting {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 28px;
}