.root {
  padding: 20px;
  overflow-y: auto;
  height: calc(100vh - var(--navbar-height) - 136px - 150px);
  border-top: 1px solid var(--gray-l);
  box-sizing: border-box;
}

.files {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(286px, 1fr));
  grid-auto-rows: minmax(180px, 225px);
}

.file {
  height: 100%;
  border: 1px solid var(--gray-xl);
  border-radius: 14px;
  overflow: hidden;
  background-color: #fff;
}

.file *,
.file *::before,
.file *::after {
  box-sizing: border-box;
}

.file .thumb {
  height: 70%;
  background-color: var(--gray-xl);
}

.generic-thumb {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-xl);
  height: 100%;
}

.generic-thumb .icon {
  color: var(--gray);
  height: 65%;
  width: fit-content;
}

.file .info {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--gray-xl);
  height: 30%;
  padding: 10px;
}

.file .info .icon {
  display: flex;
  align-items: center;
  color: var(--gray-d);
}

.file .info .details {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  overflow: hidden;
}

.file .info .details .name {
  color: var(--black-l);
  font-size: 14px;
  font-family: var(--font-semibold);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.file .info .details .last-modified {
  color: var(--gray);
  font-size: 12px;
  margin-top: 4px;
}

.pagination-root {
  display: flex;
  justify-content: flex-end;

  padding: 10px;
}

.page-size {
  display: flex;
  margin-right: 25px;
}

.dragging .root {
  background-color: var(--blue-xl);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%230A68EFFF' stroke-width='4' stroke-dasharray='6%2c 10' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 3px;
}

.link {
  cursor: pointer;
}