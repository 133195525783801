.root {

}

.wrap {

}

.main {

}

.row {
  display: flex;
}

.cell {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--pri-01);
  height: 39px;
  padding: 0 5px;
}

.b .row:nth-child(even) .c {
  background-color: var(--gray-xl);
}

.b .row:nth-child(odd) .c {
  background-color: #ededed;
}

.name {
  font-size: 14px;
  font-family: var(--font-bold);
}

.h .cell.c {
  background-color: #e8e8e8;
}

.cell.c {
  border-left: 1px solid var(--pri-01);
  width: 139px;
}

.h .cell.r {
  justify-content: center;
  background-color: var(--gray-xl);
}

.b .cell.r {
  background-color: #e8e8e8;
  justify-content: flex-end;
}

.b .cell.c {
  font-size: 15px;
}

.h .cell.c,
.b .cell.c {
  padding: 0 10px;
}

.r {
  width: 200px;
  padding: 0 10px;
}

.c {
  width: 140px;
}

.input {
  padding: 2px;
  font-size: 14px;
}