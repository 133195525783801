.label {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  font-size: 15px;
  margin-right: 5px;
  width: 62px;
}

.sum-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.compare-row {
  display: flex;
  align-items: center;
}

.edit {
  cursor: pointer;
  color: var(--hyperlink);
}

.operator-dropdown {
  width: 75px;
  margin-right: 15px;
}

.comparate-input {
  width: 75px;
}

.sum-text {
  color: var(--gray);
  margin-right: 8px;
}