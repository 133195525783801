.root {
  position: relative;
}

.more {
  background-color: var(--pri-01);
  color: var(--gray);
  font-size: 14px;
  position: absolute;
  bottom: 0;
  right: 0;

  cursor: pointer;
}

.more:hover {
  color: var(--pri-02);
  text-decoration: underline;
}