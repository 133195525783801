.root {

}

.wrap {

}

.main {

}

.table {
  padding: 30px 0;
}

.items {
  min-height: 100px;
  overflow-y: scroll;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--gray-xl);
  height: 35px;
  border-top: 1px solid var(--sec-01);
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid var(--sec-01);
}

.item:first-child {
  border-top: 1px solid var(--sec-01);
}

.label {
  font-family: var(--font-bold);
  color: var(--font-black-l);
  margin-bottom: 15px;
}

.header .role {
  margin-left: 5px;
}

.header .name {
  margin-left: 3px;
}

.header .label {
  font-family: var(--font-bold);
  font-size: 15px;
  margin-bottom: 0;
}

.name {
  display: flex;
  align-items: center;
  gap: 5px;
  width: calc(100% - 250px);
}

.name .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
}

.role {

}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
}

.item .role .select {
  width: 150px;
  border: none;
}

.item .role .select.disabled:hover {
  background-color: var(--pri-01);
}

.item .role .select:hover {
  background-color: var(--gray-xl);
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 8;
  background-color: rgba(0, 0, 0, 0.5);
}

.dialog .inner {
  background-color: var(--pri-01);
  padding: 20px;
  border-radius: 4px;
}

.dialog .title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-family: var(--font-bold);
  font-size: 18px;
  color: var(--black);
}

.dialog .dlabel {
  font-size: 14px;
  font-family: var(--font-bold);
  color: var(--black-d);
  margin-bottom: 5px;
}

.dialog .field {
  margin-bottom: 10px;
}

.dialog .input {
  border-radius: 6px;
  border: 2px solid var(--sec-01);
  font-size: 16px;
  font-family: var(--font-reg);
  padding: 5px;
  color: var(--black);
  width: 200px;
}

.dialog .footer {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px 0 10px;
}

.btn {
  padding: 0;
  height: 40px;
  line-height: 37px;
  border-radius: 4px;
  min-width: 140px;
}

.checkbox-row {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 5px 0;
}