.root {
  display: flex;
  align-items: center;

  padding: 10px;
  font-size: 12px;
  height: 35px;

  color: var(--black-l);
  font-family: var(--font-semibold);
}