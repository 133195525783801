@value 800 from 'vars';

.root {
  border-bottom: 1px solid var(--gray-xl);
}

.wrap {
  padding: 10px 0;
}

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  color: var(--black-l);
  margin: 0 10px;
}

@media 800 {
  .name {
    width: 70%;
    overflow: hidden;
  }
}

.clamp {
  display: -webkit-box;
  position: relative;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 1.4em;
}

.name a:hover {
  color: var(--pri-02);
}

.img {
  width: 45px;
  height: 45px;
  border-radius: 2px;
}

.info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.following {

}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  max-height: 41px;
}

.stats {
  display: none;
  color: var(--gray);
  font-size: 14px;
}

@media  800 {
  .stats {
    display: block;
    white-space: nowrap;
  }
}

.unfollow {
  background-color: var(--blue-l);
}