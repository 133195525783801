.root {
  padding: 15px 25px;
  border-bottom: 1px solid var(--gray-xl);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  min-height: 50px;
}

.label {
  margin-left: 10px;
  color: var(--gray-d);
}

.closed {
  cursor: pointer;
}

.closed:hover {
  background-color: var(--blue-xl);
}