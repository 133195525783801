.root {
  transition: 0.2s;
}

.open {
  transform: rotate(-180deg);
}

.fill {
  fill: var(--pri-01);
  fill-opacity: 0;
}

.root.open .fill,
.root:hover .fill {
  fill: var(--sec-01);
  fill-opacity: 1;
}