.root {

}

.text {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  margin-right: 30px;
}

.input {
  max-height: 350px;
  resize: vertical;
}

.textarea {
  margin-right: 20px;
}

@media (min-width: 800px) {
  .textarea {
    margin-right: 50px;
  }
}