.root {

}

.wrap {

}

.main {
  margin: 0 20px 30px;
}

.msg {
  margin-bottom: 15px;
}

.btn {
  width: 140px;
  height: 40px;
  padding: 0;
  border-radius: 4px;
}