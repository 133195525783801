.root {

}

.wrap {
  position: sticky;
  top: var(--navbar-height);
  background-color: var(--gray-xl);
  border-top: 1px solid var(--gray-l);
  border-bottom: 1px solid var(--gray-l);
  z-index: 2;
}

.header {
  display: flex;
}

.th {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  font-family: var(--font-reg);
  font-weight: 800;
  color: var(--black);
  height: 40px;
  padding: 0 10px;
}

.body {
  font-size: 16px;
}

.row {
  border-bottom: 1px solid var(--gray-l);
}

.cell {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding: 0 10px;
}

.link {
  color: var(--hyperlink);
}

.status {
  display: flex;
  align-items: center;
  gap: 10px;
}

.inprogress {
  color: var(--gray);
}

.status .icon {
  width: 30px;
}

.status .text {

}

.status.header {
  padding-left: 40px;
}

.value {

}

.field {
  display: flex;
  align-items: center;
  outline: 1px solid var(--gray-l);
  border-radius: 2px;
  width: 260px;
}

.field:focus-within {
  outline: 1px solid var(--pri-02);
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  color: var(--gray-l);
}

.input {
  font-family: var(--font-reg);
  font-size: 16px;
  border: none;
  padding: 6px 4px 6px 0;
  width: 230px;
}

.input::placeholder {
  color: var(--gray);
}