.date-field-value {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .date-picker {
    width: 100px;
    z-index: var(--z-index-popper);
  }
  
  .btns {
    margin-left: 5px;
  }
  
  .date-field-value:hover .pencil {
    visibility: visible;
  }
  
  .pencil {
    transition: all 200ms;
    visibility: hidden;
    width: 14px;
    height: 14px;
    color: var(--pri-02);
    margin-left: 10px;
  }