.root {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.root:last-of-type {
  margin-bottom: 0;
}

.root:nth-last-child() {
  margin-bottom: 0px;
}

.input {
  flex-grow: 1;
  position: relative;
}

.textarea:disabled {
  background-color: var(--pri-01);
}

.action {
  margin-left: 15px;
  width: 24px;
  height: 24px;
}

.remove {
  color: var(--pri-04);
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: calc(50% - 14px - 4px);
  right: 0;
  height: 28px;
  width: 28px;
}