.version-tag {
  display: flex;
  align-items: center;

  cursor: pointer;
}

.date {
  color: var(--gray);
  margin-left: 2px;
}

.chevron {
  margin-left: 8px;

  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-top: 5px solid var(--gray-d);
}

.popper {
  z-index: 2;
}

.item {
  color: var(--black-l);
  padding: 10px 25px;
  white-space: nowrap;
  width: 200px;
}

.item:hover {
  color: var(--pri-02);
  background-color: var(--blue-l);
}

.item:hover .date {
  color: var(--gray-d);
}