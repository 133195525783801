.config {
  border: 1px solid var(--gray-l);
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.label {
  display: inline-block;
  font-family: var(--font-semibold);
  padding-right: 20px;
  color: var(--black-l);
  justify-content: space-between;
}

.label-query-string {
  display: inline-block;
  font-family: var(--font-bold);
  padding: 10px 0 10px 0;
  color: var(--gray-d);
}

.value {
  display: inline-block;
}

.value-query-string {}

.value-query-string-header {
  color: var(--gray-d);
  font-family: var(--font-semibold);
  margin-bottom: 5px;
  border-bottom: 1px solid var(--gray-xl);
}

.value-query-string-key {
  display: inline-block;
  width: 40%;
}

.value-query-string-value {
  display: inline-block;
  width: 60%;
}

.link {
  width: 125px;
}

.available {
  color: var(--gray-d);
  margin-right: 10px;
}

.anchor {
  height: 35px;
  width: 35px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.save {
  margin-right: 6px;
}

.disabled {
  cursor: inherit;
  color: var(--gray);
  border-color: var(--gray);
  background-color: #fff;
}

.disabled:hover svg {
  background-color: red;
  color: red;
}