.root {
    display: flex;
    flex-direction: column;
    height: 100%;
    --icon-size: 50px;
}

.vertical-line {
    width: 2px;
    background-color: var(--sec-01);
    margin: 5px auto 0 auto;
    height: 100%;
}

.icon {
    width: var(--icon-size);
    display: flex;
    justify-content: center;
    align-items: center;
}

.default-icon {
    color: var(--gray-d);
    border: 2px solid var(--gray-d);
    border-radius: 10px;
    padding: 9px;
    margin-bottom: 10px;

    width: auto;
    height: 28px;
}

.circle {
    width: 12px;
    height: 12px;
    margin: 5px 0;
    border-radius: 50%;
    background-color: var(--gray-l);
}

.logo {
    height: var(--icon-size);
    width: var(--icon-size);
}