.network-test {
  font-family: var(--font-bold);
  font-size: 12px;
  text-align: center;
  margin: auto;
  display: block;

  margin-top: 30px;
  margin-bottom: 10px;
}

.network-test:hover {
  text-decoration: underline;
}