.row {
  display: flex;
  align-items: center;
}

.link {
  color: var(--hyperlink);
  cursor: pointer;
  text-decoration: underline;
}

.edit {
  composes: link;
  margin-left: 5px;
}