.root {
  padding: 60px 0 40px;
}

.wrap {
  margin: 0 auto;
  width: 550px;
}

.main {
  padding-bottom: 20px;
  min-height: 360px;
}

.copy {
  color: var(--black-l);
  font-size: 18px;
}

.footer {
  padding: 20px 0;
  text-align: right;
}

.btn {
  width: 140px;
  border-radius: 4px;
  height: 40px;
  padding: 5px;
}

.link {
  color: var(--hyperlink);
}