.root {
  border-right: 1px solid var(--sec-01);
  border-left: 1px solid var(--sec-01);
  overflow-x: scroll;
}

.table {

}

.wrap {
  font-family: var(--font-bold);
  color: var(--black-l);
  border-top: 1px solid var(--sec-01);
  border-bottom: 1px solid var(--sec-01);
  width: fit-content;
}

.header {
  display: flex;
  align-items: center;
  font-family: var(--font-semibold);
  color: var(--black-d);
  padding: 10px 5px;
}

.th {
  display: flex;
  font-size: 16px;
}

.th span:nth-child(2) {
  margin-left: 5px;
  line-height: 14px;
  height: 14px;
}

.body {
  font-size: 16px;
}

.row {
  height: 50px;
  border-bottom: 1px solid var(--sec-01);
  width: fit-content;
}

.trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 5px;
  font-size: 15px;
}

.header + .header,
.cell + .cell {
  border-left: 1px solid var(--sec-01);
}

.name {
  font-family: var(--font-semibold);
  font-size: 16px;
  color: var(--black-d);
}

.field {
  background-color: var(--gray-xl);
}