.root {
  max-width: 900px;
  margin: 0 auto;
  margin-top: 20px;

  padding: 0 15px;
}

.back {

}

.body {

}