.root {

}

.wrap {

}

.main {

}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  font-family: var(--font-bold);
  font-size: 15px;
  background-color: var(--sec-01);
}

.items {
  min-height: 50vh;
  max-height: 280px;
  overflow-y: scroll;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.item:nth-child(odd) {
  background-color: var(--gray-xl);
}

.name {
  padding-left: 15px;
}

.name {
  display: flex;
  align-items: center;
  gap: 5px;
  width: calc(100% - 150px);
}

.name .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header .name {
  width: calc(100% - 175px);
}

.role {
  width: 150px;
}

.footer {
  padding: 40px 0 0;
}

.btn {
  min-width: 140px;
}

.edit {
  padding: 10px 15px;
}

.icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.add {
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--pri-02);
  font-family: var(--font-bold);
  transition: color 120ms ease-in;
}

.add:hover {
  color: var(--sec-02);
}