.root {
  padding: 20px 0;
}

.wrap {
  max-width: 780px;
  margin: 0 auto;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  padding: 20px 15px;
  min-height: 360px;
}

.img {
  text-align: center;
  width: 160px;
}

.header {
  font-family: var(--font-semibold);
  font-size: 24px;
  text-align: center;
}

.message {
  text-align: center;
  font-size: 24px;
  font-family: var(--font-reg);
}

.footer {

}

.btn {
  width: 250px;
}

.loading {
  width: 100%;
  min-height: calc(100vh - var(--navbar-height));
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-text {
  font-size: 36px;
  text-align: center;
  margin-top: 40px;
}

.error-msg {
  composes: error-msg;
}

.missing-link {
  composes: error-msg;
  color: var(--pri-04);
}