.root {
  --height-breadcrumbs: 60px;
  --height-tabbar: 45px;
  max-width: var(--navbar-width);
  width: 100%;
  margin: 0 auto;
}

.video {
  --height-media: 250px;
  --top-media: 240px;
  --sticky-space: 0px;
}

.audio {
  --height-media: 60px;
  --top-media: 50px;
  --sticky-space: 60px;
}

.wrap {
  margin: 0 auto;
  padding: 0 10px;
}

.main {

}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: var(--navbar-height);
  padding: 20px 0;
  background-color: var(--pri-01);
  z-index: 2;
}

.breadcrumbs {
  margin-left: -8px;
}

.transcript {

}

.header {
  padding: 0 0 20px;
}

.tabview {
  background-color: var(--pri-01);
}