.root {

}

.generic {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 200px;
  background-color: var(--pri-01);
  border-radius: 5px;
  padding: 25px 40px;
}

.bg {
  min-height: calc(100vh - var(--footer-height));
  background: linear-gradient(0deg, rgba(85, 115, 177, 0.3), rgba(85, 115, 177, 0.3)), url('static/images/bg-login.png');
  background-position: center;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  width: 100%;
}

.row {
  margin-bottom: 10px;
}

.col {
  position: relative;
  padding: 0;
  margin: 0 auto;
}

@media (min-width: 800px) {
  .col {
    padding: 30px;
    margin: 0;
  }
}

.logo {
  display: flex;
  justify-content: center;
  margin: 0 0 25px 0;
}

.logo-image {
  height: 50px;
  width: initial;
  max-width: initial;
}

.form {
  background-color: var(--pri-01);
  border-radius: 5px;
  padding: 25px 40px;
}

.link {
  color: var(--hyperlink);
  cursor: pointer;
}

.row {
  margin-bottom: 10px;
}

.header {
  margin-bottom: 0;
}

.title {
  display: flex;
  align-items: center;
  height: 70px;
  margin-bottom: 0;
}

.input {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.input:nth-child(2) {
  margin-bottom: 0;
}

.forgot {
  font-family: var(--font-bold);
  color: var(--pri-02);
  text-align: right;
  margin: 5px 0 10px 0;
}

.submit,
.submit:hover,
.submit:disabled,
.resend .submit,
.resend .submit:hover,
.resend .submit:disabled {
  font-size: 18px;
  width: 300px;
  height: 50px;
  border-radius: 3px;
  background-color: #304659;
  border-color: #304659;
}

.login {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.visitor.row.input:nth-child(2)  {
  height: 40px;
}

.error {
  position: relative;
  top: 0;
  margin: 10px 0;
}

.other-strategy {
  font-family: var(--font-semibold);
  font-size: 12px;
  color: var(--gray-d);
  text-align: center;
  margin-top: 30px;
}

.message {
  font-size: 18px;
  color: var(--black);
  font-family: var(--font-reg);
  margin-bottom: 20px;
}

.subtitle {
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 23px;
  color: var(--black);
  margin-bottom: 15px;
  max-width: 450px;
  text-align: center;
}

.em {
  font-family: var(--font-bold);
}

.resend {
  height: 50px;
}

.resent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: var(--font-semibold);
  font-size: 18px;
}