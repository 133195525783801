.header {
  display: flex;
}

.least,
.most {
  color: var(--black-l);
  padding: 20px 0;
  font-family: var(--font-semibold);
}

.left,
.right {
  text-align: center;
  width: 15%;
}

.middle {
  text-align: center;
  width: 70%;
}

.attributes {
  border: 1px solid var(--gray-xl);
  border-radius: 2px;
}

.attribute {
  display: flex;
  align-items: center;
  min-height: 60px;
  box-sizing: border-box;
  padding: 10px 0;
}

.attribute:nth-child(odd) {
  background-color: rgba(239, 238, 238, 0.3);
}

.radio {
  cursor: pointer;
}