.root {
  display: flex;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.name {
  color: var(--black);
  font-size: 15px;
  font-family: var(--font-semibold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  margin-right: 10px;
  flex-shrink: 0;
  height: 50px;
  width: 50px;
}

.description {
  color: var(--gray-m);
  font-size: 14px;
  margin: 6px 0;
  overflow: hidden;
  text-overflow: ellipsis;
}