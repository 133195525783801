.root {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.add-entry {
  cursor: pointer;
}

.input {
  display: none;
}

.image {
  height: var(--image-height);
}

.img-container {
  position: relative;
}

.remove {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: var(--pri-04);
}

.name-input {
  width: 60px;
}