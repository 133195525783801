.root {
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.input {
  width: 100%;
  border: none;

  font-family: var(--font-reg);
  font-size: 16px;
}

.close {
  cursor: pointer;
  color: var(--gray-d);
}

.popper {
  z-index: 10;
}