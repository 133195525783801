.root {
  border: 1px solid var(--gray-xl);
  width: 100%;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  margin-bottom: 5px;
}

.screened {
  background-color: var(--gray-xl);
}

.wrap {
  padding: 0 15px;
}

.main {

}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
}

.info {
  display: flex;
  align-items: center;

}

.sender {
  font-size: 15px;
  font-family: var(--font-semibold);
  color: var(--black);
  margin-right: 15px;
}

.date {
  font-size: 14px;
  font-family: var(--font-reg);
  color: var(--gray-m);
  line-height: 20px;
}

.body {
  color: var(--gray-d);
  font-size: 16px;
  padding: 15px 0 20px;
  white-space: pre-wrap;
  word-break: break-word;
}