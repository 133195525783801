.root {
  max-width: 1000px;
  margin: 0 auto;
  padding: 15px;
}

.message-root {
  padding-top: 30px;
}

.capture-sheet-root {
  --matrix-grid-header-top: 0;
  min-width: 600px;
  margin: 0 auto;
  padding: 15px;
}

.title {
  font-family: var(--font-semibold);
  font-size: 28px;
  color: var(--black-l);
  margin-bottom: 25px;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--gray-xl);
}

.content {
  min-height: 400px;
}

.footer {
  margin-top: 50px;
}

.aie-content {
  min-height: 400px;
  max-height: calc(100vh - 200px);
  margin-left: auto;
  margin-right: auto;
}

.img-container {}

.aie-image {
  width: auto !important;
  max-height: calc(100vh - 200px);
  object-fit: contain;
  max-width: 1000px;
}

.carousel {
  display: flex;
}

.carousel>div {
  display: flex;
}

.carousel-action {
  align-self: center;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}

.carousel-action:hover {
  background-color: var(--gray-xl);
}

.next-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.next-btn svg {
  stroke-width: 2.5;
}