.root {
  padding: 0 15px;
}

.details-root {
  padding: 0 15px 25px 15px;
}

@media (min-width: 800px) {
  .root {
    padding: 0 25px;
  }

  .details-root {
    padding: 0 25px 25px 25px;
  }
}

.header {
  text-align: left;
  margin-bottom: 25px;
}

.copy {
  margin-bottom: 15px;
}

.textarea {
  margin-bottom: 25px;
}

.cancel {
  margin-right: 20px;
}

.submit {

}

.footer {
  text-align: center;
}

.label {
  margin-bottom: 15px;
}