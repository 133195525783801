.root {
  display: inline-block;
  cursor: pointer;
  font-family: var(--font-bold);
  color: var(--gray);
  font-size: 16px;
  line-height: 18px;
}

.skip {
  height: auto;
  min-width: auto;
}

.skip::after {
  content: ' >';
  margin-right: 5px;
}