.root {
  width: 500px;
}

.wrap {
  padding: 40px 20px 20px;
}

.main {

}

.title {
  color: var(--black);
}

.row {
  margin: 0 auto 20px;
  width: 400px;
}

.label {
  font-size: 14px;
  color: var(--gray-d);
  font-family: var(--font-bold);
}

.footer {
  width: 400px;
  margin: 0 auto;
  padding: 20px 0;
}

.btn {
  width: 100%;
  border-radius: 4px;
}