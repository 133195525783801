.root {

}

.wrap {

}

.main {

}

.header {

}

.row {
  margin-bottom: 20px;
}
