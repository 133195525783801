.modal {
  width: 800px;
}

.section-body {
  max-height: calc(100vh - 360px);
  overflow-y: auto;
}

.buttons {
  margin-top: 20px;
}