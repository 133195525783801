.root {
  --bottom-height: 100px;
  --right-padding: 20px;
  --left-width: 600px;
  --video-container-margin: 20px 0 0 0;
  --video-height-fudge: 40px;
}

.root-fs {
  composes: root;

  --video-container-margin: 0;
  --video-height-fudge: 0px;
  --navbar-height: 0px;
  --left-width: 100%;

  background-color: var(--pri-01);
  width: 100%;
}

.wrap {
  display: flex;
}

.left {
  display: none;
}

@media (min-width: 800px) {
  .left {
    display: flex;
    height: calc(100vh - var(--navbar-height));
    border-right: 1px solid var(--gray-l);
    transition: width 1s ease-in-out;
  }
}

.right {
  display: flex;
  flex-direction: column;
  padding: var(--right-padding);
  width: 100%;
}

.top {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 1100px) {
  .top > div {
    margin: 0 20px 0 0;
  }

  .top > div:last-child {
    margin: 0;
  }
}

.bottom {
  display: flex;
  align-items: center;
  height: var(--bottom-height);
  max-width: var(--left-width);
}