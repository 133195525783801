.body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
}

.submit {
  margin-left: 15px;
}

.row {
  display: flex;
  align-items: center;
}

.existing {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.dropdown {
  width: 150px;
  margin-left: 10px;
}

.label {
  font-family: var(--font-semibold);
}

.input {
  width: 400px;
}

.link {
  color: var(--hyperlink);
  cursor: pointer;
  text-decoration: underline;
}