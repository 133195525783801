.buttons {
  margin-top: 20px;
}

.sync-row {
  margin-top: 10px;
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
}

.sync-label {
  font-family: var(--font-semibold);
}