@value 800 from 'vars';

.root {
  width: 100%;
  padding-bottom: 60px;
  z-index: 0;
}

@media 800 {
  .root {
    margin: 0 auto;
    padding-bottom: 0;
  }
}

.wrap {
  width: 100%;
}

.main {

}