.root {
  border-top: 1px solid var(--gray-xl);
  padding-bottom: 60px;
}

.row {
  min-height: 50px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--gray-xl);
  padding: 15px 25px;
}