.root {
  position: relative;
}

.text {
  position: absolute;
  top: 15px;

  font-family: var(--font-semibold);
  font-size: 14px;
}

.min {
  composes: text;
  left: 0;
}

.max {
  composes: text;
  right: 0;
}