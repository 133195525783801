.name {
  display: flex;
  align-items: center;
  gap: 10px;
}

.avatar {
  width: 25px;
  height: 25px;
}

.link:hover {
  color: var(--hyperlink);
  text-decoration: underline;
}

.action {

}

.action:disabled .icon .bg,
.action:disabled .icon .bg:hover {
  cursor: default;
  background-color: inherit;
}

.action:disabled .svg {
  color: var(--sec-01);
}

.icon {

}

.icon .bg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  cursor: pointer;
}

.icon .bg:hover {
  background-color: var(--gray-xl);
}

.svg {
  color: var(--gray-m);
  width: 20px;
  height: 100%;
}

.trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}