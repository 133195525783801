.popper {
  z-index: 3;
}

.menu {
  background-color: var(--pri-01);
  border-radius: 4px;
  box-shadow: 0px 1px 2px 1px rgb(238 238 238);
  max-height: 300px;
  overflow-y: auto;
}

.item {
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-bold);
  padding: 10px 15px;
  cursor: pointer;
  width: 200px;
  box-sizing: border-box;
}

.no-suggestions {
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-bold);
  padding: 10px 15px;
  width: 200px;
  box-sizing: border-box;
}

.item.focused,
.item:hover {
  background-color: var(--blue-l);
}