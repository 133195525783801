.root {
  position: fixed;
  bottom: 30px;
  left: calc((100vw - 325px) / 2);
  z-index: var(--z-index-toast);
}

.item {
  box-sizing: border-box;
  width: 325px;
  min-width: 325px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #e0e0e0;
  background-color: var(--pri-01);
  color: var(--black-l);
  border-radius: 8px;
  padding: 10px;
  margin-top: 5px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, .15);
}

@media (min-width: 400px) {
  .root {
    left: 30px;
  }
  .item {
    width: unset;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main {
  width: 100%;
  padding: 0 15px;
}

.title {
  font-size: 16px;
  line-height: 29px;
  font-family: var(--font-semibold);
}

.icon {
  border-radius: 50%;

  height: 20px;
  width: 20px;
  padding: 3px;
}

.close {
  height: 20px;
  width: 20px;
  color: var(--gray);
  cursor: pointer;
}

.body {
  font-size: 13px;
  width: 300px;
  min-height: 60px;
  margin-top: 5px;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

.enter {
  animation-name: slideInLeft;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

.exit {
  animation-name: slideOutLeft;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

.success {
  background-color: var(--sec-03);
  color: var(--pri-01);
}

.info {
  background-color: var(--pri-02);
  color: var(--pri-01);
}

.error {
  background-color: var(--sec-04);
  color: var(--pri-01);
}

.warn {
  background-color: #fe8e26;
  color: var(--pri-01);
  padding: 3px;
}