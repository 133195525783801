.root {

}

.wrap {

}

.main {

}

.label {
  font-family: var(--font-bold);
  color: var(--font-black-l);
  margin-bottom: 15px;
}

.row {
  display: flex;
  align-items: stretch;
  gap: 30px;
  margin-bottom: 30px;
}

.description {
  width: 100%;
}

.amount {
  flex-shrink: 2;
}

.btn {
  min-width: 140px;
}

.footer {
  padding-top: 20px;
}

.readonly {
  line-height: 32px;
  border-bottom: 2px solid var(--gray-l);
  height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.na {
  line-height: 32px;
  color: var(--gray-l);
}

.display {
  flex-grow: 1;
  max-width: 50%;
}

.input {
  line-height: 32px;
  padding-bottom: 0;
}