.root {
  height: 100%;
  width: 100%;
}

.video {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.local {
  transform: rotateY(180deg);
}

.root video {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/* .root {
  width: 100%;
  height: 100%;
} */