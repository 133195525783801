.root {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  height: 60px;
}

.name {
  color: var(--gray-d);
  font-family: var(--font-semibold);
  margin-left: 5px;
  margin-right: 40px;
  font-size: 14px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name:hover {
  color: var(--pri-02);
}

.details {
  display: flex;
  align-items: center;
  overflow: hidden;
}