.root {
  max-width: 450px;
}

.body {
  font-size: 14px;
}

.modal .btn {
  height: 40px;
  min-width: 120px;
  padding: 5px 10px;
  border-radius: 4px;
}

.modal .btns {
  padding-bottom: 10px;
}

.message {
  font-size: 18px;
  max-width: 250px;
  margin: 0 auto 20px;
}