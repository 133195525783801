.menu-item {
  font-size: 14px;
  display: flex;
  gap: 5px;
  vertical-align: middle;
}

.no-suggestions {
  composes: menu-item;
  font-style: italic;
}