.item {
  border-bottom: 1px solid var(--gray-xl);
}

.item:nth-child(5) {
  border-bottom: none;
}

.header {
  display: flex;
  align-items: center;
}

.filter {
  margin-left: 10px;
}

.share {
  color: var(--gray-d);
}

.body {
  box-sizing: border-box;
  height: 300px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid var(--gray-xl);

  padding: 10px 25px 10px 15px;
}