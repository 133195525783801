.root {
  --column-width: 150px;
  --attribute-width: 100px;
  --concept-margin: 10px;

  overflow-x: auto;
}

.concepts {
  display: flex;
}

.set {

}

.attribute {
  font-family: var(--font-semibold);
  width: var(--attribute-width);
  min-width: var(--attribute-width);
  box-sizing: border-box;
  padding: 15px 5px;
  margin-right: var(--concept-margin);
  display: flex;
  justify-content: flex-end;

  background-color: var(--pri-01);
  position: sticky;

  left: 0;

  font-size: 14px;
  word-break: break-word;
}

.row {
  display: flex;
  align-items: stretch;
}

.row:nth-child(even) .level {
  background-color: var(--gray-xl);
}

.row:first-child .level {
  border-top: 1px solid var(--gray-l);
}

.row:last-child .level {
  border-bottom: 1px solid var(--gray-l);
}

.level {
  width: var(--column-width);
  min-width: var(--column-width);
  box-sizing: border-box;

  margin-right: var(--concept-margin);
  text-align: center;

  border-right: 1px solid var(--gray-l);
  border-left: 1px solid var(--gray-l);

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
}

.level:last-child {
  margin-right: 0;
}

.level-wrap {
  padding: 15px 5px;
}

.none {
  width: var(--column-width);
  min-width: var(--column-width);
  box-sizing: border-box;

  border: 1px solid var(--gray-l);
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 15px;
  margin-left: var(--concept-margin);
}

.form {
  display: flex;
  margin-top: 15px;
}

.form-wrap {
  display: flex;
  align-items: center; 
}

.selection {
  width: var(--column-width);
  min-width: var(--column-width);
  box-sizing: border-box;

  display: flex;
  justify-content: center;

  margin-right: var(--concept-margin);
}

.selection:first-child {
  width: var(--attribute-width);
  min-width: var(--attribute-width);

  background-color: var(--pri-01);
  height: 24px;
  position: sticky;
  left: 0;
  z-index: 2;
}

.selection:last-child {
  margin-right: 0;
}