.root {
  margin: 0 auto;
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

.title {
  color: var(--black);
  font-family: var(--font-semibold);
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 30px;
}

.description {
  display: flex;
  margin-bottom: 30px;
}

.form {
  min-width: 250px;
  margin-bottom: 10px;
}

.form-error {
  color: var(--pri-04);
  font-family: var(--font-semibold);
  margin-top: 4px;
}

.input {
  margin-bottom: 15px;
}

.input-error {
  background-color: var(--pri-04);
}

.label {
  color: var(--black);
  font-family: var(--font-semibold);
  font-size: 16px;
}

.help {
  padding: 16px 14px;
  max-width: 400px;
}

.help-clicker {
  color: var(--gray);
  cursor: pointer;
  font-size: 12px;
  margin-top: 30px;
}

.help-bullet {
  color: var(--black);
  font-size: 13px;
}

.help-bullet:not(:last-of-type) {
  margin-bottom: 10px;
}

.consent {
  color: var(--gray);
  font-size: 12px;
  margin-top: 30px;
}

.enter-btn {
  width: 250px;
}