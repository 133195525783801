.root {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
  max-width: var(--navbar-width);
  z-index: var(--z-index-navbar);
  touch-action: manipulation;
}

.main {
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: var(--navbar-height);
  position: fixed;
  z-index: 5;
  background-color: var(--pri-01);
  box-shadow: 0 0px 0px 0px rgba(0,0,0,0.1),
              0 1px 0px 0 rgba(0,0,0,0.1),
              0 0 0px 0 rgba(0,0,0,0.12);
}

.pad {
  height: var(--navbar-height);
}

.col {
  display: flex;
  align-items: center;
  height: var(--navbar-height);
}

.box {
  position: relative;
  display: inline-flex;
  padding-bottom: 4px;
}

.badge {
  position: absolute;
  bottom: -2px;
  right: -10px;
  border-radius: 50%;
  min-width: 14px;
  padding: 3px;
  background-color: var(--pri-04);
  color: var(--pri-01);
  border: 2px solid var(--pri-01);
  font-size: 12px;
  text-align: center;
  font-family: var(--font-bold);
  line-height: 14px;
  box-shadow: 2px 2px 3px 0px rgba(238, 238, 238);
}