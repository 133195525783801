.root {

}

.wrap {

}

.main {
  margin: 10px 0 20px;
}

.topics {

}

.edit {

}

.cancel {
  margin-right: 10px;
}

.editing {
  max-width: 400px;
}

.input {
  flex-direction: row-reverse;
  padding-left: 0;
  padding-right: 0;
}

.tags {
  padding: 0;
}