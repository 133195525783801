@value 1000 from 'vars';

.root {
  width: 100%;
}

@media 1000 {
  .root {
    width: 750px;
  }
}

.wrap {

}

.main {

}

.header {

}

.indicator {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.footer {
  padding: 40px 0;
}