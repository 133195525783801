.root {

}

.wrap {
  padding: 20px 0 0;
}

.main {
  max-height: 340px;
  overflow-y: scroll;
}

.label {
  font-family: var(--font-bold);
  color: var(--font-black-l);
  margin-bottom: 15px;
}

.row {
  margin-bottom: 15px;
}

.option {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0 0;
}

.option:first-child {
  padding-top: 20px;
}

.option:last-child {
  padding-bottom: 20px;
}

.message {

}

.footer {
  padding: 0;
}

.footer a > .btn {
  margin-left: 20px;
}

.btn {
  min-width: 140px;
}

.participants {

}

.time {

}