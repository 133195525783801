.root {

}

.label {
  font-family: var(--font-bold);
  color: var(--black);
  font-size: 15px;
  margin-bottom: 5px;
}

.field {
  line-height: 32px;
  min-height: 32px;
  word-wrap: break-word;
}