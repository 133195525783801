.root {
  cursor: pointer;
  stroke-width: 2;
}

.starred {
  color: gold;
  fill: gold;
}

.unstarred {
  color: var(--gray-l);
}

.unstarred:hover {
  color: gold;
  fill: #FFF6C9;
}