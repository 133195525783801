.wrap {
  display: block;
}

.title {
  margin: 0 0 30px 0;
  text-align: center;
}

.subtitle {
    margin: 40px 0;
}

.body {
  color: var(--gray-d);
  font-family: var(--font-reg);
  font-size: 14px;
}

.section {
  margin: 30px 0;
}

.section:last-child {
  margin: 0;
}

.label {
  margin: 20px 0;
  font-family: var(--font-semibold);
  color: var(--black);
}

.input {
  border: 2px solid var(--gray-l);
  border-radius: 3px;
  box-sizing: border-box;
  color: var(--gray-d);
  padding: 8px;
  font-family: var(--font-reg);
  font-size: 14px;
}

.input::placeholder {
  color: var(--gray);
}

.emails {
  font-size: 14px;
  /* composes: input;
  margin: 0 0 20px 0;
  width: 100%; */
}

.sendto {
  min-height: 33px;
  margin: 10px 0 4px 0;
  padding: 0;
  list-style-type: none;
}

.sendtoitem {
  color: var(--pri-02);
  cursor: pointer;
  display: inline-block;
  padding: 5px 15px;
  background-color: var(--blue-l);
  border-radius: 15px;
  margin-right: 8px;
  margin-bottom: 6px;
}

.sendtoitem:last-child {
  margin-bottom: 6px;
}

.sendtoitem label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sendtoitem label svg {
  margin: 1px 0 0 3px;
}

.note {
  composes: input;
  outline: none;
  resize: none;
  min-height: 90px;
  line-height: 22px;
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: center;
  margin: 15px 0 0 0;
  height: 42px;
}

.send {
  composes: fill from 'static/css/button.css';
  padding: 5px 0;
  margin: 0;
  font-size: 14px;
  width: 100%;
}

.error {
  color: var(--sec-04);
  font-family: var(--font-semibold);
  padding: 10px 0 0 0;
  text-align: center;
}

.later {
  color: var(--pri-02);
  font-family: var(--font-bold);
  font-size: 16px;
  cursor: pointer;
  display: inline;
}

.connected {
  margin: 15px 0;
}

.email-section {
  composes: section;
  min-height: 180px;
}

@media (min-width: 600px) {
  .buttons {
    margin: 25px 0 0 0;
  }

  .send {
    padding: 10px 0;
    width: 150px;
  }

  .section {
    margin: 45px 0;
  }

  .wrap {
    padding: 0 25px;
  }
}