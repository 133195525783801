.root {
    margin-top: 40px;
}

@media(min-width: 800px){
    .root {

    }
}

.phases-root {
    margin: 0;
    margin-top: 40px;
}

@media(min-width: 800px){
    .phases-root {
        display: flex;
        align-content: center;
    }
}

.wrap {
    margin: 0 auto;
    width: 100%;
    max-width: 350px;
    padding: 0 10px;
}

@media(min-width: 600px) {
    .wrap {
        padding: 0 15px;
        max-width: 500px;
    }
}


@media(min-width: 800px) {
    .wrap {
        max-width: 650px;
    }
}

.hide {
    display: none;
}

.btn {
  width: 130px;
}

.next {
  composes: btn;
}

.complete {
    composes: next;
    background-color: var(--pri-03);
    border-color: var(--pri-03);
}

.complete:hover {
    background-color: var(--sec-03);
    border-color: var(--sec-03);
}

.next:disabled {
    background-color: var(--gray-l);
    border-color: var(--gray-l);
}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.back {
    cursor: pointer;
    font-family: var(--font-bold);
    color: var(--black-l);
    font-size: 18px;
}

.back-disabled {
  color: var(--gray-m);
}

.header {
    min-height: 150px;
}

@media(min-width: 450px){
    .header {
        min-height: 100px;
        margin-bottom: 10px;
    }
}

.title {
    margin-bottom: 20px;
    font-family: var(--font-bold);
    font-size: 24px;
}

@media (min-width: 600px) {
    .title {
        display: flex;
        align-content: center;
    }
}

.title-text {
    color: var(--black);
}

.optional-text {
    color: var(--gray);

}

@media (min-width: 600px) {
    .optional-text {
        margin-left: 10px;
    }
}

.subtitle {
    font-family: var(--font-reg);
    font-size: 18px;
    color: var(--gray-d);
}

.body {
    margin-bottom: 25px;
    min-height: 250px
}

.sidebar {
    display: none;
}

@media(min-width: 900px){
    .sidebar {
        display: block;
        margin-left: 35px;
        width: 215px;
        min-width: 215px;
    }
}

.lower-buttons {
    display: flex;
    justify-content: flex-end;
}

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
