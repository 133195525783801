.btns {
  margin-top: 10px;
}

.label {
  font-family: var(--font-semibold);
  margin-bottom: 10px;
  color: var(--gray-d);
}

.action {
  margin-bottom: 20px;
}

.conditions {
  margin: 0 15px;
}

.condition {
  padding: 15px 0;
  border-bottom: 1px solid var(--sec-01);
}

.condition:first-of-type {
  padding-top: 10px;
}

.condition:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}

.add-condition-btn {
  margin-top: 15px;
  margin-left: 15px;
}