.root {

}

.wrap {
  margin-bottom: 10px;
}

.main {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 30px;
}

.table {
  margin-bottom: 62px;
}

.title {
  width: 250px;
}

.currency {
  width: 120px;
}

.amount {
  width: 150px;
}

.remove {
  display: flex;
  justify-content: center;
  align-items: center;
}

.minus {
  color: var(--pri-04);
}

.add {
  padding: 10px 0;
}

.plus {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  color: var(--pri-02);
  font-family: var(--font-bold);
}

.header {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  color: var(--gray-d);
  font-family: var(--font-semibold);
  margin-bottom: 15px;
}

.readonly {
  letter-spacing: -0.25px;
  line-height: 32px;
}