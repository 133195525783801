.root {
  position: relative;
  min-height: 50px;
}

.buttons {
  margin-top: 20px;
}

.editor {
  position: relative;
  width: 100%;
  padding-right: 50px;
  box-sizing: border-box;

  --line-height: 24px;
}

.editor :global(.remirror-editor[contenteditable="true"]) {
  min-height: calc(5 * var(--line-height));
  max-height: calc(15 * var(--line-height));
}

.toolbar {
  margin-bottom: 10px;
}

.text {
  display: flex;
  align-items: flex-start;
  min-height: 35px;
}

.number {
  margin-right: 8px;
}

.viewRoot {
  display: flex;
  justify-content: space-between;
}

.menus {
  display: flex;
  align-items: center;
  gap: 10px;
}