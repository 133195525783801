.root {
  height: calc(100vh - 80px - var(--navbar-height));
}

.wrap {
  display: flex;
  flex-direction: column;
  margin: 40px auto;
  height: 100%;
  width: 360px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 20px 0;
}

.title {
  font-size: 28px;
  font-family: var(--font-bold);
  margin: 0 0 14px 0;
  text-align: center;
}

.date {
  color: var(--gray-d);
  font-size: 14px;
  margin: 0 0 10px 0;
  height: 17px;
}

.subtitle {
  font-size: 15px;
  line-height: 24px;
  text-align: center;
}

.content {
  
}

.join {
  width: 100%;
}

.actions {
  margin: 30px 0 0 0;
}