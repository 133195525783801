.root {

}

.wrap {

}

.main {
  max-width: 450px;
  margin: 0 auto;

}

.modal {
  width: 630px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
}

.title {
  font-size: 29px;
}

.subtitle {
  max-width: 400px;
  margin: 0 auto;
}

.graphic {
  width: 250px;
}

.icon {
  text-align: center;
  width: 70px;
}

.icon svg {
  height: 35px;
  width: 35px;
  color: var(--black-l);
}

.bulb {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  height: 80px;
  width: 80px;
  box-shadow: 1px 2px 16px 2px rgb(92, 115, 173);
}

.footer {
  display: flex;
  justify-content: center;
  padding: 5px 0;
}

.btn {
  background-color: var(--pri-02);
  color: var(--pri-01);
  font-family: var(--font-bold);
  width: 250px;
  height: 45px;
  border-radius: 3px;
}