.btn {
  font-size: 14px;
  font-family: var(--font-semibold);
  color: var(--pri-02);
  height: 30px;
  padding: 0 25px;
  border-radius: 20px;
  border: none;
  background-color: var(--pri-01);
}

.action {
  min-width: 100px;
  text-align: right;
}

@media (min-width: 800px) {
  .btn:hover {
    background-color: var(--blue-l);
    border: none;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}
