.root {
  width: 100%;
  overflow: hidden;
}

.wrap {
  padding: 20px;
}

.main {
  position: relative;
  height: 315px;
}

.video {
  width: 100%;
  min-height: 56.25%;
}

.delete {
  position: absolute;
  top: 14px;
  right: 12px;
  width: 27px;
  height: 27px;
  background-color: rgba(50, 50, 50, 0.42);
  border-radius: 50%;
  transition: background-color 200ms ease-in-out 30ms;
}

.delete:hover {
  background-color: var(--black);
}

.x {
  color: var(--pri-01);
  font-size: 14px;
  line-height: 27px;
}