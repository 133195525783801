.root {

}

.wrap {
  margin-bottom: 20px;
}

.main {
  --gap: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--gap);
}

.label {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  margin-right: 5px;
}

.tag {
  border-color: var(--sec-01);
  color: var(--gray-d);
}