.root {
  padding: 0 20px 0 0;
}

@media (min-width: 800px) {
  .root {
    padding: 0;
  }
}

.header {
  display: flex;
  text-align: center;
  margin-bottom: 25px;
}

.header .label {
  display: none;
}

@media (min-width: 800px) {
  .header .label {
    display: initial;
  }
}

.header .value {
  display: none;
}

@media (min-width: 800px) {
  .header .value {
    display: initial;
  }
}

.row {
  word-break: break-word;
  padding: 5px 0;
  min-height: 38px;
  margin-bottom: 35px;
}

@media (min-width: 800px) {
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}

.content, .header-content {
  width: calc(100% - 3px);
}

@media (min-width: 800px) {
  .content, .header-content {
    max-width: 400px;
  }
}

.label {
  color: var(--black-l);
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

@media (min-width: 800px) {
  .label {
    width: 160px;
    margin-right: 25px;
    margin-bottom: 0;
    font-family: var(--font-reg);
  }

  .no-slider .label {
    width: unset;
    min-width: 160px;
    display: flex;
    flex-grow: 1;
    max-width: 400px;
  }
}

.value {
  margin: 5px 0 15px 0;
  width: 30px;
}

@media (min-width: 800px) {
  .value {
    margin: 0 20px 0 0;
    width: 30px;
  }
}

.value input {
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid var(--gray-l);
  color: var(--black-l);
  font-family: var(--font-reg);
  font-size: 16px;
  padding: 0 2px;
  line-height: 18px;
  text-align: right;
  width: 100%;
}

.value input:focus {
  border-bottom: 1px solid var(--pri-02);
}

.value input::placeholder {
  color: var(--gray);
  text-align: right;
}

.validation {
  color: var(--pri-04);
}

.total-row {
  composes: row;
  width: 500px;
}

.total-row .label {
  color: var(--black-l);
  font-family: var(--font-semibold);
}

.total {
  width: 75px;
  display: flex;
  justify-content: flex-end;
}