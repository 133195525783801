.root {
  display: flex;
}

.wrap {
  display: flex;
  align-items: center;
  border: 1px solid var(--gray-l);
  border-radius: 8px;
  padding: 4px 6px;
}

.rec {
  font-family: var(--font-bold);
}

.icon {
  display: flex;
  color: var(--pri-04);
}

.icon svg {
  height: 20px;
  width: 20px;
}