.popper {
  z-index: var(--z-index-popper);
}

.link-button {
  display: block;
  padding: 10px 20px;
  margin: -10px -20px;
}

.export-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  min-width: 23px;
  height: 23px;
  padding-left: 5px;
}

.menu {
  position: relative;
  z-index: 7;
}