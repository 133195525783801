.row {
  display: flex;
  align-items: center;
}

.label {
  composes: label from './Builder.css';
}

.expression {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.rule-dropdown {
  width: 200px;
}

.edit {
  cursor: pointer;
  color: var(--hyperlink);
}

.margin-left {
  margin-left: 5px;
}