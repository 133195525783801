.row {
  padding: 15px;
  border-bottom: 1px solid var(--gray-xl);
  font-family: var(--font-semibold);
  color: var(--gray-d);
  min-height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.user {
  padding-left: 50px;
}

.selectable-option {
  cursor: pointer;
}

.selectable-option:hover {
  background-color: var(--blue-xl);
}