.modal {
  width: 500px;
}

.format {
  display: flex;
  flex-direction: column;
}

.label {

}

.field {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
}

.footer {
  padding: 25px 0 10px;
}