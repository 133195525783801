.root {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.separator {
  margin: 0 5px;
  color: var(--gray-d);
}

.crumb {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: var(--font-semibold);
  padding: 8px;
  color: var(--gray-d);
  border-radius: 8px;
}

.crumb:hover {
  background-color: var(--gray-xl);
}

.stretch {
  margin: 10px 10px 0 0;
}