@value 500 from 'vars';
@value 1100 from 'vars';

.root {
  border-bottom: 1px solid var(--gray-xl);
  padding: 25px;
}

.wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.main {
  display: flex;
  width:  100%;
}

@media 1100 {
  .main {
    width:  calc(100% - 180px);
  }
}

.info {
  width: 100%;
  overflow: hidden;
  margin: 0 15px;
}

@media 1100 {
  .info {
    margin: 0 25px;
  }
}

@media (min-width: 1200px) {
  .info {
  }
}

.avatar {
  width: 55px;
  height: 55px;
}

@media 1100 {
  .avatar {
    width: 55px;
    height: 55px;
  }
}

.name {
  width: calc(100% - 160px);
  margin-bottom: 25px;
}

@media 1100 {
  .name {
    width: 100%;
    margin-bottom: 7.5px;
  }
}

.career {

}

.tags {
  display: none;
}

@media 500 {
  .tags {
    display: block;
  }
}

.btn {
  width: 150px;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: -10px;
  right: 0;
  width: 150px;
}

@media 1100 {
  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    margin-left: 25px;
  }
}

.actions > *:not(:last-child) {
  margin-bottom: 7.5px;
}
