.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 6px;
}

.title {
  color: var(--gray-d);
  font-family: var(--font-bold);
  font-size: 18px;
}

.clear {
  color: var(--pri-02);
  cursor: pointer;
  font-family: var(--font-semibold);
  font-size: 14px;
}

.file-types {
  display: flex;
  flex-direction: column;
}

.file-type {
  color: var(--gray-d);
  font-size: 16px;
  box-sizing: border-box;
}

.file-type:not(:last-of-type) {
  margin: 0 0 6px 0;
}