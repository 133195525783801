.footer {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.buttons {
  flex: 1;
  display: flex;
  justify-content: center;
}

.buttons > *:nth-child(2) {
  margin-left: 15px;
}

.remove {
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-icon {
  cursor: pointer;
}

.remove-icon:hover {
  color: var(--pri-04);
}

.remove-inline {
  display: inline-flex;
}

.body {
  max-width: 500px;
  margin: 0 auto;
}

.label {
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

.input {
  margin-bottom: 15px;
}