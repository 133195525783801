.root {

}

.wrap {

}

.main {

}

.header {

}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px 0;
}

.tag {
  display: initial;
  background-color: var(--gray-xl);
  border: none;
  border-radius: 3px;
  padding: 3px 5px;
  color: var(--black);
  font-size: 14px;
  font-family: var(--font-reg);
}

.tag svg {
  color: var(--gray-d);
  height: 22px;
  width: 22px;
  margin-left: 5px;
}

.tag svg:hover {
  color: var(--gray-d);
}

.removable {
  display: flex;
}

.removable .tag {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.action {
  display: flex;
  gap: 5px;
}

.edit {
  font-family: var(--font-semibold);
  font-size: 14px;
  color: var(--pri-02);
}

.inputroot {
  max-width: 500px;
}

.input {
  font-size: 16px;
  letter-spacing: 0;
  max-width:
}

.save {
  color: var(--pri-03);
}

.cancel {
  color: var(--gray-d);
}

.save:disabled,
.cancel:disabled {
  color: var(--gray-l);
}

.save svg,
.cancel svg {
  width: 30px;
  height: 30px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--sec-01);
  height: 100%;
  width: 30px;
  color: var(--gray-d);
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
}

.icon:hover {
  background-color: var(--gray-l);
  color: var(--black-l);
}

.icon:active {
  background-color: var(--sec-01);
  color: var(--gray-d);
}

.icon svg {
  stroke-width: 3px;
}