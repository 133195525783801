.root {

}

.wrap {
  padding: 20px 10px;
}

.main {
  max-width: 1260px;
  margin: 0 auto;
}

.stack {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tab {
  background-color: var(--pri-01);
}

.tab:hover {
  background-color: var(--gray-xl);
}

.tabview {
  max-width: 900px;
}

.skel {

}

.view {
  padding: 20px 0;
}

.tile {
  height: calc(100vh - 200px);
}