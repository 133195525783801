@value 1100 from 'vars';

.root {

}

.main {
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 115px;
  min-height: calc(100vh - 390px);
}

@media 1100 {
  .main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.h5 {
  font-size: 22px;
  color: var(--black);
  font-family: var(--font-bold);
  margin: 20px 0;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 20px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 400px;
  margin: 0 auto;
  padding: 0 20px;
}

@media 1100 {
  .form {
    width: 400px;
    margin: 0 20px;
    padding: 0
  }
}

.row {
  min-height: 80px
}

.row:last-child {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@media 1100 {
  .row {
    /* min-height: 110px; */
  }
}

.btn {
  width: 100%;
  height: 50px;
}

.label {
  font-size: 17px;
  color: var(--gray-d);
  font-family: var(--font-bold);
  margin-bottom: 5px;
}

.error {
  color: var(--pri-04);
  font-size: 15px;
  font-family: var(--font-semibold);
  margin-left: 5px;
}

.submitted {
  font-family: var(--font-reg);
  color: var(--gray-d);
  font-size: 16px;
  margin-bottom: 25px;
}

.box {
  margin-top: 15px;
}

.textarea {
  border: 2px solid var(--gray-l);
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;
  resize: none;
  font-family: var(--font-reg);
  font-size: 16px;
  outline: none;
  padding: 5px;
}

.textarea:focus {
  border-color: var(--pri-02);
}

.information {
  width: 100%;
}

@media 1100 {
  .information {
    width: 450px;
  }
}

.card {
  display: none;
  max-width: 450px;
  box-shadow: 2px 2px 6px 3px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 30px;
}

@media 1100 {
  .card {
    display: block;
  }
}

.headline {
  font-size: 22px;
  color: var(--black);
  font-family: var(--font-bold);
  margin-bottom: 30px;
}

.info {
  display: flex;
  font-family: var(--font-reg);
  color: var(--gray-d);
  font-size: 16px;
  margin-bottom: 30px;
}

.info:last-child {
  margin-bottom: 0;
}

.icon {
  height: 26px;
  margin-right: 10px;
}

.required {
  color: var(--pri-02);
}

.captcha {
  margin-top: 20px;
}