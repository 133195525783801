.root {
  
}

.header {
  font-size: 14px;
  font-family: var(--font-semibold);
  margin: 0 0 30px 0;
}

.copy {
  font-size: 14px;
  margin: 0 0 30px 0;
}

.btns {

}

.share {
  display: flex;
  align-items: center;
  height: 48px;
}

.share svg {
  margin: 0 6px 0 0;
}

.amount {
  font-family: var(--font-semibold);
}