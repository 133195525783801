.root {
  display: flex;
  align-items: center;
  gap: 10px;
}

.title {

}

.badge {
  font-size: 12px;
  line-height: 12px;
  background-color: var(--blue-l);
  color: var(--sec-05);
  padding: 4px;
  border-radius: 4px;
}

.popover {
  background-color: transparent;
  border: none;
  margin-top: 5px;
  box-shadow: none;
}

.menu {
  width: 260px;
  background-color: var(--pri-01);
  box-shadow: 0 2px 6px 2px rgba(60,64,67,.15);
  border-radius: 6px;
  overflow: hidden;
}

.arrow {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 18px solid var(--pri-02);
  margin-top: -2px;
}

.header {
  padding: 15px;
  color: var(--pri-01);
  background-color: var(--pri-02);
  font-size: 15px;
  font-family: var(--font-bold);
}

.body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.subtitle {
  font-size: 14px;
}

.btn {
  width: 100%;
}

.graphic {
  border: 1px solid var(--sec-01);
}

.row {
  display: flex;
  align-items: center;
  height: 45px;
  width: 100%;
}

.row:first-child {
  border-bottom: 1px solid var(--sec-01);
}

.row:last-child {
  border-top: 1px solid var(--sec-01);
}

.bullet {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  min-width: 45px;
}

.circ {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: gold;
}

.line {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
}

.skel {
  width: 150px;
  height: 20px;
  background-color: var(--sec-01);
  border-radius: 2px;
}