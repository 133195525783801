@value 800, 1100, 1200, width-navbar from 'vars';

.root {
  height: 100%;
}

.wrap {
  padding-top: 20px;
}

.main {
  max-width: var(--navbar-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

@media 1200 {
  .main {
    flex-direction: row;
    justify-content: space-between;
  }
}

.col {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

@media 1200 {
  .col {
    display: flex;
    flex: auto;
    flex-direction: column;

    width: unset;
    max-width: unset;
    margin: unset;
  }
}

.col:nth-of-type(2) {
  order: 2;
}

@media 1200 {
  .col:nth-of-type(2) {
    width: calc(100% - 660px);
    max-width: 600px;
    order: 0;
  }
}

@media (min-width: width-navbar) {
  .col:nth-of-type(2) {
    max-width: 600px;
    min-width: 550px;
  }
}

@media 1200 {
  .col:not(:nth-of-type(2)) {
    margin: 0 20px;
  }

  .col:not(:nth-of-type(2)),
  .col:not(:nth-of-type(2)) .fixed {
    flex: 1 1 290px;
    min-width: 260px;
    max-width: 290px;
    width: 100%;
    box-sizing: border-box;
  }
}

@media 1200 {
  .fixed {
    position: fixed;
    top: calc(20px + var(--top-offset));
  }
}