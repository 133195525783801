@value 800, 1100 from 'vars';

.root {

}

.wrap {
  position: relative;
  padding: 0 20px;
  max-width: 1280px;
  margin: 0 auto;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100vh - var(--navbar-height));
  min-height: 640px;
  z-index: 1;
}

.header {
  margin-bottom: 40px;
}

.root .icon {
  color: var(--pri-03);
  width: 80px;
  height: 80px;
}

@media 800 {
  .root .icon {
    width: 90px;
    height: 90px;
  }
}

.title {
  font-size: 30px;
  font-family: var(--font-bold);
  color: var(--black-d);
  margin-bottom: 40px;
}

@media 800 {
  .title {
    font-size: 34px;
  }
}

@media 1100 {
  .title {
    font-size: 40px;
  }
}

.subtitle {
  font-size: 20px;
  font-family: var(--font-semibold);
  color: var(--gray-d);
  margin-bottom: 100px;
}

@media 800 {
  .subtitle {
    font-size: 24px;
  }
}

@media 1100 {
  .subtitle {
    font-size: 30px;
  }
}

.btn {
  width: 300px;
}

@media 800 {
  .btn {
    width: 220px;
  }
}

@media 800 {
  .bg-1 {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 50%;
    height: 50%;
    background-image: url('static/images/website/thank-you/bg-dots-top-left.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
  }

  .bg-2 {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 50%;
    height: 50%;
    background-image: url('static/images/website/thank-you/bg-dots-bottom-right.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
  }
}