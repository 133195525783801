.footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sources {
  height: 30px;
  width: 200px;
  margin-top: 15px;
}

.questions {
  width: 400px;
}

.submit {
  margin-left: 10px;
}