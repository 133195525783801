.row {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: -12px;
  margin-right: 6px;
}

.empty-icon {
  width: 12px;
}