.text {
  border: none;
  border-bottom: 1px solid var(--gray-l);
  line-height: 23px;
  font-family: var(--font-reg);
  font-size: 16px;
  width: 100%;
}

.checkbox {
  position: relative;
}

.theme {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  overflow: hidden;
}

.open-ended {

}

.open-ended input {
  margin-top: 5px;
}

.group {
  display: flex;
  flex-direction: column;
}