.root {

}

.wrap {
  padding: 20px 0;
  max-width: 900px;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.na {
  color: var(--gray);
}

.editable {
  display: flex;
  justify-content: space-between;
}

.title {

}

.btns {

}

.toolbar {
  margin-bottom: 10px;
}