@value 1200 from 'vars';

.root {

}

.content {
  min-height: 325px;
}

.empty {
  text-align: center;
  padding-top: 35px;
}

.empty-img {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  margin-bottom: 10px;
}

.empty-txt {
  font-family: var(--font-semibold);
  color: var(--gray-d);
}

.row {
  display: flex;
  align-items: flex-start;
  padding: 10px 10px 10px 15px;
  border-bottom: 1px solid var(--gray-xl);
}

.avatar {
  margin-right: 15px;
}

.details {
  color: var(--gray-d);
  font-size: 14px;
  word-wrap: break-word;
  overflow: hidden;
}

.name {
  font-family: var(--font-semibold);
}

.name:hover {
  color: var(--hyperlink);
  text-decoration: underline;
}

.title {
  margin-top: 5px;
}