@value 500, 600, 800, 1100, 1200 from 'vars';

.root {
  height: 100%;
}

.wrap {
  padding: 20px 10px 0;
}

@media 800 {
  .wrap {
    /* padding-top: 20px; */
  }
}

.main {
  max-width: 1260px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

@media 1200 {
  .wrap {
    padding: 20px 10px;
  }

  .main {
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
  }
}