.grid-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-form img {
  max-width: 33%;
}

.default-form img {
  max-width: 200px;
}