.root {
  padding: 20px 15px;
}

.article {
  max-width: 600px;
}

.wrap {
  border-radius: 8px;
  border: 1px solid var(--sec-01);
  overflow: hidden;
}

.main {

}

.header {
  margin-bottom: 15px;
}

.base {
  display: flex;
  flex-direction: row;
  height: 125px;
}

.full {
  display: flex;
  flex-direction: column;
}

.cover {
  position: relative;
  padding-top: 56%;
  width: 100%;
  overflow: hidden;
}

.base .image {
  height: 125px;
  width: 125px;
}

.full .image {
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.url {
  display: -webkit-box;
  position: relative;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 14px;
  color: var(--black-l);
}

.description {
  display: -webkit-box;
  position: relative;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: var(--black-l);
}

.base .description {
  -webkit-line-clamp: 1;
}

.full .description {
  -webkit-line-clamp: 2;
}

.title {
  display: -webkit-box;
  position: relative;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: 20px;
  font-family: var(--font-semibold);
  color: var(--black-l);
}

.base .title {
  -webkit-line-clamp: 1;
}

.full .title {
  -webkit-line-clamp: 1;
}

.info {
  background-color: #fafafa;
  height: 145px;
  width: 100%;
}

.details {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
}

.refetching {
  height: 20px;
  text-align: right;
}