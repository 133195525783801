.root {
  text-align: center;
  color: var(--gray-d);
  margin: 40px 0 25px 0;
}

.img {
  margin: 0 auto;
  width: 125px;
  height: auto;
}

.text {
  margin-top: 40px;
  font-family: var(--font-reg);
}

.title {
  margin-top: 40px;
  font-family: var(--font-semibold);
}