.root {
  position: relative;
}

.toolbar {
  margin-bottom: 10px;
}

.editor {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  --line-height: 22px;
  min-height: calc(12 * var(--line-height));
}