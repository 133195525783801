.input {
  composes: input from 'static/css/input.css';
  font-family: var(--font-reg);
}

.input:read-only {
  color: var(--gray);
}

.results {
  position: relative;
  height: 100%;
}

.list {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--pri-01);
  border-radius: 4px;
  box-shadow: 0px 1px 2px 1px rgba(238,238,238, 1);
  z-index: 3;

  max-height: 300px;
  overflow-y: auto;
}

.open {
  display: block;
  margin-bottom: 50px;
}

.item {
  padding: 10px 15px;
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--gray-d);
  cursor: pointer;
}

.item:hover {
  background-color: var(--blue-l);
}

.highlight {
  composes: item;
  background-color: var(--blue-l);
}

.recommended {
  color: var(--pri-02);
}