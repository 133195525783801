.root {

}

.item {
  margin-bottom: 10px;
}

.title {
  font-family: var(--font-semibold);
}

.ul {
  padding-inline-start: 20px;
  margin: 0;
}

.li {
  padding: 5px 0;
}