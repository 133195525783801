.root {
  padding: 20px;
  border: 1px solid var(--gray-l);
  border-radius: 6px;
}

.add-item {
  margin: 15px 0;
  display: flex;
  justify-content: center;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--sec-01);
  margin: 20px 0;
}