.root {

}

.wrap {

}

.main {
  display: flex;
  gap: 25px;
  min-height: 200px;
  padding: 20px 0;
}

.header {

}

.item {
  position: relative;
  height: 155px;
  width: 155px;
  border: 1px solid var(--sec-01);
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
}

.item:hover {
  background-color: var(--blue-xl);
}

.value {
  font-size: 35px;
  color: var(--black-l);
  font-family: var(--font-semibold);
}

.title {
  color: var(--black-l);
  font-family: var(--font-semibold);
  font-size: 18px;
  margin: 0 0 20px;
}

.desc {
  font-size: 15px;
}