.root {

}

.wrap {

}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 20px 0;
}

.label {
  font-family: var(--font-bold);
}

.footer {
  padding: 20px 0;
  max-width: 400px;
  margin: 0 auto;
}

.btn {
  padding: 0;
  height: 40px;
  width: 100%;
  border-radius: 4px;
}

.compliance {

}

.compliance .main {
  flex-direction: column;
  align-items: flex-start;
  max-width: 480px;
  margin: 0 auto;
}

.message {

}

.confirmation {
  padding: 15px 0 0;

}

.confirm {
  display: flex;
  align-items: center;
  gap: 5px;
}

.agree {
  font-family: var(--font-semibold);
}