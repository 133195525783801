.desktop {
  display: none;
}

.mobile {

}

@media (min-width: 800px) {
  .desktop {
    display: block;
  }
  
  .mobile {
    display: none;
  }
}

.hide {
  display: none;
}