.container {
  position: relative;
  z-index: 2;
  transform: translateY(-50%);
}

.root {
  min-width: 350px;
  border-radius: 4px;
  background-color: var(--pri-01);
  box-shadow: 0 2px 6px 2px rgb(60 64 67 / 15%);
  font-size: 14px;
  font-family: var(--font-reg);
}

.wrap {
  padding: 15px;
}

.main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.header {
  font-size: 18px;
  color: var(--black-l);
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

.row {
  width: 100%;
  margin-bottom: 10px;
}

.input {
  --border-width: 2px;
  --padding-horizontal: 5px;
  font-family: var(--font-reg);
  font-size: 16px;
  padding: 10px var(--padding-horizontal);
  border: var(--border-width) solid var(--gray-l);
  border-radius: 5px;
  width: calc(100% - ((var(--border-width) * 2) + (var(--padding-horizontal) * 2)));
}

.input:focus {
  border: var(--border-width) solid var(--pri-02);
}

.input::placeholder {
  color: var(--gray);
}

.btns {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-top: 10px;
}

.label {
  font-size: 12px;
  color: var(--black-l);
  font-family: var(--font-bold);
  margin-bottom: 5px;
}

.btn {
  padding: 5px 10px;
  font-size: 14px;
  width: 130px;
  min-width: 90px;
}

.dict-row {
  display: flex;
  gap: 5px;
  align-items: center;
}