.root {
  height: 40px;
  box-sizing: border-box;
  border: 2px solid var(--sec-01);
  border-radius: 3px;
  width: 100%;
}

.textarea {
  font-family: var(--font-reg);
  font-size: 16px;
  color: var(--black);
  outline: none;
  resize: none;
  border: none;
  padding: 8px;
  width: calc(100% - 16px);
}

.textarea::placeholder {
  color: var(--gray);
}

.input {
  padding: 2px 8px;
  width: calc(100% - 16px);
}

.focusable {
  display: none;
}

.btn {
  composes: brick from 'css/button.css';
  min-width: 125px;
  border-radius: 3px;
  margin-left: 10px;
}