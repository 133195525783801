.date,
.time {
  border: none;
  box-sizing: border-box;
  color: var(--black);
  font-family: var(--font-reg);
  font-size: inherit;
  width: 100px;
  cursor: pointer;
}

.range {
  border: 1px solid var(--gray-l);
  border-radius: 4px;
  color: var(--black);
  font-family: var(--font-reg);
  font-size: inherit;
}

.wrap {
  width: 100%;
}

.input {
  border-bottom: 2px solid var(--gray-l);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close {
  cursor: pointer;
  height: 22px;
  width: 22px;
  color: var(--gray-d);
}

.input input {
  font-family: var(--font-reg);
  font-size: 16px;
  color: var(--gray-d);
  line-height: 24px;
}

.custom-picker {
  box-shadow: 0 2px 6px 2px rgba(60,64,67,.15);
  border: 1px solid var(--gray-l) !important;
}

.custom-picker :global(.react-datepicker__current-month) {
  font-family: var(--font-semibold);
  color: var(--black);
}

.custom-picker :global(.react-datepicker__day-name) {
  font-family: var(--font-semibold);
  color: var(--black-l);
}

.custom-picker :global(.react-datepicker__header) {
  border-bottom: none;
  padding: 10px 0;
}

.custom-picker :global(.react-datepicker__day) {
  color: var(--black-l);
  font-family: var(--font-semibold);
}

.custom-picker :global(.react-datepicker__day--selected) {
  color: var(--pri-01);
  background-color: var(--pri-02);
}

.custom-picker :global(.react-datepicker__day:hover) {
  color: var(--pri-02);
  background-color: var(--blue-l);
}

.custom-picker :global(.react-datepicker__day--disabled) {
  background-color: var(--pri-01);
  color: #ccc;
}

.custom-picker :global(.react-datepicker__day--disabled:hover) {
  color: #ccc;
  background-color: var(--pri-01);
}

.custom-picker :global(.react-datepicker-popper[data-placement="top"] .react-datepicker__triangle::before)
{
  border-top-color: var(--gray-l);
}

.custom-picker :global(.react-datepicker-popper[data-placement="top"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before) {
  border-bottom-color: var(--gray-l);
}