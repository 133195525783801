.root {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 80px 0 0;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.message {
  margin: 15px 0;
}

.header .message .text {
  font-size: 24px;
}

.header .message:last-of-type .text {
  font-family: var(--font-reg);
  margin: 20px 0;
  font-size: 22px;
}

.main .message {
  display: flex;
  align-items: center;
}

.icon {
  height: 30px;
  width: 30px;
  color: var(--gray-d);
  stroke-width: 2.5;
  margin-right: 10px;
}

.btn {
  margin-left: 5px;
  color: var(--pri-02);
  text-decoration: underline;
}

.text {
  font-family: var(--font-semibold);
  font-size: 22px;
}

.logo {

}

.img {
  width: 80px;
  margin: 15px 0;
}

.footer {
  margin: 60px 0 40px;
  font-family: var(--font-helv);
  font-size: 22px;
}