.root {
  --root-padding: 10px;
  display: flex;
  padding: var(--root-padding);
}

.simple-message {
  font-size: 24px;
  text-align: center;
  margin-top: 80px;
  font-family: var(--font-semibold);
}

.controls {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  max-width: 1000px;
}

.form-root {
  min-width: 1000px;
  margin: 0 auto;
}