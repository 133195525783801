.icon {
  composes: icon from '../style.css';
  height: 40px;
  width: 40px;
}

.active {
  composes: active from '../style.css';
}

.icon svg {
  height: 30px;
  width: 30px;
}

.participants {
}

.header {
  font-size: 18px;
  font-family: var(--font-bold);
  margin: 0 0 8px;
}

.row {
  display: flex;
  align-items: flex-start;
  margin: 0 0 20px 0;
}

.details {
}

.name {
  font-size: 14px;
  margin: 0 0 4px 0;
}

.avatar {
  margin: 0 10px 0 0;
  height: 38px;
  width: 38px;
  min-height: 38px;
  min-width: 38px;
}

.tag {
  display: inline-block;
  color: var(--pri-01);
  font-size: 12px;
  font-family: var(--font-semibold);
  border-radius: 5px;
  padding: 3px 6px;
}

.organizer {
  composes: tag;
  background-color: var(--pri-03);
}

.attendee {
  composes: tag;
  background-color: var(--gray-d);
}

.badge {
  composes: badge from '../style.css';
}

.badge-alert {
  composes: badge from '../style.css';
  background-color: var(--pri-04);
}