.player {
  cursor: pointer;
  height: 40px;
  width: 40px;
  position: relative;
  flex-shrink: 0;
}

.icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
}

.play-icon {
  composes: icon;
  margin-left: 1px;
  color: black;
  fill: black;
}

.pause-icon {
  composes: icon;
  color: var(--pri-02);
  fill: var(--pri-02);
}