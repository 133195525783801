.root {

}

.wrap {
  position: relative;
  padding: 0 20px;
  max-width: 1280px;
  margin: 0 auto;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.icon {
  color: var(--gray);
}

.title {
  font-family: var(--font-bold);
  font-size: 30px;
}

.msg {
  font-size: 18px;
}

.link {
  color: var(--pri-02);
  text-decoration: underline;
  font-size: 18px;
}

.row {
  margin-bottom: 30px;
}