.row {
  display: flex;
  align-items: center;
}

.label {
  margin-left: 10px;
}

.wrapped {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -3px;
}

.wrapped span {
  margin-top: 3px;
}

.bold {
  font-family: var(--font-semibold);
  margin-right: 5px;
}

.subtitle {
  color: var(--gray-m);
}