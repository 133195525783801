.root {
  width: 450px;
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.avatar {
  border-radius: 20px;
  height: 90px;
  width: 90px;
  cursor: default;
}

.title {
  font-family: var(--font-bold);
}

.body {
  margin-bottom: 20px;
}

.subtitle {
  text-align: center;
}

.submit {
  font-size: 18px;
  width: 300px;
  height: 50px;
  border-radius: 3px;
}

.error {
  position: relative;
  text-align: center;
  height: 25px;
}

.input {
  display: flex;
  align-items: flex-start;
  height: 57px;
}

.input:nth-child(2) {
  margin-bottom: 0;
}

.login {
  margin-bottom: 20px;
}

.login .error {
  position: relative;
  top: 0;
}

.signup {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  max-width: 320px;
}

.e {
  text-align: center;
  font-size: 14px;
  font-family: var(--font-helv);
  color: var(--pri-04);
}

.link {
  color: var(--pri-02);
  cursor: pointer;
}