.image-container {
  position: relative;
  display: inline-block;
}

.image-container img {
  max-width: 100%;
  object-fit: contain;
}

.remove-image {
  color: red;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}

.image-input-container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.piped-preview {
  display: flex;
  gap: 20px;
  align-items: center;
}