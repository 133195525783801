@value 1200 from 'vars';

.sidebar,
.forehead {
  display: none;
}

@media 1200 {

  .sidebar {
    display: block;
  }

  .forehead {
    display: block;
    margin: 0 0 15px 0;
    height: 50px;
  }
}

.card {
  padding: 20px;
}