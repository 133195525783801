.skel {

}

.bar {
  display: flex;
  gap: 5px;
}

.tab {
  opacity: 0.6;
}

.view {
  padding: 20px 0;
}