.row {
  display: flex;
  align-items: center;
}

.action-dropdown {
  width: 200px;
  margin-right: 15px;
}

.classification-input {
  width: 250px;
}