.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 32px;
  height: 34px;

  color: var(--gray-d);
  border-radius: 4px;
}

.btn:last-of-type {
  margin-bottom: 0;
}

.btn:hover {
  background-color: var(--gray-xl);
  color: var(--black-l);
}

.enabled,
.enabled:hover {
  background-color: var(--gray-d);
  color: var(--pri-01);
}

.btn:disabled,
.btn:disabled:hover {
  color: var(--gray-l);
  background-color: var(--sec-01);
  cursor: default;
}

.input {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.toolbar {
  display: inline-flex;

  border: 1px solid var(--sec-01);
  border-radius: 5px;
  padding: 4px;

  align-items: center;
}

.toolbar > * {
  margin-right: 4px;
}