@value 1000 from 'vars';
@value 800 from 'vars';

.root {
  position: relative;
  --top-title: var(--navbar-height);
  --height-title: 0px;
  --top-toolbar: calc(var(--top-title) + var(--height-title));
  --height-toolbar: 50px;
  --top-header: calc(var(--top-toolbar) + var(--height-toolbar));
}

.wrap {
  display: flex;
  position: relative;
}

.main {
  height: 100%;
}

.header {
  position: sticky;
  top: var(--top-title);
  background-color: var(--pri-01);
  z-index: 2;
}

.title {
  text-align: center;
  font-size: 24px;
  font-family: var(--font-bold);
  height: var(--height-title);
  line-height: 50px;
}

@media 1000 {
  .title {
    margin-left: 270px;
  }
}

.sidebar {
  display: none;
  position: sticky;
  top: var(--top-toolbar);
  min-width: 270px;
  height: 100%;
}

@media 1000 {
  .sidebar {
    display: block;
  }
}

.gutter {
  padding: 50px 20px 0 20px;
}

.picker {
  padding-top: 10px;
  margin-left: 10px;
}

.calendar {
  padding-bottom: 100px;
}

@media 1000 {
  .calendar {
    padding-bottom: 50px;
  }
}

.button {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 2;
}

@media 800 {
  .button {
    display: none;
  }
}

.root :global(.fc-right),
.root :global(.fc-left) {
  width: 115px;
  height: 100%;
}

.root :global(.fc-head-container.fc-widget-header) {
  position: sticky;
  top: var(--top-header);
  z-index: 2;
  background-color: var(--pri-01);
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
}

.root :global(.fc-toolbar.fc-header-toolbar) {
  position: sticky;
  top: var(--top-toolbar);
  z-index: 2;
  right: 0;
  left: 0;
  background-color: var(--pri-01);
  border-bottom: 1px solid #dddddd;
  padding-top: 1px;
}

.tz {
  position: fixed;
  top: 145px;
  width: 55px;
  text-align: center;
  z-index: 2;
  color: var(--gray-d);
  font-size: 14px;
  letter-spacing: -0.5px;
}