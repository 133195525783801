.file-picker {
  margin-top: 30px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
}

.input {
  width: 300px;
}

.label {
  font-family: var(--font-semibold);
  margin-bottom: 5px;
}

.file-name {
  font-size: 14px;
  margin-top: 10px;
}

.footer {
  margin-top: 25px;
}