.root {

}

.wrap {

}

.main {

}

.label {
  display: flex;
  align-items: flex-start;
}

.header {
  composes: label from './Settings.Branding.Theming.css';
  margin-right: 5px;
}

.indicator {
  height: 20px;
  width: 20px;
  color: var(--pri-03);
}

.enter {
  opacity: 1;
  transition: opacity 800ms ease;
}