.root {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
}

.wrap {
  margin: 0 auto 30px;
  padding: 0 30px;
  max-width: 300px;
}

.main {

}

.header {
  padding: 40px 15px;
}

.title {
  font-size: 23px;
  color: var(--black-l);
  font-family: var(--font-semibold);
  text-align: center;
}

.root .check {
  color: var(--pri-03);
  width: 70px;
  height: 70px;
}

.graphic {
  text-align: center;
  margin-bottom: 50px;
}

.details {
  margin-bottom: 35px;
  text-align: center;
}

.day {
  color: var(--gray-d);
  font-size: 19px;
  font-family: var(--font-semibold);
  margin-bottom: 15px;
}

.time {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  font-size: 15px;
}

.message {
  font-size: 14px;
  font-family: var(--font-reg);
  margin-bottom: 15px;
}

.message + .message {
  margin-bottom: 35px;
}

.h4 {
  color: var(--black);
  font-size: 17px;
  font-family: var(--font-bold);
  margin-bottom: 15px;
}

.conf {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.additional {
  margin: 6px 0 10px 0;
}

.info {
  margin-bottom: 70px;
}

.root .icon {
  color: var(--gray-d);
  width: 28px;
}

.num {
  color: var(--black);
  font-size: 18px;
  font-family: var(--font-reg);
  margin-left: 15px;
}

.done {
  position: absolute;
  bottom: 15px;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  left: 30px;
  right: 30px;
}

.bottom {
  width: 100%;
  text-align: center;
}

.btn {
  height: 55px;
  width: 100%;
}