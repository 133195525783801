.root {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: 30px;
}

.tag:not(:last-of-type) {
  margin-right: 6px;
}

.hidden-popper {
  display: flex;
  background: var(--pri-01);
  max-width: 300px;
  box-shadow: var(--box-shadow);
  padding: 10px;
  border-radius: 4px;
  flex-wrap: wrap;
  padding-top: 0px;
}

.hidden-popper > * {
  margin-top: 10px;
}