.root {
  max-width: var(--navbar-width);
  width: 100%;
  margin: 0 auto;
}

.wrap {
  padding: 40px 10px;
}

.main {
  max-width: 700px;
}

.header {

}

.title {
  font-size: 28px;
  color: var(--black);
  font-family: var(--font-bold);
  margin-bottom: 20px;
}

.item {
  max-width: 100%;
  padding: 15px;
  background-color: #fdf6dd;
  border: 1px solid rgba(253, 209, 58, 0.3);
  border-left: 4px solid #fdd13a;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.details {
  display: flex;
  gap: 10px;
}

.pic {
  background-color: var(--pri-01);
  width: 30px;
  height: 30px;
}

.user {
  margin-bottom: 10px;
}

.info {

}

.name {
  font-family: var(--font-bold);
}

.email {

}

.object {

}

.label {
  color: var(--gray-d);
}

.object .name {
  font-family: var(--font-bold);
}

.actions {
  display: flex;
  gap: 10px;
}

.action {
  color: var(--hyperlink);
  cursor: pointer;
}

.link {
  display: flex;
  align-items: center;
  gap: 5px;
}

.link svg {
  width: 19px;
  color: var(--hyperlink);
}