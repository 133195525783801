.root {
  max-width: calc(var(--navbar-width) - 20px);
  width: 100%;
  margin: 0 auto;
  padding: 40px;
  height: calc(100vh - var(--navbar-height) - 80px);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.title {
  font-family: var(--font-bold);
  font-size: 24px;
  margin-bottom: 20px;
}

.sub-title {
  font-size: 18px;
  margin-bottom: 40px;
}

.form-wrapper {
  flex-grow: 1;
}