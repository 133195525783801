.root {

}

.wrap {

}

.main {
  padding-top: 20px;
}

.header {

}

.input {
  font-size: 16px;
  letter-spacing: 0;
}

.selections {
  min-height: 200px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px 0;
}

.tag {
  display: initial;
  background-color: var(--sec-01);
  font-size: 16px;
  color: var(--gray-d);
  border-radius: 8px;
}

.tag svg {
  color: var(--gray-d);
  height: 22px;
  width: 22px;
  margin-left: 5px;
}

.tag svg:hover {
  color: var(--gray-d);
}

.removable {
  display: flex;
  justify-content: space-between;
  align-items: center;
}