.root {

}

.wrap {

}

.main {

}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.compact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 10px 0;
}

.compact .color {
  margin: 0 0 0 7px;
}

.collapse,
.remove {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.caret,
.x {
  color: var(--gray-d);
}

.x:hover {
  color: var(--pri-04);
}

.select {
  width: 100%;
}

.labeling {
  display: flex;
  gap: 2px;
  align-items: center;
}

.labeling.untitled {
  cursor: pointer;
}

.controls {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 32px;
}

.compact .controls {
  padding-right: 0
}

.name {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.placeholder {
  color: var(--gray);
  font-size: 14px;
  font-style: italic;
}

.name,
.placeholder {
  width: 100%;
  max-width: 130px;
  min-width: 130px;
  padding: 5px 4px;
  border-radius: 8px;
  border: 1px solid transparent;
}

.labeling:hover .name,
.labeling:hover .placeholder {
  border: 1px solid var(--gray-xl);
}

.duration {
  font-size: 13px;
  white-space: nowrap;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 4px;
  padding: 0;
  min-width: 60px;
}

.btn:disabled:hover {
  cursor: default;
}

.indicator {
  display: flex;
  align-items: center;
}

.saved {
  animation-duration: 225ms;
  animation-name: remove;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-delay: 500ms;
}

.discarded {
  animation-duration: 225ms;
  animation-name: remove;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.check {
  color: var(--pri-02);
}

@keyframes remove {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.menu {
  z-index: 8;
}