.th {
  font-size: 14px;
  font-family: var(--font-semibold);
  color: var(--black-l);

  padding: 10px;
}

.tr {
  border-bottom: 1px solid var(--sec-01);
  padding-left: 5px;
}

.td {
  padding: 5px 10px;
  font-family: var(--font-reg);
  color: var(--gray-d);
  font-size: 14px;

  display: flex;
  align-items: center;
}

.header {
  border-top: 1px solid var(--sec-01);
  border-bottom: 1px solid var(--sec-01);

  background-color: #f5f5f5;
}

.th {
  font-size: 14px;
  font-family: var(--font-semibold);
  color: var(--black-l);

  padding: 10px;
}

.tr {
  border-bottom: 1px solid var(--sec-01);
  padding-left: 5px;
}

.hr {
  composes: tr;
  border-bottom: none;
}

.td {
  padding: 5px 10px;
  font-family: var(--font-reg);
  color: var(--gray-d);
  font-size: 14px;

  display: flex;
  align-items: center;
}

.sort-by {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sort-by:hover {

}

.chevron {
  margin-left: 8px;
  width: 0;
  height: 0;
}

.chevron-up {
  composes: chevron;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--gray-d);
}

.chevron-down {
  composes: chevron;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--gray-d);
}