.root {
  margin: 0px auto;
}

.title {
  font-family: var(--font-bold);
  margin-bottom: 5px;
}

.subtitle {
  font-family: var(--font-semibold);
  margin-bottom: 5px;
}

.accordion-root {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
}