.root {
  border: 1px solid var(--sec-01);
  border-radius: 5px;
  min-height: 500px;
  width: 100%;
  margin: 20px 0;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.main {
  padding: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  height: 60px;
  border-bottom: 1px solid var(--sec-01);
}

.title {
  font-size: 22px;
  color: var(--black-l);
  font-family: var(--font-semibold);
}

.actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.action {
  width: 150px;
  height: 40px;
  padding: 0;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.placeholder {
  color: var(--gray);
  line-height: 30px;
}

.updating {
  opacity: 0.5;
}