.empty {
  text-align: center;
  padding: 35px 0;
}

.empty .text {
  margin-top: 40px;
  font-family: var(--font-reg);
  color: var(--gray-d);
}

.empty .title {
  color: var(--gray-d);
  font-family: var(--font-semibold);
}

.box {
  border-color: var(--sec-01);
  border-radius: 4px;
  --row-height: 55px;
}

.body {
  max-height: calc((5 * var(--row-height)) + 4px); /* 5 rows + 4 borders*/
  height: calc((5 * var(--row-height)) + 4px);
}

.td {
  box-sizing: border-box;
  height: var(--row-height);
  font-size: 14px;
}

.jump-to {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: var(--font-reg);
  font-size: 16px;
  padding: 8px;
  border-radius: 8px;
  margin-left: 15px;
}

.jump-to.open, .jump-to:hover {
  background-color: var(--gray-xl);
}

.chevron {
  margin-left: 8px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--gray-d);
}