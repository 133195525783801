.root {
  position: relative;
  height: calc(100% - var(--navbar-height) - 22px);
  margin: var(--video-container-margin);
}

.main-root {
  composes: root;
  display: flex;
  gap: 10px;
}

.gallery-root {
  composes: root;
}