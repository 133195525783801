.link {
  color: var(--pri-02);
  font-family: var(--font-semibold);
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 5px;
}

.disabled-link {
  pointer-events: none;
  color: var(--gray)
}