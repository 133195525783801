.root {
  margin-top: 55px;
}

.ticks {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.border {
  height: 2px;
  width: 100%;
  background-color: var(--gray-l);
  border-radius: 5px;
}

.value {
  word-break: break-word;
}

.value-wrap {
  position: absolute;
  width: 80px;
  left: -40px;
  top: -55px;

  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.middle {
  display: none;
}

@media (min-width: 800px) {
  .middle {
    display: flex;
  }
}

.tick {
  height: 6px;
  width: 2px;
  background-color: var(--gray-l);

  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  position: relative;
}

.border {
  height: 2px;
  width: 100%;
  background-color: var(--gray-l);
}

.label {
  color: var(--gray);
  font-family: var(--font-semibold);
  margin-bottom: 10px;
  font-size: 14px;
}