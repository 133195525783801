.header {
  font-size: 18px;
  font-family: var(--font-bold);
  margin: 0 0 8px;
}

.details {
  font-size: 14px;
}

.phone,
.pin {
  display: flex;
  align-items: center;
  margin: 0 0 6px 0;
}

.phone:last-child,
.pin:last-child {
  margin: 0;
}

.phone svg,
.pin svg {
  color: var(--gray-d);
  margin: 0 10px 0 0;
  width: 22px;
  height: 22px;
}

.additional {
  margin-top: 16px;
}

.guest {
  margin-bottom: 30px;
}

.link {
  color: var(--hyperlink);
  text-decoration: underline;
}