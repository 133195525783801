.root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
}

.conditions {
  margin-top: 5px;
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
}

.if {
  margin: 0 5px;
}

.dq {
  color: var(--pri-04);
}

.plus {
  margin: 0 2px;
}

.expression {
  /* margin: 0 3px; */
}

.paren {
  margin: 0 3px;
}

.operator {
  margin: 0 3px;
}