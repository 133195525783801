.label-input {
  margin-bottom: 12px;
}

.columns {
  margin-bottom: 15px;
}

.menu {
  margin-left: 15px;
  width: 35px;
  height: 35px;
}

.row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;

  border-bottom: 1px solid var(--sec-01);
  padding-bottom: 10px;
}

.row:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.row-content {
  width: 100%;
}

.row-metadata {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.bold-btn {
  margin-left: 5px;
}

.row-label {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  font-size: 15px;
  margin-right: 8px;
  width: 82px;
  min-width: 82px;
}

.row-input {
  margin-bottom: 10px;
}

.row-input input {
  font-family: var(--font-semibold);
}

.editor {
  width: 100%;
}

.option {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.option-value {
  margin-right: 10px;
  width: 100px;
  min-width: 100px;
}

.option-help-text {
  width: 100%;
  padding-bottom: 7px;
}

.checkbox {
  margin-top: 15px;
}