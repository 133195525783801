.root {
  display: flex;
  align-items: center;
}

.folder {
  font-family: var(--font-semibold);
  font-size: 18px;
}

.back {
  cursor: pointer;
  font-size: 18px;
}

.back:hover {
  color: var(--hyperlink);
  text-decoration: underline;
}