.icon {
  width: 25px;
  height: 25px;
  padding: 5px;
}

.root {
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.root:hover, .root.open {
  color: var(--black-l);
  background-color: var(--gray-xl);
}

.small {
  padding: 5px;
}