.root {
  max-width: var(--navbar-width);
  width: 100%;
  margin: 0 auto;
}

.wrap {
  padding: 20px 10px;
}

.main {
  display: flex;
  gap: 40px;
}

.table {
  width: 100%;
}

.box {
  border-color: var(--sec-01);
  border-radius: 4px;
}

.body {
  max-height: calc(100vh - var(--navbar-height) - var(--height-workspace-tab) - 185px);
  height: calc(100vh - var(--navbar-height) - var(--height-workspace-tab) - 185px);
}