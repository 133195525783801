@value 800, 1200 from 'vars';

.root {
  height: 100%;
}

.wrap {
}

.main {
  max-width: 1260px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

@media 1200 {
  .main {
    flex-direction: row;
    justify-content: space-between;
  }
}

.col {

}

.col:nth-of-type(1) {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

@media 1200 {
  .col {
    display: flex;
    flex: auto;
    flex-direction: column;
  }
}

.row {

}

.row:not(:last-of-type) {
  margin-bottom: 20px;
}

@media 800 {
  .row {
    display: flex;
    flex: auto;
  }
}

.card {
  padding: 10px 15px 20px 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 0;
}

.card:empty {
  display: none;
}

@media 800 {
  .card {
    padding: 10px 25px 20px 25px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 41px;
  margin-bottom: 10px;
}

.title {
  color: var(--black-l);
  font-size: 18px;
  font-family: var(--font-semibold);
}