.root {
  width: 280px;
  min-width: 280px;
  box-sizing: border-box;
  border: 1px solid var(--sec-01);
  border-radius: 5px;
  height: 260px;
  min-height: 260px;
  overflow: hidden;
}

.body {
  cursor: pointer;
  user-select: none;
  height: 100%;
  box-sizing: border-box;
  padding: 15px;
}

.clamped {
  line-height: 20px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.info {
  height: 90px;
  padding: 10px 10px 20px;
  background-color: #fafafa;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 140px;
  width: 100%;
}

.title {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 18px;
  font-family: var(--font-semibold);
  color: var(--black);
}

.description {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--gray-d);
}

.noimage .info {
  height: 180px;
  padding: 40px 10px;
  background-color: var(--pri-01);
}

.noimage .details {
  justify-content: flex-start;
}

.noimage .title {
  margin-bottom: 15px;
  -webkit-line-clamp: 3;
}

.noimage .description {
  -webkit-line-clamp: 4;
}