@value 800, 1100 from 'vars';

.root {
  border-top: 1px solid var(--gray-xl);
}

@media 800 {
  .root {
    border-top: none;
    margin-top: 40px;
  }
}

@media 1100 {
  .root {
    background-image: url('static/images/website/collaboration-tools/bg-dots-grey-top-left.png');
    background-repeat: no-repeat;
    background-size: 500px;
    margin-top: 0;
  }
}

.wrap {
  margin: 0 auto;
  padding: 40px 20px;
}

@media 800 {
  .wrap {
    max-width: 890px;
    background-image: url('static/images/website/collaboration-tools/bg-dots-grey-top-left.png');
    background-repeat: no-repeat;
    background-size: 500px;
  }
}

@media 1100 {
  .wrap {
    background-image: none;
    max-width: 1280px;
  }
}

.main {
  max-width: 400px;
  margin: 40px auto;
}

@media 800 {
  .main {
    max-width: var(--max-width);
  }
}

@media 1100 {
  .main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: var(--max-width);
  }
}

.header {

}

.img {
  width: 100%;
  height: auto;
  margin-top: 40px;
}

.graphic {
  width: 100%;
  margin: 0 auto;
}

@media 800 {
  .graphic {
    max-width: 100%;
  }

  .img {
    display: block;
    max-width: 700px;
    margin: 0 auto 30px;
  }
}

@media 1100 {
  .graphic {
    height: auto;
    margin: 0;
  }

  .img {
    max-width: 650px;
    margin: unset;
    margin-left: -30px;
  }
}

.info {

}

@media 800 {
  .info {
    min-width: 425px;
  }
}

.title {
  font-size: 30px;
  color: var(--black-d);
  font-family: var(--font-bold);
  margin-bottom: 30px;
}

@media 800 {
  .title {
    font-size: var(--font-size-header);
    text-align: center;
    max-width: 800px;
    margin: 0 auto 30px;
  }
}

@media 1100 {
  .title {
    max-width: 420px;
    text-align: left;
    margin: 0 0 30px;
  }
}

.subtitle {
  font-size: 20px;
  color: var(--black-l);
  margin-bottom: 50px;
  max-width: 370px;
}

@media 800 {
  .subtitle {
    max-width: 730px;
    text-align: center;
    margin: 0 auto;
  }
}

@media 1100 {
  .subtitle {
    max-width: 340px;
    text-align: left;
    margin: 0;
  }
}