.root {

}

.wrap {
  padding: 20px 0px;
}

.main {
  position: relative;
  min-height: 100px;
  max-width: 450px;
  margin: 0 auto;
}

.events {
  overflow-y: scroll;
  max-height: 300px;
  min-height: 100px;
}

.editor {
  height: 200px;
}

.uncommitted {

}

.footer {
  padding: 40px 0 0;
}

.btn {
  min-width: 140px;
}

.add {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--pri-02);
  font-size: 18px;
  font-family: var(--font-bold);
}

.icon {
  width: 31px;
  color: var(--pri-02);
  font-size: 28px;
  margin-right: 10px;
}

.row {
  margin: 15px 0;
  min-height: 60px;
}

.fields {
  display: flex;
  justify-content: space-between;
}

.field {

}

.label {
  font-family: var(--font-bold);
  color: var(--font-black-l);
  margin-bottom: 15px;
}

.datepicker {
  position: relative;
  z-index: 7;
  padding-bottom: 5px;
}

.icons {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  height: 100%;
  width: 65px;
}

.cancel {
  font-size: 30px;
  color: var(--gray-d);
  cursor: pointer;
}

.confirm {
  font-size: 30px;
  color: var(--pri-03);
  cursor: pointer;
}

.item {
  display: flex;
  align-items: center;
  height: 40px;
  gap: 10px;
  border-bottom: 1px solid var(--sec-01);
  padding: 0 10px;
}

.range {

}

.timezone {
  padding-top: 25px;
  font-size: 15px;
  font-style: italic;
}