.root {
  width: 100%
}

.header, .header .row-name {
  background-color: var(--pri-01);
}

.header {
  position: sticky;
  top: var(--matrix-grid-header-top, var(--navbar-height));
  z-index: 2;
  padding-top: 10px;
}

.row:nth-child(odd) {
  background-color: #f5f5f5;
}

.header, .row {
  display: flex;
}

.header-na {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}

.header:not(:first-of-type) {
  margin-top: 25px;
}

.cell {
  text-align: center;
  max-width: 180px;
}

.header-na {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.cell-na, .header-na {
  background-color: var(--gray-xl);
  width: 8%;
  min-width: 8%;
}

.header-na .header-cell-wrap {
  padding-bottom: 20px;
}

.row-name {
  width: 20%;
  min-width: 20%;
  flex-grow: 1;
  word-break: break-word;
  box-sizing: border-box;
  padding: 5px;

  display: flex;
  align-items: center;
}

.cell-wrap {
  padding: 15px 0;

  height: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
}

.cell-last.cell-na {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.input {
  background-color: unset;
}