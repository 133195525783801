.status {
  color: var(--gray-d);
  font-family: var(--font-semibold);
}

.blocked {
  color: var(--pri-04);
}

.link {
  cursor: pointer;
  text-decoration: underline;
  margin-top: 20px;
}