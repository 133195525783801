@value 1000 from 'vars';

.root {
  width: 100%;
}

@media (min-width: 850px) {
  .root {
    min-height: calc(100vh - var(--navbar-height) - 25px - 25px);
  }
}

.wrap {

}

.main {
  border: 1px solid var(--gray-l);
  border-radius: 6px;
}

.header {
  padding: 0 20px 20px;
  font-family: var(--font-bold);
  color: var(--black-l);
}

.title {
  font-size: 22px;
}

.btn {
  color: var(--pri-02);
  padding: 5px 0;
  font-family: var(--font-semibold);
  font-size: 14px;
}


.btn:disabled {
  color: var(--gray);
}

.btn:hover {
  color: var(--sec-02);
}