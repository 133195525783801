.root {
  max-width: var(--navbar-width);
  margin: 0 auto;
  box-sizing: border-box;
}

.wrap {
  display: flex;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

.filters {
  min-width: 175px;
  width: 215px;
  padding: 20px 20px 0 10px;
  box-sizing: border-box;
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  padding: 20px 0;
}