.info {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 5px;
  font-size: 10px;
  font-family: monaco, monospace, Consolas;
  z-index: 20;
  opacity: 0.6;
  transition: 0.3s ease;
  cursor: pointer;
}

@media (min-width: 1100px) {
  .info {
    font-size: 11px;
    opacity: 0.8;
  }
}

.pill {
  border-radius: 4px;
  border: none;
  box-shadow: none;
  padding: 4px 6px;
  margin-right: 6px;
  margin-bottom: 4px;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label {
  font-weight: bold;
  margin-right: 3px;
}

.info:hover {
  opacity: 1;
}

.info:hover .pill {
  max-width: 100%;
}

.info:hover .hash {
  display: block;
  opacity: 1;
}

.hash {
  composes: pill;
  display: none;
  opacity: 0;
  background-color: #969696;
}

.development {
  composes: pill;
  background-color: #00c07f;
}

.test {
  composes: pill;
  background-color: #fbbd08;
}

.stage {
  composes: pill;
  background-color: #f2711c;
}

.production {
  composes: pill;
  color: #fff;
  background-color: #db2828;
}