.label {
  margin-top: 25px;
  margin-bottom: 8px;
  font-family: var(--font-semibold);
  color: var(--gray-d);
}

.item {
  display: flex;
  align-items: flex-start;
  line-height: 26px;
}

.bullet {
  font-family: var(--font-semibold);
  margin-right: 10px;
}