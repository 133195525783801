@value 900 from 'vars';

.root {
  --purple: #5c5da7;
}

.wrap {

}

.main {

}

.h2 {
  color: var(--black-l);
  font-size: 22px;
  font-family: var(--font-semibold);
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
  height: 70px;
  justify-content: space-between;
  border-bottom: 1px solid var(--sec-01);
}

.trunc {
  height: 2em;
  width: 100%;
}

.notepad {
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-height: calc(500px - 70px);
  justify-content: space-between;
}

@media 900 {
  .notepad {
    min-height: calc(100% - (70px + 44px));
  }
}

.title {
  font-family: var(--font-bold);
  font-size: 17px;
}

.actions {
  display: flex;
  align-items: center;
}

.download {
  width: 110px;
}

.pad {

}

.action {
  width: 150px;
  height: 40px;
  padding: 0;
}

.export {
  width: 130px;
}

.loading {
  display: flex;
  justify-content: center;
  padding: 50px 0 0;
}

.circ {
  color: #565add;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.placeholder {
  color: var(--gray);
  line-height: 30px;
}

.refresh {
  margin-right: 20px;
}