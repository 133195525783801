@value 800, 1100 from 'vars';

.root {
  max-width: 500px;
  min-height: 525px;
  width: 100%;
}

.wrap {
}

.main {

}

.header {

}

.switch {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.btns {
  width: 100%;
  z-index: 0;
  position: relative;
}

@media 800 {
  .btns {
    width: 350px;
  }
}

.thumb {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 10px;
  background-color: var(--gray-xl);
  color: var(--black-l);
  font-family: var(--font-reg);
  font-size: 18px;
  height: calc(100% + 1px);
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 3px -2px rgba(0, 0, 0, 0.2);
  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@media 800 {
  .thumb {
    width: 175px;
  }

}

.on {
  transform: translateX(100%);
}

@media 800 {
  .on {
    transform: translateX(175px);
  }
}

.active {
  transition: color 250ms ease-in-out 100ms;
}

.track {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--gray-l);
  color: var(--black-l);
  font-family: var(--font-reg);
  font-size: 18px;
  border-radius: 10px;
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.2);
}

@media 800 {
  .track {
    border-radius: 10px;
  }
}

.control {
  position: relative;
  width: 175px;
  text-align: center;
  height: 50px;
  cursor: pointer;
  z-index: 2;
  line-height: 50px;
}

.hidden {
  display: none;
}