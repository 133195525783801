.root {
  border-radius: 5px;
  background-color: var(--pri-01);
  text-align: center;
  overflow: hidden;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.10);
}

.wrap {
  padding: 15px;
}

.wrap:active {
  background-color: var(--blue-xl);
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
}

.carousel {

}

.header {

}

.logo {
  margin-bottom: 15px;
  width: 48px;
  height: 48px;
}

.img {
  border-radius: 4px;
}

.name {
  display: -webkit-box;
  position: relative;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 15px;
  max-height: calc(1.4em * 2);
}

.footer {

}

.btn {
  color: var(--pri-02);
  font-family: var(--font-semibold);
  width: 100%;
  height: 40px;
  border-top: 1px solid var(--gray-xl);
}

.btn:hover {
  background-color: var(--blue-xl);
  border-top: 1px solid var(--sec-01);
}

.btn:active {
  border-top: 1px solid var(--gray-l);
}

.unfollow,
.btn.unfollow:hover {
  background-color: var(--blue-l);
  border-top: 1px solid var(--sec-01);
}