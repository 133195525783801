.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.controls {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 75px;
}

.chevron {
  color: var(--gray-d);
}

.controls button:disabled .chevron {
  color: var(--gray-l);
}

.table {
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  grid-template-columns:
    minmax(50px, 50px)
    minmax(150px, 1fr)
    minmax(75px, 75px)
    minmax(75px, 75px)
    minmax(90px, 90px);
  margin: 0 0 10px 0;
}

.thead,
.tbody,
.tr {
  display: contents;
}

.th,
.td {
  padding: 4px;
  word-break: break-word;
  text-align: left;
}

.th {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 15px;
}

.th:last-child {
  border: 0;
}

.td {
  display: flex;
  font-size: 14px;
  padding: 8px 4px;
  align-items: center;
  min-height: 24px;
}

.tr:nth-child(even) .td {
  background: var(--gray-xl);
}

.nocharges {
  color: var(--gray-d);
  font-family: var(--font-reg);
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 0 0;
}

.badge {
  color: var(--pri-01);
  background-color: var(--gray);
  border-radius: 4px;
  font-size: 12px;
  font-family: var(--font-bold);
  text-align: center;
  padding: 4px 0;
  width: 65px;
}

.pending {
  composes: badge;
}

.open {
  composes: badge;
  background-color: var(--pri-02);
}

.paid {
  composes: badge;
  background-color: var(--pri-03);
}

.failed {
  composes: badge;
  background-color: var(--pri-04);
}

.refunded {
  composes: badge;
  background-color: var(--pri-02);
}

.icon-btn {
  height: 30px;
  width: 30px;
}

.icon-btn:hover {
  background-color: var(--gray-xl);
}