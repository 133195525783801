.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.title {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 20px;
}

.clear {
  color: var(--pri-02);
  cursor: pointer;
  font-family: var(--font-semibold);
  font-size: 14px;
}

.sections > :not(:last-child) {
  margin: 0 0 20px 0;
}
