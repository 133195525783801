@value 1100 from 'vars';

.header {
  composes: header from './Messages.css';
  border-bottom: 1px solid var(--gray-l);
}

.contain {
  composes: header-wrap from './Messages.css';
  padding: 0 15px;
  height: 60px;
}

.edit {
  composes: edit from './Messages.css';
}

.title {
  font-size: 24px;
  font-family: var(--font-semibold);
}

.btn {
  composes: btn from './Messages.css';
}

.main {
  composes: main from './Messages.css';
}

.content {
  composes: content from './Messages.css';
}

.scroll {
  composes: scroll from './Messages.css';
  z-index: 2;
}

.items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0;
  width: 100%;
}

.section {
  position: sticky;
  top: 0;
  z-index: 1;
  font-family: var(--font-bold);
  color: var(--gray-d);
  background-color: var(--gray-xl);
  height: 30px;
  width: 100%;
  overflow: hidden;
}

.project {
  line-height: 30px;
  font-size: 14px;
  padding-left: 30px;
  user-select: none;
}

.item {
  cursor: pointer;
  width: 100%;
  user-select: none;
}

.item + .item {
  border-top: 1px solid var(--gray-l);
}

@media 1100 {
  .item:hover {
    background-color: var(--blue-l);
  }
}

.user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 45px;
  padding: 10px;
}

.name {
  font-family: var(--font-semibold);
  color: var(--gray-d);
}

.avatar {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-family: var(--font-bold);
  color: var(--gray-d);
}