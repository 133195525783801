@value 800 from 'vars';
@value 1100 from 'vars';

.root {

}

.section-root {

}

.wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media 800 {
  .wrap {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media 1100 {
  .section-root {
    border-bottom: 1px solid var(--gray-xl);
  }
}

.section-wrap {
  composes: wrap;
}

.title {
  font-size: 30px;
  font-family: var(--font-semibold);
  color: #1d1d1d;
}


@media 800 {
  .title {
    font-size: 32px;
  }
}

@media 1100 {
  .title {
    font-size: 40px;
  }
}

.headline {
  font-size: 30px;
  font-family: var(--font-semibold);
  color: #1d1d1d;
}

.copy {
  font-size: 20px;
  color: var(--gray-d);
}

.caption {
  font-size: 22px;
  font-family: var(--font-bold);
  color: #1d1d1d;
}

.banner-root {
  background-color: var(--blue-l);
}

.banner-wrap {
  max-width: 1280px;
  margin: 0 auto;

  padding: 20px;
}

.banner-link {
  color: var(--pri-02);
  font-family: var(--font-bold);
  font-size: 20px;
  padding: 0;
  height: auto;
}

@media (min-width: 600px) {
  .banner-link {
    margin-left: 20px;
  }
}

.banner-link::after {
  content: '>';
  padding-left: 5px;
}

.action-root {
  border-bottom: none;
}