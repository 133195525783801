@value 800, 1000 from 'vars';

.root {
  padding: 0 30px;
}

@media 800 {
  .root {
    padding: 0 15px;
  }
}

.root:not(:first-child) {
  border-top: 1px solid var(--sec-01);
}

.wrap {
  padding: 70px 0 70px 0;
}

@media 800 {
  .wrap {
    max-width: 640px;
    margin: 0 auto;
  }

  .root:not(:first-child) .wrap {
    padding: 70px 0 0 0;
  }
}

@media 1000 {
  .root:first-child .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1280px;
    padding: 0;
    height: calc(100vh - var(--navbar-height));
    min-height: 640px;
  }
}

.graphic {
  display: none;
}

@media 800 {
  .graphic {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.img {
  width: 270px;
}

@media 1000 {
  .img {
    width: 400px;
  }
}

.main {

}

@media 800 {
  .main {
    display: flex;
    justify-content: space-between;
  }
}

.content {

}

@media 800 {
  .content {
    max-width: 320px;
  }

}

@media 1000 {
  .content {
    max-width: 420px;
  }
}

.title {
  font-family: var(--font-bold);
  font-size: 24px;
  color: var(--black-d);
  margin-bottom: 25px;
}

@media 800 {
  .title {
    font-size: 30px;
  }
}

@media 1000 {
  .title {
    font-size: 40px;
  }
}

.h2 {
  composes: title;
}

@media 800 {
  .h2 {
    text-align: center;
    font-size: 30px;
  }
}

.subtitle {
  font-size: 20px;
  font-family: var(--font-reg);
  color: var(--black-d);
  margin-bottom: 30px;
}

.btn {
  width: 170px;
  margin-bottom: 30px;
}

@media 800 {
  .btn {
    margin-bottom: 0;
  }
}