@value 800 from 'vars';
@value 1100 from 'vars';

.root {
  padding-top: 40px;
}

.wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media 800 {
  .wrap {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav {
  display: flex;
  align-items: center;
}

.btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
  width: 230px;
}

.btn {
  padding: 0px 10px;
  height: 40px;
}

.signup {
  composes: btn;
  width: 120px;
}

.login {
  font-family: var(--font-semibold);
  color: #4a4a4a;
  margin-right: 25px;
}

.login:hover {
  color: var(--pri-02)
}

.theme {
  min-height: calc(100vh - (40px));
}