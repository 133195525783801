.root {
}

.table {

}

.pageheader {
  display: flex;
  justify-content: space-between;
}

.wrap {
  padding: 5px 0;
  border-top: 2px solid var(--gray-xl);
  border-bottom: 2px solid var(--gray-xl);
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  padding: 5px;
}

.title {
  font-size: 20px;
  font-family: var(--font-bold);
  color: var(--gray-d);
  margin-right: 15px;
}

.project {
  display: flex;
  width: 100%;
  font-size: 16px;
  font-family: var(--font-semibold);
}

.project span:nth-child(2) {
  margin-left: 5px;
  line-height: 16px;
  height: 16px;
}

.body {
  font-size: 14px;
}

.inline {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  height: 30px;
}

.row {
  padding: 5px 0;
  height: 50px;
  border-bottom: 1px solid var(--gray-l);
}

.trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.counts {
  display: flex;
  align-items: center;
  width: 100%;
}

.count {
  min-width: 50px;
}

.star {
  cursor: pointer;
}

.cell {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 5px;
}

.archived {
  color: var(--gray);
}

.name {
  display: flex;
}

.badge:global(.MuiChip-outlined) {
  border-color: var(--gray-m);
  color: var(--gray-d);
}

.icon {
  margin-top: 1px;
  padding-left: 2px;
}

.badge span:global(.MuiTouchRipple-root) {
  visibility: hidden;
}

.sortable {
  cursor: pointer;
  margin: 0 -5px;
  padding: 0 5px;
  border-radius: 2px;
}

.sortable:hover {
  background-color: var(--gray-xl);
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.btns {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  min-width: 35px;
  color: var(--hyperlink);
  border: none;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
}

.btn:hover {
  border: none;
}

.btn:disabled {
  color: var(--gray);
  cursor: default;
}

.btn:not(:disabled):hover {
  background-color: var(--gray-xl);
}

.create {
  color: var(--pri-02);
  height: 40px;
  width: 140px;
}

.link {
  color: var(--hyperlink);
}

.link:hover {
  text-decoration: underline;
}

.filter:first-child {

}

.filter:not(:first-child) {
  margin-top: 10px;
}

.field {
  width: 100%;
}

.pageheader.sticky {
  position: sticky;
  top: 20px;
  z-index: 2;
  background-color: var(--pri-01);
}

.pageheader.sticky .pad {
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  z-index: 2;
  height: 20px;
  background-color: var(--pri-01);
}

.head {
  position: sticky;
  top: 81px;
  z-index: 2;
  background-color: var(--pri-01);
}

.pageactions {
  display: flex;
}

.clickable:hover {
  color: var(--hyperlink);
  text-decoration: underline;
}