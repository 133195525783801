.root {
  composes: root from '../style.css';
  height: 30px;
  margin-bottom: 25px;
}

.label {
  composes: label from '../style.css';
}

.select {
  min-width: 130px;
}