@value 800 from 'vars';

.root {

}

.wrap {
  padding: 0 10px 20px;
}

@media 800 {
  .wrap {
    padding: 0 50px 20px;
  }
}
.body {
  text-align: left;
}

.body > * {
  margin-bottom: 20px;
}

.btn {
  padding: 5px 20px;
  height: 40px;
  border-radius: 3px;
}