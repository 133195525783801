@value 1100, 500 from 'vars';

.root {
  margin: 40px auto 60px auto;
  width: 100%;
  text-align: center;
}

@media 1100 {
  .root {
    margin: 40px auto 90px auto;
    width: 100%;
  }
}

.wrap {
  padding: 0 25px;
}

.row {
  margin-bottom: 15px;
}

@media 500 {
  .text,
  .link {
    font-size: 20px;
    line-height: 30px;
  }
}

@media 1100 {
  .text {
    display: inline-block;
    white-space: pre;
  }
}

.text {
  color: var(--black-l);
  font-size: 18px;
}

@media 1100 {
  .text {
    font-size: 20px;
    line-height: 30px;
  }
}

.link {
  composes: text;
  color: var(--hyperlink);
  font-family: var(--font-bold);
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.subtitle {
  color: var(--black-l);
  font-family: var(--font-reg);
  font-size: 20px;
  margin-bottom: 10px;
}

.vancery {
  composes: subtitle;
  margin-bottom: 40px;
}

.no-more-results {
  composes: subtitle;
  font-family: var(--font-semibold);
}

.zero {
  composes: subtitle;
  font-family: var(--font-semibold);
}

.empty {
  padding: 30px 30px 15px 30px;
  text-align: center;
}

.graphic {
  height: 115px;
}

@media 1100 {
  .graphic {
    height: 170px;
  }
}

.image {
  width: 200px;
}

@media (min-width: 1000px) {
  .image {
    width: 300px;
  }
}

.message {
  color: var(--black-l);
  font-family: var(--font-semibold);
  font-size: 26px;
  /* margin-bottom: 50px; */
  line-height: 50px;
}

@media 1100 {
  .message {
    font-size: 32px;
  }
}

.loading {
  position: relative;
  margin: 0 auto;
  padding-bottom: 25px;
  padding-top: 50px;
}