.root {
  max-width: calc(var(--navbar-width) - 20px);
  margin: 20px auto;
}

.name {
  cursor: pointer;
  color: var(--hyperlink);
  font-family: var(--font-semibold);
}

.actions {
  display: flex;
  gap: 20px;
}

.action {
  cursor: pointer;
  color: var(--gray);
}

.table {
  height: auto;
  margin-bottom: 20px;
}

.tbody {
  height: auto;
}

.name-cell {
  flex-grow: 1;
}

.type-cell {
  flex: 0 1;
  min-width: 150px;
}

.actions-cell {
  flex: 0 1;
  min-width: 100px;
  justify-content: end;
}

.back {
  margin-bottom: 15px;
}

.clone {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
}

.clone .btn {
  border-radius: 4px;
  width: 140px;
}

.clone .header {
  text-align: center;
  font-size: 22px;
  font-family: var(--font-semibold);
  color: var(--black);
}

.clone .body {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  width: 100%;
}

.clone .footer {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}