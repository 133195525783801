.header {
  composes: header from './Shared.css';
}

.option-label {
  composes: option-label from './Shared.css';
}

.select {
  composes: select from './Shared.css';
}

.microphone {
  color: var(--gray-d);
}

.microphone svg {
  width: 22px;
  height: 22px;
}

.off {
  color: var(--gray-d);
}

.option-disabled {
  composes: option-disabled from './Shared.css';
}