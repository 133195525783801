@value 800, 1000 from 'vars';

.root {
  margin: 0 auto;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 640px;
  padding: 0 20px 40px;
  margin: 0 auto;
}

@media 1000 {
  .wrap {
    max-width: var(--max-width);
  }
}

.main {
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
}

@media 800 {
  .main {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    max-width: var(--max-width);
  }
}

@media 1000 {
  .wrap {
  }
}

.info {
  max-width: 400px;
  margin: 0 auto;
}

@media 800 {
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 340px;
    margin: unset;
  }
}

@media 1000 {
  .info {
    max-width: 470px;
  }
}

.title {
  font-size: var(--font-size-heading-2);
  color: var(--black-d);
  font-family: var(--font-bold);
  margin-bottom: 30px;
}

@media 800 {
  .title {
    font-size: var(--font-size-heading-1);
  }
}

@media 1000 {
  .title {
    font-size: var(--font-size-heading-xl);
  }
}

.subtitle {
  font-size: var(--font-size-subtitle-1);
  color: var(--black-l);
  margin-bottom: 50px;
}

@media 1000 {
  .subtitle {
    max-width: 460px;
  }
}

.graphic {
  margin-bottom: 20px;
  width: 100%;
  max-width: 450px;
}

.img {
  display: none;
  animation-name: fade;
  animation-duration: 300ms;
  width: 100%;
  max-width: 450px;

  transform: scale(1.3);
  margin-top: 50px;
  margin-bottom: 50px;
}

.img.ready {
  display: block;
}

@media 800 {
  .graphic {
    width: 450px;
    max-width: 100%;
    margin: auto;
    margin: auto 0 60px auto;
  }

  .img {
    width: 430px;

    transform: unset;
    margin-top: unset;
    margin-bottom: unset;
  }
}

@media 1000 {
  .graphic {
    width: 540px;
    height: auto;
  }

  .img {
    width: 540px;
    max-width: unset;
  }
}

.btnset {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn {
  width: 300px;
}

.btnset .btn:first-child {
  margin-bottom: 10px;
}

@media 800 {
  .btnset {
    justify-content: flex-start;
  }

  .btn {
    width: 250px;
  }

  .btnset .btn:first-child {
    margin: 0 20px 0 0;
  }

}

@media 1000 {
  .btn {
    width: 180px;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}