.root {
  margin-bottom: 30px;
}

.th {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  font-family: var(--font-bold);
  font-size: 14px;
  background-color: var(--sec-01);
}

.tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  font-size: 13px;
}

.tr:nth-child(odd) {
  background-color: var(--gray-xl);
}

.name {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 400px;
  padding-left: 15px;
}

.name .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pin {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100px;
}

.digits {

}

.copy {

}

.copy .icon {
  width: 17px;
  height: 17px;
  color: var(--black-l);
}

.copy .icon:hover {
  color: var(--sec-02);
}

.role {
  width: 150px;
}

.visibility {
  display: flex;
  justify-content: flex-end;
  width: 100px;
}

.badge {
  width: 60px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 4px;
  color: var(--pri-01);
  font-family: var(--font-semibold);
  font-size: 13px;
}

.visible {
  composes: badge;
  background-color: var(--pri-02);
}

.hidden {
  composes: badge;
  background-color: var(--gray-m);
}