.row {
  padding: 15px 25px;
  border-bottom: 1px solid var(--gray-xl);
  font-family: var(--font-semibold);
  color: var(--gray-d);
  min-height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.label-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -3px;
}

.label {
  margin-top: 3px;
  margin-right: 5px;
}

.subtitle {
  margin-top: 3px;
  color: var(--gray-m);
  font-family: var(--font-reg);
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  background-color: var(--blue-xl);
}

.bucket-row {
  cursor: pointer;
  padding: 10px 25px;
}

.bucket-row:hover {
  background-color: var(--blue-xl);
}