.root {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.client-root {
  width: calc(100vw - 40px);
  margin-left: calc((50vw - 435px - 20px)* -1);
}

.root>div {
  flex-grow: 1;
  max-width: 900px;
}