.root {
  display: none;
}

@media (min-width: 1100px) {
  .root {
    display: block;
    min-width: 300px;
    width: 100%;
    max-width: 500px;
    /* voice more */
    /* max-width: 800px; */
    box-shadow: var(--shadow);
    border-radius: 5px;
    padding: 14px 20px;
    max-height: calc(100vh - var(--navbar-height) - 170px);
  }
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--gray-d);
  padding: 0 0 8px 0;
  margin: 0 0 10px 0;
}

.label {
  color: var(--gray-d);
  font-size: 24px;
  font-family: var(--font-bold);
}

.private {
  color: var(--gray-d);
  height: 24px;
  width: 24px;
}

.private svg {
  height: 24px;
  width: 24px;
}