.root {
  height: 100%;
}

.wrap {
  width: 260px;
  height: 146.25px;
}

.video {
  width: 100%;
  min-height: 146.25px;
  max-height: 146.25px;
  object-fit: cover;
}

.preview {
  position: relative;
  display: flex;
  overflow: hidden;
  padding-top: 56.25%;
  background-color: rgba(0, 0, 0, 0.04);
  border: 1px solid var(--sec-01);
  box-sizing: border-box;
}

.img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}