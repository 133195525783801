@value 800 from 'vars';

.row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.error {
  color: var(--pri-04);
  font-size: 14px;
  margin-top: 2px;
}