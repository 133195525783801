@value 1100 from 'vars';

@media 1100 {
  .scroll {
    padding-top: 115px;
  }
}

.loading {
  position: relative;
  margin: 0 auto;
  padding-bottom: 25px;
  padding-top: 50px;
}

.btn {
  width: 150px;
}

.busy {
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.6, 1);
  opacity: 0.6;
}

.ready {
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.6, 1);
  opacity: 1;
}