.top {
  margin-bottom: 25px;
}

.link {
  cursor: pointer;
  color: var(--hyperlink);
  text-decoration: underline;

  margin-bottom: 15px;
}

.upload {
  margin-bottom: 20px;
}

.picker {
  margin-top: 15px;
  margin-bottom: 15px;
}

.loader {
  margin-top: 15px;
}