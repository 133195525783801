.root {
  display: flex;
}

.input:disabled {
  cursor: default;
  background-color: var(--pri-01);
  color: var(--black-l);
  border-color: unset;
}

.menu {
  border-radius: 4px;
  box-shadow: 0px 1px 2px 1px rgba(238,238,238, 1);
  background-color: var(--pri-01);

  max-height: 300px;
  overflow-y: auto;
}

.menu-item {
  padding: 10px 15px;
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--gray-d);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.menu-item:hover {
  background-color: var(--blue-l);
}

.close {
  cursor: pointer;
  height: 22px;
  width: 22px;
  color: var(--gray-d);
}

.type {
  font-family: var(--font-reg);
  margin-left: 5px;
}

.tags {
  --gap: 8px;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  min-height: 35px;
  margin-top: 10px;
}

.tag {
  margin: var(--gap) 0 0 var(--gap);
}