.btn {
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--gray-l);
  cursor: pointer;
  align-items: center;
  padding: 2px 4px;
  height: 30px;
  min-width: initial;
  transition: background-color 180ms ease-in-out,
              border 120ms ease-in,
              border-color 120ms ease-in,
              color 120ms ease-in;
}

.btn:hover {
  background-color: var(--gray-xl);
}

.btn-label {
  color: var(--pri-02);
  font-family: var(--font-semibold);
  font-size: 14px;
  margin: 0 18px;
}


.menu-item {
  color: var(--black);
  font-size: 14px;
  line-height: 22px;
  width: 200px;
}