.root {
  --thumb-diameter: 22px;

  box-sizing: border-box;
  touch-action: none;
  z-index: 3;
  cursor: pointer;
  border-radius: 50%;
  border: 3px solid var(--pri-02);
  background-color: var(--pri-01);

  height: var(--thumb-diameter);
  width: var(--thumb-diameter);
}