.root {

}

.wrap {

}

.main {
  padding-top: 15px;
}

.time {
  margin-bottom: 10px;
}

.paragraph {
  margin-bottom: 20px;
  color: var(--gray-d);
}

.sentences {
  color: var(--black);
}

.sentence {
  font-size: 15px;
  word-break: break-word;
  white-space: pre-line;
}