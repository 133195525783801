.footer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.row {
  display: flex;
  align-items: center;
}

.options-dropdown {
  width: 250px;
}

.subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  width: 425px;
  color: var(--gray-d);
}

.operator-dropdown {
  width: 60px;
  margin-right: 20px;
}

.number-input {
  width: 250px;
}

.submit {
  margin-left: 10px;
}

.add-item {
  margin-top: 10px;
}

.form-buttons {
  margin-left: 10px;
}

.bullet {
  font-family: var(--font-semibold);
  width: 34px;
  color: var(--gray-d);
}

.trash, .edit {
  margin-left: 10px;
}