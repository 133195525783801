.root {
  cursor: pointer;
  border-bottom: 1px solid var(--gray-l);
}

.wrap {
  padding: 15px;
}

.selected {
  background-color: var(--blue-l);
}

.selected .name {
  color: var(--hyperlink);
}