.root {
  min-height: 95px;
}

.lg {
  display: none;
}

@media (min-width: 425px) {
  .sm {
    display: none;
  }

  .lg {
    display: block;
  }
}


.header {
  display: flex;
  justify-content: space-between;
  min-height: 95px;
}

.columns {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.avatar {
  margin-right: 30px;
}

@media(max-width: 425px) {
  .avatar {
    margin-bottom: 15px;
  }
}

.edit {
  position: absolute;
  color: var(--pri-02);
  cursor: pointer;
  right: 0;
  bottom: 0;
  padding: 4px 4px 0px 4px;
  border-radius: 50%;
  background-color: var(--pri-01);
  border: 2px solid var(--pri-02);
}

.info {
  min-height: 95px;
}

.name {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 20px;
  line-height: 20px;
}

@media (max-width: 425px) {
  .name {
    font-size: 18px;
    line-height: 18px;
  }
}

.rate {
  font-size: 18px;
  line-height: 18px;
  margin: 5px 0 11px 0;
}

@media (max-width: 425px) {
  .rate {
    font-size: 16px;
    line-height: 16px;
    margin: 8px 0 10px 0;
  }

  .rate:before {
    font-size: 16px;
  }
}

.input {
  composes: input from 'css/input.css';
  border: none;
}

.nofield {
  color: var(--gray-d);
  font-family: var(--font-semibold);
  font-size: 20px;
  line-height: 20px;
}

.field {
  border-bottom: 2px solid var(--gray-l);
}

.social {
  font-family: var(--font-semibold);
  font-size: 16px;
  text-align: center;
  width: 95px;
}

.external {
  border: 2px solid var(--pri-02);
  color: var(--pri-02);
  padding: 7px 10px;
  border-radius: 5px;
}

.external:hover {
  color: var(--pri-01);
  border: 2px solid var(--sec-02);
  background-color: var(--sec-02);
}

.btns {
  display: flex;
  flex-direction: column;
}

.btns .btn:not(:last-child) {
  margin-bottom: 5px;
}

.btn {
  min-width: 140px;
}