.item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 55px;
  width: 100%;
  text-align: center;
  background-color: var(--pri-01);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.20);
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 15px;
  color: var(--gray-d);
  font-family: var(--font-semibold);
}

.selected {
  background-color: var(--blue-l);
}

.check {
  display: none;
  position: absolute;
  left: 15px;
  color: var(--pri-02);
}

.item.selected {
  font-family: var(--font-bold);
  color: var(--pri-02);
}

.selected .check {
  display: block;
}