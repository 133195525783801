.content {
  padding: 2px 0;
}

.tags {
  margin: 10px 0 20px 0;
}

.add {
  color: var(--pri-02);
  font-family: var(--font-bold);
  font-size: 14px;
}

.buttons svg {
  stroke-width: 3;
  height: 14px;
  width: 14px;
}

.empty {
  color: var(--gray-m);
  font-size: 14px;
  margin: 6px 0 0 0;
}

.ac {
  margin: 10px 0 0 0;
}