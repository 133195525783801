.menu {
  background-color: var(--pri-01);
  border-radius: 5px;
  border: 1px solid var(--gray-l);
  box-shadow: 0 2px 6px 2px rgba(60, 64, 67, .15);
  padding: 12px;

  width: 375px;
  /* height: 60px; */
  box-sizing: border-box;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.submit {
  box-sizing: border-box;
  height: 35px;
  width: 40px;
  font-size: 14px;
  padding: 2px;
}