.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remove {
  cursor: pointer;
  color: var(--pri-04);
  margin-left: 10px;
}

.row {
  display: flex;
  align-items: center;
}

.label {
  font-size: 15px;
  font-family: var(--font-semibold);
  color: var(--gray-d);
  margin-right: 8px;
  width: 65px;
}

.question-dropdown {
  width: 350px;
}

.options-dropdown, .rows-dropdown, .condition-type-dropdown {
  width: 250px;
  margin-right: 10px;
}

.operator-dropdown {
  margin-right: 10px;
  width: 85px;
}

.condition-row, .value-row {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.comparate-input {
  width: 55px;
}

.tally-text {
  margin-left: 15px;
}

.margin-bottom {
  margin-bottom: 15px;
}