.root {
  padding: 20px 0;
  font-size: 14px;
}

.time-cell {
  min-width: 125px;
  flex: 0;
}

.tag-cell {
  min-width: 225px;
  flex: 0;
}

.actions-cell {
  min-width: 35px;
  flex: 0;
}

.text-cell {
  flex-grow: 1;
}

.empty-msg {
  font-style: italic;
  text-align: center;
  color: var(--gray-m);
  margin-bottom: 20px;
}