@value 800 from 'vars';

.root {
  width: 100%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  --height-head: 82px;
  --height-toolbar: 50px;
  --height-header: 60px;
}

.wrap {
  width: 100%;
}

.calendar {
  padding-bottom: 80px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 15px 0;
  height: 30px;
}

.back {
  position: absolute;
  left: 15px;
}

.arrow {
  width: 28px;
  height: 28px;
}

.title {
  color: var(--gray-d);
  font-size: 23px;
  font-family: var(--font-bold);
  width: calc(100% - 50px);
  text-align: center;
  overflow: hidden;
}