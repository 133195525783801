.root {
  padding: 0px 15px 15px 15px;
  border: 1px solid var(--gray-l);
  border-radius: 6px;
}

.header {
  padding: 15px 0;
  border-bottom: 1px solid var(--gray-l);
}

.question {
  border-bottom: 1px solid var(--gray-xl);
}

.question:last-of-type {
  border-bottom: 0;
}