.root {
  --padding-x-info: 16px;
  --width-action: 30px;
  --width-actions: 60px;
  --width-indicator: 34px;
  --background-color-hover: rgba(0, 0, 0, 0.04);
}

.btn {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid var(--gray-l);
  cursor: pointer;
  padding: 2px 0;
  height: 30px;
  min-width: initial;
  width: 100%;
  transition: background-color 180ms ease-in-out,
              border 120ms ease-in,
              border-color 120ms ease-in,
              color 120ms ease-in;
}

.btn:hover {
  border: 1px solid var(--gray-l);
  background-color: var(--background-color-hover);
}

.indicator {
  display: flex;
  align-items: center;
}

:global(.MuiMenuItem-root).input {
  width: 100%;
  padding: 0;
  min-height: auto;
}

:global(.MuiListItem-gutters).input {
  padding: 0;
  padding-left: 0;
  padding-right: 0;
}

:global(.MuiListItem-button).input:hover {
  background-color: initial;
}

.btn .input.readonly {
  width: 100%;
}

.input {

}

.input:not(.readonly) .option .info:hover {
  background-color: var(--background-color-hover);
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.editable {

}

.preset {

}

.option {
  display: flex;
}

.option.editable .name {
  width: 50%;
}

.option.preset .name {
  width: 50%;
}

.readonly .name {
  width: 50%;
}

.item,
.info {
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.info {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px var(--padding-x-info);
}

.readonly .info {
  padding-right: calc(var(--padding-x-info) + (var(--width-actions) - var(--width-indicator)))
}

.preset .info {
  padding-right: calc(var(--padding-x-info) + var(--width-actions));
}

.name {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--black-l);
  font-family: var(--font-reg);
}

.details {
  display: flex;
  align-items: center;
  width: 50%;
}

.palette {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  height: 18px;
}

.color {
  height: 18px;
  width: 18px;
}

.color:not(:last-child) {
  margin-right: 3px;
}

.actions {
  display: none;
}

.editable .actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.action {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--width-action);
  padding: 6px 2px;
  border-radius: 50%;
}

.action:hover {
  background-color: var(--background-color-hover);
}

.action:active {
  background-color: var(--blue-l);
}

.action:active .icon {
  color: var(--pri-02);
}

.icon {
  color: var(--gray-d);
  width: 18px;
  height: 18px;
}

.edit {
  composes: action;
}

.delete {
  composes: action;
}

.text {
  font-family: var(--font-reg);
  color: var(--black-l);
}

.create {

}