.root {
  display: flex;
  align-items: center;
}

.quote {
  padding: 12px;

  background-color: #f9f9f9;

  border-radius: 2px;
  border-left-Style: solid;
  border-left-width: 5px;
  border-left-color: var(--pri-02);

  width: 100%;
}

.quote-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.copy {
  display: flex;
  flex-shrink: 0;
  color: var(--gray-d);
  cursor: pointer;
}

.star {
  display: flex;
  flex-shrink: 0;
  width: 24px;
  margin-right: 14px;
}

.view-more {
  color: var(--hyperlink);
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  flex-shrink: 0;
}

.item {
  margin-bottom: 10px;
}

.header {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  margin-bottom: 5px;
  font-size: 15px;
}

.content {
  font-size: 15px;
  color: var(--black-l);
  line-height: 20px;
}

.highlight {
  border-radius: 3px;
  background-color: yellow;
}

.paragraph {
  margin-block-start: 0;
  margin-block-end: 0;
}