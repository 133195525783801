.root {
  max-width: var(--navbar-width);
  width: 100%;
  margin: 0 auto;
}

.wrap {
  padding: 20px 10px 0;
}

.header {
  margin-bottom: 20px;
}

.title {

}

.main {

}