.root {
  display: inline-block;
  cursor: pointer;

  font-family: var(--font-semibold);
  color: var(--pri-02);
  font-size: 16px;
  line-height: 18px;
}

.root::before {
  content: '<';
  margin-right: 5px;
}