.subtitle {
  font-family: var(--font-bold);
  font-size: 16px;
  color: var(--black-l);
  margin-top: 15px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--pri-02);
  border-radius: 11px;
  min-width: 260px;
  height: 55px;
}

.text {
  color: var(--pri-01);
  margin: 0 15px;
  font-family: var(--font-bold);
  font-size: 18px;
}

.link {
  font-family: var(--font-bold);
  font-size: 16px;
  margin-bottom: 30px;
  color: var(--pri-02);
}

.caption {
  color: var(--gray);
  font-family: var(--font-helv);
  font-size: 14px;
  text-align: center;
  line-height: 25px;
}

.grow {
  display: inline-block;
  width: fit-content;
  cursor: pointer;
}

.grow:hover {
  color: var(--pri-02);
}

.underline {
  height: 2px;
  background-color: var(--pri-02);
  width: 0;
  opacity: 0;
  transform: scaleX(0);
  transition: opacity 120ms ease-out, transform 120ms ease-out, width 0s linear 80ms;
}

.grow:hover .underline {
  transition: width 80ms ease-out, opacity 120ms ease-in, transform 120ms ease-in;
  opacity: 1;
  transform: scale(1);
  width: 100%;
}

.notify {
  position: absolute;
  text-align: center;
  top: 14px;
  left: 0;
  right: 0;
  min-height: 14px;
}

.error {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: var(--font-reg);
  color: var(--pri-04);
}

.terms {
  font-size: 14px;
  color: var(--gray-d);
  font-family: var(--font-helv);
  line-height: 20px;
  text-align: center;
}

.tou {
  color: var(--pri-02);
}