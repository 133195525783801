.root {
  width: 300px;
}

.wrap {

}

.main {

}

.name {
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.title {
  font-family: var(--font-bold);
  font-size: 18px;
}

.close {

}

.field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;
}

.select {
  min-width: 170px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.details {
  display: flex;
  align-items: center;
  padding-top: 15px;
}

.moment-details {
  display: flex;
  align-items: center;
}

.speaker {
  color: var(--pri-02);
  font-family: var(--font-bold);

  display: flex;
  align-items: center;
}

.moment-speaker {
  color: var(--gray-d);
}

.speaker-role {
  font-size: 12px;
  margin-left: 4px;
}

.text {
  margin: 8px 0;
  font-family: var(---font-reg);
  line-height: 28px;
  cursor: text;
  font-size: 14px;
}

.duration {
  margin-left: 10px;
}