.root {

}

.wrap {

}

.main {
  display: flex;
  justify-content: space-between;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 35px;
  width: 110px;
  border-radius: 4px;
  color: var(--pri-02);
  font-size: 15px;
}

.icon {
  color: var(--pri-02);
  cursor: pointer;
  width: 16px;
}

.item {
  color: var(--black);
  font-size: 14px;
  line-height: 22px;
  width: 200px;
}