@value 1100 from 'vars';

.root {
  display: flex;
  position: relative;
  height: calc(100vh - var(--navbar-height));
  --top-bar-height: 80px;
  --filters-width: 350px;
}

@media 1100 {
  .root {
    padding: 0;
  }
}

.general-header {
  min-height: 80px;
  padding: 0 25px;
}

.header {
  border-bottom: 1px solid var(--gray-xl);
  background-color: var(--pri-01);
}

@media 1100 {
  .header {
    position: fixed;
    left: 351px;
    right: 0;
    z-index: 2;
  }
}

.main {
  max-width: 885px;
  margin: 0 auto;
}

@media 1100 {
  .main {
    margin: 0;
  }
}

.loading {
  position: relative;
  margin: 0 auto;
  padding-bottom: 25px;
  padding-top: 50px;
}

.title {
  text-align: center;
  font-family: var(--font-bold);
  font-size: 27px;
  color: var(--black-l);
}

@media 1100 {
  .title {
    font-size: 36px;
  }
}

.option {
  font-family: var(--font-bold);
  font-size: 20px;
  color: var(--black-l);
  width: 100%;
  max-width: 500px;
  margin-top: 45px;
  margin-bottom: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dropdown {
  width: 100%;
  max-width: 500px;
  margin-bottom: 50px;
}

.content {
  height: 250px;
  display: flex;
  justify-content: center;
}

.btns {
  display: flex;
  justify-content: center;
}

.end {
    text-align: center;
}

.btn {
  font-family: var(--font-bold);
  font-size: 16px;
  text-align: center;
  padding: 10px 20px;
  border-radius: 25px;
  border: 1px solid var(--pri-02);
  transition: background-color 0.1s ease-in;
  cursor: pointer;
  min-width: 100px;
}

@media 1100 {
  .btn:hover {
    border: 1px solid var(--sec-02);
  }
}

@media 1100 {
  .sidebar {
    position: fixed;
    width: var(--filters-width);
    border-right: 1px solid var(--gray-xl);
    z-index: 2;
  }
}

.items {
  position: relative;
  max-width: 885px;
  margin: 0 auto;
  height: calc(100% - 145px);
}

@media 1100 {
  .items {
    height: 100%;
    margin-left: 350px;
  }
}

@media 1100 {
  .scroll {
    padding-top: 80px;
  }
}

.wrap {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.blue {
  border: 1px solid #DDEFFE;
  background-color: #DDEFFE;
}

.red {
  border: 1px solid #DDEFFE;
  background-color: #DDEFFE;
}

.green {
  border: 1px solid #DDEFFE;
  background-color: #DDEFFE;
}

.gray {
  border: 1px solid #F2F2F2;
  background-color: #F2F2F2;
}