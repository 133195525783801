.root {
}

.wrap {
  padding: 0 20px 0 60px;
}

.header {
  font-size: 26px;
  line-height: 26px;
  font-family: var(--font-bold);
  color: var(--black);
}

.row {
  margin: 20px 0;
}

.item {
  display: flex;
}

.thumb {
  min-width: 200px;
  max-width: 200px;
  height: 112.5px;
  border-radius: 3px;
  overflow: hidden;
}

.img {
  width: 100%;
  height: auto;
}

.info {
  margin: 10px 0 0 20px;
}

.title {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 14px;
  margin-bottom: 5px;
}

.author {
  color: var(--gray-d);
  font-size: 14px;
}