.bg {
  min-height: 100vh;
  background: linear-gradient(0deg, rgba(85, 115, 177, 0.3), rgba(85, 115, 177, 0.3)), url('static/images/bg-login.png');
  background-position: center;
  background-size: cover;

  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-flow: row wrap;
}

.row {
  margin-bottom: 10px;
}

.col {
  position: relative;
  padding: 30px;
  margin: 0 auto;
}

@media (min-width: 800px) {
  .col {
    padding: 30px 0;
    margin: 0;
    right: 10%;
  }
}

.logo {
  display: flex;
  justify-content: center;
  margin: 0 0 25px 0;
}

.logo-image {
  height: 50px;
  width: initial;
  max-width: initial;
}

.form {
  background-color: #fff;
  border-radius: 5px;
  padding: 25px 40px;
}

.link {
  color: var(--hyperlink);
  cursor: pointer;
}

.row {
  margin-bottom: 10px;
}

.input {
  margin: 15px 0;
}

.submit {
  font-size: 18px;
  width: 100%;
  height: 50px;
  border-radius: 3px;
}

.visitor {
  width: 100%;
}

.visitor.btn {
  width: 100%;
}

.error {
  position: relative;
  width: 100%;
  max-width: 300px;
  top: 0;
  margin: 10px 0;
}

.signup {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.other-strategy {
  font-family: var(--font-semibold);
  font-size: 12px;
  color: var(--gray-d);
  text-align: center;
  margin-top: 30px;
}