.root {
  width: 100%;
  position: relative;
}

.wrap {
  overflow: auto;
  white-space: nowrap;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.main {
  display: inline-block;
  padding: 8px 10px;
  font-size: 14px;
  font-family: var(--font-semibold);
  line-height: 22px;
}

.chip {
  border-radius: 28px;
  overflow: hidden;
}

.chip:not(:last-child) {
  margin-right: 10px;
}

.chip:hover {
  color: var(--pri-02);
  background-color: var(--pri-01);
  border-color: #a8c8ff;
}

.fade {
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  width: 30px;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), var(--pri-01));
  opacity: 0;
}

.visible {
  opacity: 1;
}

.fade:nth-child(2) {
  position: absolute;
  right: -10px;
  top: 0;
  bottom: 0;
  left: auto;
  width: 30px;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--pri-01));
}

.active,
.active:hover {
  color: var(--pri-02);
  background-color: var(--blue-l);
  border-color: #a8c8ff;
}