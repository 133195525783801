.item {
 padding: 10px;
 border-bottom: 1px solid var(--gray-l);
}

.item:last-child {
 border-bottom: none;
}

.label {
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

.notes, .rating, .structured-feedback {
  margin-bottom: 10px;
}

.user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.updated-on {
  color: var(--gray-d);
  margin-left: 5px;
  font-size: 12px;
}

.tags {
  width: 300px;
}

.tag {
  cursor: pointer;
  padding: 6px;
  margin-right: 10px;
  margin-bottom: 10px; 
}