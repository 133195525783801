.root {
  border: 1px solid var(--gray-l);
  border-radius: 2px;

  z-index: var(--z-index-modal);
  width: 325px;
  max-width: 350px;

  background-color: var(--pri-01);
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.2);
  font-size: 15px;

  color: var(--gray-d);
}

.items {
  max-height: 240px;
  overflow-y: auto;
}