.root {
  padding: 0 25px 10px 25px;
}

.header {
  text-align: left;
  margin-bottom: 25px;
}

.password {
  display: flex;
  align-items: center;
  height: 20px;
}

.password .value {

}

.password .title {
  font-family: var(--font-semibold);
  margin-right: 10px;
}

.password .input {
  color: var(--black);
  font-family: var(--font-reg);
  border: none;
  font-size: 16px;
  width: 260px;
}

.link {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.link .input {
  border: 2px solid var(--gray-l);
  border-radius: 3px;
  width: calc(100% - 16px);
  padding: 8px;
  font-size: 14px;
  color: var(--black);
  font-family: var(--font-reg);
}

.text {
  font-size: 14px;
}

.reveal {
  color: var(--pri-02);
  cursor: pointer;
}

.reveal:hover {
  text-decoration: underline;
}

.btn {
  margin-left: 10px;
  border-radius: 3px;
  padding: 0;
  height: 38px;
  font-size: 14px;
}

.check-icon {
  margin-left: 10px;
  color: var(--pri-03);
}

.copy-icon {
  margin-left: 10px;
  color: var(--gray-d);
  cursor: pointer;
}