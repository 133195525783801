.root {

}

.wrap {

}

.main {
  min-height: 290px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.readonly {
  color: var(--gray-d);
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 2px solid var(--sec-01);
  line-height: 32px;
  width: 170px;
}

.select {
  width: auto;
}