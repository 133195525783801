.root {
  display: flex;
  align-items: center;
}

.img {
  display: flex;
  height: 48px;
  width: 48px;
  margin-right: 10px;
}

.name {
  font-weight: bold;
}

.age {
  margin-left: 5px;
  font-size: 12px;
  color: #999999;
}