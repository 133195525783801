.black {
  border-color: var(--black);
  color: var(--black);
}

.black:not(:disabled):hover {
  background-color: var(--gray-xl);
}

.green {
  border-color: var(--pri-03);
  color: var(--pri-03);
}

.green:not(:disabled):hover {
  background-color: var(--greenish-white);
}

.red {
  border-color: var(--pri-04);
  color: var(--pri-04);
}

.red:not(:disabled):hover {
  background-color: var(--reddish-white);
}

.gray {
  border-color: var(--gray-d);
  color: var(--gray-d);
}

.silver:not(:disabled):hover,
.gray:not(:disabled):hover {
  background-color: var(--gray-xl);
}

.silver {
  border-color: var(--gray);
  color: var(--gray-d);
}

.blue {
  border-color: var(--pri-02);
  color: var(--pri-02);
}

.blue:not(:disabled):hover {
  background-color: var(--blue-l);
}

.thin {
  border-width: 1px;
}

.medium {
  border-width: 2px;
}

.thick {
  border-width: 3px;
}

.light {
  font-family: var(--font-helv);
}

.regular {
  font-family: var(--font-reg);
}

.semibold {
  font-family: var(--font-semibold);
}

.bold {
  font-family: var(--font-bold);
}

.link {
  background-color: transparent;
  border-color: transparent;
  font-family: var(--font-bold);
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: background-color 180ms ease-in-out,
              border 120ms ease-in,
              border-color 120ms ease-in,
              color 120ms ease-in;
}

.link:disabled {
  color: var(--gray);
  background-color: transparent;
  border-color: transparent;
}