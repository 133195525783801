.root {
  margin: auto;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.title {
  color: var(--black-l);
  font-family: var(--font-bold);
  font-size: 18px;
  line-height: 20px;
  margin: 0 0 10px 0;
}

.copy {
  color: var(--black-l);
  font-size: 14px;
  margin: 0 0 40px 0;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px 0;
}


@media (min-width: 800px) {
  .root {
    max-width: 700px;
  }

  .wrap {
    display: block;
    height: auto;
  }

  .title {
    font-size: 24px;
    line-height: 30px;
  }

  .copy {
    font-size: 20px;
    line-height: 24px;
  }

  .navigation {
    margin: 30px 0 20px 0;
  }
}