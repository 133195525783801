.item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.item:last-child {
  margin-bottom: 0;
}

.ordinal {
  width: 25px;
  min-width: 25px;
  margin-right: 15px;
  text-align: right;
}