.root {
  max-width: var(--navbar-width);
  width: 100%;
  margin: 0 auto;
}

.wrap {
  padding: 20px 10px 0;
}

.header {

}

.title {

}

.tools {
  display: flex;
  justify-content: space-between;
}

.filters {
  position: relative;
  z-index: 3;
}

.actions {
  flex: 0 0;
  margin: 10px 0 0 40px;
}

.main {
  padding: 7px 0 20px;
}

.thead {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.thead .tr {
  border-top: none;
  border-bottom: 1px solid var(--gray-l);
}

.td {
  font-size: 14px;
}

.name {
  display: flex;
  align-items: center;
  gap: 10px;
}

.avatar {
  width: 25px;
  height: 25px;
}

.link:hover {
  color: var(--hyperlink);
  text-decoration: underline;
}

.notes {
  padding: 6px 10px;
  font-size: 14px;
  max-width: 500px;
}

.notes .header {
  font-family: var(--font-bold);
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 5px;
  width: 100%;
  border-bottom: 1px solid var(--sec-01);
}

.notes .section {
  margin-bottom: 10px;
}

.notes .field {
  margin-bottom: 5px;
}

.notes .field .label {
  font-family: var(--font-bold);
  margin-bottom: 5px;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.pagination {
  padding: 0;
}

.dnc {
  display: flex;
  align-items: center;
}

.caution {
  color: var(--pri-04);
}

.warn {
  color: #eed202;
}

.icons {
  display: flex;
  gap: 10px;
}