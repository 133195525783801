.root {

}

.wrap {
  padding: 40px 20px;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  color: var(--gray-d);
}

.icon {
  color: var(--gray-l);
  margin-bottom: 15px;
}

.icon svg {
  stroke-width: 1;
}

.message {
  font-family: var(--font-semibold);
  margin-bottom: 40px;
  font-size: 14px;
  color: var(--black-l);
}

.note {
  font-family: var(--font-reg);
  font-size: 14px;
  font-size: 14px;
}