@value 1100 from 'vars';

.root {
  height: calc(100vh - var(--navbar-height));
}

@media 1100 {
  .root {
    composes: shadow from './Messages.css';
    border-top: none;
    border-left: none;
    border-bottom: none;
  }
}

.hidden {
  composes: hidden from './Messages.css';
  composes: root;
}

.visible {
  composes: visible from './Messages.css';
  composes: root;
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.icon {
  width: 200px;
  height: 200px;
  color: var(--sec-01);
  stroke-width: .4;
}