.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.empty {
  text-align: center;
  padding: 35px 0;
}

.text {
  margin-top: 40px;
  font-family: var(--font-reg);
  color: var(--gray-d);
}

.title {
  color: var(--gray-d);
  font-family: var(--font-semibold);
}

.footer {
  text-align: center;
  color: var(--pri-02);
  font-family: var(--font-semibold);
  padding: 15px;
}

.body {
  height: auto;
}

.td {
  height: 55px;
  font-size: 14px;
}

.tr:last-child {
  border-bottom: none;
}

.name-cell {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
}

.brand-icon {
  color: var(--gray-d);
  flex-shrink: 0;
}