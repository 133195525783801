.title {
  width: 100%;
  font-family: var(--font-bold);
  font-size: 22px;
  color: var(--black);
  line-height: 45px;
  border-bottom: 1px solid var(--sec-01);
  padding-bottom: 10px;
  margin-bottom: 20px;
}

@media (min-width: 800px) {
  .title {
    font-size: 28px;
  }
}

.sessions {
  font-size: 14px;
}

.session {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.session:not(:last-child) {
  border-bottom: 1px solid var(--gray-xl);
}

.left {
  max-width: 50%;
}

@media (min-width: 800px) {
  .left {
    max-width: 65%;
  }
}

.right {
  display: flex;
  justify-content: right;
  align-items: center;
  min-width: 200px;
}

.logout {
  border-radius: 4px;
  padding: 2px 0;
  margin: 0 0 0 6px;
  font-size: 14px;
  min-width: 70px;
}

.ip {
  font-family: var(--font-semibold);
  margin-bottom: 4px;
}

.ua {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.current {
  color: var(--gray-d);
  font-family: var(--font-semibold);
}