.root {
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.details {
  composes: details from './Results.css';
}

.name {
  composes: name from './Results.css';
}

.type {
  composes: type from './Results.css';
  color: var(--gray);
  font-size: 12px;
  font-family: var(--font-reg);
}

.icon {
  composes: icon from './Results.css';
  height: 28px;
  width: 28px;
}

.highlighted {
  composes: highlighted from './Results.css';
}

.return {
  composes: return from './Results.css';
}