.root {
  max-width: 300px;
  margin: 0 auto;
}

@media (min-width: 1120px) {
  .root {
    max-width: 700px;
  }
}

.header {
  text-align: left;
}

@media (min-width: 1120px) {
  .header {
    text-align: center;
  }
}

.title {
  font-size: 30px;
  font-family: var(--font-bold);
  color: var(--black-d);
  margin: 0 auto 20px;
}

.subtitle {
  font-size: 18px;
  margin: 0 auto 20px;
  color: var(--black-l);
}

.label {
  font-size: 18px;
  font-family: var(--font-semibold);
  color: var(--black-d);
}

.value {
  font-size: 18px;
  color: var(--black-d);
  border-bottom: 1px solid var(--sec-01);
}

@media 800 {
  .value {
    padding-right: 30px;
  }
}

.panel {
  border-bottom: none;
  padding: 15px 0;
}

.text {
  padding-bottom: 20px;
}

.footer {
  display: flex;
  align-items: center;
  font-family: var(--font-bold);
  color: var(--pri-02);
  font-size: 18px;
  margin: 20px 0;
}

.icon {
  font-size: 16px;
  stroke-width: 3px;
  margin-left: 10px;
  line-height: 18px;
}