.root {
  border: 1px solid var(--gray-l);
  border-radius: 8px;
  --height-layout-header: 60px;
}

.main {

}

.table {
  --height-row-head: 40px;
  --height-row-footer: 40px;
  --gutter-table-bottom: 20px;
  --height-body: calc(100vh - (var(--top-row-head) + var(--height-row-head) + var(--height-row-footer) + var(--gutter-table-bottom)));
}

.head {
  position: sticky;
  top: var(--top-row-head);
  background-color: var(--gray-xl);
  z-index: 2;
}

.th {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  font-family: var(--font-semibold);
  color: var(--black-l);
  padding: 0 10px;
  width: 100%;
}

.head .tr {
  display: flex;
  flex: 1 0 auto;
  box-sizing: border-box;
  border-top: 1px solid var(--gray-l);
  min-height: var(--height-row-head);
}

.tr {
  border-bottom: 1px solid var(--gray-l);
}

.body:last-child .tr:last-child:not(:first-child) {
  border-bottom: none;
}

.td {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 0 10px;
}

.body {
  max-height: 600px;
  overflow-y: auto;
}

@media (max-height: 759px) {
  .body {
    height: var(--height-body);
    max-height: var(--height-body);
  }
}

@media (min-height: 760px) {
  .body {
    max-height: 600px;
    height: 600px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  min-height: var(--height-layout-header);
}

.title {
  font-size: 20px;
  font-family: var(--font-semibold);
  color: var(--black);
}

.footer {
  padding: 0 10px;
  min-height: var(--height-row-footer);
  border-top: 1px solid var(--gray-l);
  margin-top: -1px;
}

.c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  width: 100%;
  padding: 0 10px;
}

.cs {
  background-color: rgba(0, 0, 0, 0.15);
}