.root {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrap {
  max-width: 350px;
  margin: 0 20px;
}

.sync {
  max-width: 550px;
}

.sync .main {
  padding: 40px 20px 20px;
}

.main {
  padding: 40px 0 20px;
}

.header {
  font-size: 28px;
  font-family: var(--font-bold);
  margin-bottom: 20px;
}

.body {
  margin: 15px 0;
}

.footer {
  margin: 25px 0;
}

.text {
  margin-bottom: 20px;
}

.btn {
  composes: brick from 'css/button.css';
  display: block;
}

.syncing {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 30px;
}

.dots {
  position: relative;
  width: 100px;
  height: 20px;
  border-top: 4px dotted var(--gray);
}

.syncing .dots:nth-of-type(3) {
  width: 90px;
  margin-right: 10px;
}

.chevron {
  position: absolute;
  right: -10px;
  bottom: 0;
  border-top: 3px solid var(--gray);
  border-right: 3px solid var(--gray);
  height: 11px;
  width: 11px;
  transform: rotate(45deg);
  border-radius: 2px;
  margin-bottom: 15px;
  background-color: transparent;
}

.linkedin {
  color: #0e76a8;
  position: absolute;
  left: -3px;
  bottom: -2px;
}

.box {
  overflow: hidden;
  width: 40px;
  height: 40px;
  position: relative;
}

.title {
  font-family: var(--font-bold);
  color: var(--black);
  font-size: 22px;
}

.indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 175px;
}

.spinner {

}

.skip {
  margin-left: 15px;
  min-width: auto;
  height: auto;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
}

.proceed {

}

.indicator {
  position: relative;
}

.ring {
  position: absolute;
  height: 86px;
  width: 86px;
  border: 18px solid var(--gray-xl);
  border-radius: 50%;
  border-color: var(--gray-xl);
}

.svg {
  position: relative;
  z-index: 1;
}

.progress {
  transition: 3s stroke-dashoffset cubic-bezier(0, 0.2, 0.58, 1.0);
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}