.root {
  --font-size-heading-xl: 40px;

  --font-size-heading-1: 34px;
  --font-size-heading-2: 30px;

  --font-size-subtitle-1: 20px;
  --font-size-subtitle-2: 18px;

  --max-width: 1280px;

  --height-fullpage: calc(100vh - var(--navbar-height));
}