.root {
  border-bottom: 1px solid var(--sec-01);
}

.wrap {
  padding: 15px;
  /* padding-left: calc(15px + 38px); */
  position: relative;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.expand {
  cursor: pointer;
  color: var(--pri-02);
  font-family: var(--font-semibold);
}

.highlight {
  margin-bottom: 10px;
}

.highlight:last-child {
  margin-bottom: 0;
}