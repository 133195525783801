@value 900 from 'vars';

.root {
  display: flex;
  flex-direction: column;
  padding: 0 25px 0 0;
  width: 100%;
  box-sizing: border-box;
}

@media 900 {
  .root {
    width: 200px;
    min-width: 200px;
  }
}

.item {
  display: flex;
  align-items: center;
  min-height: 25px;
}

.link {
  display: block;
  color: var(--gray-d);
  font-family: var(--font-bold);
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 3px;
}

.link:hover {
  background-color: var(--gray-xxl);
}

.link.active {
  background-color: var(--gray-xl);
  color: var(--black-l);
}