.root {
  font-size: 15px;
  color: var(--gray-d);
  padding: 10px 15px;
  background-color: var(--pri-01);
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
  position: relative;
  left: 50%;
  max-width: 200px;
}

.repeat-root {
  composes: root;

  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.header {
  font-family: var(--font-bold);
}

.label {
  font-family: var(--font-semibold);
}

.subtitle {
  margin: 5px 0 10px 0;
}

.stat {

}
