.root {

}

.wrap {

}

.main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 10px;
}

.header {
  display: flex;
  margin-bottom: 15px;
}

@media (min-width: 500px) {
  .main {
    flex-direction: row;
    margin-bottom: 0;
  }
  .header {
    margin-bottom: 25px;
  }
}

.avatar {
  height: 90px;
  width: 90px;
}

.avatar img {
  border-radius: 20px;
}

.name {
  font-family: var(--font-bold);
  font-size: 18px;
  margin-bottom: 5px;
}

.career {
  color: var(--gray-d);
  font-size: 15px;
}

.career span {
  display: block;
  margin-bottom: 5px;
}

.info {
  margin-left: 15px;
}

.bio {
  position: relative;
  color: var(--gray-d);
  font-size: 15px;
  max-width: 600px;
  max-height: 100px;
  overflow: hidden;
}

.overlay {
  position: absolute;
  bottom: 0;
  height: 80%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--pri-01));
}

.btn {
  width: 180px;
  font-size: 15px;
  margin-bottom: 5px;
}

.btn:last-child {
  margin-bottom: 0;
}

.btns {
  display: flex;
  flex-direction: column;
}

.link {
  color: var(--black-l);
  font-family: var(--font-semibold);
  font-size: 15px;
  padding: 20px 0;
}