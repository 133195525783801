.root {
  width: 350px;
}

.main {
  padding: 10px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
}

.title {
  font-size: 18px;
  font-family: var(--font-semibold);
}

.label {
  font-family: var(--font-semibold);
  margin-right: 10px;
  width: 50px;
}

.close {
  cursor: pointer;
}

.tag-selector {
  width: 100%;
}

.tag-option {
  display: flex;
  align-items: center;
}

.tag-option .color {
  margin-right: 8px;

  border-radius: 50%;

  width: 12px;
  min-width: 12px;
  height: 12px;
}

.tag-option .star {
  margin-right: 5px;
  margin-left: -2px;
  width: 16px;
  min-width: 16px;;
  height: 16px;

  color: var(--gray);
}

.tag-option.selected .star {
  margin-left: 0;
}