.root {
  width: 100%;
  padding: 0 20px;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.main {
}

.label {
  font-family: var(--font-semibold);
  color: var(--pri-02);
  font-size: 12px;
  line-height: 15px;
}

input.input[type='file']::-webkit-file-upload-button {
  cursor: pointer;
}

.input {
  position: absolute;
  height: 15px;
  width: 90px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
}

.btn {
  position: relative;
  height: 15px;
  width: fit-content;
  margin-bottom: 15px;
}

.subtitle {
  font-family: var(--font-reg);
  color: var(--gray-d);
  font-size: 12px;
  line-height: 15px;
}