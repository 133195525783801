@value 1100 from 'vars';

.root {
  display: none;
}

@media 1100 {
  .root {
    display: block;
  }
}

.wrap {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #F5F5F5;
}

.main {
  padding-left: 80px;
  padding-top: 120px;
}

.header {
  font-family: var(--font-bold);
  font-size: 20px;
  color: var(--gray-d);

  margin-bottom: 40px;
}

.trusted {
  font-family: var(--font-bold);
  font-size: 20px;
  color: var(--gray-d);

  margin-bottom: 35px;
}

.items {
  margin-bottom: 90px;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.item-number {
  color: var(--gray-d);
  font-family: var(--font-bold);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 18px;

  /* border-radius: 50%;
  background-color: var(--gray);
  width: 38px;
  height: 38px; */

  margin-right: 20px;
}

.item-text {
  font-family: var(--font-reg);
  color: var(--gray-d);
  font-size: 18px;
}

.logos {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-wrap: wrap;

}

.logo img{
  height: 40px;
  margin-bottom: 15px;
  margin-right: 25px;
}

@media(max-width: 1400px) {
  .logo:last-child img{
    display: none;
  }
}