.form {
  display: flex;
  flex-direction: column;
}

.input {
  margin: 10px 0px;
}

.spinner {
  width: 24px;
  margin-left: auto;
  margin-right: auto;
}

.delete-connection {
  margin-top: 20px;
}

.external-link {
  font-family: var(--font-bold);
  display: inline-flex;
  align-items: center;
}

.linkContainer {
  display: flex;
  align-items: center;
}

.external-icon {
  margin-left: 2px;
}

.instruction {
  margin-bottom: 10px;
}

.external-doc {
  font-size: 14px;
  color: var(--gray-m);
  margin-bottom: 10px;
}

.error-msg {
  font-family: var(--font-bold);
  color: var(--pri-04);
  margin-top: 10px;
}