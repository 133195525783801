.root {
  position: relative;
}

.icon {
  position: absolute;
  top: calc(50% - 12px);
  right: 0;
  color: rgba(0, 0, 0, 0.54);
  pointer-events: none;
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}