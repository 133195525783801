.root {
  max-width: 600px;
  margin-bottom: 30px;
}

.wrap {
  border-radius: 8px;
  border: 1px solid var(--sec-01);
  overflow: hidden;
}

.main {

}

.header {

}

.base {
  display: flex;
  flex-direction: row;
  height: 125px;
}

.full {
  display: flex;
  flex-direction: column;
}

.base .image {
  height: 125px;
  width: 125px;
}

.full .image {
  width: 100%;
  height: 100%;
}

.url {
  display: -webkit-box;
  position: relative;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: var(--black-l);
}

.description {
  display: -webkit-box;
  position: relative;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: var(--black-l);
}

.base .description {
  -webkit-line-clamp: 1;
}

.full .description {
  -webkit-line-clamp: 1;
}

.title {
  display: -webkit-box;
  position: relative;
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: 20px;
  font-family: var(--font-semibold);
  color: var(--black-l);
}

.base .title {
  -webkit-line-clamp: 1;
}

.full .title {
  -webkit-line-clamp: 2;
}

.info {
  background-color: #fafafa;
  height: 125px;
  width: 100%;
}

.details {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
}

.refetching {
  height: 20px;
  text-align: right;
}