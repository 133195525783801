.root {
  width: 100%;
  background-color: rgba(118, 209, 255, 0.1);
}

.wrap {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 45px 15px;
}

.title {
  font-family: var(--font-semibold);
  font-size: 32px;
  line-height: 42px;
  color: var(--black);
}

.subtitle {
  font-family: var(--font-reg);
  font-size: 18px;
  line-height: 42px;
  color: var(--black);
  text-align: center;
}

.btn {
  height: 60px;
  font-size: 18px;
  width: 250px;
  border-radius: 27px;
}