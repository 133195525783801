.root {
  height: 300px;
}

.custom-text {
  font-size: 18px;
  margin-bottom: 10px;
}

.enter-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.phone-input {
  width: 300px;
  margin-bottom: 20px;
}