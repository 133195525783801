.root {
  padding: 20px 15px;
}

.header {
  margin-bottom: 15px;
}

.media {
  border: 1px solid var(--sec-01);
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
}

.topics {
  margin-top: 10px;
}

.preview {
  margin-top: 20px;
}