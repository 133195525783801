.root {
  background-color: #c5ecd1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 50px;
  box-sizing: border-box;
}

.text {
  color: var(--sec-03);
  font-family: var(--font-bold);
}

.x {
  position: absolute;
  cursor: pointer;
  right: 25px;
  color: var(--gray-d);
  top: calc(50% - 15px);
}