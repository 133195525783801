.root {
  border-radius: 5px;
  background-color: var(--gray-xxl);
  padding: 10px;
  box-sizing: border-box;

  max-width: 550px;
  width: 100%;
  margin: 5px 0;
}

.item {
  margin-bottom: 10px;
}

.item:last-child {
  margin-bottom: 0;
}

.header {
  font-family: var(--font-semibold);
}

.content, .header {
  font-size: 14px;
  line-height: 20px;
}

.highlight {
  border-radius: 3px;
  background-color: yellow;
}

.paragraph {
  margin-block-start: 0;
  margin-block-end: 0;
}

.see-more {
  background-color: var(--gray-xxl);
  color: var(--gray-d);
  font-family: var(--font-semibold);
}