.root {
  padding: 20px;
}

.author {
  margin-bottom: 15px;
}

.scroll {
  max-height: 400px;
  overflow-y: auto;
}

.message {
  height: 35px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.char-limit {
  color: var(--pri-04);
  font-family: var(--font-semibold);
  font-size: 14px;
}

.preview {
  padding: 20px 0;
}

.content {
  position: relative;
}

.remove {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  background-color: var(--gray-m);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 2px;
  cursor: pointer;
}

.remove:hover {
  background-color: var(--gray-d);
}

.remove .cancel {
  color: var(--pri-01);
  width: 20px;
  height: 20px;
}

.loading {
  color: var(--gray-d);
  font-size: 15px;
  text-align: right;
  width: 100%;
}