.root {
  
}

.title {
  font-family: var(--font-semibold);
  font-size: 16px;
}

.range {
  display: flex;

}

.indicator {
  width: 100%;
}

.label-indicator {
  margin-top: 30px;
}

.text {
  color: var(--gray);
  width: 30px;
}