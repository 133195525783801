.section {
  min-height: 220px;
  padding-bottom: 100px;
}

.row {
  margin-bottom: 10px;
}

.module-option {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 18px;
  font-size: 15px;
}

.module-option:hover {
  background-color: #f7f9fe;
  cursor: pointer;
}

.module-option-name-container {
  flex: 1;
  padding-right: 10px;
}

.module-option-name {

}

.module-option-subtitle {
  font-size: 12px;
  color: var(--gray-d);
  margin-top: 2px;
}

.module-load-draft {
  visibility: hidden;
  color: var(--hyperlink);
  font-family: var(--font-semibold);
  align-items: center;
  display: flex;
}

.module-option:hover .module-option-name {
  color: var(--hyperlink);
  text-decoration: underline;
}

.module-option:hover .module-load-draft {
  visibility: visible;
}