.toolbar {
  display: inline-flex;

  border: 1px solid var(--sec-01);
  border-radius: 5px;
  padding: 4px;

  align-items: center;
}

.toolbar > * {
  margin-right: 4px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  padding: 5px;
  min-width: 30px;

  color: var(--gray-d);
  border-radius: 4px;
}

.btn:last-of-type {
  margin-bottom: 0;
}

.btn:hover {
  background-color: var(--gray-xl);
  color: var(--black-l);
}

.divider {
  height: 22px;
  background-color: var(--sec-01);
  width: 1px;
  margin: 0 5px;
}