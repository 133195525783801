.body {
  margin: 0 auto;
}

.modal-wrap {
  width: 700px;
}

.link {
  cursor: pointer;
  color: var(--hyperlink);
  text-decoration: underline;

  margin-bottom: 15px;
}

.toolbar {
  margin-bottom: 10px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.footer > *:nth-child(2) {
  margin-left: 15px;
}