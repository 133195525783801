.root {

}

.wrap {
  position: relative;
  background-color: var(--gray-xl);
}

.main {
  padding: 0 25px;
  position: relative;
}

.carousel {
  display: flex;
  position: relative;
  transition: transform 150ms ease-out;
  padding: 10px 0;
}

.header {
  font-family: var(--font-semibold);
  font-size: 17px;
  padding: 25px;
}

.item {
}

.item > * {
  margin: 0 7.5px;
}

.left {
  position: absolute;
  top: calc(50% - 15px);
  left: 2px;
}

.left svg {
  margin-right: 3px;
}

.right svg {
  margin-right: 0px;
}

.right {
  position: absolute;
  top: calc(50% - 15px);
  right: 2px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: var(--pri-01);
  transition: background-color 100ms ease-out;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 10%),
              0px 6px 10px 0px rgb(0 0 0 / 8%),
              0px 1px 18px 0px rgb(0 0 0 / 4%);
}

.btn:disabled {
  cursor: default;
  display: none;
}

.btn:not(:disabled) {
  color: var(--gray-d);
}

.btn:active:not(:disabled) {
  background-color: var(--sec-01);
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 25px;
}

.launch {
  color: var(--black-l);
}

.launch:hover {
  color: var(--pri-02);
}