.body {
  max-width: 750px;
  margin: 0 auto;
}

.image-body {
  max-width: 800px;
  margin: 0 auto;
}

.image-modal-wrap {

}

@media(min-width: 800px){
  .image-modal-wrap {
    width: 800px;
  }
}