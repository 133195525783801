.root {

}

.label {
  font-size: 15px;
  font-family: var(--font-semibold);
  color: var(--gray-d);
  margin-right: 8px;
  width: 65px;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.row:last-of-type {
  margin-bottom: 0;
}

.tooltip {
  font-family: var(--font-semibold);
}