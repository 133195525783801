.root {
  font-size: 22px;
  width: 100%;
  font-family: var(--font-bold);
  color: var(--black);
  line-height: 45px;
  border-bottom: 1px solid var(--gray-l);
  box-shadow: 0 1px 0 0 var(--sec-01);
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 10px;
}