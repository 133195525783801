.root {
  width: 100%;
  text-align: center;
}

.briefcase {
  width: 115px;
  margin-top: 15px;
  height: auto;
  color: var(--gray-l);
}

.text {
  margin: 20px auto 20px auto;

  font-family: var(--font-semibold);
  color: var(--gray-d);
  width: 260px;

  box-sizing: border-box;
  min-height: 40px;
}

@media (min-width: 800px) {
  .briefcase {
    width: 125px;
    margin-top: 25px;
  }

  .text {
    margin: 40px auto 20px auto;
  }
}