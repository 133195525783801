.root {
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.title {
  font-family: var(--font-semibold);
  color: var(--black-l);
  font-size: 18px;
  max-width: 575px;

  margin-bottom: 25px;
}

.table {
  margin-bottom: 20px;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer-item {
  color: var(--gray-d);
  font-style: italic;
}