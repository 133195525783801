.row {
  display: flex;
  align-items: center;
}

.label {
  font-size: 15px;
  font-family: var(--font-semibold);
  color: var(--gray-d);
  margin-right: 10px;
}

.classification-dropown {
  width: 200px;
}