.item {
  padding: 0 30px 20px;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.name {
  font-family: var(--font-bold);
  color: var(--gray-d);
  font-size: 14px;
}

.current {
  composes: name;
  color: var(--pri-02);
}

.time {
  font-family: var(--font-reg);
  color: var(--gray-m);
  font-size: 12px;
  margin-left: 10px;
}

.body {
  font-family: var(--font-reg);
  color: var(--black);
  font-size: 15px;
  white-space: pre-wrap;
  word-break: break-word;
}

.review {
  color: var(--gray);
}

.status {
  color: var(--gray-d);
  font-family: var(--font-semibold);
  font-size: 12px;
  text-align: right;
}

.actions {
  margin: 20px 0 0 0;
}

.invite-accept {
  width: 130px;
  font-size: 14px;
}

.invite-reject {
  width: 130px;
  font-size: 14px;
  margin: 0 20px 0 0;
}