.root {
  display: flex;
  align-items: flex-end;
  gap: 2px;
}

.currency {
  flex-shrink: 1;
  flex-basis: 80px;
}

.value {
  flex-grow: 1;
  flex-basis: 200px;
}