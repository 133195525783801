.root {
  border-radius: 50%;
  border: 2px solid var(--pri-02);
  border-width: 2px;
  padding: 5px;
  transition: all 0.2s ease;
  cursor: pointer;
  display: flex;
}

.pencil {
  background-color: var(--pri-01);
  color: var(--pri-02);
  transition: all 0.2s ease;
}

.root:hover {
  background-color: var(--pri-02);
  color: var(--pri-01);
}

.root:hover .pencil {
  background-color: var(--pri-02);
  color: var(--pri-01);
}