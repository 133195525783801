.container {
  display: flex;
  align-items: center;
  border: 1px solid var(--gray-m);
  padding: 1px 5px;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search-icon {
  margin-right: 5px;
  margin-left: 5px;
  color: var(--gray-m);
}

.container:focus-within {
  border: 2px solid var(--gray-d);
  padding: 0px 4px;
}

.container:focus-within .search-icon {
  color: var(--gray-d);
}

.input div {
  display: none;
}

.hide-focus {
  display: none;
}

.spinner {

}

.clear {
  cursor: pointer;
}