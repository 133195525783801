.error-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  padding: 0 20px 100px 20px;
  box-sizing: border-box;
}

.wrong {
  color: var(--black);
  background-color: var(--reddish-white);
  border-radius: 15px;
  padding: 32px;
  font-size: 16px;
}

@media 800 {
  .wrong {
    font-size: 18px;
  }
}

.wrong h1 {
  display: flex;
  font-size: 20px;
  font-family: var(--font-bold);
  line-height: 28px;
  margin-bottom: 16px;
  
}

@media 800 {
  .wrong h1 {
    font-size: 24px;
    line-height: 32px;
  }
}

.frown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.frown svg {
  color: var(--black);
  opacity: 0.4;
  height: 30px;
  width: 30px;
}