@value 1100 from 'vars';

.root {
  width: 100%;
}

.container {
  padding: 0 40px;
  max-width: 350px;
  margin: 0 auto;
}

@media 1100 {
  .container {
    overflow-y: auto;
    height: calc(100vh - 60px);
  }
}

@media (max-width: 1099px) {
  .root {
    position: fixed;
    z-index: 4;
    right: 0px;
    bottom: 0px;
    top: var(--navbar-height);
    background-color: var(--pri-01);
    width: 100%;
    overflow-y: auto;
  }

  .visible {
    composes: root;
    right: 0px;
    transition: right 250ms cubic-bezier(0.25, 0.1, 0.25, 1.0) 0ms,
                box-shadow 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    box-shadow: 0px 2px 104px 10px rgba(0,0,0,0.1),
                0px 4px 105px 100vw rgba(0,0,0,0.1),
                0px 1px 110px 200px rgba(0,0,0,0.1);
  }

  .hidden {
    composes: root;
    right: 100vw;
    box-shadow: 0px 2px 4px 10px rgba(0,0,0,0),
                0px 4px 5px 0px rgba(0,0,0,0.0),
                0px 1px 10px 0px rgba(0,0,0,0);
    transition: right 220ms cubic-bezier(0.0, 0.0, 0.58, 1.0) 0ms,
                box-shadow 250ms cubic-bezier(0.0, 0.0, 0.58, 1.0) 0ms;
  }

  .visible .container {
    margin: 0 auto;
    padding-bottom: 30px;
  }
}

@media 1100 {
  .visible,
  .hidden {
    display: block;
  }
}

.btns {
  display: flex;
  justify-content: center;
}

.btn {
  max-width: 100px;
}

@media 1100 {
  .btns {
    display: none;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.title {
  display: none;
  color: var(--black-l);
  font-family: var(--font-bold);
  font-size: 24px;
  line-height: 24px;
}

@media 1100 {
  .title {
    display: flex;
  }
}

.reset {
  display: flex;
  color: var(--pri-02);
  font-family: var(--font-bold);
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
  transition: all 0.5s ease;
}

@media 1100 {
  .reset {
    padding-left: 30px;
  }
}

.filter {
  margin-bottom: 20px;
}

.label {
    color: var(--black-l);
    font-family: var(--font-semibold);
    font-size: 16px;

    margin-bottom: 4px;
}

.save {
    position: relative;
    font-family: var(--font-bold);
    font-size: 17px;
    line-height: 17px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    width: 125px;
    border-radius: 25px;
    transition: all 0.5s ease;
    text-align: center;
    background-color: var(--pri-02);
    color: var(--pri-01);
    height: 20px;

    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;

    cursor: pointer;
}

.save:hover {
    background-color: var(--sec-02);
}

.tag-container {
    width: 100%;
    margin-top: 5px;
    min-height: 41px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.input {
    composes: input from 'static/css/input.css';
    font-family: var(--font-reg);
}

.filters-container .filter:last-of-type {
  margin-bottom: 125px;
}

.select {
  height: 32px;
}