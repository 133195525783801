.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  gap: 10px;
}

.share {

}

.actions {
  display: flex;
  align-items: center;
  gap: 5px;
}

.context-menu {
  /* margin-left: 10px; */
}

.access {
  /* margin-left: 10px; */
}

.discover {
  margin-right: 20px;
}