.rows {
  display: flex;
  flex-direction: column;
  gap: 15px;

  margin-bottom: 20px;
}

.lang-row {
  border-bottom: 1px solid var(--gray-l);
  padding-bottom: 15px;
}

.row-title {
  font-family: var(--font-bold);
  margin-bottom: 5px;
}