.root {
  border-radius: 4px;
  background-color: var(--pri-01);
  box-sizing: border-box;
  border: 1px solid var(--sec-01);
  max-width: 350px;
}

.unfocused:hover,
.focused
{
  box-shadow: 0 2px 6px 2px rgb(60 64 67 / 15%);
  transition: opacity 0.5s ease-in-out;
}

.unfocused {
  cursor: pointer;
}