.root {
  display: flex;
  justify-content: space-between;
}

.left {
  margin-right: 20px;
}

.left,
.right {
  display: flex;
}

.logo {
  margin-right: 20px;
  height: 84px;
  width: 84px;
}

.name {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 18px;
  line-height: 22px;
  word-break: break-all;
}