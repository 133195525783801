.root {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--pri-04);
  overflow: hidden;
  cursor: pointer;
}

.root:hover .icon {
  background-color: var(--pri-04);
  color: var(--pri-01);
}

.icon {
  padding: 7px;
  transition: all 0.2s ease;
  color: var(--pri-04);
  overflow: visible;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--pri-04);
}