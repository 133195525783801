.root {

}

@media (min-width: 800px) {
  .root {
    max-width: 225px;
  }
}

.copy {
  margin-bottom: 10px;
}

.btn {
  color: var(--pri-02);
  font-family: var(--font-semibold);
  padding: 0;
  font-size: 14px;
}

.btn:before {
  content: '+';
  margin-right: 5px;
}