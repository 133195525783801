.root {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.title {
  font-size: 28px;
  text-transform: uppercase;
  font-family: var(--font-bold);
  margin-bottom: 20px;
  text-align: center;
  color: #5573b1;
}

.divider {
  width: 40%;
  border-bottom: 4px solid #5573b1;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.block {
  border: 1px solid var(--gray-l);
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: none !important;
}

.hidden {
  display: none;
}

.question {
  font-size: 18px;
  font-family: var(--font-semibold);
  color: var(--black-l);
  position: relative;
  padding-right: 40px;
  width: 100%;
}

.toggle-icon {
  position: absolute;
  right: 0px;
  top: -3px;
}

.answer {
  margin-top: 10px;
  transition: 0.5s ease;
}