@value 800 from 'vars';

.root {
  padding-top: 40px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.em {
  font-family: var(--font-bold);
}

.graphic {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img {
  max-width: 800px;
}