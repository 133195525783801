@value 800, 1000 from 'vars';

.root {
}

@media 800 {
  .root {
    border-top: 1px solid var(--gray-xl);
  }
}

.wrap {
  margin: 0 auto;
  padding: 0 20px;
}

@media 800 {
  .wrap {
    padding: 40px 20px 0;
  }
}

.main {
  max-width: 400px;
  margin: 0 auto;
}

@media 800 {
  .main {
    max-width: 700px;
    display: flex;
    justify-content: center;
  }
}

@media 1000 {
  .main {
    max-width: var(--max-width);
    width: 100%;
  }
}

.header {
  max-width: var(--max-width);
  text-align: center;
  margin: 0 auto 20px;
}

@media 800 {
  .header {
    margin-bottom: 50px;
  }
}

@media 1000 {
  .header {
    margin: 0 auto 30px;
  }
}

.title {
  font-size: var(--font-size-header);
  font-family: var(--font-bold);
  color: var(--black-d);
  max-width: 480px;
  margin: 30px auto;
}

.subheader {
  font-size: 18px;
  color: var(--black-l);
  margin: 0 auto;
  max-width: 760px;
}

@media 800 {
  .subheader {
    font-size: 20px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 150px;
}

@media 800 {
  .item {
    width: 260px;
  }
}

.text {
  font-family: var(--font-semibold);
  font-size: 22px;
  color: var(--black);
  margin: 10px 0;
}

.icon {
  width: 70px;
  height: 70px;
}