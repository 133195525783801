.root {
  height: 100%;
}

.header {
  font-size: 18px;
  font-family: var(--font-bold);
  margin: 0 0 10px;
  padding: 0 8px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 74px);
}

.active {
  composes: active from './Bar.Right.css';
}

.icon {
  composes: icon from './Bar.Right.css';
  height: 40px;
  width: 40px;
}

.icon svg {
  height: 28px;
  width: 28px;
}

.badge {
  composes: badge from './Bar.Right.css';
  background-color: var(--pri-04);
}

.top {
  height: 100%;
  max-height: 100%;
  /* overflow: hidden; */
  overflow-y: auto;
  padding: 0 10px;
}

/* .top:hover {
  overflow-y: auto;
} */

.bottom {
  justify-content: flex-end;
  margin: 10px 0 0 0;
}

.bottom-padding {
  padding: 8px 6px;
}

.message {}

.message-header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 2px 0;
}

.author-vancery {
  color: var(--pri-04);
  font-size: 14px;
  font-family: var(--font-semibold);
}

.author-you {
  color: var(--pri-02);
  font-size: 14px;
  font-family: var(--font-semibold);
}

.author {
  color: var(--pri-02);
  font-size: 14px;
  font-family: var(--font-semibold);
}

.time {
  display: flex;
  align-items: center;
  color: var(--gray-d);
  font-size: 12px;
}

.text {
  color: var(--black);
  font-size: 14px;
}

.messages>div {
  margin: 0 0 12px 0;
}

.messages>div:last-child {
  margin: 0;
}

.none {
  color: var(--gray);
  font-size: 16px;
  font-family: var(--font-semibold);
}

.entry {
  color: var(--black-l);
  box-sizing: border-box;
  overflow: auto;
  border: none;
  font-size: 14px;
  font-family: var(--font-reg);
  resize: none;
  outline: none;
  height: 100%;
  width: 100%;
}

.help-text {
  font-size: 14px;
  padding: 6px 12px;
  background: var(--blue-l);
  color: var(--azure);
  font-family: var(--font-bold);
}

.help-popover {
  max-width: 300px;
  padding: 10px;
  font-size: 14px;
}

.help-popover-row:not(:last-child) {
  margin-bottom: 10px;
}

.channel-selector {
  margin-bottom: 10px;
}

.channel-select {
  font-size: 14px;
}

.channel-options {
  max-height: 100px;
  overflow-y: auto;
}