.root {
  min-width: 275px;
  border: 1px solid var(--gray-l);
  padding: 15px;
  border-radius: 4px;
  background-color: var(--pri-01);
  box-shadow: 0 2px 6px 2px rgb(60 64 67 / 15%);

  font-size: 14px;
  font-family: var(--font-reg);
}

.box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.row {
  margin-bottom: 20px;;
}

.display {
  font-family: var(--font-semibold);
}

.link {
  color: var(--pri-02);
}

.close {
  cursor: pointer;
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.btn {
  padding: 4px;
  min-width: 75px;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.btn:last-child {
  margin-left: 10px;
}