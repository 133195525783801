.root {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 80px 0;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message {
  margin: 15px 0;
}

.btn {
  background-color: var(--blue-l);
  padding: 20px 40px;
  color: var(--pri-02);
  font-size: 22px;
}

.link {

}

.text {
  font-family: var(--font-semibold);
  font-size: 22px;
}

.logo {

}

.img {
  width: 80px;
  margin: 15px 0;
}