.settings {
  max-width: 400px;
}

.item {
  margin-bottom: 20px;
}

.entry-settings {
  display: flex;
  justify-content: space-between;
}

.label {
  font-family: var(--font-semibold);
}

.entry-labels {
  margin-top: 10px;
  margin-bottom: 20px;
}

.entry-label-input {
  margin-top: 10px;
}