.checkbox {
  flex-shrink: 0;
}

.checked {
  composes: checkbox;
}

.unchecked {
  composes: checkbox;
}