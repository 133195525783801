.flagged-text {
  font-style: italic;
}

.body {
  margin-left: 0;
}

@media (min-width: 450px) {
  .body {
    margin-left: var(--left-offset);
  }
}

.details {
  margin-bottom: 30px;
}

.user {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;

  margin-bottom: 10px;
}

@media (min-width: 450px) {
  .user {
    margin-bottom: 0;
  }
}

.avatar {
  width: 60px;
  height: 60px;
  cursor: default;
}

.username-link {
  position: absolute;
  margin-left: var(--left-offset);
}

a .avatar {
  cursor: pointer
}

a .username {
  cursor: pointer;
}

a .username:hover {
  color: var(--pri-02);
  text-decoration: underline;
}