.modal {
  width: 525px;
}

.toolbar {
  margin-bottom: 15px;
}

.footer {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  margin-right: 20px;
}