.root {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  outline: none;
  overflow-x: hidden;
}

.noselect {
  user-select: none;
  -webkit-user-select: none;
}