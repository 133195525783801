.root {

}

.wrap {

}

.main {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.header {

}

.label {
  font-family: var(--font-bold);
  color: var(--black-d);
  margin-bottom: 5px;
}

.item {
  width: 325px;
}

.contents {
  display: flex;
  min-height: 34px;
}

.value {

}

.trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.full {
  margin-right: 15px;
}

.icon {
  width: 30px;
}

.btn {
  display: none;
  color: var(--pri-02);
  cursor: pointer;
}

.contents:hover .btn.editable {
  display: block;
}

.save {
  color: var(--pri-03);
}

.cancel {
  color: var(--gray-d);
}

.save:disabled,
.cancel:disabled {
  color: var(--gray-l);
}

.save svg,
.cancel svg {
  width: 30px;
  height: 30px;
}

.inputroot {
  max-height: 30px;
}

.input {
  line-height: 20px;
  letter-spacing: 0;
}