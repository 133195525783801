.label {
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

.add-row {
  display: flex;
  align-items: center;
  color: var(--pri-02);
  cursor: pointer;
  margin-top: 20px;
  font-family: var(--font-semibold);
}

.add-icon {
  margin-right: 10px;
}

.input-rows {

}

.input {
  max-width: 500px;
  width: 500px;
}

.entry-label {
  font-family: var(--font-semibold);
  margin-bottom: 10px;
  max-width: 125px;
}

@media (min-width: 800px) {
  .input-row {
    display: flex;
    align-items: center;
  }

  .entry-label {
    font-family: var(--font-semibold);
    margin-right: 15px;
    margin-bottom: 0;
  }
}

.input-row {
  margin-bottom: 15px;
}

.sub-row {
  display: flex;
}

.remove-icon {
  margin-left: -28px;
  color: var(--pri-04);
  z-index: 5;
  cursor: pointer;
}

.ta {
  padding-right: 25px;
  box-sizing: border-box;
}