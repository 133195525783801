.content {
  padding: 2px 0;
}

.files {
  margin: 10px 0;
}

.file {
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.file:not(:last-of-type) {
  margin-bottom: 10px;
}

.icon {
  margin-right: 10px;
  flex-shrink: 0;
}

.details {
  overflow: hidden;
}

.name {
  font-size: 14px;
  font-family: var(--font-reg);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.type {
  font-size: 13px;
  font-family: var(--font-reg);
  color: var(--gray-d);
  margin: 2px 0 0 0;
}

.time {
}

.empty {
  color: var(--gray-m);
  font-size: 14px;
  margin: 6px 0 0 0;
}