@value 800, 1100 from 'vars';

.wrap {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media 800 {
  .wrap {
    display: block;
    margin: 60px 0;
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media 800 {
  .main {
    flex-direction: row;
    justify-content: space-between;
  }
}

.headline {
  margin: 0 auto;
  font-family: var(--font-semibold);
  font-size: 30px;
  text-align: center;
}

@media 800 {
  .headline {
  }
}

.benefit {
  margin-bottom: 50px;
  text-align: center;
  width: 375px;
}

@media 800 {
  .benefit {
    margin-bottom: 0;
  }
}

.caption {
  composes: caption from '@/access/website/screens/Main/style.css';
  margin: 20px 0 30px 0;
}

@media 1100 {
  .caption {
    margin: 20px 0 30px 0;
  }
}

.copy {
  composes: copy from '@/access/website/screens/Main/style.css';
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  margin: 0 auto;
}

.img {
  height: 100%;
}

.interview {
  padding: 30px;
  box-sizing: border-box;
}