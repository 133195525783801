.root {
  position: relative;
}

.day {
  position: sticky;
  top: 0;
  padding: 6px 0 6px 15px;
  font-family: var(--font-bold);
  color: var(--gray-d);
  background-color: var(--gray-xl);
  font-size: 14px;
}