.root {
  display: flex;
}

.field {
  width: 250px;
}

.input {
  height: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  width: 100%;
}

.btns {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.cancel,
.save {
  height: 30px;
  width: 30px;
  background-color: lime;
  border-radius: 4px;
}

.cancel:disabled,
.cancel:disabled:hover,
.save:disabled,
.save:disabled:hover {
  background-color: var(--gray);
  cursor: not-allowed;
}

.save {
  margin-left: 10px;
}

.cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-d);
  width: 40px;
  height: 25px;
  border-radius: 3px;
}

.cancel:hover {
  background-color: var(--black-l);
}

.cancel .icon {
  color: var(--pri-01);
}

.save {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--pri-02);
  width: 40px;
  height: 25px;
  border-radius: 3px;
}

.save:hover {
  background-color: var(--sec-02);
}

.save .icon {
  color: var(--sec-01);
}

.select {
  padding: 0;
  line-height: 37px;
  /* min-height: 37px; */
}

.select .value {
  padding: 0;
}

.select .input {
  padding: 0 5px;
  margin: 0;
}

.select .single {
  padding: 0 5px;
}

.select .control {
  min-height: 37px;
  padding: 0;
}

.option {
  padding-top: 0;
  padding-bottom: 0;
}

.menu {
  margin: 0;
}