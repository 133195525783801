.root {

}

.wrap {
  padding: 20px 0px;
}

.main {
  min-height: 200px;
  margin-bottom: 20px;
}

.row {
  margin-bottom: 15px;
}

.fields {
  display: flex;
  justify-content: space-between;
}

.field {

}

.label {
  font-family: var(--font-bold);
  color: var(--font-black-l);
  margin-bottom: 15px;
}

.footer {
  padding: 40px 0 0;
}

.datepicker {
  position: relative;
  z-index: 2;
  padding-bottom: 5px;
}

.btn {
  min-width: 140px;
}

.option {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 40px;
  padding: 20px 0;
}

.message {

}

.timezone {
  padding: 10px 0;
  font-size: 15px;
  font-style: italic;
}

.warning {
  max-width: 480px;
}

.warn {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--gray-d);
  font-size: 15px;
  margin-bottom: 10px;
}

.info {
  font-size: 15px;
  color: var(--black-l);
  font-family: var(--font-semibold);
  padding: 10px 0;
}

.icon {
  color: var(--pri-04);
  width: 16px;
  height: 16px;
}

.duration {
  width: 115px;
}

.duration .select {
  line-height: normal;
}

.duration .input {
  padding-top: 0;
  padding-bottom: 5px;
}