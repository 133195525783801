.bullet {
  composes: bullet from './QuestionOption.css';
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section-value {
  font-family: var(--font-semibold);
}

.settings-icon {
  transform: rotate(90deg);
  cursor: pointer;
}

.section-type, .option, .section-header, .divider {
  margin-bottom: 15px;
}

.divider {
  border-bottom: 2px solid var(--gray-xl);
}

.option-container {
  display: flex;
}

.exclusive-option-subtext {
  color: var(--gray-m);
  margin-left: 5px;
  /* margin-top: 2px; */
}