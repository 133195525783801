.input {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid var(--gray);
  border-radius: 4px;
  padding: 4px;
  text-align: right;
  font-size: 14px;
  font-family: var(--font-reg);
}

.input:focus {
  border: 2px solid var(--pri-02);
}

.input::placeholder {
  text-align: center;
  color: var(--gray);
}

input:focus::placeholder {
  color: transparent;
}

.invalid {
  border: 2px solid var(--pri-04);
}

.theme {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid var(--gray);
  border-radius: 4px;
  padding: 4px;
  text-align: right;
}