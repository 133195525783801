.btn {
  font-family: var(--font-bold);
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  min-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: background-color 180ms ease-in-out,
    border 120ms ease-in,
    border-color 120ms ease-in,
    color 120ms ease-in;
}

.primary {
  composes: btn;
  border: 2px solid var(--pri-02);
  background-color: var(--pri-02);
  color: var(--pri-01);
}

@media (min-width: 1000px) {
  .primary:hover {
    border: 2px solid var(--sec-02);
    background-color: var(--sec-02);
  }
}

.secondary {
  composes: btn;
  color: var(--pri-02);
  border: 2px solid var(--pri-02);
}

@media (min-width: 1000px) {
  .secondary:hover {
    background-color: var(--blue-l);
  }
}

.destructive {
  composes: btn;
  color: var(--gray-d);
  border: 2px solid var(--gray-d);
}

@media (min-width: 1000px) {
  .destructive:hover {
    background-color: var(--gray-xl);
  }
}

.delete {
  composes: btn;
  color: var(--pri-04);
  border: 2px solid var(--pri-04);
}

@media (min-width: 1000px) {
  .delete:hover {
    background-color: var(--reddish-white);
  }
}

.affirmative {
  composes: btn;
  border: 2px solid var(--pri-03);
  background-color: var(--pri-03);
  color: var(--pri-01);
}

@media (min-width: 1000px) {
  .affirmative:hover {
    border: 2px solid var(--sec-03);
    background-color: var(--sec-03);
  }
}

.transparent {
  composes: btn;
  background-color: transparent;
  border-color: transparent;
}

.transparent:disabled,
.transparent:hover {
  background-color: transparent;
  border-color: transparent;
}

;

.text {
  composes: btn;
  composes: transparent;
}

.link {
  background-color: transparent;
  border-color: transparent;
  font-family: var(--font-bold);
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: background-color 180ms ease-in-out,
    border 120ms ease-in,
    border-color 120ms ease-in,
    color 120ms ease-in;
}

.pill {
  padding: 10px 20px;
  border-radius: 25px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.pill:disabled {
  border-color: var(--gray-l);
  color: var(--pri-01);
  background-color: var(--gray-l);
  cursor: not-allowed;
}

.brick {
  padding: 10px;
  border-radius: 5px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.brick:disabled {
  border-color: var(--gray-l);
  color: var(--pri-01);
  background-color: var(--gray-l);
  cursor: not-allowed;
}

.implicit:disabled {
  cursor: pointer;
}

.primary.implicit:disabled {
  border-color: var(--pri-02);
  background-color: var(--pri-02);
  color: var(--pri-01);
}

.secondary.implicit:disabled {
  color: var(--pri-02);
  border-color: var(--pri-02);
  background-color: var(--pri-01);
}

.destructive.implicit:disabled {
  color: var(--gray-d);
  border-color: var(--gray-d);
  background-color: var(--pri-01);
}

.affirmative.implicit:disabled {
  border-color: var(--pri-03);
  background-color: var(--pri-03);
  color: var(--pri-01);
}

.small-size {
  padding: 6px;
  font-size: 14px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}