.title {
  font-size: 18px;
  font-family: var(--font-semibold);
  margin: 20px 0 15px;
}

.name {
  font-size: 16px;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
}

.payment {
  font-size: 18px;
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

.toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.label {
  margin-left: 10px;
}