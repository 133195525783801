.body-base {
  align-items: center;
  display: flex;
  padding: 5px 10px;
}

.body-action {
  align-items: center;
  display: flex;
  padding: 5px 5px 5px 10px;

  --a-color: #26AAE1;
  --a-hover-color: var(--sec-02);
}

.action {
  color: var(--a-color);
  display: inline-flex;
  margin: 0 0 0 4px;
}

.body-action:not(.disabled) .action {
  cursor: pointer;
}

.body-action:not(.disabled) .action:hover {
  color: var(--a-hover-color);
}

.action svg {
  width: 19px;
  height: 19px;
}

.clickable:not(.disabled):hover .action svg {
  color: var(--a-hover-color);
}

.body-action:not(.disabled) .action:hover svg {
  color: var(--a-hover-color);
}

.clickable .action svg,
.body-action .action svg
{
  transition: background-color 200ms ease-in-out,
              border 140ms ease-in,
              border-color 140ms ease-in,
              color 140ms ease-in;
}