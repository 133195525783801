.root {
  display: flex;
  align-items: flex-end;
  height: 45px;
}

.root button + button {
  margin-left: 15px;
}

.status {
  display: flex;
  align-items: flex-start;
  height: 25px;
}

.btn {
  height: 35px;
  font-size: 14px;
  padding: 5px 10px;
}

.affirmative {
  composes: btn;
  color: var(--pri-03);
  border-color: var(--pri-03);
  background-color: var(--pri-01);
}

.affirmative:hover {
  background-color: var(--greenish-white);
}

.destructive {
  composes: btn;
  color: var(--pri-04);
  border-color: var(--pri-04);
  background-color: var(--pri-01);
}

.destructive:hover {
  background-color: var(--reddish-white);
}

.text {
  font-size: 13px;
  font-family: var(--font-reg);
  color: var(--gray-m);
  line-height: 17px;
  margin-left: 15px;
}

.icon {
  color: var(--gray-m);
  width: 17px;
  height: 17px;
}

.flag {
  width: 18px;
  height: 18px;
}

.static {
  margin-right: 15px;
}