.root {
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.bar {
  height: 3px;
  width: 100%;
  background-color: var(--pri-02);
  transition: ease-in-out;
  display: none;
}

.root.over .bar {
  display: block;
}