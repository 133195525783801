.root {
  display: flex;
  position: relative;
  padding: 40px 0;
}

.wrap {
  margin: 0 auto;
}

@media (min-width: 1200px) {
  .wrap {
    margin: 0 auto 0 225px;
  }
}

.sidebar {
  display: none;
  width: 220px;
  padding: 0 20px;
}

@media (min-width: 1000px) {
  .sidebar {
    display: inline-block;
  }
}

@media (min-width: 1200px) {
  .sidebar-wrap {
    position: fixed;
  }
}

.main {
  max-width: 750px;
}

.stepper {
  margin-top: 0;
}

@media (min-width: 800px) {
  .main {
    max-width: auto;
    width: 750px;
  }
}

@media (min-width: 1100px) {
  .main {
    width: 850px;
  }
}

.hidden {
  display: none;
}

.content {
  margin: 0 auto;
  width: 100%;
  max-width: 350px;
  padding: 0 10px;
}

@media (min-width: 600px) {
  .content {
    padding: 0 15px;
    max-width: 500px;
  }
}

@media (min-width: 800px) {
  .content {
    max-width: 700px;
  }
}

.ta {
  display: flex;
  width: 100%;
}

.section {
  min-height: 350px;
  padding-bottom: 30px;
}

.description {
  resize: vertical;
  min-height: 140px;
  width: 95%;
}

.dropdown-text {
  font-size: 18px;
}

.skip-button {
  cursor: pointer;
  margin: 25px 0;

  color: var(--gray);
  font-size: 18px;
  font-family: var(--font-bold);
}

.skip-button:hover {
  color: var(--pri-02);
}

.additional-filter-label {
  font-size: 16px;
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

.bar {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
}

.complete {
  width: 130px;
  background-color: var(--pri-03);
  border-color: var(--pri-03);
}

.complete:hover {
  background-color: var(--sec-03);
  border-color: var(--sec-03);
}

.row {
  margin-bottom: 20px;
}