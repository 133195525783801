.root {

}

.row {
  margin-bottom: 15px;
}

.fields {
  display: flex;
  gap: 15px;
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 90px;
}

.label {
  font-family: var(--font-bold);
  color: var(--font-black-l);
  margin-bottom: 15px;
}

.footer {
  padding: 40px 0 0;
}

.datepicker {
  position: relative;
  z-index: 2;
  padding-bottom: 5px;
}

.timezone {
  font-size: 15px;
  font-style: italic;
}

.end {
  height: 24px;
}

.duration {
  width: 115px;
}

.duration .select {
  line-height: normal;
}

.duration .input {
  padding-top: 0;
  padding-bottom: 5px;
}