.item {
  composes: item from './StandardMessage.css';
}

.header {
  composes: header from './StandardMessage.css';
  margin: 0 0 10px 0;
}

.name {
  composes: name from './StandardMessage.css';
}

.current {
  composes: current from './StandardMessage.css';
}

.time {
  composes: time from './StandardMessage.css';
}

.body {
  composes: body from './StandardMessage.css';
}

.paid {
  border: 1px solid var(--gray-l);
  border-radius: 4px;
  padding: 18px;
  max-width: 400px;
}

.paid-header {
  color: var(--black);
  font-size: 14px;
  font-family: var(--font-bold);
  margin: 0 0 10px 0;
}

.paid-body {
  font-family: var(--font-reg);
  color: var(--black);
  font-size: 15px;
  white-space: pre-wrap;
  word-break: break-word;
  margin: 0 0 14px 0;
}

.paid-status {
  color: var(--black);
  font-family: var(--font-semibold);
  font-size: 12px;
  text-align: right;
}

.actions {
  margin: 25px 0 0 0;
}

.accept {
  font-size: 14px;
  width: 120px;
  height: 45px;
}

.reject {
  font-size: 14px;
  height: 45px;
  width: 120px;
  margin: 0 20px 0 0;
}

@media (min-width: 800px) {
  .accept {
    width: 130px;
  }
  
  .reject {
    width: 130px;
  }
}