.copy {
  display: flex;
  align-items: center;
  border: 1px solid var(--gray);
  border-radius: 3px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
}

.link {
  width: 100%;
  padding: 8px;
}

.input {
  border: none;
  font-size: 16px;
  color: var(--black);
  font-family: var(--font-reg);
  width: 100%;
}

.input::selection {
  background: transparent;
}

.btn,
.btn:hover {
  background-color: var(--gray-xl);
  height: 40px;
  width: 110px;
  color: var(--black-l);
  border: 1px solid var(--gray);
  box-sizing: border-box;
}

.btn:disabled {
  background-color: var(--gray-xl);
  color: var(--gray);
  border: 1px solid var(--gray);
  cursor: default;
}

.twitter,
.twitter:hover {
  display: flex;
  align-items: center;
  background-color: #1da1f2;
  height: 40px;
  min-width: 125px;
  color: var(--pri-01);
  border-radius: 3px;
}

.twitter .icon {
  fill: var(--pri-01);
  width: 17px;
  height: 17px;
  margin-right: 10px;
}

.linkedin,
.linkedin:hover {
  background-color: #0a66c2;
  display: flex;
  align-items: center;
  height: 40px;
  min-width: 125px;
  color: var(--pri-01);
  border-radius: 3px;
}

.linkedin .icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.group {
  display: flex;
}

.group :not(:last-child) {
  margin-right: 10px;
}