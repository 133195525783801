@value 800 from 'vars';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

@media 800 {
  .root {
    display: none;
  }
}

.wrap {
  margin: 0 15px 25px;
}

.main {

}

.row {
  margin-bottom: 15px;
}

.check {
  composes: check from './Column.Plan.css';
  height: 12px;
  width: 12px;
  margin-right: 15px;
}

.title {
  font-size: 14px;
  color: var(--black-d);
  font-family: var(--font-semibold);
}

.text {
  font-size: 14px;
  color: var(--black-d);
}

.feature {
  display: flex;
  align-items: center;
}

.group {
  margin-bottom: 25px;
}

.info {
  padding: 20px;
  border-radius: 12px;
  width: 260px;
}

.price {
  font-size: 24px;
  color: var(--black-d);
  font-family: var(--font-semibold);
}

.rate {
  font-size: 18px;
}

.header {

}

.name {
  font-family: var(--font-semibold);
  font-size: 22px;
  text-align: center;
  width: 180px;
  margin: 0 auto 10px;
}

.description {
  font-size: 14px;
  color: #787878;
  text-align: center;
  margin: 0 auto 15px;
  width: 260px;
}

.payment {
  font-size: 18px;
  font-family: var(--font-semibold);
  color: var(--black-d);
  margin-bottom: 10px;
}

.toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.label {
  margin-left: 10px;
}

.controls {
  width: 300px;
  margin: 0 15px 50px;
}