.header {
  composes: header from './Bar.Right.css';
}

.root {
  padding: 20px;
  overflow-y: auto;
  max-height: 100%;
}

.survey-root {
  padding: 20px 0px;
  border-bottom: 1px solid var(--gray-l);
}

.name-row {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.survey-name {
  flex-grow: 1;
  font-family: var(--font-bold);
  color: var(--pri-02);
}

.survey-type {
  font-family: var(--font-semibold);
  font-size: 14px;
}

.question-count {
  font-size: 12px;
}

.completed {
  color: var(--pri-03);
}