.root {
}

.author {
  display: flex;
  align-items: center;
  padding: 0 0 10px 0;
}

.avatar {
  margin-right: 10px;
}

.video {
  display: flex;
  box-sizing: border-box;
  margin-bottom: 4px;
}

.text {
  margin-bottom: 4px;
}

.left {
  width: 160px;
  min-width: 160px;
  height: 90px;
  max-height: 90px;
  border-radius: 5px;
  overflow: hidden;
}

.right {
  padding: 0 10px;
}

.title {
  color: var(--black);
  font-family: var(--font-semibold);
}

.name {
  color: var(--black);
  font-family: var(--font-semibold);
}

.age {
  color: var(--gray);
  margin-left: 8px;
}

.p {
  margin: 4px 0 0 0;
}