.root {
  display: flex;
  align-items: center;
  background-color: var(--gray-xl);
  color: var(--black);
  cursor: default;
  border-radius: 3px;
  font-family: var(--font-reg);
  font-size: 14px;
  overflow: hidden;
}

.content {
  padding: 6px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected .content {
  padding: 6px 2px 6px 10px;
}

.clickable {
  cursor: pointer;

  transition: background-color 200ms ease-in-out,
              border 140ms ease-in,
              border-color 140ms ease-in,
              color 140ms ease-in;
}

.clickable:hover {
  color: var(--pri-02);
  background-color: var(--blue-l); 
}

.remove {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  color: var(--gray-d);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px 0 5px;
  height: 100%;

  transition: background-color 200ms ease-in-out,
              border 140ms ease-in,
              border-color 140ms ease-in,
              color 140ms ease-in;
}

.remove:hover {
  background-color: var(--gray-l);
}

.tags > * {
  margin-bottom: 8px; 
}

.tags > *:not(:last-child) {
  margin-right: 8px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.hidden-popper {
  display: flex;
  background: var(--pri-01);
  max-width: 300px;
  box-shadow: var(--box-shadow);
  padding: 10px;
  border-radius: 4px;
  flex-wrap: wrap;
  padding-bottom: 0px;
}