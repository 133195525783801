.root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 106px;
  background-color: var(--blue-l);
  color: var(--pri-02);
  font-size: 16px;
  font-family: var(--font-bold);
  border: 2px dashed var(--pri-02);
  border-radius: 7px;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}