.root {
  opacity: 0;
}

.enter {
  opacity: 1;
  transition: opacity 800ms ease;
}

.exit {
  opacity: 0;
}