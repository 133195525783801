.root {
  border: 1px solid var(--sec-01);
  border-radius: 7px;
  max-width: 900px;
  box-sizing: border-box;
}

.wrap {
  padding: 10px 15px;
}

.main {
  margin: 20px 0;
}

.header {
  box-sizing: border-box;
  border-bottom: 2px solid var(--sec-01);
}

.title {
  font-size: 18px;
  font-family: var(--font-semibold);
  line-height: 40px;
}

.content {

}