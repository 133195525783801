.root {}

.wrap {}

.main {
  /* display: flex;
  gap: 10px;
  margin-bottom: 5px; */
}

.input {
  --border-width: 1px;
  font-family: var(--font-reg);
  font-size: 16px;
  border: none;
  padding: 6px 8px;
  /* border: var(--border-width) solid var(--gray-l); */
  width: 260px;
}

.input:focus {
  /* border: var(--border-width) solid var(--pri-02); */
}

.input::placeholder {
  color: var(--gray);
}

.stats {
  font-size: 12px;
  font-family: var(--font-bold);
}

.checkbox {
  margin-left: 0;
  margin-right: 0;
}

.checkbox .label {
  margin-left: 5px;
  font-size: 12px;
  color: var(--gray);
}

.row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.field {
  display: flex;
  align-items: center;
  gap: 5px;
  outline: 1px solid var(--gray-l);
  border-radius: 1px;
}

.mod {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 30px;
}

.find-mod {
  composes: mod;
  width: 20px;
}

.find-btn {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: var(--gray-xl);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: var(--black-l);
}

.btn:not(:disabled):hover {
  background-color: var(--blue-l);
}

.btn:disabled {
  color: var(--gray-l);
}

.btn.enabled,
.btn.enabled:hover {
  box-shadow: 3px 0px 10px rgb(40 170 255 / 0.2);
  outline: 1px solid var(--pri-02);
  background-color: var(--blue-l);
}

.label {
  font-size: 11px;
  color: var(--gray-d);
  margin-bottom: 2px;
  font-family: var(--font-reg);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.previews {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 400px;
  overflow-y: auto;
  padding: 5px;
}

.preview {
  background-color: var(--gray-xl);
  border-radius: 8px;
  padding: 10px;
  font-size: 12px;
  cursor: pointer;
}

.preview.active {
  border: 1px solid var(--sec-05);
  background-color: var(--blue-l);
}

.highlighted {
  background-color: yellow;
}

.btns {
  display: flex;
  gap: 5px;
  align-items: center;
}