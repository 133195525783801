.root {

}

.wrap {

}

.main {
  max-width: 360px;
  margin: 0 auto 30px;
}

.modal {
  width: 500px;
}

.message {
  color: var(--gray-d);
}

.icon {
  width: 30px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px;
}

.stacked {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.btn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dismiss {
  color: var(--pri-02);
  font-size: 14px;
  padding: 8px 0;
  border-radius: 6px;
}

.dismiss:hover {
  color: var(--sec-02);
  background-color: var(--gray-xl);
}