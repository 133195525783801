.row {

}

.tag {
  margin-right: 5px;
  margin-bottom: 5px;
}

.tags {
  composes: tags from 'static/css/tags.css';
  padding: 5px 0 0 0;

  min-height: 41px;
}