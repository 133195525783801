.root {
  font-size: 15px;
  color: var(--gray-d);
  padding: 10px 15px;
  background-color: var(--pri-01);

  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.row {
  display: flex;
  align-items: center; 
}

.more {
  composes: row;
  margin-top: 5px;
}

.color-offset {
  width: 28px;
}

.header {
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

.item {
  margin-bottom: 10px;
}

.item:last-of-type {
  margin-bottom: 0;
}