.career {
  padding: 10px 0;
}

.header {
  font-size: 16px;
  font-family: var(--font-semibold);
  color: var(--black);
  line-height: 20px;
  margin-bottom: 10px;
}

.jobs {
  margin-block-start: 0;
  padding-left: 0;
  list-style: none;
  margin: 0;
}

li.job::before {
  display: inline-block;
  color: var(--gray-d);
  content: '●';
  margin: 0 15px 0 5px;
}

.job {
  margin-bottom: 5px;
}

.text {
  font-size: 16px;
}