.ta-warning {
  border: 2px solid #FEC700;
}

.ta-warning:focus {
  border-color: #FEC700;
}

.block {
  margin-top: 5px;
  height: 40px;
}

.warning {
  color: #FEC700;
  display: flex;
  align-items: center;
}

.warning-text {
  margin-left: 15px;
  font-size: 14px;
}

@media (min-width: 500px) {
  .warning-text {
    font-size: 16px;
  }
}

.thanks {
  display: flex;
  align-items: center;
  color: var(--pri-03);
}

.thanks-text {
  margin-left: 10px;
}

.ta {
  height: 250px;
  min-height: 250px;
  resize: vertical;
}