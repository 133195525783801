.circle {
  min-width: 20px;
  min-height: 20px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
}

.popper {
  display: grid;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  width: 150px;
  grid-template-columns: auto auto auto auto auto auto;
  row-gap: 5px;
}

.btn {
  width: 64px;
  min-width: auto;
  display: flex;
  justify-content: space-between;
  border-color: var(--gray-l);
  border-width: 1px;
}

.disabled {
  border: 1px solid var(--gray-l);
  padding: 6px;
  cursor: not-allowed;
  border-radius: 4px;
}

.disabled * {
  cursor: not-allowed;
}

.clickable {
  cursor: pointer;
}

.inline-select-container {
  display: flex;
  align-items: center;
}

.inline-edit-icon {
  display: none;
  margin-left: 5px;
  width: 14px;
  height: 14px;
  color: var(--pri-02);
}

.inline-select-container:hover .inline-edit-icon {
  display: inline;
}