.root {

}

.wrap {
  padding: 20px 0;
}

.tr {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  padding: 10px 0;
}

.td {
  padding: 0 8px;
}

.call {
  min-width: 270px;
  width: 270px;
  flex: 0;
}

.tag {
  min-width: 240px;
  width: 240px;
  flex: 0;
}

.good-quote-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  min-width: 110px;
  box-sizing: border-box;
}

.text {
  flex-grow: 1;
}

.actions {
  min-width: 35px;
  flex: 0;
}

.link {
  color: var(--hyperlink);
  cursor: pointer;
}

.sortable {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.filter-icon {
  color: var(--black-l);
  margin-top: 4px;
  cursor: pointer;
}

.filter-icon.active {
  fill: var(--black-l);
}

.filter-icon.disabled {
  fill: var(--gray);
  cursor: default;
}

.filter-group {
  display: flex;
  align-items: top;
  gap: 5px;
}

.star {
  color: var(--gray-d);
  cursor: pointer;
}

.star.active {
  cursor: default;
  fill: var(--gray-d);
}

.search {
  display: flex;
  align-items: center;
  gap: 10px;
}