.wrap {
  margin: 0 auto;
  padding: 0 10px;
}

@media (min-width: 900px) {
  .wrap {
    display: flex;
  }
}

.main {

}

@media (min-width: 900px) {
  .main {
    width: 100%;
    margin-left: auto;
    margin-right: 60px;
  }
}

.sidebar {
  display: none;
}

@media (min-width: 900px) {
  .sidebar {
    display: block;
    flex: 0 0 400px;
  }
}