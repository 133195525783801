.modal {
  width: 500px;
}

.body {
  display: flex;
  justify-content: center;
}

.input {
  width: 350px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}