.root {
  padding: 20px 15px;
}

.header {
  margin-bottom: 15px;
}

.title {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  margin-bottom: 10px;
}

.description {
  line-height: 20px;
  color: var(--gray-d);
}

.picture {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.img {
  max-width: 100%;
  max-height: 400px;
  border-radius: 4px;
}