.input {
  composes: input from 'static/css/input.css';
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 18px;
  line-height: 18px;
  color: var(--gray-d);
  font-family: var(--font-reg);
  border-bottom: 2px solid var(--gray-l);
  height: 30px;
}

.input:focus {
  border: 0;
  box-shadow: 0;
  outline: none;
  border-bottom: 2px solid var(--gray-l);
}

.icon {
  padding-right: 10px;
  padding-bottom: 5px;
}

.icon:after {
  display: block;
  content: '\25BE';
  padding-left: 10px;
}

.list {
  position: relative;
  height: 100%;
}

.options {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--pri-01);
  border-radius: 4px;
  box-shadow: 0px 1px 2px 1px rgba(238,238,238, 1);
  z-index: 3;
  max-height: 300px;
  overflow-y: auto;
}

.option {
  padding: 10px 15px;
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--gray-d);
  cursor: pointer;
}

.option:hover {
  background-color: var(--blue-l);
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

.text {
  font-family: var(--font-reg);
  color: var(--gray-d);
  font-size: 16px;
  padding: 0 0 0 1px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
}

.placeholder {
  color: var(--gray);
}