.label {
  font-family: var(--font-semibold);
  /* margin-right: 10px; */
  width: 80px;
}

.row {
  display: flex;
  align-items: center;
}

.value-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.footer {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  margin-right: 20px;
}

.items {

}

.item {
  padding: 15px 0;
  border-bottom: 1px solid var(--sec-01);
}

.item:first-of-type {
  padding-top: 10px;
}

.item:last-of-type {
  border-bottom: none;
  /* padding-bottom: 0; */
}

.question-dropdown {
  width: 350px;
  margin-right: 15px;
}

.values {
  margin-top: 15px;
  margin-left: 80px;
}

.rows-dropdown, .options-dropdown {
  width: 215px;
  margin-right: 15px;
}

.add-question {

}

.remove {
  cursor: pointer;
  color: var(--pri-04);
}