.input {
  font-size: 18px;
  border: none;
  font-family: var(--font-reg);
  line-height: 24px;
  letter-spacing: -0.25px;
  width: 100%;
}

@media (min-width: 800px) {
  .input {
    font-size: 20px;
    line-height: 32px;
  }
}

.input::placeholder {
  color: var(--gray);
}