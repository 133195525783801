.avatar {
  height: 95px;
  width: 95px;
  margin-right: 30px;

  position: relative;
}

@media(max-width: 425px) {
  .avatar {
    margin-bottom: 15px;
  }
}

.edit-avatar {
  position: absolute;
  color: var(--pri-02);
  cursor: pointer;
  right: 0;
  bottom: 0;

  padding: 4px 4px 0px 4px;

  border-radius: 50%;
  background-color: white;
  border: 2px solid var(--pri-02);
}