.root {
  height: 100%;
  padding: 10px;
}

.header {
  font-size: 18px;
  font-family: var(--font-bold);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.logs {
  margin-top: 10px;
  overflow-y: auto;
  max-height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.item {
  font-size: 12px;
  display: flex;
  gap: 5px;
}

.timestamp {
  flex-shrink: 0;
}

.monitor-icon {
  cursor: pointer;
}