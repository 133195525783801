.btn {
  width: 160px;
}

.downloader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  height: 40px;
}

.spinner {
  padding: 10px;
}

.downloader .export {
  color: var(--black-l);
}

.downloader .export:hover {
  background-color: var(--blue-l);
  color: var(--black-d);
}

.downloader .export:hover svg {
  color: var(--black-d);
}

.downloader .export:disabled,
.downloader .export:disabled:hover {
  background-color: var(--sec-01);
  opacity: 0.7;
  color: var(--black-l);
}

button.export {
  padding-right: 15px;
}