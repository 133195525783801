.parenthesis {
  font-family: var(--font-semibold);
  margin: 0 3px;
}

.operator {
  margin: 0 3px;
  color: var(--gray-d);
  font-size: 14px;
}

.condition {
  color: var(--pri-02);
  margin: 0 3px;
}