.icon-screen,
.icon-screen-off {
  composes: icon from './Shared.css';
}

.icon-screen-off {
  composes: icon-off from './Shared.css';
}

@media (min-width: 800px) {
  .icon-screen:hover {
    background-color: var(--gray-l);
  }

  .icon-screen-off:hover {
    background-color: var(--gray-xl);
  }
}

.icon-screen svg,
.icon-screen-off svg {
  height: 32px;
  width: 32px;
}