.root {
  display: none;
}

@media (min-width: 1100px) {
  .root {
    display: flex;
    flex-direction: column;
    width: calc(var(--width-panel, 100%) + 4px);
    min-width: calc(var(--min-width-panel, 300px) + 4px);
    max-width: calc(var(--max-width-panel, 500px) + 4px);
    height: 100%;
    overflow-y: auto;
    flex-shrink: 2;
    margin: -2px -2px -2px -3px;
    min-height: 150px;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow);
  border-radius: 3px;
  height: 100%;
  overflow-y: auto;
  margin: 2px 2px 2px 3px;
}

.main {
  padding: 10px;
  height: 100%;
  overflow-y: auto;
}

.editor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.editable {
  margin: 0 0 6px 0;
  overflow: auto;
  height: 100%;
}

.editable p {
  margin: 0;
}

.tools {
  display: flex;
  justify-content: flex-end;
}

.icon {
  margin: 0 6px 0 0;
}

.icon:last-child {
  margin: 0;
}