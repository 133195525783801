.root {
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 15px;
}

@media (min-width: 800px) {
  .root {
    max-width: 700px;
  }
}

.title {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 28px;

  margin: 30px 0 20px 0;
}

.body {
  min-height: 400px;
}

.copy {
  margin-bottom: 25px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  color: var(--pri-02);
}