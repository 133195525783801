.root {
  height: 100vh;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 85%;
}

.img {
  width: 128px;
}

/* .spinning {
  animation: spin 3s cubic-bezier(0.2, 0.6, 0.9, 0.3) infinite;
} */

.text {
  color: var(--black-l);
  font-family: var(--font-reg);
  font-size: 24px;
  margin: 28px 0 0 0;
  text-align: center;
  letter-spacing: -1.1px;
}

/* @keyframes spin {
  100% {
    transform-origin: 50% 50%;
    transform: rotate(360deg); 
  }
} */

.login-link {
  margin-top: 25px;
  color: var(--pri-02);
  cursor: pointer;
}