.root {
  padding-bottom: 40px;
}

.map-background {
  min-height: 550px;
  background: transparent url('static/images/contact-us-map.png');;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 50%;
  margin-bottom: 40px;
  align-items: center;
  display: flex;
}

.map-text {
  color: white;
  font-size: 48px;
  width: 90%;
  margin: auto;
}

.details {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  font-family: var(--font-semibold);
  font-size: 24px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.map-title {
  padding-bottom: 40px;
}

.icon {
  color: var(--pri-02);
  margin-right: 15px;
}

.col-title {
  font-family: var(--font-semibold);
  font-size: 18px;
}

.col>div {
  margin-bottom: 10px;
}

.row {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.row .col {
  width: 50%;
  margin-left: 40px;
  padding-right: 40px;
}

.row .col:first-child {
  border-right: 1px solid var(--gray);
}

.email {
  text-align: center;
}