.name {
  cursor: pointer;
  color: var(--hyperlink);
  font-family: var(--font-semibold);
}

.actions {
  display: flex;
  gap: 20px;
}

.action {
  cursor: pointer;
  color: var(--gray);
}

.table {
  height: auto;
  margin-bottom: 20px;
}

.tbody {
  height: auto;
}

.name-cell {
  flex-grow: 1;
}

.type-cell {
  flex: 0 1;
  min-width: 150px;
}

.actions-cell {
  flex: 0 1;
  min-width: 100px;
  justify-content: end;
}