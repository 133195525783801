.root {
  max-width: 400px;
  position: relative;
}

.wrap {

}

.main {
  max-width: 300px;
  margin: 0 auto;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-weight: 800;
  color: var(--gray-d);
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 40px;
  padding: 0 0 20px;
}

.message {

}

.footer {
  padding: 20px 0 0;
}

.btn {
  min-width: 140px;
}

.info {
  font-style: italic;
  font-size: 15px;
  padding: 10px 0 30px;
  color: var(--gray-d);
}