@value 800, 1100 from 'vars';

.root {
  border-top: var(--section-separator);
}

.wrap {
  padding: 40px 0;
}

@media 800 {
  .wrap {
    padding: 60px 0 40px;
  }
}

.content {
  margin: 0 auto;
}

@media 800 {
  .content {
    background-image: url('static/images/website/careers/bg-triangle-right-red.png');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 260px;
    max-width: var(--max-width);
  }
}

.main {
  max-width: 360px;
  margin: 0 auto;
  padding: 0 20px;
}

@media 800 {
  .main {
    max-width: var(--max-width);
  }
}

@media 1100 {
  .main {
    margin-left: 20px;
  }
}

.header {
  font-size: var(--font-size-heading-2);
  color: var(--black-d);
  font-family: var(--font-bold);
  max-width: 360px;
  margin: 0 auto 30px;
  text-align: center;
  padding: 0 20px;
}

@media 800 {
  .header {
    font-size: var(--font-size-heading-1);
    text-align: left;
    margin: 0 0 50px;
  }
}

@media 1100 {
  .header {
    font-size: var(--font-size-heading-xl);
    margin: 0 auto 50px;
    max-width: calc(var(--max-width) - 80px);
    padding: 0 40px;
  }
}

@media 800 {
  .items {
    display: flex;
    flex-wrap: wrap;
    max-width: 760px;
  }
}

.item {
  position: relative;
  min-height: 250px;
  width: 100%;
  max-width: 365px;
  background-color: var(--pri-01);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-bottom: 15px;
  z-index: 2;
}

@media 800 {
  .item {
    margin-bottom: 30px;
  }

  .item:nth-child(odd) {
    margin-right: 30px;
  }
}

.box {
  padding: 30px;
}

.title {
  font-size: var(--font-size-subtitle-1);
  font-family: var(--font-semibold);
  color: var(--black-d);
  margin-bottom: 20px;
}

.text {
  font-size: var(--font-size-subtitle-1);
  color: var(--black-d);
}