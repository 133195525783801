.root {
  --height-header: 45px;
  height: 100vh;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  height: calc(100% - var(--height-header));
  padding: 10px;
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: center;
  background-color: var(--gray-xl);
  min-height: var(--height-header);
  max-height: var(--height-header);
}

.win {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}

.title {
  color: var(--black);
  font-size: 18px;
  font-family: var(--font-bold);
}