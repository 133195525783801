.root {
  top: -9999px;
  left: -9999px;
  position: absolute;
  z-index: var(--z-index-popper);
  padding: 3px;
  background-color: var(--pri-01);
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,.2);
}

.wrap {

}

.main {

}

.option {
  padding: 10px 15px;
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--gray-d);
  cursor: pointer;
}

/* .option:hover {
  background-color: var(--blue-l);
} */

.active {
  background-color: var(--blue-l);
}