.root {
  max-width: var(--navbar-width);
  margin: 0 auto;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.query {
  color: var(--black-l);
  font-size: 18px;
  font-family: var(--font-semibold);
  margin: 30px 10px 20px 10px;
}

@media (min-width: 1250px) {
  .query {
    margin: 30px 0 20px 0;
  }
}

.clicker {
  overflow: hidden;
}