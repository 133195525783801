.top {
  display: flex;
}

.filters {
  flex: 1 1;
}

.actions {
  flex: 0 0;
  margin: 10px 0 0 40px;
}

.pagination {
  padding: 20px 0;
  justify-content: flex-end;
}

.nofilters {
  display: flex;
  justify-content: center;
  color: var(--black);
  font-family: var(--font-semibold);
  max-width: 800px;
  margin: 10px 0;
}