.root {

}

.title {
  font-family: var(--font-bold);
  font-size: 16px;
  margin: 0 0 4px 0;
}

.content {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  margin: 0 12px 0 -2px;
}

.quality {
  font-size: 14px;
}