.root {

}

.wrap {

}

.main {

}

.header {

}

.btn {
  display: flex;
  border-radius: 4px;
  border: 1px solid var(--gray-l);
  cursor: pointer;
  align-items: center;
  padding: 2px 4px;
  height: 30px;
  min-width: initial;
  transition: background-color 180ms ease-in-out,
              border 120ms ease-in,
              border-color 120ms ease-in,
              color 120ms ease-in;
}

.btn:hover {
  background-color: var(--gray-xl);
}

.action {
  color: var(--pri-02);
  padding: 5px 0;
  font-family: var(--font-semibold);
  font-size: 14px;
}


.action:disabled {
  color: var(--gray);
}

.action:hover {
  color: var(--sec-02);
}

.label {
  color: var(--pri-02);
  font-family: var(--font-semibold);
  font-size: 14px;
  margin: 0 18px;
}

.input {
  padding: 0;
  padding-left: 0;
  padding-right: 0;
  min-width: 150px;
}

.gutters {
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.item {
  display: flex;
  align-items: center;
  gap: 10px;
}