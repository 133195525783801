.root {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--pri-02);
}

.plus {
  margin-right: 10px;
}

.label {
  font-family: var(--font-semibold);
}