.handle {
  box-sizing: border-box;
  flex: 50 0 auto;
  min-width: 50px;
  width: 50px;
  max-width: 50px;
}

.ordinal,
.color {
  box-sizing: border-box;
  flex: 60 0 auto;
  min-width: 60px;
  width: 60px;
  max-width: 60px;
}

.title {
  box-sizing: border-box;
  flex: 200 0 auto;
  min-width: 200px;
  width: 200px;
}

.title .input {
  width: 100%;
}

.summary {
  box-sizing: border-box;
  flex: 90 0 auto;
  min-width: 90px;
  width: 90px;
  max-width: 90px;
}

.actions,
.accordion {
  box-sizing: border-box;
  flex: 50 0 auto;
  min-width: 50px;
  width: 50px;
  max-width: 50px;
}

.more {
  width: 30px;
  height: 30px;
}

.color .cell {
  width: 40px;
  display: flex;
  align-items: center;
}

.handle .cell.hidden {
  display: none;
}

.handle .cell {
  width: 30px;
  color: var(--gray);
}

.handle .cell.child svg {
  height: 20px;
  margin-left: 10px;
}

.accordion .cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.chevron {
  width: 25px;
  height: 25px;
  padding: 2px;
  cursor: pointer;
  user-select: none;
}

.chevron:hover {
  background-color: var(--gray-xl);
  border-radius: 50%;
}

.star {
  color: var(--gray);
  margin: -2px;
}