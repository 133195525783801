.avatar {
  margin-bottom: 15px;
}

.btn {
  margin-bottom: 12px;
}

.details {
  margin-bottom: 35px;
}

.item {
  margin-bottom: 15px;
}

.label {
  font-family: var(--font-semibold);
  font-size: 18px;
  color: var(--black-l);
  margin-bottom: 5px;
}

.value {
  line-height: 20px;
}

.phone {
  width: 250px;
}

.invalid {
  background-color: var(--pri-04);
}

.field {
  display: flex;
  align-items: center;
}

.edit {
  margin-left: 10px;
  border-radius: 4px;
  padding: 2px 0;
  font-size: 14px;
  min-width: 70px;
}

.btns {
  margin-left: 5px;
}