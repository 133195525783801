.root {
  padding: 40px 20px 20px;
  max-width: 650px;
  margin: 0 auto;
}

.footer {
  padding: 40px 0;
  text-align: right;
}

.proceed .btn {
  margin-bottom: 20px;
}

.proceed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
}

.btn {
  padding: 10px 25px;
}

.loader {
  text-align: center;
}