.row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.label {
  font-family: var(--font-semibold);
  width: 175px;
  margin-right: 10px;
}