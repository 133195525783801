.root {
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 100%;
}

.text {
  composes: name from './Results.css';
  color: var(--pri-02);
}

.highlighted {
  composes: highlighted from './Results.css';
}

.return {
  composes: return from './Results.css';
}