.root {
  max-width: 650px;
  margin: 0 auto;
}

.wrap {
  padding: 25px 15px 0 15px;
}

.section {
  margin-bottom: 30px;
}

.label {
  font-family: var(--font-semibold);
  color: var(--black);
  margin-bottom: 10px;
}

.note {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.requested-by {
  display: flex;
  align-items: center;
}

.avatar {
  margin-right: 10px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.btn {
  width: 150px;
}

.reject {
  composes: btn;
  margin-right: 20px;
}

.link:hover {
  color: var(--hyperlink);
  text-decoration: underline;
}