.status {
  font-size: 13px;
  border-radius: 10px;
  border: 1px solid var(--gray-d);
  padding: 5px;
  width: 90px;
  text-align: center;
}

.title {
  font-size: 26px;
  font-family: var(--font-bold);
  color: var(--gray-d);
}

.verification-dropdown {
  width: 150px;
}

.download {
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.download:hover {
  color: var(--hyperlink);
}

.actions {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  right: 0;
  top: 0;
}

.details {
  /* padding: 25px 45px; */
  max-width: 550px;
  position: relative;
}

.profile {
  display: flex;
  align-items: center;
}

.name {

}

.name:hover {
  color: var(--hyperlink);
  text-decoration: underline;
}

.avatar {
  filter: unset;
  height: 30px;
  width: 30px;

  margin-right: 10px;
}

.page-title {
  font-size: 26px;
  font-family: var(--font-bold);
  color: var(--gray-d);
}

.calendar {
  color: var(--gray-d);
  transition: color 0.3s ease-out;
  height: 17px;
}

.calendar svg {
  vertical-align: middle;
}

.calendar:hover {
  color: var(--pri-02);
}

.badge {
  display: inline-block;
  border: 1px solid var(--gray);
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 12px;
  color: var(--gray-d);
  font-family: var(--font-bold);
  min-width: 80px;
  text-align: center;
  height: 20px;
  line-height: 20px;
}