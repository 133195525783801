.root {
  min-height: calc(100vh - var(--navbar-height));
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrap {
  max-width: 550px;
  margin: 0 20px;
}

.sync {

}

.billing {

}

.main {
  padding: 40px 20px 20px;
}

.header {
  font-size: 28px;
  font-family: var(--font-bold);
  margin-bottom: 20px;
}

.sync .header {
  margin-bottom: 50px;
}

.body {
  margin: 15px 0;
}

.footer {
  margin: 25px 0;
}

.sync .footer {
  height: 250px;
}

.text {
  margin-bottom: 20px;
}

.btn {
  composes: brick from 'css/button.css';
  display: block;
}

.syncing {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 30px;
}

.dots {
  position: relative;
  width: 100px;
  height: 20px;
  border-top: 4px dotted var(--gray);
}

.syncing .dots:nth-of-type(3) {
  width: 90px;
  margin-right: 10px;
}

.chevron {
  position: absolute;
  right: -10px;
  bottom: 0;
  border-top: 3px solid var(--gray);
  border-right: 3px solid var(--gray);
  height: 11px;
  width: 11px;
  transform: rotate(45deg);
  border-radius: 2px;
  margin-bottom: 15px;
  background-color: transparent;
}

.linkedin {
  color: #0e76a8;
  position: absolute;
  left: -3px;
  bottom: -2px;
}

.box {
  overflow: hidden;
  width: 40px;
  height: 40px;
  position: relative;
}

.title {
  font-family: var(--font-bold);
  color: var(--black);
  font-size: 22px;
}

.skip {
  min-width: auto;
  height: auto;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
}

.proceed {
  margin-bottom: 30px;
}

.sync .proceed:first-child {
  margin-bottom: 50px;
}

.btn {
  padding: 5px 15px;
}

.link {
  height: auto;
  min-width: auto;
  color: var(--hyperlink);
  font-family: var(--font-bold);
}

.link:hover {
  text-decoration: underline;
}

.billing :last-child {
  margin-left: auto;
}