.root {

}

.wrap {
  position: relative;
  /* overflow: hidden; */
}

.main {

}

.header {
  max-width: 470px;
  margin: 10px auto;
}

.loader {
  height: 300px;
}

.close {
  position: absolute;
  top: 30px;
  right: 30px;
  font-weight: 800;
  color: var(--gray-d);
}

.bar {
  padding: 0 0 10px;
}

.tab {
  width: 50%;
}

.tabs {
  width: 100%;
}