.root {
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.details {
  overflow: hidden;
}

.name {
  color: var(--black);
  font-size: 15px;
  font-family: var(--font-semibold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  margin-right: 10px;
  flex-shrink: 0;
  height: 50px;
  width: 50px;
}

.title {
  color: var(--gray-m);
  font-size: 14px;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}