.root {

}

.wrap {

}

.main {

}

.row {
  margin-bottom: 15px;
}

.notify {
  font-size: 15px;
}

.options {
  display: flex;
  align-items: center;
  gap: 30px;
}

.option {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 0 0;
}

.message {

}

.validation {
  color: var(--pri-04);
  margin-top: 20px;
}

.footer {
  display: flex;
  align-items: center;
  padding: 40px 0 0;
}

.btn {
  min-width: 140px;
}