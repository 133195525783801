.root {
  position: fixed;
  left: 20px;
}

.wrap {
  padding: 10px;;
  background-color: var(--pri-01);
  border-radius: 5px;
  overflow: hidden;
  box-shadow: var(--shadow);
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 90px;
  width: 300px;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--black-l);
}

.content {
  display: flex;
}

.info {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.icon {
  width: 40px;
}

.stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.title {
  font-family: var(--font-semibold);
  color: var(--black);
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

.name {
  font-family: var(--font-reg);
  color: var(--black);
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.percent {
  font-family: var(--font-reg);
  font-size: 21px;
  color: var(--pri-02);
}

.bar {
  position: relative;
  height: 9px;
  width: 150px;
  background-color: var(--gray-l);
  border-radius: 5px;
  overflow: hidden;
}

.one {
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: 0px;
  transition: transform 0.2s linear 0s;
  transform-origin: left center;
  width: auto;
  background-color: var(--pri-03);
  animation: 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) 0s infinite normal none running elastic;
}

.two {
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: 0px;
  transition: transform 0.2s linear 0s;
  transform-origin: left center;
  width: auto;
  background-color: var(--pri-03);
  animation: 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite normal none running lag;
}

@keyframes elastic {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes lag {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

.cancel {
  color: var(--hyperlink);
  text-decoration: underline;
}

.transferring {
  animation-duration: 225ms;
  animation-name: remove;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-delay: 500ms;
}

@keyframes remove {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}