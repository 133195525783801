.root {
  position: relative;
  border-radius: 4px;
  width: 100%;
  height: 8px;
  overflow: hidden;
  background-color: #ececec;
}

.fill {
  position: absolute;
  background-color: var(--pri-03);
  left: 0;
  height: 100%;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}