.timestamp {
  color: var(--gray-d);
  font-family: var(--font-semibold);
  font-size: 12px;
  line-height: 16px;
}

.seek {
  cursor: pointer;
}

.seek:hover {
  color: var(--pri-02);
  text-decoration: underline;
}