.root {
  --gap: 10px;
  --margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  --width: calc(100% + var(--gap));
  --min-height: calc(55px + var(--gap));
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: var(--margin);
  width: var(--width);
  min-height: var(--min-height);
  padding: 10px 20px;
  box-sizing: border-box;
}

.add-topic {
  display: flex;
  align-items: center;
  color: var(--pri-02);
  cursor: pointer;
  white-space: nowrap;
  margin: var(--gap) 0 0 var(--gap);
}

.tag {
  margin: var(--gap) 0 0 var(--gap);
  white-space: nowrap;
}

.disabled {
  color: var(--gray-m);
  cursor: not-allowed;
}