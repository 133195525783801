.root {
  font-size: 13px;
  padding: 12px 40px 12px 12px;
}

.goal {
  font-family: var(--font-bold);
  margin-bottom: 12px;
  color: var(--black-l);
}

.stat {
  display: flex;
  white-space: nowrap;
  margin-bottom: 10px;
  color: var(--black-l);
}

.completed:before {
  display: inline-block;
  content: '';
  height: 15px;
  width: 15px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: #099566;
}

.pipeline:before {
  display: inline-block;
  content: '';
  height: 15px;
  width: 15px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: #A3CB5F;
}

.rejected:before {
  display: inline-block;
  content: '';
  height: 15px;
  width: 15px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: var(--pri-04);
}

.scheduling:before {
  display: inline-block;
  content: '';
  height: 15px;
  width: 15px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: var(--pri-03);
}