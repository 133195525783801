.root {
  padding: 0 10px;
}

@media (min-width: 900px) {
  .root {
    padding: 0 25px;
    width: 550px;
  }
}

.header {
  text-align: left;
  margin-bottom: 25px;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.label {
  font-family: var(--font-semibold);
  margin-right: 10px;

  display: flex;
  flex-shrink: 0;
}

.body {
  height: 225px;
  max-height: 250px;
  overflow-y: scroll;
}

.select {

}

.path {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}