.root {
  width: 280px;
  min-width: 280px;
  box-sizing: border-box;
  border: 1px solid var(--sec-01);
  border-radius: 5px;
}

.preview {
  height: 150px;
  max-height: 150px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  overflow: hidden;
}

.details {
  height: 110px;
  min-height: 110px;
  box-sizing: border-box;

  border-top: 1px solid var(--gray-xl);
  padding: 15px;

  cursor: pointer;
  user-select: none;
}

.clamped {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.title {
  composes: clamped;
  font-family: var(--font-semibold);
  color: var(--black-l);
  margin-bottom: 8px;
}

.description {
  composes: clamped;
  font-size: 14px;
}

.date {
  color: var(--gray);
  font-size: 14px;
  margin-bottom: 8px;
}