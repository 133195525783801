@value 800 from 'vars';

.root {

}

.wrap {

}

.main {
  margin: 20px 0;
}

.header {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.root:first-child .header {
  margin: 0 0 20px;
}

.title {
  font-size: 22px;
  line-height: 30px;
  color: var(--black);
  font-family: var(--font-bold);
}

.label {
  font-family: var(--font-bold);
  margin-bottom: 10px;
}

.value {
  margin-bottom: 10px;
  line-height: 33px;
}

.placeholder {
  color: var(--gray-l);
  margin-bottom: 10px;
  line-height: 33px;
}

.divider {
  height: 3px;
  background-color: var(--sec-01);
  width: 100%;
}

.edit {
  display: flex;
  justify-content: center;
  width: 40px;
  height: 30px;
}

.btn {
  color: var(--pri-02);
  cursor: pointer;
  width: 20px;
}

.modal {
  position: relative;
}

@media 800 {
  .modal {
    width: 800px;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.role {
  border-bottom: none;
}

.root .wrap .placeholder {
  color: var(--gray);
}

.root .wrap .input,
.root .wrap .input:focus {
  border: none;
  background-color: var(--gray-xl);
}