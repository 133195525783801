@value 800, 1000 from 'vars';

.root {

}

.wrap {
  max-width: 830px;
  margin: 0 auto;
  padding: 0 15px;
}

@media 1000 {
  .wrap {
    max-width: 900px;
  }
}

.main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
}

@media 800 {
  .main {
    justify-content: flex-start;
  }
}

.header {
  font-family: var(--font-semibold);
  font-size: 24px;
  padding: 40px 0 20px 0;
}

.item {
  width: 160px;
}

.card {
  border: 1px solid var(--sec-01);
  border-radius: 4px;
  box-shadow: none;
}

.footer {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
}

.btns {
  max-width: 830px;
  margin: 0 auto;
  padding: 0 15px 20px 15px;
  background-color: var(--pri-01);
}

@media 1000 {
  .btns {
    max-width: 900px;
  }
}

.overlay {
  height: 70px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--pri-01));
}

.navigation {
  max-width: 715px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

@media 800 {
  .navigation {
    margin: unset;
  }
}

@media 1000 {
  .navigation {
    max-width: 900px;
  }
}

.btn {
  composes: brick from 'css/button.css';
}

.search-bar {
  margin-bottom: 20px;
}