.root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.main {
  min-height: calc(100vh - var(--navbar-height));
}