.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.text {
  color: var(--gray-d);
  font-family: var(--font-reg);
  line-height: 20px;
}

.add  {
  font-size: 15px;
  margin: 20px 0 0 0;
  padding: 0;
  height: 34px;
  width: 160px;
}