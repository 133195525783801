@value 800, 1200 from 'vars';

.root {
  height: 100%;
}

.main {
  max-width: var(--navbar-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  box-sizing: border-box;
}

@media 1200 {
  .main {
    flex-direction: row;
    justify-content: space-between;
  }

  .col {
    max-width: 900px;
  }

  .col:nth-of-type(1) {
    width: 0;
  }
}

.col:not(:first-of-type) {
  margin-left: 20px;
}

@media 1200 {
  .col {
    display: flex;
    flex: auto;
    flex-direction: column;
  }

  .col:nth-of-type(2) {
    flex: 1 1 300px;
    min-width: 300px;
    max-width: 300px;
  }
}

.card {
  padding: 15px 15px 20px 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 0;
}

.card:empty {
  display: none;
}

@media 800 {
  .card {
    padding: 15px 25px 20px 25px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.desktop {
  display: none;
}

@media 1200 {
  .desktop {
    display: block;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  height: 32px;
}

.title {
  color: var(--gray-d);
  font-size: 18px;
  font-family: var(--font-semibold);
}

.padding {
  margin-bottom: 200px;
}