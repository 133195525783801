.root {

}

.label {
  display: flex;
  gap: 15px;
  color: var(--black);
  font-family: var(--font-bold);
  margin-bottom: 4px;
  height: 26px;
}

.content {
  display: flex;
  align-items: center;
  color: var(--black-l);
  min-height: 30px;
  margin-bottom: 5px;
  overflow-wrap: break-word;
}

.edit {
  cursor: pointer;
}

.field {
  display: flex;
  gap: 5px;
  width: 80px;
}

.check {
  color: var(--pri-03);
  cursor: pointer;
}

.cancel {
  cursor: pointer;
}