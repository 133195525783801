.root {
  height: 100%;
}

.wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  margin-bottom: 20px;
  flex-grow: 1;
}

.add {
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--pri-02);
  font-family: var(--font-bold);
  transition: color 120ms ease-in;
}

.add:disabled {
  color: var(--gray);
  cursor: default;
}

.add:not(:disabled):hover {
  color: var(--sec-02);
}

.icon {
  margin-right: 5px;
}

.btn {
  width: 150px;
  border-radius: 4px;
  height: 40px;
  padding: 0;
}

.row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.delete {
  color: var(--pri-04);
}

.field {
  width: 250px;
}

.name-field {
  width: 500px;
}

.select {
  width: 100%;
}

.label {
  width: 250px;
  font-family: var(--font-bold);
}

.name-label {
  composes: label;
  width: 500px;
}

.input {
  --border-width: 1px;
  --padding-x: 8px;
  font-family: var(--font-reg);
  font-size: 16px;
  padding: 7px var(--padding-x);
  border: var(--border-width) solid var(--gray-l);
  border-radius: 4px;
  width: calc(100% - ((var(--padding-x) * 2) + (var(--border-width) * 2)));
}

.input:focus {
  border: var(--border-width) solid var(--pri-02);
}

.input::placeholder {
  color: var(--gray);
}

.footer {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.modal {
  overflow-y: visible;
}