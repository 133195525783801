.bar {
  position: sticky;
  top: calc(var(--navbar-height) + var(--height-breadcrumbs) + var(--sticky-space));
  z-index: 2;
}

.no-media {
  top: calc(var(--navbar-height) + var(--height-breadcrumbs));
}

.tab {
  background-color: var(--pri-01);
}

.tab:hover {
  background-color: var(--gray-xl);
}

.tabs {

}