@value 1100 from 'vars';

.title {
  color: var(--black);
  font-size: 24px;
  font-family: var(--font-bold);
}

.main {
  --input-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
}

.content {
  width: 100%;
  height: 100%;
}

.scroll {
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - var(--input-height));
}

.items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.item {
  padding: 15px;
}

.field {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  border-top: 1px solid var(--gray-l);
  background-color: var(--pri-01);
}

@media 1100 {
  .field {
    position: absolute;
  }
}

.textarea {
  height: calc(var(--input-height) - 16px);
  width: 100%;
  font-size: 16px;
  border: none;
  outline: none;
  resize: none;
  border-radius: 0;
  font-family: var(--font-reg);
  color: var(--black-l);
  padding: 8px;
}

@media 1100 {
  .textarea {
    max-width: 891px;
  }
}

.textarea::placeholder {
  color: var(--gray);
}

.textarea:disabled {
  background: #fcfcfc;
}

.actions {
  border-top: 1px solid var(--sec-01);
  height: 59px;
  width: 100%;
  background-color: #f8f8f8;
}

.wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding: 0 30px;
}

@media 1100 {
  .wrap {
    max-width: 885px;
  }
}

.actions button + button {
  margin-left: 15px;
}

.date {
  display: inline;
  position: relative;
  z-index: 1;
  padding: 5px 10px;
  background-color: var(--pri-01);
  height: 30px;
  font-family: var(--font-reg);
  font-size: 12px;
  color: var(--gray-m);
  line-height: 30px;
}

.timebreak {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.timebreak:after,
.timebreak:before {
  display: block;
  content: '';
  background-color: var(--sec-01);
  height: 1px;
  width: 50%;
}

.timebreak:before {
  position: absolute;
  left: 0;
  top: 15px;
}

.timebreak:after {
  position: absolute;
  right: 0;
  top: 15px;
}

@media 1100 {
  .timebreak:before {
    left: 30px;
  }

  .timebreak:after {
    right: 30px;
  }
}

.btn {
  height: 35px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 10px;
}

.sendbtn {
  composes: btn;
  width: 110px;
}

.sendicon {
  transform: rotate(90deg);
  height: 16px;
  width: 16px;
  font-size: 16px;
  margin-left: 5px;
}

.button-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-family: var(--font-semibold);
}

.paid-button {
  composes: btn;
  width: 150px;
}

.paid-icon {
  stroke-width: 3;
  width: 15px;
  height: 15px;
}

.paid-on,
.paid-on:hover {
  background-color: rgba(92, 153, 255, .15);
  border-color: var(--pri-02);
  color: var(--pri-02);
}

.paid-off {
  background-color: var(--gray-xl);
  border-color: var(--gray-d);
  color: var(--gray-d);
}

.paid-off:hover {
  background-color: var(--gray-xl);
  border-color: var(--pri-02);
  color: var(--pri-02);
}