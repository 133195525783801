.author {
  padding: 20px 20px 0;
}

.btn {
  font-size: 15px;
  padding: 5px 10px;
  height: 40px;
  width: 100px;
}

.next {
  composes: btn;
}

.next:disabled {
  background-color: #979797;
  border-color: #979797;
  color: var(--sec-01);
}

.back {
  composes: btn;
  color: var(--black-l);
  font-family: var(--font-semibold);
  border-radius: 5px;
}

.back:hover {
  background-color: var(--sec-01);
  border-color: var(--sec-01);
}

.textarea {
  padding: 10px;
  border: 1px solid var(--sec-01);
  border-radius: 4px;
  margin-bottom: 20px;
}

.textarea:focus-within {
  border: 1px solid var(--pri-02);
}

.main {
  padding: 20px;
}

.title {
  margin-bottom: 20px;
}

.btn ~ .btn {
  margin-left: 7px;
}

.input {
  height: 1px;
}

.unfocused {
  background-color: var(--sec-01);
}

.invalid {
  background-color: red;
}

.label {
  margin-bottom: 15px;
  font-size: 14px;
  color: var(--black);
  font-family: var(--font-semibold);
}

.upload {
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 20px;
}

.topics {
  border-top: 1px solid var(--gray-xl);
}