@value 800 from 'vars';

.action {
  width: 160px;
}

.header {
  margin-bottom: 20px;
}

.body {
  margin: 0 auto;
  text-align: left;
}

@media 800 {
  .body {
    width: 440px;
  }
}

.msg {
  margin-bottom: 20px;
}

.footer {
  margin-bottom: 20px;
}

.btn {
  display: block;
  width: 145px;
  font-size: 14px;
  font-family: var(--font-reg);
}