@value 800, 1000 from 'vars';

.root {
  border-top: 1px solid var(--gray-xl);
}

.wrap {
  padding: 40px 0 0;
}

@media 800 {
  .wrap {
    padding: 70px 0 40px;
  }
}

.main {
  max-width: 400px;
  margin: 0 auto;
}

@media 800 {
  .main {
    max-width: 700px;
    background-image: url('static/images/website/customer-research/bg-triangle.svg?url');
    background-repeat: no-repeat;
    background-position: 100% 30%;
    margin: 0 auto;
  }
}

@media 1000 {
  .main {
    max-width: 1000px;
    width: 100%;
    background-position: 80% 30%;
  }
}

.header {
  font-size: 28px;
  font-family: var(--font-bold);
  color: var(--black-d);
  max-width: 400px;
  margin: 0 auto 40px;
  text-align: center;
}

@media 800 {
  .header {
    max-width: 550px;
  }
}

@media 1000 {
  .header {
    font-size: var(--font-size-header);
    max-width: 600px;
  }
}

.img {
  filter: grayscale(1);
}

.text {
  font-size: 18px;
  color: var(--gray-d);
  margin-bottom: 20px;
}

.item {
  padding: 20px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.12);
  border-radius: 8px;
  margin-bottom: 30px;
  background-color: var(--pri-01);
}

@media 800 {
  .item {
    padding: 30px;
    width: 500px;
    margin: 0 auto 0px;
  }
}

@media 800 {
  .row {
    width: 600px;
    margin: 0 auto;
    padding: 30px 0 0;
  }

  .row:first-child {
    background-image: url('static/images/website/customer-research/dots-top-left.svg?url');
    background-repeat: no-repeat;
  }
}

.avatar {
  margin-right: 15px;
}

.name {
  font-size: 20px;
  font-family: var(--font-semibold);
  color: var(--black-d);
  margin-bottom: 5px;
}

.position {
  font-size: 16px;
  color: var(--black-d);
  max-width: 270px;
}

@media 800 {
  .position {
    max-width: 100%;
  }
}


.info {
  display: flex;
}

.details {

}