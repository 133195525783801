.root {
  min-height: 220px;
  padding-bottom: 30px;
}

.wrap {
  position: relative;
  width: 100%;
}

.row {
  margin-bottom: 10px;
}

.header {
  display: flex;
  flex-direction: column;
  composes: row;
}

.title {
  font-family: var(--font-bold);
  font-size: 24px;
  color: var(--black);
}

.optional {
  color: var(--gray);
}

.subtitle {
  font-family: var(--font-reg);
  font-size: 18px;
  color: var(--gray-d);
}