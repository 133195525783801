@value 800, 1100 from 'vars';

.root {
  margin-bottom: 60px;
}

@media 800 {
  .root {
    margin-bottom: unset;
  }
}

.wrap {
  max-width: 1280px;
  margin: 0 auto;
}

.content {
  height: calc(100vh - var(--navbar-height));
  min-height: 800px;
  padding: 0 20px 40px;
}

@media 800 {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.main {
  max-width: 400px;
  margin: 0 auto;
}

@media 800 {
  .main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: var(--max-width);
  }
}

.header {

}

.img {
  width: 100%;
  height: auto;
  margin-top: 40px;
}

.graphic {
  width: 100%;
  margin: 0 auto;
}

@media 800 {
  .graphic {
    max-width: 100%;
    margin: 0;
    width: 450px;
  }

  .img {
    display: block;
    margin-left: -30px;
    max-width: 500px;

    width: unset;
    height: unset;
    margin-top: unset;
  }
}

@media 1100 {
  .graphic {
    height: auto;
  }

  .img {
    max-width: 650px;
  }
}

@media 800 {
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 450px;
  }
}

@media 1100 {
  .info {

  }
}

.title {
  font-size: 30px;
  color: var(--black-d);
  font-family: var(--font-bold);
  margin-bottom: 30px;
}

@media 800 {
  .title {
    font-size: var(--font-size-header);
    max-width: 400px;
  }
}

.subtitle {
  font-size: 20px;
  color: var(--black-l);
  margin-bottom: 50px;
  max-width: 370px;
}

@media 800 {
  .subtitle {
    max-width: 370px;
  }
}