.anchor {
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
}

.preview .play {
  position: absolute;
  bottom: calc(50% - 36px);
  left: calc(50% - 36px);
  color: var(--pri-01);
  opacity: 0.58;
  height: 66px;
  width: 66px;
  border: 3px solid var(--pri-01);
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  z-index: 1;
}

.preview:hover .play {
  opacity: 0.95;
}

.preview {
  position: relative;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.05);
}

.thumb {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}