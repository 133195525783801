:global(.ant-picker).picker {
  border: var(--border-width) solid var(--gray-l);
  border-radius: 5px;
  width: 100%;
  font-family: var(--font-reg);
}

:global(.ant-picker-large).picker {
  padding: 7px var(--padding-horizontal);
}

:global(.ant-picker).picker :global(.ant-picker-input) > input,
:global(.ant-picker).picker :global(.ant-picker-input) > input:focus,
:global(.ant-picker).picker :global(.ant-picker-input) > input:hover,
:global(.ant-picker).picker:hover,
:global(.ant-picker.ant-picker-focused) {
  font-family: var(--font-reg);
  border-color: var(--pri-02);
  box-shadow: none;
}

:global(.ant-picker).picker.invalid {
  border-color: var(--pri-04);
}

@keyframes :global(antSlideDownIn) {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes :global(antSlideDownOut) {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

@keyframes :global(antSlideUpIn) {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes :global(antSlideUpOut) {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}