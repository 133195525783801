@value 800, 900, 1200 from 'vars';

.root {
  background-color: var(--blue-l);
  padding: 10px;
  --width-btn: 30px;
}

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  width: 100%;
}

.main {
  width: 100%;
  color: var(--black-l);
  text-align: center;
  margin-left: var(--width-btn);
}

.hyperlink {
  color: var(--pri-02);
  font-family: var(--font-bold);
}

.hyperlink:hover {
  text-decoration: underline;
}

.text {
  font-family: var(--font-bold);
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--width-btn);
  color: var(--gray-d);
}

.span {
  white-space: pre;
}

.group:not(:last-child) {
  margin-bottom: 10px;
}

@media 800 {
  .group .text {
    display: inline-block;
    white-space: pre;
  }
}

@media 900 {
  .group:not(:last-child) {
    margin-bottom: 0;
  }

  .group {
    display: inline-block;
  }

  .main {
    text-align: left;
  }
}

@media 1200 {
  .main {
    text-align: center;
  }
}
