.active {
  position: absolute;
  height: 3px;
  background-color: var(--pri-02);

  z-index: 2;
}

.inactive {
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: var(--pri-02);

  z-index: 1;  
}
