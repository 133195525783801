.root {
  /* font-size: 15px; */
  box-sizing: border-box;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid var(--gray-l);
  background-color: var(--pri-01);
  min-height: 50px;
  display: flex;
  align-items: flex-start;
}

.dragging {
  border: 1px solid var(--pri-02);
}