@value 800 from 'vars';

.root {
  background-color: var(--blue-xl);
}

.wrap {
  padding: 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.details {
  color: var(--gray-d);
  margin-left: 15px;
  margin-right: 40px;

  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  font-family: var(--font-semibold);
  font-size: 14px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title:hover {
  color: var(--pri-02);
}

.description {
  font-size: 14px;
  font-family: var(--font-reg);
  margin-top: 5px;
}

.icon {
  color: var(--gray-d);
  display: flex;
  flex-shrink: 0;
}

.btn {
  width: 115px;
  font-size: 14px;
  border: .5px solid var(--pri-02);

  display: none;
}

@media 800 {
  .btn {
    display: block;
  }
}