@value 800, 1100 from 'vars';

.root {
  width: 100%;
}

.wrap {
}

.wrap {
  background-image: url('static/images/website/about/bg-dots-top-left.png');
  background-repeat: no-repeat;
  background-size: 90%;
  padding: 70px 20px 40px;
  max-width: 400px;
  margin: 0 auto;
}

@media 800 {
  .wrap {
    max-width: unset;
    background-size: 390px;
    padding: 70px 20px 50px;
  }
}

@media 1100 {
  .wrap {
    max-width: 1440px;
    background-size: 410px;
  }
}

.content {

}

@media 800 {
  .content {
    max-width: var(--max-width);
    padding: 0 20px;
  }
}

@media 1100 {
  .content {
    margin: 0 auto;
  }
}

.main {
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
}

@media 800 {
  .main {
    max-width: 100%;
  }
}

@media 1100 {
  .main {
    margin: 0;
  }
}

.header {
  font-family: var(--font-bold);
  font-size: var(--font-size-heading-2);
  color: var(--black-d);
  text-align: center;
  padding: 20px 0;
  margin-bottom: 20px;
}

@media 800 {
  .header {
    font-size: var(--font-size-heading-1);
    text-align: left;
    max-width: var(--max-width);
    margin: 0 auto 25px;
    padding: 20px;
  }
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

@media 800 {
  .items {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    max-width: 900px;
    margin: unset;
  }
}

@media 1100 {
  .items {
  }
}

.item {
  height: 260px;
}

.graphic {
  margin-bottom: 25px;
  text-align: center;
}

@media 800 {
  .graphic {
    text-align: left;
  }
}

.img {
  height: 110px;
  border-radius: 50%;
}

.info {
  text-align: center;
}

@media 800 {
  .info {
    text-align: left;
  }
}

.title {
  font-family: var(--font-bold);
  color: var(--black);
  font-size: var(--font-size-subtitle-1);
  margin-bottom: 15px;
}

.text {
  font-family: var(--font-bold);
  color: var(--gray-m);
  font-size: var(--font-size-subtitle-1);
}