.answer {
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
}

.answer-value {
  width: 85%;
}

.star {
  width: 50px;
}

.question {
  border-top: 1px solid var(--gray-xl);
  padding: 15px 5px;
}

.question:last-of-type {
  border-bottom: 1px solid var(--gray-xl);
}

.hidden {
  background-color: var(--gray-xl);  
}