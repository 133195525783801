@value 1200 from 'vars';

.col {
  position: relative;
}

@media 1200 {
  .col:not(:nth-of-type(1)) {
    flex: 1 1 300px;
    min-width: 300px;
    max-width: 300px;
    /* margin: 0 20px; */
  }

  .col {
    display: flex;
    flex: auto;
    flex-direction: column;
  }
}

.sidebar {
  display: none;
}

@media 1200 {
  .col:nth-of-type(1) {
    width: 900px;
    min-height: calc(100vh - var(--navbar-height) - 20px);
    /* margin: 0 20px; */
  }

  .sidebar {
    display: block;
  }
}

.workspaces {
  margin-bottom: 20px;
}