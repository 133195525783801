.root {
  padding: 15px;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.item:last-child {
  margin-bottom: 0;
}

.icon {
  margin-right: 15px;

  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.checked {
  background-color: var(--pri-03);
  cursor: default;
  margin-right: 15px;
}

.check {
  color: var(--pri-01);
  height: 14px;
  width: 14px;
  stroke-width: 3px;
  padding: 3px;
}

.text {
  font-family: var(--font-semibold);
}

.incomplete .icon {
  border: 2px solid var(--gray-l);
}

.incomplete .text {
  cursor: pointer;
  color: var(--black);
}

.incomplete .text:hover {
  color: var(--pri-02);
}

.complete .text {
  color: var(--trolley);
}

.progress {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 12px;
  width: 100%;
  background-color: var(--gray-xl);
}

.bar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 100%;
  background-color: var(--pri-03);
  transition: all 0.3s ease 0.2s;
}

.hidden {
  visibility: hidden;
}