.btn {
  position: relative;
  z-index: 1;
  height: 40px;
  min-width: 125px;
  color: var(--pri-01);
  font-family: var(--font-bold);
  font-size: 16px;
}

.content {
  position: relative;
  z-index: 1;
}

.brick {
  border-radius: 4px;
}

.rounded {
  border-radius: 25px;
}

.blue {
  --r: 39;
  --g: 170;
  --b: 225;
  --full: rgba(var(--r), var(--g), var(--b), 1);
  --dim: rgba(var(--r), var(--g), var(--b), 0.7);
  --none: rgba(var(--r), var(--g), var(--b), 0);
}

.purple {
  --r: 92;
  --g: 115;
  --b: 173;
  --full: rgba(var(--r), var(--g), var(--b), 1);
  --dim: rgba(var(--r), var(--g), var(--b), 0.7);
  --none: rgba(var(--r), var(--g), var(--b), 0);
}

.green {
  --r: 25;
  --g: 155;
  --b: 155;
  --full: rgba(var(--r), var(--g), var(--b), 1);
  --dim: rgba(var(--r), var(--g), var(--b), 0.7);
  --none: rgba(var(--r), var(--g), var(--b), 0);
}

.disabled {
  cursor: not-allowed;
  background-color: var(--gray-l);
}

.pulse {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--full);
  box-shadow: 0 0 0 0 var(--full);
}

.animate {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--dim);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px var(--none);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--none);
  }
}