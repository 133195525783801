.root {
  padding: 0 15px;
}

.row {
  padding: 15px 0;
}

.label {
  font-family: var(--font-bold);
  color: var(--black-l);
  margin-bottom: 10px;
}

.footer {
  /* margin-top: 100px; */
}

.btn {
  min-width: 150px;
  border-radius: 4px;
  height: 40px;
  padding: 5px 10px;
}

.project {
  display: flex;
  align-items: center;
  height: 32px;
}

.clear {
  color: var(--gray-m);
  cursor: pointer;
  margin-left: 10px;
}

.select {

}