
.access {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 50px;
  height: 50px;
}

.bg {
  height: 35px;
  width: 30px;
  padding: 0.5px 3px;
}

.bg:hover {
  border-radius: 50%;
  background-color: var(--gray-xl);
}

.access .icon {
  height: 30px;
  width: 30px;
}

.btn {

}

.counter {
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: var(--hyperlink);
  color: var(--pri-01);
  font-size: 12px;
  font-family: var(--font-semibold);
  line-height: 20px;
  text-align: center;
}
