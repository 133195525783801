.btns {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 10px;
}

.back {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 40px;
  cursor: pointer;
  font-family: var(--font-bold);
  color: var(--black-l);
}

.btn {
  composes: brick from 'css/button.css';
  width: 130px;
  box-sizing: border-box;
  height: 40px;
}

.discard-button {
  margin-right: 10px;
}

.skip {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}