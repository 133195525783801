.root {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: var(--z-index-modal);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
}

.wrap {
  background-color: var(--pri-01);
  margin: auto;
  border-radius: 5px;
  z-index: 3;
  width: 100vw;
  max-height: 100vh;
  transition: width 525ms cubic-bezier(0.4, 0, 0.6, 1) 500ms;
  overflow-y: auto;
}

@media(min-width: 800px){
  .wrap {
    max-height: 90vh;
    width: 600px;
  }
}

.main {
  padding: 20px;
}

.wrap::-webkit-scrollbar {
  display: none;
}

.close {
  font-size: 28px;
  line-height: 23px;
  color: var(--gray);
  cursor: pointer;
  display: inline-block;
}

.close-container {
  text-align: right;
}

.header {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
}

.body {
  min-height: 150px;
}

.content {
  padding: 15px 0 25px;
  text-align: center;
}

.row {
  padding: 15px 0;
  text-align: left;
}

.label {
  font-family: var(--font-bold);
  color: var(--black-l);
  margin-bottom: 10px;
}

.footer {
  display: flex;
  justify-content: center;
}

.paragraph {
  text-align: center;
}

.btns {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btns button:nth-child(n+2) {
  margin-left: 20px;
}

.prompt-modal {
  max-width: 550px;
}

.prompt-btn {
  min-width: 130px;
}

.prompt-root {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prompt-header {
  text-align: center;
  margin: 10px auto;
  width: 80%;
}

.prompt-body {
  margin: 0 auto 20px;
  max-width: 400px;
}

.prompt-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.confirmation-root {
  padding: 0 15px;
}

.alert-message {
  text-align: center;
  margin: 0 auto;
  margin: 20px 0;
}

.alert {
  max-width: 360px;
  padding: 0 20px;
}

.alert-btns {
  height: 75px;
}

.alert-btn {
  min-width: 110px;
}

.select {
  margin-bottom: 50px;
}

.add-to-project {
  overflow-y: unset;
}