@value 1000 from 'vars';
@value 800 from 'vars';

.root {
  background-color: var(--blue-l);
}

.wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding: 50px 45px;
}

@media 800 {
  .wrap {
    padding: 50px 35px;
  }
}

.main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media 800 {
  .main {
    flex-wrap: nowrap;
  }
}


.item {
  width: 100%;
  min-height: 60px;
}

@media 800 {
  .item {
    width: auto;
    min-height: auto;
  }
}

.link {
  color: var(--pri-02);
  text-decoration: underline;
  font-family: var(--font-semibold);
  font-size: 20px;
}

.title {
  color: var(--black);
  font-size: 22px;
  font-family: var(--font-bold);
  margin-bottom: 40px;
}

.text {
  margin-right: 10px;
  font-size: 18px;
}