.root {

}

.add-section {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.divider {
  margin: 15px 0;
}

.menu {
  width: 250px;
  max-height: 325px;
  overflow-y: hidden;

  background-color: var(--pri-01);
  border-radius: 4px;
  box-shadow: 0px 1px 2px 1px rgb(238 238 238);
}

.overflow {
  overflow-y: auto;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 15px;
  font-family: var(--font-semibold);
  font-size: 14px;
  color: var(--gray-d);
  cursor: pointer;
}

.item:hover {
  background-color: var(--blue-xl);
  color: var(--pri-02);
}

.item.active {
  color: var(--pri-02);
}

.icon {
  min-width: 23px;
  height: 23px;
  padding-left: 5px;
}