.checkbox {
  margin-bottom: 15px;
}

.item {
  display: flex;
  align-items: center;
}

.item {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.input {
  width: 70px;
}

.title {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  margin-right: 10px;
}