.options {
  border-top: 1px solid var(--gray-xl);
  padding-bottom: 60px;
}

.value {
  padding: 15px 25px;
  border-bottom: 1px solid var(--gray-xl);
  display: flex;
  align-items: center;
  box-sizing: border-box;

  font-family: var(--font-semibold);
  color: var(--gray-d);
}