@value 500 from 'vars';
@value 600 from 'vars';

.root {
  padding: 40px 20px 20px;
  max-width: 650px;
  margin: 0 auto;
}

.header {
  min-height: 150px;
}

@media 500 {
  .header {
    min-height: 100px;
    margin-bottom: 10px;
  }
}

.extended {
  margin-bottom: 30px;
}

.title {
  font-size: 24px;
  font-family: var(--font-bold);
  margin-bottom: 20px;
}

@media 600 {
  .title {
    display: flex;
    align-content: center;
  }
}

.subtitle {
  font-size: 18px;
  font-family: var(--font-reg);
  color: var(--gray-d);
}

.main {
  border-top: 1px solid var(--gray-xl);
  margin-bottom: 25px;
  min-height: 250px;
}

.footer {
  padding: 40px 0;
  text-align: right;
}

.footer .btn:last-child {
  margin-left: auto;
}

.btn {
  padding: 10px 25px;
}

.loader {
  text-align: center;
}

.create {
  margin-top: 20px;
}

.progress {
  position: fixed;
  top: var(--navbar-height);
}

.section-entry {
  padding: 30px 0;
  border-bottom: 1px solid var(--gray-xl);
}

.back {
  cursor: pointer;
  font-family: var(--font-semibold);
  color: var(--black-l);
}