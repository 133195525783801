.root {
  max-width: 800px;
  margin: 0 auto;
}

.action {
  cursor: pointer;
}

.section-root {
  margin-bottom: 30px;
}

.row {
  min-height: 30px;
}

.cell {
  align-self: center;
}

.actions-cell {
  text-align: right;
}