.root {
  display: flex;
  align-items: center;
}

.count {
  margin-right: 15px;
  color: var(--gray-d);
}

.navigation {
  display: flex;
  justify-content: space-between;
  width: 60px;
}

.chevron {
  cursor: pointer;
  border-radius: 50%;
  color: var(--black-l);

  width: 25px;
  height: 25px;
}

.chevron:hover {
  background-color: var(--gray-xl);
}

.disabled {
  cursor: default;
  color: var(--gray);
}

.disabled:hover {
  background-color: unset;
  color: var(--gray);
}