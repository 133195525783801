.root {
  width: 100%;
}

.header, .row, .footer {
  display: flex;
  align-items: center;
}

.footer {
  border-top: 1px solid var(--gray-xl);
}

.footer .row-name {
  font-family: var(--font-semibold);
}

.row:nth-of-type(odd) {
  background-color: var(--gray-xl);
}

.row-name {
  flex-grow: 1;
  word-break: break-word;
  padding: 10px;

  display: flex;
  align-items: center;
}

.header .cell {
  font-family: var(--font-semibold);
}

.cell {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  word-break: break-word;
}