.root {
  display: flex;
  align-items: center;
}

.imported {
  font-size: 14px;
  color: var(--gray-d);
}

.gmail {
  margin-right: 10px;
  cursor: pointer;
}

.outlook {
  margin-left: 10px;
  cursor: pointer;
}