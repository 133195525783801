/* .root {

}

.wrap {
  padding: 0 0 20px;
}

.main {
  background-color: var(--pri-01);
} */

.row {
  display: flex;
  justify-content: space-between;
}

.field {
  height: 60px;
}

.label {
  color: var(--black);
  font-family: var(--font-bold);
}

.value {
  line-height: 25px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.media {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--pri-01);
  z-index: 2;
  height: 25px;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.sticky {
  position: sticky;
  top: calc(var(--navbar-height) + var(--height-breadcrumbs));
}

.media.ready {
  height: var(--height-media);
}

.player {
  height: 25px;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.player.audio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: var(--top-media);
}

.player.video {
  height: var(--height-media);
  padding-bottom: 15px;
}

.player video {
  height: var(--top-media);
  outline: none;
}

.loader {
  display: flex;
  justify-content: center;
}

.item {
  visibility: hidden;
  opacity: 0;
  transition: opacity 800ms cubic-bezier(0.4, 0, 0.2, 1) 400ms;
}

.item.in {
  visibility: visible;
  opacity: 1;
}

.off-screen {
  position: fixed;
  bottom: 10px;
  height: 150px;
  z-index: 2;
  transform: translateX(20%);
}

.off-screen video {
  height: 150px;
}

.hide-docked-video {
  display: none;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.hide-docked-video:hover {
  color: var(--pri-01);
}

.off-screen .hide-docked-video {
  display: block;
}

.skel {
  margin-bottom: -8px;
}

.discover {
  margin-right: 20px;
}