@value 800 from 'vars';

.root {

}

.wrap {

}

.main {
  margin: 40px 0;
}

.group {
  margin-bottom: 15px;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.item {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.remove {
  width: 30px;
  height: 30px;
  color: var(--pri-04);
  cursor: pointer;
}

.remove svg {
  height: 30px;
  width: 30px;
}

.cancel {
  font-family: var(--font-bold);
  color: var(--black-l);
  cursor: pointer;
}

.btn {
  width: 150px;
}

.uneditable {
  height: 32px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 2px solid var(--gray-l);
  font-family: var(--font-bold);
  color: var(--black-l);
}

.text {
  line-height: 32px;
  letter-spacing: 0;
}

.add {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--pri-02);
  font-family: var(--font-bold);
  height: 32px;
  cursor: pointer;
}

.complete {
  display: flex;
  justify-content: flex-end;
}

@media 800 {
  .review {
    padding: 0 15px;
  }

  .review .gutter {
    margin: 0 auto;
    padding: 0;
    max-width: 1080px;
  }
}