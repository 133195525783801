.root {

}

.wrap {
  padding: 20px 0;
  max-width: 900px;
}

.main {
  display: flex;
  gap: 20px;
}

.col {
  display: flex;
  flex-basis: 300px;
  flex-direction: column;
  gap: 20px;
}

.col:nth-child(2) {
  flex-grow: 1;
}

.field ~ .field {
  margin-top: 10px;
}

.label {
  font-family: var(--font-bold);
  line-height: 24px;
  margin-bottom: 5px;
}

.value {
  line-height: 24px;
}

.na {
  color: var(--gray);
  line-height: 24px;
}