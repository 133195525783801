.root {
  max-width: 1280px;
  margin: 0 auto;
}

.wrap {
  padding: 20px 10px 40px;
}

.main {
  display: flex;
}