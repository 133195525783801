@value 1100, 800 from 'vars';

.menu {
  width: 100%;
  max-width: unset;
}

@media 800 {
  .menu {
    max-width: 400px;
  }
}

@media 1100 {
  .overlay {
    background-color: unset;
  }
}

.drawer {
  position: relative;
  padding: 30px;
  min-height: calc(100% - var(--navbar-height));
}

.links {

}

.section {
  margin-bottom: 40px;
}

.title {
  font-size: 20px;
  color: var(--black-l);
  font-family: var(--font-bold);
  margin-bottom: 30px;
}

.link {
  display: block;
  margin-bottom: 30px;
  font-size: 20px;
  color: var(--gray-d);
}

.link:hover {
  color: var(--hyperlink);
  text-decoration: underline;
}

.link:last-child {
  margin-bottom: 0;
}

.btns {
  margin: 0 auto;
  width: calc(100% - 60px);
  padding-bottom: 30px;
}

@media 800 {
  .btns {
    position: unset;
    width: 100%;
  }
}

@media 1100 {
  .btns {
    display: none;
  }
}

.btn {
  width: 100%;
}

a:not(:last-of-type) .btn {
  margin-bottom: 15px;
}

.hamburger {
  display: flex;
  align-items: center;
}

@media 1100 {
  .hamburger {
    display: none;
  }
}