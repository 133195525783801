.item {
  margin-bottom: 25px;
}

.row {
  display: flex;
  margin-bottom: 15px;
}

.field {
  --border-width: 2px;
  --padding-horizontal: 15px;
  width: 100%;
}

.label {
  font-size: 16px;
}

.tooltip {
  margin-left: 10px;
}

.input {
  font-family: var(--font-reg);
  font-size: 16px;
  padding: 10px var(--padding-horizontal);
  border: var(--border-width) solid var(--gray-l);
  border-radius: 5px;
  width: calc(100% - ((var(--border-width) * 2) + (var(--padding-horizontal) * 2)));
  resize: none;
  line-height: normal;
}

.input:focus {
  border: var(--border-width) solid var(--pri-02);
  outline-color: var(--pri-02);
}

.phone {
  width: 250px;
}

.sms {
  display: flex;
  justify-content: space-between;
}

.consent {
  font-size: 15px;
  margin-left: 10px;
}

.select {
  width: 250px;
}

.research {
  width: 100%;
}

.tzall {
  color: var(--gray);
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
}

.footer {
  margin: 60px 0;
}

.skip {
  color: var(--pri-02);
}

.prompt {
  margin-bottom: 20px;
  font-family: var(--font-bold);
  font-size: 24px;
  color: var(--black);
}

.prompt:nth-child(2) {
  margin-bottom: 50px;
}