.root {

}

.wrap {
}

.main {
  position: relative;
  overflow-y: scroll;
  max-height: 450px;
  border-top: 1px solid var(--sec-01);
}

.header {
  position: sticky;
  top: 0;
  padding: 0 10px;
  background-color: var(--gray-xl);
  border-bottom: 1px solid var(--sec-01);
  z-index: 3;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.title {
  font-family: var(--font-bold);
  color: var(--gray-d);
  line-height: 40px;
}

.empty {
  font-style: italic;
  padding: 10px;
  height: 35px;
}

.footer {
  padding: 15px 0 0;
}

.btn {
  width: 100%;
  border-radius: 4px;
}

.loader {
  height: 300px;
}