.root {
  --thumb-diameter: 22px;
  --track-height: 3px;

  touch-action: none;
  width: 100%;
  position: relative;

  cursor: pointer;
}

.active-track {
  height: var(--track-height);
}

.inactive-track {
  height: var(--track-height);
}

.thumb {
  bottom: calc(0px - (var(--thumb-diameter) / 2) - (var(--track-height) / 2));
  height: var(--thumb-diameter);
  width: var(--thumb-diameter);
}