.root {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  height: calc(100vh - 785px); /* height of header and footer*/
}

.title {
  font-family: var(--font-bold);
  font-size: 20px;
}

.settings {
  max-width: 600px;
  margin: 10px 0px;
}