.root {
  padding: 20px 0;
}

.wrap {
  margin: 0 auto;
}

.main {
  padding-bottom: 20px;
  min-height: 360px;
}

.paragraph {
  font-size: 19px;
  margin-bottom: 25px;
}

.btn {
  width: 170px;
}

.footer {

}

.role {
  width: 300px;
}

.overview {
  padding-bottom: 20px;
}

.item {
  margin-bottom: 25px;
}

.field {
  --border-width: 2px;
  --padding-horizontal: 15px;
  width: 250px;
}

.select {
  width: 250px;
}

.tooltip {
  margin-left: 10px;
}