.rt-toggle {
  margin-left: 15px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
}

.rt-enabled {
  background-color: var(--gray-l);
}