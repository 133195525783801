.root {

}

.wrap {
  padding: 15px 0 0 0;
}

.main {
  padding: 0 15px;
  margin-bottom: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  margin-bottom: 5px;
}

.title {
  font-size: 19px;
  color: var(--black);
  font-family: var(--font-semibold);
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 28px;
}

.name {
  display: -webkit-box;
  position: relative;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 1.4em;
  color: var(--gray-d);
}

.name:hover {
  color: var(--pri-02);
  text-decoration: underline;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--sec-01);
  height: 40px;
}

.btn {
  font-family: var(--font-semibold);
  color: var(--trolley);
  height: 100%;
  width: 100%;
}

.btn:hover {
  color: var(--pri-02);
}

.btn:active {
  background-color: var(--blue-l);
}

.empty {
  padding: 10px 0;
  color: var(--gray-d);
}

.thumb {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border-radius: 2px;
}

.img {
  width: 18px;
  height: 18px;
  border-radius: 2px;
}

.icon {
  overflow: hidden;
  height: 18px;
  width: 18px;
}