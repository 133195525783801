@value 1100 from 'vars';

.root {
  position: relative;
  border-bottom: 1px solid var(--sec-01);
  width: 100%;
  z-index: 3;
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px;
  height: 70px;
  position: relative;
  z-index: 3;
  background-color: var(--pri-01);
}

.banner {
  height: 20px;
  width: 100%;
  position: absolute;
  background-color: var(--gray-l);
  color: var(--black);
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  z-index: 2;
}

.online {
  bottom: 0;
  transition: bottom 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.offline {
  bottom: -21px;
  transition: bottom 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
 }

.btn {
  composes: btn from './Messages.css';
}

@media 1100 {
  .btn {
    display: none;
  }
}

.back {
  composes: edit from './Messages.css';
}

.user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.info {

}

.name {
  font-family: var(--font-semibold);
  color: var(--black);
}

.name a:hover {
  color: var(--pri-02);
}

.career {
  display: none;
  font-size: 12px;
  font-family: var(--font-reg);
  color: var(--gray-d);
  margin-top: 5px;
}

@media 1100 {
  .career {
    display: block;
  }
}

.avatar {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-family: var(--font-bold);
  color: var(--gray-d);
  margin: 0 10px;
}

.nolink svg {
  cursor: default;
}

.actions {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  z-index: 3;
}

.action {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
}

.action + .action {
  margin-left: 20px;
}

.remove {
  composes: action;
  color: var(--black);
  width: 30px;
  height: 30px;
  stroke-width: 1.5;
  padding-top: 3px;
}

.calendar {
  composes: action;
  color: var(--pri-02);
  width: 30px;
  height: 30px;
  stroke-width: 1.5;
}