.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.row {
  margin-bottom: 15px;
}

.break {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 0;
}

.line {
  height: 1px;
  width: 100%;
  background-color: var(--sec-01);
  max-width: 150px;
}

.sub {
  text-transform: uppercase;
  color: var(--gray-d);
  font-family: var(--font-semibold);
  font-size: 13px;
  margin: 0 10px;
}

.title {
  font-size: 26px;
  line-height: 26px;
  font-family: var(--font-bold);
  color: var(--black);
  margin-bottom: 10px;
}

.subtitle {
  font-family: var(--font-reg);
  font-size: 18px;
  color: var(--gray-d);
}

.subtitle a:hover {
  color: var(--black-l);
  transition: 0.2s ease-out;
  text-decoration: underline;
}

.footer {
  margin-bottom: 20px;
  text-align: center;
}

.footer .subtitle {
  color: var(--gray-m);
  font-size: 15px;
}