.root {
  color: var(--black-l);
}

.title {
  font-size: 15px;
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

.subtitle {
  color: var(--gray);
  font-family: var(--font-reg);
}

.level {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.level:last-child {
  margin-bottom: 0;
}

.label {
  font-size: 14px;
  width: 100px;
  min-width: 100px;

  margin-right: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bar-root {
  width: 100%;
}

.bar-wrapper {
  display: flex;
  align-items: center;
}

.bar {
  height: 20px;
  width: 100%;
  margin-right: 5px;
  border-radius: 2px;
  cursor: pointer;
}

.utility {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 35px;
  min-width: 35px;
  flex-shrink: 0;
}