.root {
  border: 1px solid var(--gray-xl);
  width: 190px;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  transition: box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.poster {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 125px;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding: 0 20px;
}

.title {
  color: var(--black-l);
  font-family: var(--font-semibold);
  font-size: 15px;
  text-align: center;
}

.light-green {
  background-color: #dcf3f3;
}

.light-blue {
  background-color: #ddeffe;
}

.light-red {
  background-color: #f7c0cd;
}

.custom {
  color: #1271c4;
}

.root:hover {
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
}

.plus {
  color: #1271c4;
  height: 32px;
  width: 32px;
}

.net-promoter {
  background-color: #e0eaf3;
}

.employee-satisfaction {
  background-color: #f7dad4;
}

.pricing-strategy {
  background-color: #ecf4df;
}

.mce {
  background-color: #e1e2fa;
}

.market-analysis {
  background-color: #FFE2C8;
}

.external {
  background-color: var(--gray-xl);
}

.tpp {
  background-color: #DCF3F3;
}