.wrap {
  display: block;
}

@media(min-width: 800px) {
  .wrap {
    padding: 0 25px;
  }
}

.title {
  margin: 0 0 30px 0;
  text-align: center;
}

.body {
  color: var(--gray-d);
  font-family: var(--font-reg);
  font-size: 14px;
}

.section {
  margin: 0 0 25px 0;
  margin: 30px 0;
}

.section:last-child {
  margin: 0;
}

@media(min-width: 800px) {
  .section {
    margin: 45px 0;
  }
}

@media (min-width: 600px) {
  .wrap {
    padding: 0 0 10px 0;
  }
}