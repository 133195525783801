.modal {
  overflow-y: unset;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100px;
}

.dropdown {
  width: 300px;

}