.root {
  max-width: var(--navbar-width);
  width: 100%;
  margin: 0 auto;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 10px;
}

.table-wrap {
  margin: 20px 0;
}