.instruction {
  margin-bottom: 10px;
}

.external-doc {
  font-size: 14px;
  color: var(--gray-m);
  margin-bottom: 10px;
}

.value-container {
  margin-bottom: 10px;
}

.external-link {
  font-family: var(--font-bold);
  display: inline-flex;
  align-items: center;
}

.linkContainer {
  display: flex;
  align-items: center;
}

.external-icon {
  margin-left: 2px;
}

.copy-container {
  display: flex;
  align-items: center;
}

.label {
  font-family: var(--font-bold);
}

.copy-value {
  background: var(--gray-xl);
  border: 1px solid var(--gray-m);
  border-radius: 5px;
  padding: 5px 10px;
  white-space: nowrap;
}

.copy {
  cursor: pointer;
  margin-left: 5px;
}

.auth-values {
  margin-top: 20px;
}

.delete {
  margin-top: 50px;
}

.warning {
  font-family: var(--font-bold);
  color: var(--pri-04);
  margin-bottom: 10px;
}