.root {

}

.wrap {
  padding: 40px 0;
}

.main {
  display: flex;
  position: relative;
}

.header {
  font-family: var(--font-bold);
  font-size: 24px;
  color: var(--black);
  margin-bottom: 20px;
}

.subheader {
  font-family: var(--font-reg);
  font-size: 18px;
  color: var(--black-l);
  margin-bottom: 20px;
}

.steps {
  width: 220px;
  padding: 0 20px;
}

.sidebar {
  position: fixed;
}

.view {
  margin: 0 auto 0 225px;
  max-width: 750px;
  width: 100%;
}