.root {

}

.wrap {
  padding-bottom: 70px;
}

.main {
  background-color: #e7e6e6;
  max-width: 440px;
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
  padding: 15px 30px;
  line-height: 26px;
  font-size: 18px;
}

.highlight {
  color: var(--pri-02);
  font-family: var(--font-bold);
}

.em {
  font-family: var(--font-bold);
}