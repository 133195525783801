.root {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  overflow: auto;
  background-color: var(--gray-xl);
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.img {
  max-width: 100%;
  box-sizing: border-box;
}