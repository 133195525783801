.tag-root {
  display: flex;
  align-items: center;
}

.tag-badge:hover, .tag-focused {
  box-shadow: 0 2px 6px 2px rgb(60 64 67 / 15%);
  transition: opacity 0.5s ease-in-out;
}

.placeholder-tag {
  display: none;
}

.edit-icon {
  color: var(--gray-m);
  cursor: pointer;
  margin-left: 5px;
}

.popper-container {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px;
  z-index: 3;
}