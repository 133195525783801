@value 800, 1200 from 'vars';

.root {
  display: inline-block;
  height: 56.25%;
  width: 100%;
  margin-bottom: 25px;
  transition: transform 300ms ease 100ms;
}

@media 800 {
  .wrap {
    margin-right: 30px;
  }
}

.main {
  margin-right: 10px;
}

.header {
  composes: header from './Discovery.css';
  display: flex;
  justify-content: space-between;
}

.category {

}

.hidden {
  display: none;
}

.visible {
  display: block;
}

.info {
  display: none;
  overflow: hidden;
}

.details {
  display: block;
}

.details .main {
  height: 42px;
  width: 42px;
  margin-right: 15px;
}

@media 800 {
  .details {
    display: none;
  }

  .info {
    display: flex;
  }
}

.preview {
  position: relative;
  display: flex;
  overflow: hidden;
  padding-top: 56.25%;
  margin-bottom: 16px;
  border-radius: 9px;
  background-color: rgba(0, 0, 0, 0.04);
}

@media (min-width: 1265px) {
  .preview {
    max-height: 180px;
  }
}

.row {

}

.items {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  position: relative;
  transition: transform 100ms ease-out;
}

@media 800 {
  .items {
    flex-direction: row;
  }
}

.details .title {
  margin-bottom: 25px;
}

.title {
  font-family: var(--font-semibold);
  font-size: 16px;
  color: var(--black);
  margin-bottom: 15px;
}

.avatar {
  border-radius: 50%;
  height: 42px;
  width: 42px;
}

.author {
  font-size: 15px;
  color: var(--black-l);
  margin-bottom: 10px;
}

.link {
  color: var(--pri-02);
}

.creator {
  display: flex;
}

.desc {

}

.name {
  font-size: 15px;
  color: var(--black);
  line-height: 18px;
  margin-bottom: 2px;
}

.position {
  font-size: 12px;
  color: var(--gray-d);
}

.headline {

}

@media 1200 {
  .headline {
    width: calc(284px - 55px);
  }
}

.buttons {
  display: none;
}

@media 800 {
  .buttons {
    display: flex;
  }
}