.add {
  margin-top: 15px;
}

.label {
  margin: 15px 0 10px 0;
  font-family: var(--font-semibold);
}

.currencies {
  width: 150px;
}

.drug-price {
  width: 250px;
}