.clickable:hover {
  color: var(--hyperlink);
  text-decoration: underline;
}

.links-available {
  color: var(--gray);
  font-family: var(--font-reg);
}

.links-low {
  composes: links-available;
  color: var(--pri-04);
}

.secret {
  display: flex;
  margin-bottom: 6px;
}

.secret-label {
  font-style: italic;
  width: 95px;
}

.link {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.link:hover .copy-icon {
  opacity: 100;
}

.copy-icon {
  opacity: 0;
}

.download-container {
  display: flex;
  align-items: center;
}

.icon {
  transition: opacity 100ms ease;
  cursor: pointer;
  color: var(--black-l);
  margin-left: 10px;
}

.disabled-icon {
  cursor: not-allowed;
}

.tooltip {
  margin-left: 10px;
}