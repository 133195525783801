.x {
  height: 22px;
  width: 22px;
  color: var(--gray-d);
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  padding: 2.5px;
  margin: 0;
  flex-shrink: 0;
}

.close:hover {
  background-color: var(--gray-xl);
}

.close svg {
  transition: all 0.2s ease;
  color: var(--black-l);
}