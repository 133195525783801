.row {
  margin-bottom: 15px;
}

.row-text {
  font-family: var(--font-semibold);
  margin-bottom: 5px;
}

.option {
  margin-bottom: 10px;
}