.root {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--pri-04);
  overflow: hidden;
  cursor: pointer;
}

.root:hover .trash {
  background-color: var(--pri-04);
  color: var(--pri-01);
}

.trash {
  padding: 5px;
  border-radius: 50%;
  transition: all 0.2s ease;
  color: var(--pri-04);
  border: 2px solid var(--pri-04);
}