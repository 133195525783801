.btn {
  padding: 8px 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--gray);
  font-family: var(--font-semibold);
}

.btn:hover {
  background-color: var(--gray-xl);
  color: var(--black);
}