.root {
  display: flex;
  margin: 0 0 30px 0;
}

.label {
  color: var(--black-l);
  font-family: var(--font-semibold);
  margin: 0 12px 0 0;
}

.dropdown {
  min-width: 130px;
}