.table {
  border-spacing: 0;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
}

.table tbody {
  font-size: 14px;
}

.table th, .table td {
  border: 1px solid var(--gray-l);
  border-bottom: none;
  border-right: none;
}

/* borders */
.table tr:last-child td {
  border-bottom: 1px solid var(--gray-l);
}

.table tr td:last-child {
  border-right: 1px solid var(--gray-l);
}

.table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 2px;
}
.table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 2px;
}
.table thead th {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}


.table th {
  color: var(--pri-01);
  background-color: var(--sec-02);
  font-family: var(--font-bold);
}

.table th, .table td {
  padding: 8px;
}

.table td {
  padding-right: 30px;
}

.table tr td:first-child {
  font-family: var(--font-semibold);
  background-color: var(--gray-xl);
  color: var(--gray-d);
  box-sizing: border-box;
  width: 195px;
}

.table tr td:last-child {
  box-sizing: border-box;
  min-width: 315px;
  font-size: 13px;
}

.img {
  max-width: 100%;
  min-height: 200px;
}
