.root {

}

.input {
  width: 100%;
}

.root .input .select.disabled,
.disabled {
  color: var(--gray-l);
  -webkit-text-fill-color: var(--gray-l);
}

.root .input .select,
.root .input .select:focus {
  border: none;
  border-radius: 0;
  padding-left: 0;
  font-family: var(--font-reg);
}

.root .input .select.placeholder {
  color: var(--gray);
}

.li li {
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--gray-d);
}