.table {
  border-spacing: 0;
  --border-radius: 4px;
}

.table th {
  border-top: 1px solid var(--sec-01);
}

.table th, .table td  {
  border-left: 1px solid var(--sec-01);

  border-bottom: 1px solid var(--sec-01);
}

.table td:last-child, .table th:last-child {
  border-right: 1px solid var(--sec-01);
}

.table tr:last-child td:first-child {
  border-bottom-left-radius: var(--border-radius);
}

.table tr:last-child td:last-child {
  border-bottom-right-radius: var(--border-radius);
}


.table th:first-child {
  border-top-left-radius: var(--border-radius);
}

.table th:last-child {
  border-top-right-radius: var(--border-radius);
}

.table thead {
  background-color: #f5f5f5;
}

.table th, .table td {
  padding: 10px;
  font-size: 14px;
}

.table th {
  font-family: var(--font-semibold);
  color: var(--black-l);
  text-align: left;
}

.table td {
  font-family: var(--font-reg);
  color: var(--gray-d);
}




.progress td:nth-child(2), .progress th:nth-child(2) {
  text-align: center;
  box-sizing: border-box;
  width: 70px;
}

.progress td:nth-child(1), .progress th:nth-child(1) {
  box-sizing: border-box;
  width: 150px;
}

@media (min-width: 800px) {

  .basic td:nth-child(1), .basic th:nth-child(1) {
    box-sizing: border-box;
    min-width: 250px;
  }
}

.basic td:nth-child(3), .basic th:nth-child(3),
.basic td:nth-child(4), .basic th:nth-child(4)
{
  text-align: center;
  box-sizing: border-box;
  width: 70px;
}

.terminations {
  width: 100%;
}

.terminations td:first-child, .terminations th:first-child {
  min-width: 200px;
}

.terminations td:nth-child(3), .terminations th:nth-child(3) {
  text-align: center;
  box-sizing: border-box;
  width: 70px;
  max-width: 70px;
}

.empty {
  color: var(--gray-m);
}

.limit-hit {
  /* color: var(--pri-04); */
}