.popover {
  padding: 15px;
  box-sizing: border-box;
  width: 350px;
  font-size: 15px;
}

.ordinal {
  font-family: var(--font-semibold);
  width: 30px;
  min-width: 30px;
}

.item {
  margin-bottom: 5px;
}

.condition {
  display: flex;
}

.condition .ordinal {
  color: var(--gray-d);
}

.question {
  display: flex;
  margin-bottom: 8px;
}

.question .ordinal {
  color: var(--pri-02);
}

.divider {
  margin: 10px 0;
  position: relative;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
}

.line {
  height: 1px;
  width: 100%;
  background-color: var(--gray-xl);
}

.and {
  font-size: 12px;
  width: 30px;
  padding: 0 5px;
  position: absolute;
  left: calc(50% - 20px);
  z-index: 1;
  background-color: var(--pri-01);
  color: var(--gray-d);
}
