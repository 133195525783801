.root {

}

.select {

}

.menu {

}

.root.menu {
  padding: 0;
}

.options {

}

.root.paper {
  border-radius: 4px;
  background-color: var(--pri-01);
  box-shadow: var(--shadow);
}

.paper li {
  font-family: var(--font-reg);
  color: var(--gray-d);
  font-size: 16px;
  background-color: var(--pri-01);
  margin: 0;
}

.paper li:global(.Mui-selected):hover,
.paper li:global(.Mui-selected),
.paper li:hover {
  background-color: var(--blue-l);
}

.paper li span:global(.MuiTouchRipple-root) {
  visibility: hidden;
}

.root.options :global(.MuiPaper-root) {

}

.root.options :global(.Mui-selected) {

}

.input {

}

.select.input .select.placeholder {
  color: var(--gray);
}

.select.input .select.invalid {
  border: 2px solid var(--pri-04);
}