.tags {
  --gap: 8px;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
}

.tag {
  border-color: var(--sec-01);
  color: var(--gray-d);
  margin: var(--gap) 0 0 var(--gap);
}