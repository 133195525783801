.root {
  color: var(--pri-02);
  font-family: var(--font-semibold);
  margin-bottom: 15px;
}

.condition-root {
  color: var(--pri-02);
  font-family: var(--font-semibold);
  width: 30px;
  margin-right: 10px;
}