.root {
  font-family: var(--font-semibold);
  color: var(--black);
  font-size: 16px;
  padding: 25px;

  display: flex;
  justify-content: space-between;
}

@media (min-width: 900px) {
  .root {
    padding: 15px 25px;
    min-height: 40px;
  }
}