.root {

}

.wrap {
  margin-bottom: 40px;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--gray-l);
}

.select {
  max-width: 450px;
}