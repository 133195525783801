.empty {
  color: var(--gray-d);
}

.item {
  margin-bottom: 15px;
}

.title {
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

.value {

}