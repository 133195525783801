.item {
  margin-bottom: 25px;
}

.field {
  --border-width: 2px;
  --padding-horizontal: 15px;
  width: 250px;
}

.select {
  width: 250px;
}