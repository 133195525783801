.root {
  padding: 15px;
}

.author {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.author .name {
  font-size: 14px;
  margin-left: 10px;
  font-family: var(--font-semibold)
}