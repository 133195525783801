.root {
  width: 450px;
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.main {

}

.body {
  margin-bottom: 20px;
}

.row {
  margin-bottom: 10px;
}

.input {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
}

.input:nth-child(2) {
  margin-bottom: 0;
}

.submit {
  font-size: 18px;
  width: 300px;
  height: 50px;
  border-radius: 3px;
}

.error {
  position: relative;
  text-align: center;
  height: 25px;
}

.login {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.login .error {
  position: relative;
  top: 0;
}

.padtop {
  composes: wrap;
  padding-top: 15px;
}

.vistor.btn {
  width: 100%;
}

.page {
  padding-top: 40px;
}

.link {
  color: var(--hyperlink);
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.forgot {
  font-family: var(--font-bold);
  color: var(--pri-02);
  text-align: right;
  margin: 5px 0 10px 0;
}

.visitor.row.input:nth-child(2)  {
  height: 40px;
}

.login-link {
  composes: link;
  text-align: center;
}

.other-strategy {
  font-family: var(--font-semibold);
  font-size: 12px;
  color: var(--gray-d);
  text-align: center;
  margin-top: 30px;
}