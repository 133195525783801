@value 800 from 'vars';

.root {
  max-width: 800px;
  margin: 0px auto;
  padding: 32px 32px 16px;
  background-color: var(--pri-01);
  box-sizing: border-box;
}

.wrong {
  color: var(--black);
  background-color: var(--reddish-white);
  border-radius: 15px;
  margin: 40px 0 0;
  padding: 32px;
  font-size: 16px;
}

@media 800 {
  .wrong {
    font-size: 18px;
  }
}

.wrong h1 {
  display: flex;
  font-size: 20px;
  font-family: var(--font-bold);
  line-height: 28px;
  margin-bottom: 16px;
  
}

@media 800 {
  .wrong h1 {
    font-size: 24px;
    line-height: 32px;
  }
}

.email {
  font-family: var(--font-bold);
  text-decoration: underline;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.steps {
  margin: 10px 0 0 0;
}

.steps li {
  margin: 0;
  line-height: 28px;
}

@media 800 {
  .steps li {
    line-height: 34px;
  }
}

.frown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.frown svg {
  color: var(--black);
  opacity: 0.4;
  height: 30px;
  width: 30px;
}

.logo {
  height: 30px;
  width: 155px;
}

.error {
  border: 1px solid var(--gray-xl);
  border-radius: 16px;
  margin: 40px auto;
}

.error-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px 32px;
  user-select: none;
}

.error-toggle-icon {
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
}

.error-code {
  background-color: var(--gray-xl);
  box-shadow: rgb(238, 236, 241) 0px -1px 0px 0px;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  padding: 32px;
  overflow: auto;
}

.id {
  font-size: 13px;
  margin-top: 20px;
}

.block {
  font-size: 16px;
  line-height: 22px;
  margin-top: 15px;
}