@value 800 from 'vars';

.root {
  height: 100%;
}

.wrap {
  padding: 15px 25px 25px 25px;
}

.main {
  margin: 0 auto;
  max-width: 1280px;
}

.skeleton {
  padding: 20px;
  background-color: var(--gray-bg);
}

.items {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media 800 {
  .item {
    width: 33.3333333333%;
  }
}

@media (min-width: 1265px) {
  .item {
    width: 25%;
  }
}

.header {
  composes: header from '@screens/Discovery.Main/style/Discovery.css';
  display: flex;
  justify-content: space-between;
}

.name {
  font-size: 15px;
  color: var(--black);
  line-height: 18px;
  margin-bottom: 2px;
}