.root {
  width: 450px;
  margin: 0 auto;
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}