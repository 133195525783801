.anchor {
  cursor: pointer;
}

.check-label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.check {
  color: var(--pri-02);
  margin-left: -10px;
}

.check-spacer {
  margin-left: -10px;
}