.root {
  padding: 20px;
}

.wrap {
  height: 200px;
  width: 100%;
  border: 1px solid var(--sec-01);
  border-radius: 5px;
  margin-bottom: 5px;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}

.label {
  font-family: var(--font-semibold);
  color: var(--pri-02);
  font-size: 18px;
  line-height: 22px;
}

.input {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}