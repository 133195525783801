.root {
  border-bottom: 2px solid var(--sec-01);
  margin-bottom: 15px;
}

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 18px;
  font-family: var(--font-semibold);
  line-height: 40px;
}