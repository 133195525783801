.btn {
  color: var(--pri-02);
  cursor: pointer;
  border-radius: 50%;
  width: 28px;
  height: 28px;
}

.plus {
  height: 28px;
  width: 28px;
}

.btn:hover {
  background-color: var(--pri-02);
  color: var(--pri-01);
}

.btn:hover circle {
  color: var(--pri-02);
}