@value 800, 1200 from 'vars';

.root {

}

.wrap {

}

.buttons {
  display: flex;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--gray-d);
  border-radius: 50%;
  color: var(--gray-d);
  height: 37px;
  width: 37px;
}

.btn:not(first-child) {
  margin-left: 20px;
}

.btn:disabled {
  opacity: 0.3;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
}


.link {

}

.items {

}

.slider {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.seeall {
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 100ms ease-out;
}

.anchor {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--blue-l);
  color: var(--pri-02);
  font-size: 14px;
  border-radius: 9px;
  font-family: var(--font-bold);
}