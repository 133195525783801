.marker {
  box-sizing: border-box;
  /* color: var(--pri-02); */
  box-shadow: rgb(92 153 255 / 5%) 0px 0px 0px 50px inset,
    rgb(92 153 255 / 5%) 0px 0px 0px 50px inset,
    rgb(92 153 255 / 5%) 0px 0px 0px 50px inset;
  border-radius: 5px;
  z-index: -1;
  font-size: 14px;
  line-height: 17px;
}