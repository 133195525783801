.root {

}

.wrap {
  padding: 30px 0;
}

.main {
  display: flex;
  gap: 15px;
}

.header {

}

.item {
  position: relative;
  height: 165px;
  width: 165px;
  border: 1px solid var(--sec-01);
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
}

.item:hover {
  background-color: var(--blue-xl);
}

.icon {
  display: flex;
  justify-content: center;
}

.svg {
  color: var(--pri-02);
}

.title {
  color: var(--pri-02);
  font-family: var(--font-semibold);
  font-size: 18px;
  margin: 15px 0;
}

.desc {
  font-size: 15px;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}