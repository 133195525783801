@value 800, 1000, 1100 from 'vars';

.root {
  background-color: #f9f9f9;
}

.wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
}

.main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
}

@media 800 {
  .main {
    flex-direction: row;
  }
}

@media 1000 {
  .main {
    flex-direction: row;
    margin: 30px 0;
    max-width: inherit;
  }
}

.item {
  width: 100%;
  text-align: center;
}

@media 800 {
  .item {
    width: 50%;
    text-align: center;
  }
}

.item p {
  color: var(--black);
  text-align: center;
  margin: 0 0 6px 0;
}

@media 800 {
  .item p {
    text-align: left;
  }
}

@media 800 {
  .copy {
    margin-left: 40px;
    width: calc(50% - 40px);
  }
}

@media 1000 {
  .copy {
    margin-left: 60px;
    width: calc(50% - 60px);
  }
}

.link {
  color: var(--pri-02);
  font-family: var(--font-semibold);
}

.icon {
  width: 40%;
}

.logos {
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  margin: 25px 30px 25px 20px;
  background-color: var(--black);
  width: 1px;
  align-self: stretch;
}

.taller {
  margin: 5px 30px 5px 20px;
}

.ghab {

}

.sentiment {
  display: flex;
  justify-content: center;
}

.logo {

}

.whitespace {
  padding: 5% 3%;
}

.img {
  width: 100%;
}