.item {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.item:last-child {
  margin-bottom: 0
}

.input {
  width: 75px;
}

.title {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  width: 160px;
}

.warning {
  color: var(--pri-04);
  margin-top: 15px;
}