.root {
}

.accordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--sec-01);
  margin-bottom: 20px;
  transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
}

.collapsed {
  composes: accordion;
  /* min-height: 60px; */
}

.expanded {
  composes: accordion;
  /* min-height: 75px; */
}

.empty {
  composes: collapsed;
  cursor: default;
}

.empty .toggle {
  visibility: hidden;
}

.title {
  font-family: var(--font-semibold);
  color: var(--gray-d);
}

.toggle {
  position: relative;
  /* width: 35px;
  height: 35px; */
  /* padding: 3px; */
  border-radius: 50%;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 20ms;
  color: var(--black-l);
}

.ripple {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 0;
  overflow: hidden;
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  color: var(--sec-01);
}

.toggle:active .enter {
  opacity: 0.3;
  animation: ripple-enter 550ms cubic-bezier(0.4, 0, 0.2, 1);
  animation-duration: 550ms;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  transform: scale(1);
  animation-name: ripple-enter;
  /* width: 35px;
  height: 35px; */
  top: -0.5px;
  left: -0.5px;
}

.toggle:active .exit {
  animation: ripple-exit 550ms cubic-bezier(0.4, 0, 0.2, 1);
  animation-duration: 550ms;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: ripple-exit;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 1;
  border-radius: 50%;
  background-color: currentColor;
}

.expanded .toggle {
  transform: rotate(180deg);
  background-color: var(--gray-xl);
}

.icon {
  position: relative;
  z-index: 2;
  stroke-width: 2;
}

.content {
  min-height: 0px;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.hidden {
  composes: content;
  height: 0px;
  overflow: hidden;
  transition-duration: 228ms;
}

.count {
  font-family: var(--font-reg);
}

.entered {
  composes: content;
  height: auto;
  overflow: visible;
}

@keyframes ripple-enter {
  0% {
    opacity: 0.1;
    transform: scale(0);
  }
  100% {
    opacity: 0.3;
    transform: scale(0);
  }
}

@keyframes ripple-exit {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
