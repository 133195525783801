.root {

}

.wrap {

}

.main {

}

.footer {
  padding: 40px 0 0;
}

.btn {
  min-width: 140px;
}