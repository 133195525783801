.root {
  width: 100%;
}

.category {
  margin-bottom: 10px;
}

.row {
  display: flex;
  align-items: center;
}

.name {
  width: 100%;
  margin-right: 15px;
}

.label {
  width: 100px;
}

.message {
  width: 100%;
  margin-top: 10px;
}

.remove {
  margin-left: 10px;
  display: flex;
  flex-shrink: 0;
  color: var(--pri-04);
  cursor: pointer;
}

.add {
  margin-bottom: 10px;
}

.add-message {
  margin-top: 10px;
}

.bold {
  font-family: var(--font-semibold);
}

.input {
  font-family: var(--font-semibold);
}