.root {
  padding: 0 25px;
}

.tick {
  width: 10px;
  height: 25px;
  background-color: var(--pri-02);
  position: absolute;
  top: 0;
}

.spectrum-row {
  display: flex;
  align-items: center;
}

.spectrum {
  position: relative;
  background-color: var(--gray-xl);
  width: 100%;
  height: 25px;
  margin: 0 10px;
  cursor: pointer;
}

.row-data {
  margin-bottom: 10px;
}

.row-avg {
  margin-bottom: 5px;
  color: var(--gray-m);
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
}

.row-name {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  margin-bottom: 5px;
}