.root {
  margin: 0 auto;
  padding: 15px;
  margin-top: 25px;
  text-align: center;
}

.header {
  font-family: var(--font-bold);
  font-size: 24px;
  margin-bottom: 30px;
}

.subtitle {
  margin: 20px 0;
}

.link a {
  display: inline-block;
  cursor: pointer;
  font-size: 18px;
}

.link a:after {
  content: '>';
  padding-left: 5px;
}

.profile {
  composes: link;
  margin-top: 80px;
  font-family: var(--font-semibold);
  color: var(--pri-02);
}

.dashboard {
  composes: link;
  margin-top: 25px;
}