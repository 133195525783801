.root {

}

.wrap {
  padding: 20px 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.btn {
  width: 150px;
  height: 40px;
  padding: 0;
}

.parent {

}

.child {
  background: var(--gray-xxl);

}

.hidden {

}

.tbody {
  height: auto;
  max-height: none;
  overflow-y: visible;

  border-top: none;
  border-bottom: none;
}

.tr {
  display: flex;
  justify-content: space-between;
  border-top: none;
}

.thead .tr,
.tbody .tr {
  border-bottom: 1px solid var(--gray-l);
}

.tbody .tr.last {
  border-bottom: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.thead .tr {
  min-height: 45px;
}

.hidden div[role="cell"] {
  opacity: .5;
}

.full {
  width: 100%;
}

.tbody div[role="cell"] {
  padding: 5px 10px;
  min-height: 40px;
}