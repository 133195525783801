.root {
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  height: calc(100vh - var(--navbar-height) - 20px - 40px - 80px);

  font-size: 16px;
}

.title {
  font-family: var(--font-bold);
  font-size: 24px;
  margin-bottom: 20px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.nav-root {
  width: 700px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
}

.row-action {
  cursor: pointer;
  user-select: none;
}

.add-row {
  composes: row-action;
  color: var(--pri-03);
}

.remove-row {
  composes: row-action;
  color: var(--pri-04);
}

.header {
  display: flex;
  gap: 10px;
  align-items: center;
}

.cell {
  font-size: 16px;
}