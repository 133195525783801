.root {
  display: flex;
  align-items: center;
}

.circle {
  transition: 0.4s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;

  fill: transparent;
}