.card {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 0;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
}

@media (min-width: 425px) {
  .card {
    min-height: calc(100vh - var(--navbar-height) - 170px);
    height: calc(100vh - var(--navbar-height) - 170px);
  }
}

@media (max-width: 425px) {
  .card {
    border-bottom: none;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter {
  margin-left: 15px;
}

.body {
  margin-bottom: 45px;
}

.td {
  height: 55px;
}

.footer {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: var(--pri-01);
  border-top: 1px solid var(--sec-01);
  border-bottom: 1px solid var(--sec-01)
}

.rows {
  display: none;
}

@media (min-width: 425px) {
  .body {
    height: calc(100vh - var(--navbar-height) - 155px - 170px); /* 60-card header, 40-header, 45-footer, 120px margin  */
    overflow-y: auto;
    margin-bottom: 0;
  }

  .footer {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    position: unset;
    width: unset;
  }

  .rows {
    display: flex;
  }
}

.dragging {

}

.dragging .body {
  background-color: var(--blue-xl);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%230A68EFFF' stroke-width='4' stroke-dasharray='6%2c 10' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 3px;
}

.pagination-root {
  display: flex;
  justify-content: flex-end;

  padding: 10px;
}

.page-size {
  display: flex;
  margin-right: 25px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons .selector {
  display: flex;
  justify-content: center;
}

.buttons .selector:not(:last-child) {
  margin-right: 20px;
}

.thead {
  background-color: var(--gray-xl);
  border-top: 1px solid var(--gray-l);
  border-bottom: 1px solid var(--gray-l);
}

.tr {
  border-bottom: 1px solid var(--gray-l);
}

.th {
  font-size: 16px;
  font-family: var(--font-reg);
  font-weight: 800;
  color: var(--black);
}

.title {
  font-size: 20px;
  font-family: var(--font-semibold);
  color: var(--black);
}

.btn {
  width: 150px;
  height: 40px;
  padding: 8px 0;
  border-radius: 5px;
  box-sizing: border-box;
}