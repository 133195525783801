.root {
  display: flex;
  align-items: center;
}

.text {
  font-size: 14px;
}

.img {
  margin-right: 10px;
  height: 18px;
}




.btn {
  border: 1px solid var(--gray);
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 10px;
  margin: 0 0 10px 0;
  min-width: 215px;
}

.btn:last-child {
  margin: 0;
}

.btn-loading {
  composes: btn;
  cursor: not-allowed;
  opacity: 0.5;
}

.btn-img {
  width: 22px;
  height: 22px;
}

.add-text {
  display: flex;
  align-items: center;
  color: var(--gray-d);
  font-family: var(--font-bold);
  font-size: 15px;
  line-height: 16px;
  margin: 0 0 0 14px;
  text-align: left;
}

.type-container {
  margin-bottom: 35px;
}

.type-container:last-child {
  margin-bottom: 0;
}

.connection {
  display: flex;
  align-items: center;
}

.connection-text {
  color: var(--gray-d);
  font-family: var(--font-reg);
  font-size: 15px;
  margin: 0 10px 0 14px;
}

.trash {
  cursor: pointer;
  color: var(--gray-d);
  height: 18px;
  width: 18px;
}

.trash svg {
  height: inherit;
  width: inherit;
}