.role-btn {
  border: none;
  font-family: var(--font-semibold);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: unset;

}

.role-btn.open, .role-btn:hover {
  background-color: var(--gray-xl);
}

.chevron {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--gray-d);
  margin-left: 8px;
}

.menu {
  width: 150px;
  background-color: var(--pri-01);
}

.item {
  padding: 10px 20px 10px 10px;
  cursor: pointer;
}

.item:hover {
  background-color: var(--blue-l);
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--gray-l);
}

.popper {
  z-index: calc(var(--z-index-modal) + 1);
}