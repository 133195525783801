.root {
  --width: var(--navbar-width);
  --prompt-width: 232px;
  max-width: var(--navbar-width);
  margin: 0 auto;
  position: relative;
  padding: 15px;
  box-sizing: border-box;

  min-height: calc(100vh - var(--navbar-height));
}

@media (min-width: 800px) {
  .root {
    padding: 20px 25px;
  }

  .wrap {
    display: flex;
  }
}

.main {
  flex-grow: 1;
}

.back {
  margin-bottom: 15px;
}

.breadcrumbs {
  margin-bottom: 15px;
}

.prompt {
  display: none;
}

@media (min-width: 1175px) {
  .prompt {
    display: block;
    position: absolute;
    top: 20px;
    width: var(--prompt-width);

    left: calc(750px - (var(--prompt-width) / 2) + ((100vw - 750px) / 4));
  }
}

.sub-section-container {
  border: 1px solid var(--sec-01);
  border-radius: 5px;
}

.sub-section-container > * {
  padding: 20px;
  border-bottom: 1px solid var(--sec-01);
  border-top: none;
}

.sub-section-container > *:last-child {
  border-bottom: none;
}

.tags-container {
  display: block;
}

@media(max-width: 1200px) {
  .tags-container {
    display: none;
  }
}


.tags-container {
  border: 1px solid var(--sec-01);
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-left: 20px;
  flex: 0 0 300px;
  box-sizing: border-box;
  align-self: flex-start;
}