.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  min-height: 100vh;
  background: linear-gradient(0deg, rgba(85, 115, 177, 0.3), rgba(85, 115, 177, 0.3)), url('static/images/bg-login.png');
  background-position: center;
  background-size: cover;
}

.wrap {
  padding: 30px 20px 0;
  width: 100%;
  max-width: 500px;
  background-color: var(--pri-01);
  border-radius: 8px;
}

.main {
  padding: 20px;
}

.header {

}

.logo {

}

.title {
  font-size: 22px;
  color: var(--black);
  font-family: var(--font-bold);
}

.row {
  margin-bottom: 20px;
}

.email {
  color: var(--pri-02);
  text-decoration: underline;
  margin-left: 5px;
}

.link {
  color: var(--pri-02);
  text-decoration: underline;
  margin: 0 5px;
  cursor: pointer;
}

.footer {
  padding: 20px 0;
}

.btn {
  width: 100%;
}