.message {
  margin-bottom: 20px;
}

.items {

}

.item {
  margin-bottom: 15px;
}

.name {
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

.error {
  margin-left: 15px;
}