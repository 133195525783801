.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.title {
  font-family: var(--font-bold);
  font-size: 14px;
}

.lock {
  display: flex;
  margin: 0 0 0 2px;
}

.lock,
.lock svg {
  color: var(--sec-03);
  height: 18px;
  width: 18px;
}