@value 800, 1000 from 'vars';

.root {

}

.wrap {
  padding: 40px 20px 50px;
}

.main {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.header {
  font-size: 28px;
  font-family: var(--font-bold);
  color: var(--black-d);
  max-width: 400px;
  margin: 0 auto 40px;
  text-align: center;
}

@media 800 {
  .header {
    max-width: 610px;
  }
}

@media 1000 {
  .header {
    font-size: 30px;
  }
}

.btn {
  width: 150px;
}