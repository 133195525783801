.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: wrap;

  max-height: unset;
  line-height: unset;
}

.tag {
  margin-right: 5px;
}