.root {

}

.wrap {
  display: flex;
  align-items: center;
  height: var(--height-workspace-header);
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.details {
  color: var(--black-l);
}

@media (min-width: 425px) {
  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.header {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px 0;
}

.title {
  max-width: 700px;
}

.subtitle {
  display: flex;
  align-items: center;
  line-height: 15px;
}
