.root {
  height: 100%;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--navbar-height) - 40px);
  margin: 20px 0;
  padding: 0 var(--gutter-width);
}

.main {
  display: flex;
  justify-content: space-around;
  height: 100%;
}

.section {
  width: 100%;
  margin: 0 auto;
  min-height: 500px;
  box-shadow: none;
  
  position: relative;
}
.section a + .section a {
  border-top: 1px solid var(--gray-xl);
}

@media (min-width: 900px) {
  .section {
    border: 1px solid var(--sec-01);
    border-radius: 5px;
  }

  .section:first-child {
    margin-right: 15px;
  }

  .section:nth-child(2) {
    display: block;
  }
}

.scroll {
  height: 100%;
}

@media (min-width: 900px) {
  .scroll {
    overflow-y: auto;
  }
}

.main .section:first-child {
  display: block;
}

.main .section:nth-child(2) {
  display: none;
}

.main.browsing .section:first-child {
  display: none;
}

.main.browsing .section:nth-child(2) {
  display: block;
}

@media (min-width: 900px) {
  .main .section:first-child,
  .main .section:nth-child(2),
  .main.browsing .section:first-child,
  .main.browsing .section:nth-child(2) {
    display: block;
  }
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid var(--sec-01);
  height: 70px;
}

.h2 {
  color: var(--black-l);
  font-size: 22px;
  line-height: 35px;
  font-family: var(--font-semibold);
}

.browsing {

}

.opt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chevron {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: var(--black-l);
}

.back {
  height: 35px;
  margin-right: 10px;
}

@media (min-width: 900px) {
  .back {
    display: none;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}
