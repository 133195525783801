.root {

}

.wrap {

}

.main {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 15px 50px;
}

.header {
}

.input {
  border: 1px solid var(--sec-01);
  border-radius: 4px;
  padding: 7px;
  font-size: 16px;
  font-family: var(--font-reg);
  width: 300px;
}