@value 800, 1100, 1200 from 'vars';

.desktop {
  display: none;
}

@media 1200 {
  .desktop {
    display: block;
  }
}

.header {
  margin: 0 20px 20px 20px;
}

@media 1200 {
  .header {
    margin: 0 0 15px 0;
  }
}

.divider {
  margin-bottom: 20px;
  background-color: #E8E8E8;
  width: unset;
}

.card {
  border: 1px solid var(--sec-01);
  border-radius: 4px;
}

@media 1200 {
  .card {
    width: 100%;
  }
}