.root {
  box-sizing: border-box;
  width: 100%;
  border: 2px solid var(--gray-l);
  border-radius: 7px;
  padding: 8px;
  outline: none;
  resize: none;
  height: 90px;
  line-height: 22px;
  font-family: var(--font-reg);
  font-size: 16px;

  appearance: none;
}

.root:focus {
  border-color: var(--pri-02);
}

.root::placeholder {
  color: var(--gray);
}

.limit-root {
  width: 100%;
}

.warning {
  color: var(--pri-04);
}

.count {
  display: inline-block;
}

.input {
  font-size: 16px;
  font-family: var(--font-reg);
  color: var(--black-l);
  border-bottom: 2px solid var(--gray-l);
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  resize: none;
  width: calc(100% - 4px);
  line-height: 20px;
  border-radius: 0;
}

.input::placeholder {
  color: var(--gray);
}

.input:focus {
  border-bottom: 2px solid var(--pri-02);
}