.root {
  color: var(--gray-d);

  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.item {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.open {
  background-color: var(--gray-xl);
}

.folder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chevron, .folder:after {
  margin-left: 8px;

  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-top: 5px solid var(--gray-d);

  display: flex;
  content: '';
}