.root {
  padding-bottom: 55px;
}

.footer {
  box-sizing: border-box;
  position: fixed;
  bottom: 0px;
  right: 0;

  width: 100%;
  height: 55px;

  display: flex;
  justify-content: flex-end;
  text-align: center;

  padding-right: 20px;

  border-top: 1px solid var(--gray-xl);
  background-color: var(--pri-01);
}

@media (min-width: 900px) {
  .footer {
    position: absolute;
  }
}