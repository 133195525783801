.root {

}

.wrap {
  padding: 20px 0;
}

.main {

}

.screeners {
  margin-top: 20px;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 5px;
}

.label {
  font-family: var(--font-bold);
  color: var(--gray-d);
}

.select {
  width: 100%;
}

.empty {
  margin-top: 20px;
}

.screener {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.tile {
  margin-top: 20px;
  min-height: calc(100vh - 200px);
}

.col {
  width: 100%;
  max-width: 400px;
}

.item {

}

.item ~ .item {
  margin-top: 20px;
}

.q {
  font-family: var(--font-semibold);
  color: var(--gray-d);
}

.root .wrap .main .item .q p,
.root .wrap .main .item .q span,
.root .wrap .main .item .q div {
  font-size: 15px;
}

.a {
  margin: 10px 0;
}

.root .wrap .main .item .a p,
.root .wrap .main .item .a span,
.root .wrap .main .item .a div {
  font-size: 15px;
  font-family: var(--font-reg);
}

.background {

}

.background .label {
  font-family: var(--font-bold);
  line-height: 24px;
  margin-bottom: 5px;
}

.background .market {
  margin-bottom: 0;
  line-height: 30px;
  border: 2px solid var(--gray-l);
}

.background .type {
  line-height: 30px;
  border: 2px solid var(--gray-l);
}

.background .tile {
  min-height: 90px;
  margin: 0 0 20px;
}

.background .cols {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.background .cols .field {
  width: 275px;
}

.value {

}

.employment .tile {
  min-height: 180px;
}