.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-align: left;
  cursor: default;
}

.wrap {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.content {
  display: flex;
  justify-content: flex-start;
  font-family: var(--font-reg);
  color: var(--black);
}

.title {
  font-family: var(--font-semibold);
  font-size: 16px;
}

.body {
  margin-left: 10px;
  font-size: 15px;
  max-width: 370px;
}

.text {
  font-family: var(--font-reg);
  margin: 10px 0;
}

.icon {
  width: 40px;
}