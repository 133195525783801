.root {
  margin-top: 10px;
}

.title {
  font-family: var(--font-bold);
  font-size: 18px;
  margin-bottom: 10px;
}

.group {
  display: flex;
  align-items: center;
}

.group .label {
  margin-right: 5px;
  width: 90px;
}