@value 1100 from 'vars';

.root {
  position: relative;
  height: 115px;
}

.wrap {
  padding: 15px 25px;
}

@media 1100 {
  .wrap {
    padding: 20px 25px;
  }
}

.main {
  position: relative;
  height: 85px;
}

@media 1100 {
  .main {
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.title {
  font-family: var(--font-bold);
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media 1100 {
  .title {
    width: calc(100% - 175px);
  }
}

.message {
  font-size: 14px;
  max-width: 310px;
  padding: 0 15px;
  color: var(--black-l);
}

.feedback {
  display: none;
}

@media 1100 {
  .feedback {
    display: flex;
    align-items: flex-end;
  }
}

.info {
  display: none;
}

@media 1100 {
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 40px;
  }
}

.toggle {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media 1100 {

  .toggle {
    display: none;
  }
}

.pipeline {
  width: 150px;
  background-color: var(--pri-03);
  border: 2px solid var(--pri-03);
}

@media 1100 {
  .pipeline:hover {
    background-color: var(--sec-03);
    border: 2px solid var(--sec-03);
  }
}

.back {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media 1100 {
  .back {
    top: calc((100% - 45px) / 2);
  }
}

.stats {
  display: none;
  font-family: var(--font-semibold);
  color: var(--black-l);
}

@media 1100 {
  .stats {
    display: block;
  }
}

.meter {
  display: none;
}

@media 1100 {
  .meter {
    display: block;
    width: 200px;
  }
}