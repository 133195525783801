.root {
  border-radius: 10px;
  border: 2px solid var(--gray-l);
  padding: 5px;
  min-width: 43px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.root:hover {
  background-color: var(--blue-l);
  border: 2px solid var(--gray-m);
}

.text {
  font-size: 14px;
  font-family: var(--font-bold);
}

.icon {
  display: flex;
  color: var(--trolley);
}