.root {
  max-width: var(--navbar-width);
  width: 100%;
  margin: 0 auto;
}

.wrap {
  padding: 40px 10px;
}

.main {
  display: flex;
  margin: 0 auto;
  max-width: 700px;
}

.graphic {
  min-width: 185px;
}

.content {

}

.title {
  font-size: 24px;
  color: var(--black);
  font-family: var(--font-bold);
  margin-bottom: 20px;
}

.subtitle {
  margin-bottom: 30px;
}

.footer {

}

.btn {
  height: 40px;
  padding: 0;
  width: 150px;
}

.message {
  max-width: 100%;
  padding: 15px;
  background-color: var(--blue-l);
  border: 1px solid rgba(5, 74, 218, 0.3);
  border-left: 4px solid #0043ce;
  margin-bottom: 30px;
}

.notify {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timestamp {

}

.meta {
  font-size: 15px;
  color: var(--gray);
  font-style: italic;
  line-height: 18px;
}