.section {
  composes: section from '@/access/admin/Accounts/Details/style.css';
}

.label {
  composes: label from '@/access/admin/Accounts/Details/style.css';
}

.value {
  width: 425px;
}

.content {
  height: 26px;
}

.inline {
  display: flex;
  justify-content: space-between;
}