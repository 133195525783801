.option {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.option:last-of-type {
  margin-bottom: 0;
}

.option:last-child {
  margin-bottom: 0px;
}

.action {
  margin-left: 5px;
}

.tagging {
  margin: 4px 0 0 0;
}