.root {

}

.wrap {
  position: relative;
}

.main {
  margin: 0 auto;
  max-width: 500px;
  padding: 20px 0 40px;
  min-height: 300px;
}

.close {
  position: absolute;
  top: 30px;
  right: 30px;
  font-weight: 800;
  color: var(--gray-d);
}

.subtitle {
  margin-bottom: 25px;
}

.row {
  margin-bottom: 15px;
}

.option {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 20px;
  padding: 0;
}

.disabled {
  color: var(--gray-l);
}

.message {

}

.label {
  margin-bottom: 10px;
  font-family: var(--font-bold);
  color: var(--font-black-l);
}

.honorarium {
  display: flex;
  align-items: center;
  gap: 20px;
}

.honorarium.disabled {
  color: var(--gray-l);
  cursor: default;
}

.disabled .currency {
  pointer-events: none;
  cursor: default;
  user-select: none;
}

.amount {
  line-height: 32px;
  padding-bottom: 0;
  color: var(--black-l);
}

.disabled .amount {
  color: var(--gray-l);
  background-color: hsl(0, 0%, 95%);
}

:not(.disabled) .amount {
}

.input {
  margin-bottom: 30px;
}

.btn {
  min-width: 180px;
  border-radius: 4px;
}