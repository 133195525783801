.input {
  position: relative;
  width: 110px;
  background-color: var(--sec-01);
  height: 30px;
  border-radius: 4px;
}

.input input {
  position: relative;
  width: 100%;
  height: 30px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.span {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  line-height: 30px;
  width: 100%;
  text-align: center;
  z-index: 0;
  font-family: var(--font-reg);
}