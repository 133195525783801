.popper-content {
  padding: 15px 20px;
  min-width: 427px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.tag-selector {
  width: 100%;
}

.title {
  font-size: 20px;
  color: var(--gray-d);
  font-family: var(--font-bold);
}

.icon {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  border: 1px solid var(--gray);
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 20px;
}

.icon:hover {
  background-color: var(--gray-xl);
}

.icon-open {
  background-color: var(--gray-l);
}

.close-popper {
  cursor: pointer;
}

.color-circle {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.color-popper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  width: 150px;
}

.btn {
  width: 75px;
  min-width: auto;
}

.color-btn {
  width: 64px;
  min-width: auto;
  display: flex;
  justify-content: space-between;
  border-color: var(--gray-l);
  border-width: 1px;
}

.disabled-color-selector {
  border: 1px solid var(--gray-l);
  padding: 6px;
  cursor: not-allowed;
  border-radius: 4px;
}

.disabled-color-selector * {
  cursor: not-allowed;
}

.active-tag {
  color: var(--pri-02);
}

.spacer {
  flex-grow: .5;
}