.root {
  display: flex;
  align-items: center;
}

.label {
  margin-right: 10px;
  color: var(--gray-d);
}

.dropdown {
  width: 60px;
}