.root {
  padding: 20px;
}

.wrap {

}

.main {

}

.header {

}

.author {
  margin-bottom: 15px;
}

.scroll {
  max-height: 400px;
  overflow-y: auto;
}

.topics {
  border-top: 1px solid var(--gray-xl);
}

.preview {
  padding: 20px 0;
}

.content {
  position: relative;
}

.btns {
  display: flex;
  align-items: center;
}

.btn {
  height: 40px;
  min-width: 140px;
}

.dismiss {
  composes: btn;
  color: var(--gray-d);
  font-family: var(--font-bold);
}

.dismiss:disabled {
  color: var(--gray-d);
  font-family: var(--font-bold);
  background-color: transparent;
  cursor: default;
}

.dismiss:not(:disabled):hover {
  background-color: var(--gray-xl);
}

.btn ~ .btn {
  margin-left: 10px;
}