@value 800 from 'vars';

.root {
  display: flex;
  min-height: 100%;
  --height-gap: 20px;
  --height-textinput: 40px;
  --height-input: calc(var(--height-textinput) + var(--height-gap));
  --height-buttonset: 45px;
  --height-footer: calc(var(--height-buttonset) + var(--height-gap));
}

@media 800 {
  .root {
    min-height: calc(100% - 60px);
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100% - var(--height-footer));
  width: 100%;
  max-width: 480px;
}

.palette {
  height: calc(100% - (var(--height-footer) + var(--height-input)));
  margin-bottom: var(--height-gap);
}

.scroll {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  height: 100%;
}

.header {

}

.input {
  margin-bottom: var(--height-gap);
  height: var(--height-textinput);
}

.btns {
  height: var(--height-buttonset);
}

.btn {
  width: 150px;
}