.root {
  padding: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.conferenceRoot {
  margin-top: 40px;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.root > div {
  margin-bottom: 30px;
}

.title {
  font-family: var(--font-bold);
  font-size: 24px;
  text-align: center;
}

.buttons {
  position: relative;
}

.submit {
  width: 200px;
}

.skip {
  position: absolute;
  top: 10px;
  margin-left: 10px;
}