.root {
  --gap: 15px;
}

.items {
  display: flex;
  flex-wrap: wrap;

  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
}

.item {
  margin: var(--gap) 0 0 var(--gap);
}