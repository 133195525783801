.root {
  max-width: 1200px;
  margin: 0 auto;
  display: block;
}

.wrap {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 25px;
}

.nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 25px;
  height: 60px;
}

.logo a {
  height: 40px;
}

.img {
  height: 40px;
  width: auto;
}

.header {
  font-family: var(--font-semibold);
  font-size: 36px;
  color: var(--black-l);
}

.form {
  display: flex;
  flex-direction: column;
  width: 400px;
}

@media (min-width: 600px) {
  .form {
    width: 500px;
  }
}

.input {
  composes: input from 'css/input.css';
  margin-top: 70px;
}

.input::placeholder {
  color: var(--gray);
}

.link {
  padding: 15px 0;
  font-family: var(--font-bold);
  text-align: right;
  color: var(--pri-02);
  letter-spacing: -0.25px;
  cursor: pointer;
}

.submit {
  margin-top: 30px;
}

.btn {
  background-color: var(--pri-02);
  color: var(--pri-01);
  width: 270px;
  height: 50px;
  border-radius: 8px;
  font-family: var(--font-semibold);
  font-size: 18px;
}

.success {
  color: var(--sec-03);
}

.descriptor {
  color: var(--gray);
  font-family: var(--font-semibold);
  font-size: 18px;
}