@value 1100 from 'vars';

.root {
  height: 100%;
}

.wrap {
  padding: 40px 25px 25px 25px;
}

.main {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1280px;
}

.sidebar {
  display: none;
  min-width: 500px;
  width: 500px;
}

@media 1100 {
  .sidebar {
    display: block;
  }
}

.content {
  width: 100%;
  min-height: calc(780px * 0.5625);
}

.video {
  background-color: var(--black-d);
  object-fit: cover;
}

.title {
  color: var(--black);
  font-family: var(--font-bold);
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 15px;
}

.description {

}

.meta .link {
  font-size: 15px;
  padding: 0;
  margin-right: 10px;
}

.views:after {
  display: inline-block;
  content: '•';
  color: var(--gray-d);
  margin: 0 5px;
}

.views,
.date {
  color: var(--gray-d);
  font-family: var(--font-reg);
  font-size: 15px;
}

.link {
  color: var(--pri-02);
  padding: 20px 0;
  font-size: 17px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--sec-01);
  margin-top: 20px;
}

.tags {
  color: var(--black-d);
  overflow: hidden;
  max-height: 31px;
  margin-top: 5px;
}

.tag {
  border-color: var(--sec-01);
  color: var(--black-d);
  margin-right: 10px;
}

.play {
  position: absolute;
  background-color: var(--black);
  opacity: 0.7;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
}

.player .btn {
  position: absolute;
  bottom: calc(50% - 40px);
  left: calc(50% - 40px);
  color: var(--pri-01);
  opacity: 0.58;
  height: 80px;
  width: 80px;
  border: 3px solid var(--pri-01);
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  z-index: 1;
}

.btn:hover {
  opacity: 0.95;
}

.player {
  position: relative;
}

.body {
  margin: 15px 0;
}