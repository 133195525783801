.root {
  position: relative;
  padding: 20px 0;
}

.question {
  display: flex;
  justify-content: space-between;
}

.question-text {
  margin-right: 50px;
  min-height: 35px;
}

.question-text[contenteditable="false"] p {
  font-family: var(--font-reg);
  color: var(--black);
  font-size: 16px;
}

.menus {
  display: flex;
  gap: 10px;
  align-items: center;
}

.menu {
  position: absolute;
  top: 10px;
  right: 0;
}