.actions {
  padding: 10px;
  padding-top: 0px;
  display: flex;
}

.actions > *:not(:first-child) {
  margin-left: 5px;
}

.likes:not(:first-child) {
  margin-top: 10px;
}