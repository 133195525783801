.root {
  overflow: hidden;
}

.wrap {
  display: flex;
  align-items: center;
}

.avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.link {

}

.link:hover {
  color: var(--hyperlink);
  text-decoration: underline;
}

.trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}