.btn {
  position: relative;
  cursor: pointer;

  padding: 21px;
  border-radius: 50%;
  border: 2px solid var(--gray-xl);
  margin-right: 10px;

  color: var(--black-l);
}

.play {
  position: absolute;
  bottom: 9px;
  left: 11px;

  color: var(--black-l);
  fill: var(--black-l);
}

.pause {
  position: absolute;
  bottom: 9px;
  left: 9px;

  color: var(--black-l);
  fill: var(--black-l);
}