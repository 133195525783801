.root {
  height: 100%;
}

.wrap {
  width: 100%;
  height: 293px;
}

.author {
  padding: 20px 20px 0;
}

.shared {
  padding: 20px 20px 0;
}

.body {
  margin-top: 10px;
}

.video {
  width: 100%;
  min-height: 293px;
  max-height: 293px;
  object-fit: cover;
  border-radius: 4px;
}

.preview {
  position: relative;
  display: flex;
  overflow: hidden;
  padding-top: 56.25%;
  background-color: rgba(0, 0, 0, 0.04);
  border: 1px solid var(--sec-01);
  box-sizing: border-box;
  border-radius: 4px;
}

.img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.title {
  color: var(--gray-d);
  font-size: 21px;
  font-family: var(--font-semibold);
  line-height: 31px;
  margin-top: 10px;
}

.btn {
  font-size: 15px;
  padding: 5px 10px;
  height: 40px;
  width: 100px;
}

.btn ~ .btn {
  margin-left: 7px;
}

.back {
  composes: btn;
  color: var(--black-l);
  font-family: var(--font-semibold);
  border-radius: 5px;
}

.back:hover {
  background-color: var(--sec-01);
  border-color: var(--sec-01);
}

.back:disabled,
.back:disabled:hover {
  color: var(--gray);
  background-color: transparent;
  border-color: transparent;
  cursor: default;
}

.post {
  padding: 20px;
  margin: 20px;
  border: 1px solid var(--sec-01);
  border-radius: 4px;
}

.next {
  composes: btn;
  padding: 5px;
}

.next:disabled,
.next:disabled:hover {
  background-color: #979797;
  border-color: #979797;
  color: var(--sec-01);
  cursor: default;
}

.topics {
  border-top: 1px solid var(--gray-xl);
}

.indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 33px;
}