@value 800, 1100 from 'vars';

.root {
  border-top: var(--section-separator);
}

.wrap {
  max-width: var(--max-width);
  margin: 0 auto;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: var(--height-fullpage);
  min-height: 800px;
  padding: 0 20px 40px;
}

.main {
  max-width: 400px;
  margin: 0 auto;
}

@media 800 {
  .main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: unset;
  }
}

.graphic {
  width: 100%;
  margin: 0 auto;
}

@media 800 {
  .graphic {
    max-width: 100%;
    margin: 0;
  }
}

@media 1100 {
  .graphic {
    height: auto;
  }
}

.img {
  width: 100%;
  height: auto;
  margin-top: 40px;
}

@media 800 {
  .img {
    display: block;
    max-width: 420px;
    width: 100%;
  }
}

@media 1100 {
  .img {
    display: block;
    max-width: 560px;
  }
}

@media 800 {
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 450px;
  }
}

.title {
  font-size: var(--font-size-heading-2);
  color: var(--black-d);
  font-family: var(--font-bold);
  margin-bottom: 30px;
  text-align: center;
}

@media 800 {
  .title {
    font-size: var(--font-size-heading-1);
    max-width: 640px;
    text-align: left;
  }
}

@media 1100 {
  .title {
    max-width: unset;
  }
}

.subtitle {
  font-size: var(--font-size-subtitle-1);
  color: var(--black-l);
  margin-bottom: 50px;
  max-width: 360px;
  text-align: center;
}

@media 800 {
  .subtitle {
    max-width: 360px;
    text-align: left;
  }
}