.root {
  cursor: pointer;
  line-height: 0;
}

.add {
  color: var(--pri-02);
}

.remove {
  color: var(--black);
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}