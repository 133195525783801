.group {
  padding-bottom: 10px;
  border-bottom: 2px solid var(--gray-xl);
  margin-top: 10px;
}

.group:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.title {
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}