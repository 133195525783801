.table {
  margin: 20px 0px;
}

.table-header {
  font-family: var(--font-bold);
  background-color: var(--gray-l);
  padding: 5px;
}

.table-row {
  display: flex;
  padding: 5px;
  border-bottom: 1px solid var(--gray-l);
  align-items: center;
  gap: 10px;
}