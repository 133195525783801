@value 800, 1100 from 'vars';

.root {
  border-top: 1px solid var(--gray-xl);
}

.wrap {
  max-width: 1280px;
  margin: 0 auto;
}

.content {
  min-height: 700px;
  padding: 80px 20px 40px;
}

@media 800 {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px 40px;
  }
}

.main {
  max-width: 400px;
  margin: 0 auto;
}

@media 800 {
  .main {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    max-width: var(--max-width);
  }
}

.info {

}

@media 800 {
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.title {
  font-size: 30px;
  color: var(--black-d);
  font-family: var(--font-bold);
  margin-bottom: 30px;
}

@media 800 {
  .title {
    font-size: var(--font-size-header);
    max-width: 360px;
  }
}

.subtitle {
  font-size: 20px;
  color: var(--black-l);
  margin-bottom: 50px;
}

@media 800 {
  .subtitle {
    max-width: 360px;
  }
}

@media 1100 {
  .subtitle {
    max-width: 340px;
  }
}

.graphic {
  width: 100%;
  max-width: 293px;
  margin: 0 auto;
}

.img {
  width: 100%;
  height: auto;
  margin-top: 40px;
}

.img-l {
  display: none;
  animation-name: fade;
  animation-duration: 300ms;
}

@media 800 {
  .graphic {
    width: 450px;
    max-width: 100%;
    margin: auto;
    margin-right: 0;
  }

  .img {
    width: 430px;
    animation-name: fade;
    animation-duration: 300ms;

    height: unset;
    margin-top: unset;
  }
}

@media 1100 {
  .graphic {
    width: 600px;
    height: auto;
  }

  .img {
    display: none;
    width: 600px;
  }

  .img.ready {
    display: block;
  }
}

.btnset {
  display: flex;
  flex-direction: column;
}

.btn {
  width: 100%;
}

.btnset .btn:first-child {
  margin-bottom: 10px;
}

@media 800 {
  .btnset {
    flex-direction: row;
  }

  .btn {
    width: 150px;
  }

  .btnset .btn:first-child {
    margin: 0 20px 0 0;
  }

}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}