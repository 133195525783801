.root {
  width: 100%;
}

.wrap {
  margin: 0 auto;
  max-width: 800px;
}

.main {
  padding: 15px 0;
}

.header {

}

.body {

}

.label {
  font-size: 16px;
  font-family: var(--font-semibold);
  color: var(--black);
  line-height: 20px;
}

.text {
  font-size: 16px;
  font-family: var(--font-reg);
  color: var(--black);
  line-height: 20px;
}

.row {
  margin-bottom: 15px;
}

.divider {
  composes: label;
  border-bottom: 1px solid var(--sec-01);
  padding-bottom: 5px;
}