.root {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.wrap {
  position: relative;
  width: 100%;
}

.row {
  margin-bottom: 10px;
}

.title {
  font-size: 16px;
  font-family: var(--font-semibold);
}

.header {
  display: flex;
  align-items: center;
  max-width: 450px;
  margin-bottom: 10px;
}

.input {
  max-width: 450px;
}

.error {
  color: var(--pri-04);
  font-size: 14px;
  font-family: var(--font-reg);
  line-height: 16px;
  margin-left: 10px;
}

.plus {
  height: 12px;
  width: 12px;
  stroke-width: 3;
  padding-left: 20px;
}

.tag {
  margin: 5px 0;
}

.tag:not(:last-child) {
  margin-right: 10px;
}

.suggested {
  composes: tag;
}

.suggested svg {
  color: var(--trolley);
}

.tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 5px;
  min-height: 41px;
  max-width: 525px;
}

.feedback {
  display: none;
  height: 160px;
  width: 100%;
  max-width: 110px;
}

@media (min-width: 800px) {
  .feedback {
    display: block;
  }
}

.meter {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  height: 170px;
  width: 110px;
  text-align: center;
}

@media (min-width: 800px) {
  .meter {
    display: flex;
  }
}

.sm {
  color: var(--black-l);
  font-size: 14px;
  font-family: var(--font-semibold);
}

.h {
  display: flex;
  flex-direction: column;
  composes: row;
}

.h-title {
  font-family: var(--font-bold);
  font-size: 24px;
  color: var(--black);
}

.h-optional {
  color: var(--gray);
}

.h-subtitle {
  font-family: var(--font-reg);
  font-size: 18px;
  color: var(--gray-d);
}

.section {
  min-height: 220px;
  padding-bottom: 30px;
}

.select {
  cursor: pointer;
}