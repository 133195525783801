@value 800, 1000 from 'vars';

.root {
  max-width: 830px;
  margin: 0 auto;
}

.wrap {
  padding: 0 15px;
}

.title {
  font-family: var(--font-bold);
  color: var(--black-d);
  font-size: 24px;
  text-align: center;
  padding: 50px 0;
}

@media 800 {
  .title {
    font-size: 30px;
  }
}

.label {
  font-size: 20px;
  font-family: var(--font-semibold);
  color: var(--black-d);
}

.value {
  font-size: 18px;
  color: var(--black-d);
  border-bottom: 1px solid var(--sec-01);
}

@media 800 {
  .value {
    padding-right: 30px;
  }
}

.panel {
  border-bottom: none;
  padding: 15px 0;
}

.text {
  padding-bottom: 20px;
}

.btn {
  width: 170px;
}

.headline {
  composes: title;
  max-width: 350px;
}

@media 1000 {
  .headline {
    max-width: 360px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}