.root {
  margin-bottom: 80px;
}

.title {
  font-family: var(--font-bold);
  font-size: 22px;
  margin-bottom: 20px;
}

.subtitle {
  margin-bottom: 40px;
  max-width: 500px;
}

.section {
  margin-bottom: 20px;
}

.section-title {
  font-family: var(--font-semibold);
  font-size: 16px;
  display: flex;
  margin-bottom: 10px;
}

.tooltip {
  margin-left: 10px;
}

.upload-btn {
  padding: 6px 8px;
  min-width: 0px;
  font-size: 12px;
}

.num-links {
  margin-left: 10px;
  font-size: 14px;
}