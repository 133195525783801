.btns {
  margin-top: 10px;
}

.label {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  margin-bottom: 10px;
}

.action {
  margin-bottom: 20px;
}

.conditions {
  /* margin: 0 15px; */
}

.condition {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid var(--sec-01);
}

.condition:first-of-type {
  padding-top: 10px;
}

.condition:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}

.add-condition-btn {
  margin-top: 15px;
  margin-left: 15px;
}

.rule {
  margin-bottom: 20px;
}