.table-scroll {
  overflow-y: auto;
  max-height: 400px;
  height: 400px;
}

.table {
  margin: 0 auto;
}

.table th {
  /* text-align: left; */
}

.table thead {
  border-bottom: 2px solid var(--gray-xl);
}

.table tr {
  border-bottom: 1px solid var(--gray-xl);
}

.table th, .table td {
  padding: 7px;
}

.name {
  text-align: left;
}

.tagged {
  text-align: center;
}

.summary {
  text-align: center;
}

.checkbox {
  text-align: center;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.help-text {
  width: 375px;
  text-align: center;
  color: var(--gray);
  margin-bottom: 10px;
}