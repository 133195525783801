@value 850 from 'vars';
@value 1100 from 'vars';

.child {
  margin-left: 20px;
}

.border {
  border-bottom: 1px solid var(--gray-l);
  box-shadow: 0 1px 0 0 var(--sec-01);
}

.text {
  color: var(--acc-01);
  font-family: var(--font-reg);
  display: flex;
  font-size: 16px;
  max-width: 300px;
}

@media 850 {
  .text {
    max-width: 100%;
  }
}

.icon {
  width: 18px;
  height: 18px;
}

@media (min-width: 800px) {
  .icon {
    width: 20px;
    height: 20px;
  }
}

.pencil {
  composes: icon;
  stroke: var(--pri-02);
  stroke-width: 2;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--pri-02);
  border-radius: 50%;
  height: 34px;
  width: 34px;
}

.btn:hover {
  background-color: var(--pri-02);
}

.btn:hover svg {
  stroke: var(--pri-01);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--gray-l);
}

.title {
  width: 100%;
  font-family: var(--font-bold);
  color: var(--black);
  line-height: 45px;
}

@media (min-width: 800px) {
  .title {
    font-size: 24px;
  }
}

.btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btns > button {
  margin-left: 15px;
}