.root {
  padding: 15px;
}

.wrap {
  width: 100%;
}

.circle {
  width: 42px;
  height: 42px;
  margin-right: 10px;
}

.info {
  display: flex;
}

.text {
  width: 100%;
}

.desc {
  margin: 10px 0 15px;
  width: 100%;
}

.sub {
  margin: 5px 0 5px 5px;
}

.box {
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.item {
  margin-bottom: 10px;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.body {
  margin: 10px 0 15px;
  width: 100%;
}