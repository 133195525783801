.root {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

@media (min-width: 850px) {
  .root {
    padding: 0 25px 0 0;
  }
}

.item {
  display: flex;
  align-items: center;
}

.link {
  color: var(--gray-d);
  font-family: var(--font-bold);
  display: block;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 3px
}

.link:hover {
  background-color: var(--gray-xxl);
}

.link.active {
  background-color: var(--gray-xl);
  color: var(--black-l);
}

.icon {
  margin-right: 15px;
  height: 25px;
  width: 25px;
}