.root {
  position: relative;
  height: 100%;
  display: grid;
  --container-gutter: 50px;
}

.container {
  position: relative;
  grid-area: 1 / 1 / 2 / 3;
}

.participant-container {
  position: absolute;
  display: flex;
  top: var(--container-gutter);
  right: var(--container-gutter);
  bottom: var(--container-gutter);
  left: var(--container-gutter);
  margin: 0 auto;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}

.button-container {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-container-left {
  right: calc(100% - var(--container-gutter));
  left: 0;
}

.button-container-right {
  right: 0;
  left: calc(100% - var(--container-gutter));
}

.pagination.MuiPaginationItem-root {
  color: white;
}

.pagination-button {
  color: black;
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  display: flex;
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 6px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}

.pagination-button:hover {
  background: rgba(0, 0, 0, 0.04);
}

.pagination-container {
  position: absolute;
  top: calc(100% - var(--container-gutter));
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hidden-participants {
  display: none;
}