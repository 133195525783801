.wrap {
}

.body {
  text-align: unset;
  padding: 15px 20px;
}

.line {
  margin-bottom: 20px;
  line-height: 20px;
}

.btns {
  margin: 10px 0;
}