.root {
  box-sizing: border-box;
  border: 1px solid var(--sec-01);
  border-radius: 5px;
  width: 100%;
}

@media (min-width: 600px) {
  .root {
    width: 220px;
  }
}

.body {
  padding: 12px;
  box-sizing: border-box;
  height: 175px;
  color: var(--black-l);
}

.title {
  font-family: var(--font-semibold);
  margin-bottom: 8px;
}

.copy {
  color: var(--gray-d);
  font-size: 14px;
}

.icon {
  height: 32px;
  width: auto;
  margin-bottom: 5px;
}

.footer {
  position: relative;
  text-align: center;
  height: 45px;
  border-top: 1px solid var(--sec-01);
  padding: 5px;
  box-sizing: border-box;
}

.disconnect {
  color: var(--pri-04);
}

.disconnect:hover {
  background-color: var(--reddish-white);
}

.btn {
  border-radius: 4px;
  padding: 5px;
  font-size: 14px;
}

.btn:disabled {
  cursor: not-allowed;
  color: var(--gray-m);
}

.btn:disabled:hover {
  background-color: unset;
}

.connect, .disconnect {
  composes: btn;
}