.root {
  margin: auto;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.title {
  color: var(--black-l);
  font-family: var(--font-bold);
  font-size: 18px;
  line-height: 20px;
  margin: 0 0 25px 0;
}

.copy {
  color: var(--black-l);
  font-size: 14px;
}

.copy2 {
  composes: copy;
  margin: 30px 0 0 0;
}

.navigation {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.external {
  color: var(--gray-d);
  font-family: var(--font-semibold);
  font-size: 15px;
  margin: 40px 0 0 0;
}

.external:hover a {
  text-decoration: underline;
}

.skip {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0 0 0;
}

@media (min-width: 800px) {
  .root {
    max-width: 700px;
  }

  .wrap {
    display: block;
    height: auto;
  }

  .title {
    font-size: 24px;
    line-height: 30px;
  }

  .copy,
  .copy2 {
    font-size: 20px;
    line-height: 24px;
  }

  .navigation {
    display: block;
  }

  .external {
    font-size: 16px;
    margin: 40px 0 0 0;
  }

  .skip {
    display: flex;
    justify-content: flex-end;
    margin: 40px 40px 0 0;
  }
}