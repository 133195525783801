.root {

}

.wrap {

}

.main {

}

.header {
  font-family: var(--font-bold);
  font-size: 22px;
}

.subtitle {
  margin: 25px 0;
}

.permission {
  display: flex;
  align-items: center;
  gap: 15px;
}

.label {
  font-family: var(--font-semibold);
  color: var(--black-d);
}

.select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 140px;
}

.select.open,
.select:hover {
  background-color: var(--gray-xl);
  border-color: var(--gray-xl);
}

.chevron {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--gray-d);
  margin-left: 8px;
}

.menu {
  width: 150px;
  background-color: var(--pri-01);
}

.item {
  padding: 10px 20px 10px 10px;
  cursor: pointer;
}

.item:hover {
  background-color: var(--blue-l);
}

.popper {
  z-index: calc(var(--z-index-modal) + 1);
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
}

.btn {
  border-radius: 4px;
  width: 130px;
}