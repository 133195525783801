.root {
  padding: 20px;
}

.avatar {
  cursor: default;
  margin: 0 auto;
  width: 75px;
}

.title {
  font-family: var(--font-bold);
  font-size: 22px;
  color: var(--black);
  text-align: center;
}

.rating-title {
  composes: title;
  width: 300px;
  margin: 20px auto 30px auto;
}

@media (min-width: 425px) {
  .rating-title {
    width: 400px;
  }
}

.q-title {
  composes: title;
  width: 350px;
  margin: 20px auto 35px auto;
}

@media (min-width: 425px) {
  .q-title {
    width: 450px;
  }
}

.options {
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  margin-bottom: 50px;
}

.option {
  cursor: pointer;
  color: var(--gray-d);
  margin-right: 75px;
}

.option:last-child {
  margin-right: 0;
}

.icon {
  margin-bottom: 15px;
}

.option-title {
  font-family: var(--font-semibold);
  color: var(--black-l);
}

.option.selected .icon,
.option.selected:hover .icon
{
  stroke: var(--pri-02);
  fill: var(--blue-l);
}

.option.selected .option-title,
.option.selected:hover .option-title
{
  color: var(--pri-02);
}

.option:hover .icon{
  stroke: var(--pri-02);
}

.option:hover .option-title {
  color: var(--pri-02);
}

.footnote {
  text-align: center;
  font-family: var(--font-reg);
  color: var(--gray-d);

  margin-bottom: 40px;
}

.btns {
  position: relative;
  text-align: center;
}

.submit {
  width: 125px;
}

.skip {
  position: absolute;
  right: 0;
  bottom: calc(50% - 10px);
  color: var(--gray);
  cursor: pointer;
}

.call-count {
  display: flex;
  justify-content: flex-end;
  font-family: var(--font-semibold);
}

.rating {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}

.notes {
  margin-bottom: 40px;
  /* margin-left: 20px;
  margin-right: 20px; */
}

.screener-skip-btn {
  width: 135px;
  margin-right: 20px;
}

.screener-accept-btn {
  width: 135px;
}

.screener-title {
  composes: title;
  text-align: left;
}