.root {
  min-width: 65px;
}

.root:not(:last-child) {
  margin-right: 5px;
}

.badge:global(.MuiChip-outlined) {
  border-color: var(--gray-m);
  color: var(--gray-d);
}

.icon {
  margin-top: 1px;
  padding-left: 2px;
}

.badge span:global(.MuiTouchRipple-root) {
  visibility: hidden;
}