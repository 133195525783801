.selection-requirement {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-right: 15px;
  border-bottom: 1px solid var(--gray-l);
  margin-bottom: 15px;
  align-items: center;
}

.group-section-header {
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

.group-header {
  font-family: var(--font-semibold);
}

.group {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--gray-l);
}

.editing-group {
  border-bottom: none;
  padding-bottom: 0px;
}

.group-column {
  width: 45%;
}

.add-btn {
  margin-top: 5px;
  display: flex;
  align-items: center;
  color: var(--pri-02);
  cursor: pointer;
}

.add-btn svg {
  margin-right: 8px;
}

.edit-controls {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--gray-l);
  margin-bottom: 10px;
}

.group-dropdown {
  margin-bottom: 10px;
}

.save-group {
  color: var(--pri-03);
}

.disabled {
  color: var(--gray-m);
  cursor: not-allowed;
}

.modal-controls {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.modal-controls :not(:last-child) {
  margin-right: 20px;
}

.edit-btn, .remove-btn {
  cursor: pointer;
}

.remove-btn {
  color: var(--pri-04);
}