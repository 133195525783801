@media (min-width: 800px) {
  .modal {
    width: 700px;
  }
}

.chapter {
  margin-bottom: 20px;
}

.chapter .title {
  font-family: var(--font-bold);
  margin-bottom: 5px;
  color: var(--pri-02);
}

.chapter .summary {
  font-size: 15px;
  margin-bottom: 15px;
}

.section {
  margin-bottom: 20px;
}

.chapter .section {
  margin-left: 15px;
  margin-bottom: 10px;
}

.subtitle {

}

.section .title {
  font-family: var(--font-semibold);
  margin-bottom: 8px;
  color: var(--gray-d);
  text-decoration: underline;
}

.cols {
  display: flex;
  flex-wrap: wrap;
}

.item {
  margin-bottom: 5px;
  font-size: 15px;
}

.cols .item {
  width: 50%;
}

.subtitle {
  color: var(--gray);
  font-size: 14px;
  margin-left: 5px;
  font-family: var(--font-reg);
}