.root {
  padding-top: 60px;
}

.display {
  position: relative;
  box-sizing: border-box;
  height: calc(100% * 0.5);
  border-radius: 9px;
  border: 1px solid var(--gray-l);
  width: 100%;
  cursor: pointer;
}

.placeholder {
  display: flex;
  overflow: hidden;
  padding-top: 50%;
  border-radius: 9px;
  background-color: var(--gray-xl);
  width: 100%;
}

.noimage {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  color: var(--gray-d);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.icon {
  color: var(--gray);
  height: 30px;
  width: 30px;
  margin: 0 auto;
}

.header {
  font-family: var(--font-bold);
  font-size: 16px;
  line-height: 40px;
}

.p {
  font-size: 14px;
  line-height: 18px;
  font-family: var(--font-reg);
}

.modal {
  overflow-x: hidden;
  max-width: 800px;
  width: 100%;
}

.cropper {
}

.picker {
  text-align: center;
  width: 100%;
}

.original {
  max-width: 1280px;
}

.cropped {
  width: 100%;
  max-height: 380px;
  max-width: 760px;
}