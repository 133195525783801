.btn {
  width: 300px;
  border-color: #cfcfcf;
  font-size: 16px;
  padding: 8px;
}

.btn .email {
  height: 26px;
  width: 26px;
  color: var(--gray-m);
  font-size: 30px;
}

.apple {
  margin-top: -3px;
}