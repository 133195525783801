.root {
  position: relative;
  transition: opacity 0.2s linear;
}

.root:hover .edit, .root:hover .overlay-icon {
  opacity: 1;
}

.pencil {
  height: 18px;
  width: 18px;
  padding: 3px;
  cursor: pointer;
}

.overlay-icon {
  position: absolute;
  top: calc(50% - 9px);
  right: 0;
  cursor: pointer;
  transition: all .2s linear;
}

.edit {
  position: absolute;
  top: calc(50% - 14px);
  right: 0;
  background-color: transparent;
  border: 3px solid transparent;
  color: var(--pri-02);
  cursor: pointer;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  transition: all .2s linear;
}

.edit:hover {
  background-color: var(--pri-02);
  border: 3px solid var(--pri-02);
  color: var(--pri-01);
}

@media (min-width: 425px) {
  .edit, .overlay-icon {
    opacity: 0;
  }
}