.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row:not(:last-child) {
  margin-bottom: 14px;
}

.number {
  text-align: right;
  width: 165px;
}

.country {
  color: var(--gray-d);
  font-family: var(--font-semibold);
}

.header {
  margin-bottom: 10px;
}

.body {
  min-height: 150px;
}

.pin {
  display: flex;
  justify-content: center;
  color: var(--gray-d);
  font-family: var(--font-semibold);
  font-size: 18px;
  margin-bottom: 22px;
}

.pin svg {
  margin-right: 6px;
}

.numbers {
  display: flex;
  flex-direction: column;
  justify-content: center;
}