.root {
  position: fixed;
  top: calc(var(--navbar-height) + 10px);
  right: 10px;
  z-index: 3;
  min-width: 350px;
}

.wrap {
  background-color: var(--pri-01);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
              rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
              rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.main {
  padding: 10px;
  max-height: calc(100vh - var(--navbar-height) - 40px - 10px - 30px); /*40px for header height, 10px for .root offset, 30px for additional gutter*/
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: center;
  background-color: var(--gray-xl);
  height: 40px;
}

.bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}

.title {
  font-size: 18px;
  font-family: var(--font-bold);
}

.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}

.btn:hover {
  background-color: var(--gray-l);
}

.action {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  background-color: var(--blue-l);
  border-radius: 15px;
  font-family: var(--font-semibold);
  padding: 5px 15px;
}

.action svg {
  height: 19px;
  width: 19px;
  color: var(--black-l);
}

.tools {
  display: flex;
  align-items: center;
  gap: 10px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 30px;
}

.cleanup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.submit {
  min-width: 120px;
  padding: 0 10px;
  height: 35px;
  border-radius: 4px;
}

.internal {
  composes: submit;
  height: 30px;
  min-width: 110px;
}

.status {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--sec-02);
  font-family: var(--font-semibold);
  font-size: 13px;
  background-color: var(--gray-xl);
  border-radius: 20px;
  padding: 2px 6px;
}

.status .icon {
  width: 21px;
}

.status.completed {
  color: var(--pri-03);
}