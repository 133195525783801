.tags {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0;
}
  
.tag {
    background-color: var(--pri-01);
    border: 1px solid var(--gray);
    color: var(--gray-d);
    font-family: var(--font-semibold);

    font-size: 12px;
    padding: 4px 9px 5px 9px;
    border-radius: 8px;
    margin: 5px 0;
}

.read-only-tag {
    composes: tag;
}

.clickable-tag {
    composes: tag;
    cursor: pointer;
}

.clickable-tag:hover {
    background-color: var(--blue-l);
    border: 1px solid var(--pri-02);
    color: var(--pri-02);
}
  
.removable-tag {
    composes: tag;
    cursor: pointer;
    background-color: var(--blue-l);
    border: 1px solid var(--pri-02);
    color: var(--pri-02);
}

.removable-tag:after {
    content: '✕';
    padding-left: 20px;
    display: inline-block;
}
  
.tag:not(:last-child) {
    margin-right: 10px;
}