.root {
  display: flex;
  align-items: stretch;
}

.item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;

  text-align: center;
  max-width: 190px;
}

.value {
  text-align: center;
  color: var(--gray-d);
  /* word-break: break-word; */
}

.item-wrap {
  padding: 5px 10px;
}

.tick {
  height: 10px;
  width: 2px;
  background-color: var(--gray-l);
  margin: 0 auto;
  margin-top: 5px;

  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.border {
  height: 2px;
  width: 100%;
  background-color: var(--gray-l);
}

.first .border {
  width: calc(50% + 25px);
  margin-left: calc(50%);
  border-bottom-left-radius: 5px;
}
.last .border {
  width: calc(50% + 25px);
  margin-right: calc(50%);
  border-bottom-right-radius: 5px;
}