.root {

}

.wrap {
  padding: 20px 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.btn {
  width: 150px;
  height: 40px;
  padding: 0;
}