.body {
  margin: 0 25px;
}

.header {
  margin-bottom: 20px;
}

.input {
  margin-bottom: 25px;
}

.label {
  font-family: var(--font-semibold);
  margin-bottom: 15px;
}

.footer {
  margin-top: 40px;
}

.select {
  width: 200px;
}