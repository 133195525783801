.main {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--pri-01);
  height: 190px;
}

.modal-wrap {
  width: 400px;
  box-sizing: border-box;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.row:not(:last-child) {
  margin-bottom: 20px;;
  width: 100%;
}

.label {
  font-size: 15px;
  font-family: var(--font-bold);
  color: var(--black);
}

.input {
  height: 32px;
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.btn {
  padding: 4px;
  min-width: 75px;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.btn:last-child {
  margin-left: 10px;
}