.tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
}

.selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 425px) {
  .selected {
    justify-content: unset;
  }
}

.override {
  margin: 5px 0;
  border-color: #76d1ff;
}

.override:not(:last-child) {
  margin-right: 10px;
}

.override .icon svg {
  color: var(--pri-02);
}

.selections {
  display: flex;
  flex-flow: row wrap;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 20px;
}

@media (min-width: 800px) {
  .selections {
    height: 50px;
    align-content: flex-start;
  }
}

.focusable {
  display: none;
}

.specialty {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.specialty .field {
  width: 100%;
}