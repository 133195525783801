.root {
}

.wrap {
  display: flex;
  justify-content: space-between;
}

.main {
  display: flex;
  gap: 15px;
}

.avatar {

}

.info {

}

.name {
  margin: 5px 0 15px;
}

.field {
  display: flex;
}

.field:not(:first-child) {
  margin-top: 5px;
}

.label {
  font-family: var(--font-bold);
  color: var(--gray-d);
  font-size: 14px;
  margin-right: 10px;
}

.details {
  display: flex;
  flex-direction: column;
}

.star svg {
  width: 18px;
  height: 18px;
}

.rate {
  font-size: 15px;
}

.icon {
  color: #0077B5;
}

.icon svg {
  width: 28px;
  height: 28px;
}