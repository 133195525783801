.root {
  margin: 0 auto;
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;
}

.title {
  color: var(--black);
  font-family: var(--font-semibold);
  font-size: 22px;
  line-height: 22px;
  margin-bottom: 30px;
}

.text {
  display: flex;
  margin-bottom: 10px;
}