.root {
  width: 100%;
  padding: var(--padding-main) var(--padding-main) 0 var(--padding-main);
}

@media (min-width: 800px) {
  .root {
    min-width: var(--left-width);
  }
}

.title {
  font-size: 26px;
  font-family: var(--font-bold);
  margin: 0 0 10px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.participants {
  display: flex;
  margin: 0 0 10px 0;
}

.duration {
  color: var(--black);
  font-family: var(--font-bold);
}