.root {

}

.input {
  font-family: var(--font-reg);
  font-size: 16px;
  padding: 10px var(--padding-horizontal);
  border: var(--border-width) solid var(--gray-l);
  border-radius: 5px;
  width: calc(100% - ((var(--border-width) * 2) + (var(--padding-horizontal) * 2)));
  resize: none;
  line-height: normal;
}

:global(#alternate-number),
:global(#phone-number) {
  outline: none;
  padding-left: 15px !important;
}

:global(#alternate-number).input,
:global(#phone-number).input {
  margin-left: 80px;
}

.root :global(.iti--separate-dial-code .iti__selected-flag) {
  border: var(--border-width) solid var(--gray-l);
  border-radius: 5px;
  margin-top: 0;
  padding-top: 1px;
}

.root :global(.iti__flag-container) {
  padding: 0;
}

.input:focus {
  border: var(--border-width) solid var(--pri-02);
}

.focusable {
  display: none;
}

.input.invalid {
  border-color: var(--pri-04);
}