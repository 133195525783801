.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-bottom: 25px;
}

.label {
  composes: text from '../style.css';
}

.value {
  color: var(--black);
  font-family: var(--font-semibold);
}