.root {
  min-height: 500px;
  position: relative;
}

.placeholder {
  text-align: center;
  margin: 20px 0;
  color: var(--gray-d);
}

.footer {
  text-align: center;
  margin-top: 15px;
  padding: 20px 0;
}

.save {
  width: 140px;
  background-color: var(--pri-03);
  border-color: var(--pri-03);
}

.save:hover {
  background-color: var(--sec-03);
  border-color: var(--sec-03);
}

.save:disabled:hover {
  background-color: var(--gray-l);
  border-color: var(--gray-l);
}

.question-multiple {
  border-bottom: 1px solid var(--gray-xl);
}