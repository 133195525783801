
.header {
  margin-bottom: 10px;
}

.title {
  font-family: var(--font-semibold);
  color: var(--black);
  font-size: 16px;
  margin-bottom: 10px;
}

.autocomplete {
  width: 300px;
  margin-bottom: 15px;
}

.section {
  margin-bottom: 15px;
}

.section:last-of-type {
  margin-bottom: 0;
}

.tags {
  max-width: 500px;
}

.warning {
  margin-top: 5px;
  color: var(--pri-04);
  font-size: 14px;
}