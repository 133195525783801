.root {

}

.main {
  margin: 0 -20px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.header {
  font-size: 20px;
  font-family: var(--font-bold);
  color: var(--gray-d);
  margin-bottom: 25px;
  line-height: 20px;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 30px;
  padding: 0 15px;
}

.label {
  font-family: var(--font-semibold);
  color: var(--black);
  font-size: 16px;
  width: 100px;
}

.day {
  /* width: 100px; */
}

.remove {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  color: var(--black-l);
}

.remove svg {
  font-size: 21px;
}

.start,
.end {
  width: 100px;
}

.day,
.start,
.end {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  color: var(--black-l);
}

.add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 31px;
  color: var(--pri-02);
  font-size: 28px;
  cursor: pointer;
}

.busy {
  background-color: var(--gray-l);
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--gray-xl);
  height: 30px;
  width: 100%;
}

.title {
  font-family: var(--font-bold);
  color: var(--gray-d);
  line-height: 30px;
  padding: 0 20px;
}

.new {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  height: 150px;
}

.buttonset {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
}

.buttonset button:nth-child(n+2) {
  margin-left: 15px;
}