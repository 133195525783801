@value 800, 900, 1000, 1100 from 'vars';

.root {
}

@media 1100 {
  .root {

  }
}

.wrap {
}

.main {
  background-color: var(--blue-xl);
  padding: 70px 0;
}

@media 800 {
  .main {
    border-top-left-radius: 29px;
    border-bottom-left-radius: 29px;
  }
}

@media 1100 {
  .main {
    position: relative;
    background-color: unset;
    background-image: unset;
    max-width: var(--max-width);
    margin: 0 auto;
  }
}

.header {
  font-size: 28px;
  font-family: var(--font-bold);
  color: var(--black-d);
  text-align: center;
  margin: 0 20px 40px;
  max-width: var(--max-width);
  padding: 0 20px;
}

@media 800 {
  .header {
    font-size: var(--font-size-heading-1);
    margin: 40px auto;
    text-align: left;
  }
}

@media 1100 {
  .header {
    max-width: calc(var(--max-width) - 80px);
  }
}

.subtitle {
  font-size: var(--font-size-subtitle-2);
  color: var(--black-l);
  margin: 0 auto 50px;
  max-width: 340px;
  text-align: center;
  padding: 0 20px;
}

@media 800 {
  .subtitle {
    font-size: var(--font-size-subtitle-1);
    text-align: left;
    max-width: var(--max-width);
  }
}

@media 1100 {
  .subtitle {
    max-width: calc(var(--max-width) - 80px);
  }
}

.items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media 800 {
  .items {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 960px;
    margin: 0 20px;
  }
}

@media 900 {
  .items {
    margin: 0 30px;
  }
}

@media 1100 {
  .items {
    margin: 0 40px;
  }
}

.item {
  position: relative;
  background: var(--pri-01);
  box-shadow: 7px 4px 25px 0 rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  overflow: hidden;
  width: 280px;
  margin-bottom: 40px;
  z-index: 2;
}

@media 800 {
  .item {
    width: 240px;
    margin-right: 20px;
  }

  .item:nth-child(3n) {
    margin-right: 0;
  }
}

@media 900 {
  .item {
    margin-right: 40px;
  }
}

@media 1000 {
  .item {
    width: 280px;
  }
}

@media 1100 {
  .item {
    width: 280px;
    margin-right: 40px;
  }
}

.info {
  padding: 20px;
}

@media 800 {
  .info {
    padding: 15px;
  }
}

@media 1100 {
  .info {
    padding: 20px;
  }
}

.title {
  font-size: var(--font-size-subtitle-1);
  font-family: var(--font-bold);
  color: var(--black-d);
  margin-bottom: 15px;
}

.text {
  font-size: var(--font-size-subtitle-2);
}

.icon {
  font-size: 18px;
  stroke-width: 3px;
  margin-left: 10px;
}

@media 1100 {
  .con {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: var(--blue-xl);
    border-top-left-radius: 29px;
    border-bottom-left-radius: 29px;
    overflow: hidden;
  }
}