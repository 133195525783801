.footer {
  display: flex;
  justify-content: center;
}

.item {
  margin-bottom: 25px;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.question {
  width: 100%;
}

.condition {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.pct {
  display: flex;
  align-items: center;
  width: 50px;
  margin-right: 15px;
}

.add-condition {

}

.option {
  width: 100%;
}

.remove {
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: 24px;
  min-width: 24px;
}

.icon {
  color: var(--pri-04);
  cursor: pointer;
  width: 24px;
  height: 24px;
}