.root {
	display: none;
}

@media(min-width: 800px) {
	.root {
		display: block;
		position: absolute;
		background-color: var(--pri-01);
		background-clip: padding-box;
		border-radius: 4px;
		border: 1px solid rgba(0, 0, 0, 0.08);
		box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
	}

	.root:after,
	.root:before {
		position: absolute;
		left: auto;
		top: -14px;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		pointer-events: none;
	}

	.left {
		composes: root;
	}

	.left:after {
		border-color: rgba(255, 255, 255, 0);
		border-right-color: var(--pri-01);
		border-width: 14px;
		margin-top: 34px;
		left: -28px;
	}

	.left:before {
		border-color: rgba(189, 189, 189, 0);
		border-right-color: rgba(0, 0, 0, 0.2);
		border-width: 14px;
		margin-top: 34px;
		left: -29px;
	}

	.right {
		composes: root;
	}

	.right:after {
		border-color: rgba(255, 255, 255, 0);
		border-left-color: var(--pri-01);
		border-width: 14px;
		margin-top: 34px;
		right: -28px;
	}

	.right:before {
		border-color: rgba(189, 189, 189, 0);
		border-left-color: rgba(0, 0, 0, 0.2);
		border-width: 14px;
		margin-top: 34px;
		right: -29px;
	}

	.body {
		padding: 16px 4px;
		color: var(--black);
	}

	.header {
		font-size: 14px;
		text-align: center;
		font-family: var(--font-semibold);
		margin: 0 0 4px 0;
	}

	.text {
		font-size: 13px;
		text-align: center;
	}
}