.root {

}

.wrap {
  padding: 30px 0;
}

.main {
  display: flex;
  gap: 15px;
}

.item {
  border-radius: 5px;
  background-color: var(--pri-01);
  text-align: center;
  overflow: hidden;
  border: 1px solid var(--sec-01);
  width: 200px;
}

.item.connected:hover {
  background-color: var(--blue-xl);
  cursor: pointer;
}

.box {
  padding: 25px 20px;
}

.box:active {
  background-color: var(--blue-xl);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
}

.header {

}

.logo {
  margin-bottom: 15px;
}

.name {
  display: -webkit-box;
  position: relative;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 17px;
  max-height: calc(1.4em * 2);
  font-family: var(--font-semibold);
  color: var(--gray-d);
}

.footer {
  padding: 40px 0;
}

.btn {
  color: var(--pri-02);
  font-family: var(--font-semibold);
  width: 100%;
  height: 50px;
  border-top: 1px solid var(--gray-xl);
}

.btn:hover {
  background-color: var(--blue-xl);
  border-top: 1px solid var(--sec-01);
}

.btn:active {
  border-top: 1px solid var(--gray-l);
}

.connected .btn,
.connected .btn:hover {
  background-color: var(--blue-l);
  border-top: 1px solid var(--sec-01);
}