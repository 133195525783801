.root {

}

.label {
  margin-right: 25px;
  color: var(--black-l);
  width: 110px;
  min-width: 110px;
}

.row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.checkbox {
  margin: 5px 0;
  color: var(--black-l);
}

.label-input {
  display: flex;
  flex-grow: 1;
}

.input {
  width: 100px;
}

.total-input {
  width: 100px;
  cursor: pointer;
  border-bottom: 2px solid var(--gray-l);
  line-height: 30px;
}

/* Chrome, Safari, Edge, Opera */
.root input::-webkit-outer-spin-button,
.root input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.root input[type=number] {
  -moz-appearance: textfield;
}

.editor {
  width: 100%;
}