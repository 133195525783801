.root {
  display: flex;
  align-items: center;
  border: 1px solid var(--gray-l);
  background-color: var(--pri-01);
  color: var(--gray-d);
  font-family: var(--font-semibold);
  font-size: 12px;
  padding: 4px 9px 5px 9px;
  border-radius: 8px;
}

.readonly {

}

.selectable:hover {
  cursor: pointer;
  background-color: var(--blue-l);
  border-color: var(--pri-02);
  color: var(--pri-02);
}

.selected {
  background-color: var(--blue-l);
  border-color: var(--pri-02);
  color: var(--pri-02);
}

.selected:after {
  content: '✕';
  padding-left: 20px;
  display: inline-block;
  width: 12px;
}