.table-root {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: 0 auto;
}

.table-header {
  font-family: var(--font-bold);
}

.table-header .table-row {
  min-height: 40px;
  align-items: center;
}

.table-header .table-cell {
  padding: 8px 4px;
}

.table-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #dadada;
}

.table-cell {
  font-size: 14px;
  padding: 4px;
  flex: 1;
}

.sortable {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}

.icon-container {
  display: inline-flex;
  align-items: center;
}

.sort-icon {
  height: 24px;
}