.root {
  --height-project-header: 155px;
  --height-project-tabbar: 45px;
  --height-project-page: calc(100vh - var(--navbar-height));
  --height-project-tab: calc(var(--height-project-page) - (var(--height-project-header) + var(--height-project-tabbar)));
  --gutter-width: 15px;
  height: var(--height-project-page);
  max-width: var(--navbar-width);
  width: 100%;
  margin: 0 auto;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 10px;
}