.root {
  display: inline-block;
  position: relative;
  width: 100%;
}

.input {
  composes: input from 'css/input.css';
  font-size: 16px;
}

.number-input {
  composes: input;
  line-height: 24px;
  padding-bottom: 1px;
}

.time-root {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.time-input {
  composes: input;
  width: 18px;
  padding: 0px 1px;
}

/* Chrome, Safari, Edge, Opera */
.time-input::-webkit-outer-spin-button,
.time-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.time-input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}


.fancy-input {
  --border-width: 2px;
  --padding-x: 8px;
  font-family: var(--font-reg);
  font-size: 16px;
  padding: 3px var(--padding-x);
  border: var(--border-width) solid var(--gray-l);
  border-radius: 6px;
  width: calc(100% - ((var(--padding-x) * 2) + (var(--border-width) * 2)));
}

.fancy-input:focus {
  border: var(--border-width) solid var(--pri-02);
}

.fancy-input::placeholder {
  color: var(--gray);
}