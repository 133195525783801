.file-input {
  display: none;
}

.upload {
  display: block;
  cursor: pointer;
  padding: 10px 20px;
  box-sizing: border-box;
}

.menu-item {
  box-sizing: border-box;
  height: 40px;
  min-width: 140px;
}

.no-padding {
  padding: 0;
}