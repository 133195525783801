.add {
  margin-top: 20px;
}

.attribute {
  margin-top: 30px;
}

.settings {
  margin-top: 20px;
}