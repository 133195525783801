.list {
  max-width: 500px;
}

.list-item {
  padding: 20px 0;
  border-top: 1px solid var(--gray-xl);
}

.list-title {
  margin-bottom: 15px;
}

.list-option {
  margin-bottom: 0;
}