.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.header {
  font-family: var(--font-bold);
}

.hint {
  font-style: italic;
  color: var(--gray-m);
}

.input-label {
  cursor: pointer;
  color: var(--pri-02);
  font-family: var(--font-semibold);
  display: flex;
  align-items: center;
  gap: 5px;
}

.file-input {
  display: none;
}

.error-label {
  color: var(--pri-04);
  font-weight: var(--font-semibold);
}

.error-content {
  max-height: 40vh;
  overflow-y: auto;
  white-space: pre;
  font-size: 14px;
}