@value 800, 1000 from 'vars';

.root {
  border-top: 1px solid var(--gray-xl);
}

.wrap {
  margin: 0 auto;
  padding: 0 20px 40px;
}

.main {
  max-width: 400px;
  margin: 0 auto;
}

@media 800 {
  .main {
    max-width: 700px;
  }
}

@media 1000 {
  .main {
    max-width: var(--max-width);
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.header {
  max-width: var(--max-width);
  text-align: center;
  margin: 0 auto 20px;
}

@media 1000 {
  .header {
    text-align: left;
    margin: 0 auto 30px;
  }
}

.title {
  font-size: var(--font-size-header);
  font-family: var(--font-bold);
  color: var(--black-d);
  max-width: 400px;
  margin: 30px auto;
}

.subheader {
  font-size: 18px;
  color: var(--black-l);
  margin: 0 auto;
  max-width: 400px;
}

@media 800 {
  .subheader {
    font-size: 20px;
    max-width: 525px;
    margin: 0 auto 40px;
  }
}

@media 1000 {
  .title {
    margin: 40px 0 30px;
  }

  .subheader {
    margin: 0;
  }
}

.img {
  display: none;
  width: 100%;
  height: auto;
  animation-name: fade;
  animation-duration: 300ms;
}

.active .img {
  display: block;
}

@media 1000 {
  .active .img {
    width: 740px;
  }
}

.name {
  font-size: 18px;
  font-family: var(--font-semibold);
  color: var(--gray-m);
  transition: color 150ms ease-in;
}

.active .name {
  color: var(--pri-02);
}

@media 800 {
  .name {
    font-size: 20px;
  }
}

@media 1000 {
  .name {
    font-size: 24px;
  }

  .active .name {
    font-size: 26px;
  }
}

.underline {
  height: 3px;
  border-radius: 4px;
  width: 50px;
  margin: 5px auto;
  transition: background-color 100ms ease-in;
}

.active .underline {
  background-color: var(--pri-02);
}

@media 1000 {
  .underline {
    width: 70px;
    height: 4px;
    margin: 10px 0 20px;
  }
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  padding: 10px;
}

@media 800 {
  .tabs {
    padding: 0;
  }
}

@media 1000 {
  .tabs {
    flex-direction: column;
    justify-content: flex-start;
    margin: 20px 0;
    width: 450px;
  }
}

.tab {
  cursor: pointer;
}

@media 1000 {
  .tab {
    max-height: auto;
    margin-bottom: 30px;
    transition: max-height 800ms ease-in;
  }
}

.panel {
  display: none;
}

.panel.active {
  display: block;
}

@media 800 {
  .panel.active {
    background-image: url('static/images/website/customer-research/dots-right.svg?url');
    background-repeat: no-repeat;
    background-position-x: right;
  }
}

.media {
  display: none;
}

.active .media {
  display: block;
}

@media 800 {
  .media {
    background-image: url('static/images/website/customer-research/bg-spot-blue-tablet.svg?url');
    background-repeat: no-repeat;
    background-position: right bottom;
  }
}

@media 1000 {
  .media {
    background-image: url('static/images/website/customer-research/bg-spot-blue-desktop.svg?url');
    background-repeat: no-repeat;
    background-position: right bottom;
  }
}

.subtitle {
  display: none;
  text-align: center;
  font-size: 18px;
  color: var(--gray-d);
  margin: 0 auto;
}

.active .subtitle {
  display: block;
}

.description {
  display: none;
  font-size: 18px;
  color: var(--black-l);
}

@media 1000 {
  .active .description {
    display: block;
    max-width: 320px;
  }

  .active .subtitle,
  .subtitle {
    display: none;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}