.root {
  font-family: var(--font-bold);
  color: var(--pri-02);
  font-size: 20px;
}

.link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.icon {
  font-size: 18px;
  stroke-width: 3px;
  margin-left: 10px;
}