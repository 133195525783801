.topics {
  border: none;
  overflow: hidden;
}

.item {
  visibility: hidden;
  animation-name: fade;
  animation-duration: 600ms;
}

.item.in {
  visibility: visible;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}