.empty {
  text-align: center;
  padding: 35px 0;
}

.text {
  margin-top: 40px;
  font-family: var(--font-reg);
  color: var(--gray-d);
}

.title {
  color: var(--gray-d);
  font-family: var(--font-semibold);
}