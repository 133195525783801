.root {
  text-align: center;
}

.inbox {
  color: var(--gray-l);
  margin-bottom: 5px;
  stroke-width: .5px;
}

.title {
  margin-bottom: 20px;
  font-family: var(--font-semibold);
  color: var(--gray-d);
}

.text {
  margin-bottom: 30px;
  color: var(--gray-d);
}