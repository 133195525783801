.root {

}

.item {
  margin-bottom: 15px;
}

.item:last-of-type {
  margin-bottom: 0;
}

.checkbox {
  margin-top: 20px;
}

.dropdown {
  width: 55px;
}