.root {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  text-align: center;
}

.header {
  font-size: 24px;
}

.title {
  font-size: 18px;
}

.boost-graphic {
  height: 160px;
}

.continue-btn {
  width: 300px;
}