.buttons {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.toolbar-root {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.toolbar {
  box-shadow: var(--box-shadow);
  border: 1px solid var(--gray-l);
  border-radius: 4px;
  display: inline-flex;
  height: 40px;
  overflow: hidden;
}

.toolbar .button {
  padding: 4px 8px;
  min-width: 16px;
  cursor: pointer;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.toolbar .button.disabled {
  cursor: not-allowed;
  opacity: .5;
}

.toolbar .button.pressed {
  background-color: var(--gray-l);
}

.toolbar .button:hover {
  background-color: var(--gray-xl);
}

.color-root {
  display: flex;
  gap: 4px;
  align-items: center;
}

.color-circle {
  height: 16px;
  width: 16px;
  border-radius: 100%;
}

.color-input {
  opacity: 0;
  height: 0px;
  width: 0px;
}

.mui-icon {
  height: 18px;
  width: 18px;
}

.structured-icon {
  height: 18px;
  width: 36px;
  border-width: 1px;
  border-style: solid;
}

.structured-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: var(--gray-d);
  padding: 0px 6px;
  font-family: var(--font-bold);
}

.spacer {
  flex-grow: 1;

}

.right-buttons {
  display: flex;
  gap: 10px;
}

.editor-container {
  display: flex;
  justify-content: center;
  border: 2px solid var(--gray-l);
}

.vanilla-editor :global(.tui-image-editor-canvas-container) {
  margin-left: auto;
  margin-right: auto;
}

.color-menu {
  display: inline-grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 10px;
  box-shadow: var(--box-shadow);
  background-color: white;
}

.color-button {
  composes: color-circle;
  cursor: pointer;
  height: 20px;
  width: 20px;
}