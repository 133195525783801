.root {
  --width: 178px;
  background: var(--black);
  position: relative;
  display: flex;
  align-items: center;
  height: 100px;
  width: var(--width);
  overflow: hidden;
  cursor: pointer;
}

.root video {
  filter: none;
}

.root .network {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s linear, opacity 0.2s linear;
}

.root:hover .network {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.no-network {
  color: var(--pri-04);
  height: 20px;
}

.root .pinned:not(.selected) {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s linear, opacity 0.2s linear;
}

.root:hover .pinned:not(.selected) {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.info {
  position: absolute;
  background: transparent;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.topleft {
  position: absolute;
  left: 0;
  top: 0;
  padding: 4px 0 0 4px;
}

.topright {
  position: absolute;
  right: 0;
  top: 0;
  padding: 4px 4px 0 0;
}

.bottomleft {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 0 3px 3px;
}

.bottomright {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 4px 4px 0;
}

.name {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  color: var(--gray-xl);
  padding: 3px 5px;
  font-family: var(--font-semibold);
  font-size: 12px;
  max-height: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.network {
}

.switched-off video {
  filter: blur(4px) grayscale(1) brightness(0.5);
}

.no-video {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--pri-01);
  height: 100%;
  width: 100%;
}

.avatar,
.no-video svg {
  width: 45px;
  height: 45px;
}

.pinned {
  display: flex;
  /* background: rgba(0, 0, 0, 0.4);
  border-radius: 100%;
  padding: 3px 3px; */
  color: var(--gray-d);
}

.pinned svg {
  width: 18px;
  height: 18px;
}

.selected {
  /* background: none;
  border-radius: 100%;
  padding: 3px 3px; */
  display: block;
  color: var(--pri-04);
}

.bandwidth-warning {
  color: var(--pri-01);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.bandwidth-warning-copy {
  font-size: 12px;
  font-family: var(--font-semibold);
}