.root {
  padding: 15px 25px;
  border-bottom: 1px solid var(--gray-xl);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  min-height: 70px;
}

.label {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.label-value {
  color: var(--gray-d);
  font-family: var(--font-semibold);
  margin-right: 5px;
}

.score {
  color: var(--gray-m);
  min-width: 100px;
}

.closed {
  cursor: pointer;
}

.closed:hover {
  background-color: var(--blue-xl);
}