.question {
  border-bottom: 1px solid var(--gray-xl);
}

.question:first-of-type {
  border-top: 1px solid var(--gray-xl);
}

.add {
  margin-top: 15px;
}