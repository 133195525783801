@value 800 from 'vars';

.root {
  height: 100%;
}

.wrap {
  padding: 15px 25px 25px 25px;
}

.main {
  margin: 0 auto;
  max-width: 1280px;
}

.skeleton {
  padding: 20px;
  background-color: var(--gray-bg);
}

.skeleton .item {
  margin-bottom: 30px;
}

.header {
  font-size: 24px;
  font-family: var(--font-bold);
  color: var(--black);
  margin-bottom: 20px;
}

@media 800 {
  .header {
    /* margin-bottom: 40px; */
  }
}

.tablet {
  display: none;
}

@media 800 {
  .tablet {
    display: block;
  }
}

.row .creators {
  margin-bottom: 40px;
}

.main .row {
  border-bottom: 2px solid var(--gray-xl);
}

.tablet.row {
  margin-bottom: 25px;
}

.main > :not(.tablet):last-child .row {
  border-bottom-color: transparent;
}