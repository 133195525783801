.table {
  color: var(--gray-d);
  font-size: 15px;
  border-spacing: 0;
  border-style: hidden;

  --border-radius: 8px;
  --thick-border: 1px solid var(--gray-l);
  --thin-border: 1px solid var(--gray-xl);
}

.table td:not(:first-child) {
  text-align: center;
}

.table th, .table td {
  padding: 10px;
}

.table tbody tr td:first-child,
.table thead th
{
  background-color: var(--gray-xxl);
  font-family: var(--font-semibold);
}

.table thead th {
  border-bottom: var(--thick-border);
  border-top: var(--thick-border);
  border-right: var(--thick-border);
  border-left: none;
}

.table thead th:nth-child(2) {
  border-left: var(--thick-border);
}

.table tr td:first-child {
  border-right: var(--thick-border);
  border-left: var(--thick-border);
  border-bottom: var(--thick-border);
}

.table tr td:not(:first-child) {
  border-top: none;
  border-left: none;
  border-bottom: var(--thin-border);
  border-right: var(--thin-border);
}

.table tbody tr:last-child td {
  border-bottom: var(--thick-border);
}

.table thead th:first-child {
  background-color: var(--pri-01);
  border: none;
}

.table tbody tr:first-child td:first-child {
  border-top: var(--thick-border);
}

.table tbody tr td:last-child {
  border-right: var(--thick-border);
}




.table thead th:nth-child(2),
.table tbody tr:first-child td:first-child {
  border-top-left-radius: var(--border-radius);
}

.table tbody tr:last-child td:last-child {
  border-bottom-right-radius: var(--border-radius);
}

.table thead th:last-child {
  border-top-right-radius: var(--border-radius);
}

.table tbody tr:last-child td:first-child {
  border-bottom-left-radius: var(--border-radius);
}