.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.profile-container {
  padding: 20px;
  flex-grow: 1;
}

.avatar {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.name {
  font-family: var(--font-semibold);
  font-size: 18px;
  color: var(--black-l);
}

.name:hover {
  color: var(--hyperlink);
  text-decoration: underline;
}

.career {
  font-size: 14px;
  margin-top: 5px;
  color: #6C6C6C;
  height: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btns {
  border-top: 1px solid var(--gray-xl);
  display: flex;
  padding: 0px;
}

.btns button {
  width: 100%;
  border-radius: 0px;
  padding: 10px;
  color: var(--pri-02);
  font-family: var(--font-bold);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btns button svg {
  margin-right: 5px;
}

.btns button:first-child {
  border-bottom-left-radius: 10px;
}

.btns button:last-child {
  border-bottom-right-radius: 10px;
}

.btns button:not(:last-child) {
  border-right: 1px solid var(--gray-xl);
}

.btns button:hover {
  background: var(--blue-xl);
}