.root {
  max-width: 900px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 0 15px;
}

.wrap {
  position: relative;
  max-width: 800px;
  padding: 20px;
  margin: 0 auto;
}

.main {

}

.back {
  margin-bottom: 15px;
}

.label {
  font-size: 16px;
  font-family: var(--font-semibold);
  color: var(--black);
  line-height: 20px;
  margin-right: 5px;
}

.value {
  font-size: 16px;
  line-height: 20px;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.download button {
  color: var(--pri-02);
  cursor: pointer;
}

.download:hover button {
  text-decoration: underline;
  font-family: var(--font-reg);
}