.email-row {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.sub-title {
  margin-bottom: 40px;
  font-size: 18px;
}

.buttons {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.buttons>button {
  flex-grow: 1;
}

.copy-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.continue-btn {
  width: 100%;
}

.graphic-container {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.boost-graphic {
  height: 160px;
}