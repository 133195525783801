.help-text {
  margin-top: 25px;
  color: var(--gray);
}

.section {
  min-height: 220px;
  padding-bottom: 75px;
}

.copy-values {
  color: var(--sec-02);
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 15px;
}