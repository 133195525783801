.call-cell {
  min-width: 75px;
  flex: 0;
}

.time-cell {
  min-width: 110px;
  flex: 0;
}

.text-cell {
  flex-grow: 1;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 0;
}

.pagination {

}

.link {
  color: var(--hyperlink);
  cursor: pointer;
}