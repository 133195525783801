@value 800, 1100 from 'vars';

.root {
  position: relative;
    background-image:
      url('static/images/website/collaboration-tools/bg-app-integration-dots-top-s.svg?url'),
      url('static/images/website/collaboration-tools/bg-app-integration-dots-bottom-s.svg?url');
  background-position:
    top,
    bottom;
  background-repeat: no-repeat;
  background-size: auto;
}

@media 800 {
  .root {
    margin-top: 80px;
    background-image:
      url('static/images/website/collaboration-tools/bg-app-integration-dots-top-m.svg?url'),
      url('static/images/website/collaboration-tools/bg-app-integration-dots-bottom-m.svg?url');
  }
}

@media 1100 {
  .root {
    margin-top: 0;
    background-image:
      url('static/images/website/collaboration-tools/bg-app-integration-dots-top-l.svg?url'),
      url('static/images/website/collaboration-tools/bg-app-integration-dots-bottom-l.svg?url');
  }
}

.wrap {
  max-width: 1280px;
  margin: 0 auto;
}

.content {
  padding: 100px 20px;
}

@media 1100 {
  .content {
    padding: 120px 20px;
  }
}

.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 390px;
}

@media 800 {
  .main {
    flex-wrap: nowrap;
    max-width: 750px;
  }
}

@media 1100 {
  .main {
    max-width: 1000px;
  }
}

.header {
  font-size: 30px;
  color: var(--black-d);
  font-family: var(--font-bold);
  text-align: center;
  margin: 0 auto 30px;
  max-width: 400px;
}

@media 800 {
  .header {
    max-width: 100%;
    font-size: var(--font-size-header);
    margin-bottom: 40px;
  }
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100px;
}

.icon {
  width: 43px;
}

.m {
  display: none;
}

@media 800 {
  .m {
    display: inherit;
  }
}

.l {
  display: none;
}

@media 1100 {
  .l {
    display: inherit;
  }
}