.root {
  width: 100%;
  max-width: 100%;
  height: calc(100vh - var(--navbar-height));
  min-height: calc(100vh - var(--navbar-height));

  --header-height: 70px;
}

.wrap {
  box-sizing: border-box;
  min-height: auto;
  height: calc(100% - 40px);
  margin: 20px 0;
}

.iframe {
  width: 100%;
  height: calc(100vh - var(--navbar-height) - var(--header-height) - 40px);
  border: none;
  box-shadow: none;
}

.section {
  width: 100%;
  height: 100%;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid var(--sec-01);
}

.header {
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
  height: var(--header-height);
  border-bottom: 1px solid var(--sec-01);
  box-sizing: border-box;
}

.h2 {
  color: var(--black-l);
  font-size: 22px;
  line-height: 35px;
  font-family: var(--font-semibold);
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title {
  display: flex;
  align-items: center;
}

.link {
  margin-left: 15px;
  cursor: pointer;
  margin-top: 3px;
}

.link:hover {
  color: var(--hyperlink);
}