.modal {
  max-width: 750px;
  width: 100%;
  --max-height-root: calc(90vh - 85px);
  --height-view: calc(90vh - 330px);
}

.root {
  position: relative;
  max-height: var(--max-height-root);
  height: 100%;
  overflow: hidden;
  padding: 0;
  --height-footer: 45px;
}

.wrap {
  height: 100%;
  padding: 0;
}

.main {
  position: relative;
  height: calc(100% - var(--height-footer));
}

.header {
  font-family: var(--font-semibold);
  color: var(--black-l);
  font-size: 19px;
  max-width: 500px;
  padding: 30px 20px 10px;
  margin-right: 40px;
  height: 65px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--height-footer);
  border-top: 1px solid var(--gray-l);
}

.btn {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  height: 100%;
  width: 100%;
}

.btn:hover {
  color: var(--pri-02);
}

.btn:active {
  background-color: var(--blue-l);
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.view {
  overflow: scroll;
  height: var(--height-view);
  padding: 0 20px var(--height-footer);
}