.notify {
  cursor: pointer;
  display: block;
  margin: 20px 0 10px 0;
  user-select: none;
}

.check {
  margin-right: 15px;
}

.buttonset {
  margin-bottom: 15px;
}

.btn {
  composes: brick from 'css/button.css';
  width: 130px;
}

.cancel {
  composes: btn;
  color: var(--gray-d);
  border: 2px solid var(--gray-d);
}