.groups {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.group {
  display: flex;
  gap: 10px;
  align-items: center;
}

.image {
  height: 200px;
}

.settings {
  margin-top: 10px;
  color: var(--gray-d);
}

.settings ul {
  margin-top: 5px;
  margin-bottom: 5px;
}

.settings-title {
  font-family: var(--font-bold);
}