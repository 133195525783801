.link {
  color: var(--hyperlink);
  cursor: pointer;
}

.btn {
  padding: 4px 8px;
  border-radius: 12px;
  background-color: var(--gray-xl);
  font-family: var(--font-semibold);
  color: var(--gray-d);

  display: flex;
  align-items: center;
}

.btn.open {
  color: var(--pri-02);
  background-color: var(--blue-l);
}

.chevron {
  margin-left: 2px;
  stroke-width: 3px;
}