.root {
  background-color: var(--sec-01);
  border-radius: 4px;
  min-height: 500px;
}

.dragging-over {
  background-color: var(--blue-l);
  border-color: var(--blue-l);
}

.wrap {
  padding: 10px 15px;
  height: 100%;
}

.item {
  margin-bottom: 10px;
}

.title {
  font-family: var(--font-semibold);
  margin: 15px 0 25px 0;
  text-align: center;
}

.droparea {
  overflow-y: auto;
  height: 100%;
  max-height: 560px;
  box-sizing: border-box;
}