.root {
  display: none;
}

@media (min-width: 800px) {
  .root {
    display: flex;
  }
}

.item {
  composes: col from './AppBar.css';
  justify-content: center;
  width: 100px;
}

.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  height: 60px;
  cursor: pointer;
}

.name {
  font-size: 11px;
  font-family: var(--font-semibold);
  color: var(--black-l);
}

.icon {
  color: var(--black-l);
  width: 30px;
  height: 30px;
  margin-bottom: 2px;
}

.box {
  composes: box from './AppBar.css';
}

.badge {
  composes: badge from './AppBar.css';
}

.dropdown {
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 3;
  width: 370px;
  max-height: 600px;
  background-color: var(--pri-01);
  background-clip: padding-box;
  border: 1px solid var(--gray-l);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow: 1px 1px 2px 1px rgba(238, 238, 238, 1);
  overflow-y: auto;
}

.visible {
  composes: dropdown;
  composes: visible from 'css/display.css';
}

.hidden {
  composes: dropdown;
  composes: hidden from 'css/display.css';
}

.btn {
  padding: 0;
  height: 60px;
  min-width: auto;
}