.modal {
  width: 750px;
}

.header {
  text-align: left;
}

.footer {
  padding-bottom: 30px;
}

.project-table {
  padding: 30px 0;
}

.project-names-table {
  margin-top: 20px;
}

.table-body {
  min-height: 100px;
  max-height: 400px;
  overflow-y: auto;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--gray-xl);
  height: 35px;
  border-top: 1px solid var(--sec-01);
  font-family: var(--font-bold);
  font-size: 15px;
  margin-bottom: 0;
  padding: 0px 5px;
}

.table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid var(--sec-01);
  padding: 0px 5px;
}

.table-row:first-child {
  border-top: 1px solid var(--sec-01);
}

.project-cell {
  flex-grow: 1;
}

.role-cell {
  flex-shrink: 0;
  width: 150px;
}

.checkbox-cell {
  width: 30px;
}

.project-name-cell {
  flex-grow: 1;
}