.viewAll {
  display: flex;
  align-items: center;
}

.chevron {
  cursor: pointer;
  color: var(--gray-d);
}

.hoverText {
  display: none;
  color: var(--hyperlink);
  white-space: nowrap;
}

a:hover .hoverText {
  display: block;
}

a:hover .chevron {
  color: var(--pri-02);
}

a:hover .chevronIcon {
  color: var(--pri-02);
}