.item {
  margin-bottom: 25px;
}

.row {
  display: flex;
  margin-bottom: 15px;
}

.field {
  --border-width: 2px;
  --padding-horizontal: 15px;
  width: 100%;
}

.label {
  font-size: 16px;
}

.tooltip {
  margin-left: 10px;
}

.input {
  font-family: var(--font-reg);
  font-size: 16px;
  padding: 10px var(--padding-horizontal);
  border: var(--border-width) solid var(--gray-l);
  border-radius: 5px;
  width: calc(100% - ((var(--border-width) * 2) + (var(--padding-horizontal) * 2)));
  resize: none;
  line-height: normal;
}

.input:focus {
  border: var(--border-width) solid var(--pri-02);
  outline-color: var(--pri-02);
}