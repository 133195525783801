@value 800, 1100 from 'vars';

.root {
  border-top: 1px solid var(--gray-xl);
}

.wrap {

}

@media 800 {
  .wrap {
    margin-top: 40px;
  }
}

@media 1100 {
  .wrap {
    margin-top: 0;
  }
}

.content {
  margin: 0 auto;
  padding: 40px 20px;
}

@media 800 {
  .content {
    max-width: 890px;
    background-image: url('static/images/website/collaboration-tools/bg-blob-red.png');
    background-repeat: no-repeat;
    background-position: 100% 60%;
  }
}

@media 1100 {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1280px;
    background-position: 100% 75%;
  }
}

.main {
  max-width: 400px;
  margin: 40px auto;
}

@media 800 {
  .main {
    max-width: var(--max-width);
  }
}

@media 1100 {
  .main {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    max-width: var(--max-width);
    width: 100%;
  }
}

.header {

}

.img {
  width: 100%;
  height: auto;
  margin-top: 40px;
}

.graphic {
  width: 100%;
  margin: 0 auto;
}

@media 800 {
  .graphic {
    max-width: 100%;
  }

  .img {
    display: block;
    max-width: 700px;
    margin: 0 auto 30px;

    width: unset;
    height: unset;
  }
}

@media 1100 {
  .graphic {
    height: auto;
    margin: 0;
    display: flex;
    justify-content: flex-end;
  }

  .img {
    max-width: 650px;
    margin: unset;
    margin-left: -30px;
  }
}

.info {

}

@media 800 {
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 425px;
  }
}

.title {
  font-size: 30px;
  color: var(--black-d);
  font-family: var(--font-bold);
  margin-bottom: 30px;
}

@media 800 {
  .title {
    font-size: var(--font-size-header);
    text-align: center;
    max-width: 800px;
    margin: 0 auto 40px;
  }
}

@media 1100 {
  .title {
    max-width: 360px;
    text-align: left;
    margin: 0 0 30px;
  }
}

.subtitle {
  font-size: 20px;
  color: var(--black-l);
  margin-bottom: 50px;
  max-width: 370px;
}

@media 800 {
  .subtitle {
    max-width: 730px;
    text-align: center;
    margin: 0 auto;
  }
}

@media 1100 {
  .subtitle {
    max-width: 340px;
    text-align: left;
    margin: 0;
  }
}