.root {

}

.wrap {

}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--gray-l);
  box-shadow: 0 1px 0 0 var(--sec-01);
  margin-bottom: 20px;
}

.main {
}

.label {
  color: var(--black-l);
  font-family: var(--font-bold);
  font-size: 16px;
  margin-bottom: 10px;
}

.field {
  margin-bottom: 15px;
}

.row {
  margin-bottom: 30px;
}

.title {
  font-size: 24px;
  font-family: var(--font-bold);
  color: var(--black);
  line-height: 45px;
}

.h2 {
  font-size: 21px;
  font-family: var(--font-semibold);
  margin-bottom: 15px;
}