.body {

}

.dropdowns {
  display: flex;
  justify-content: center;
}

.item-dropdown {
  width: 125px;
}

.position-dropdown {
  margin-right: 20px;
  width: 125px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.footer > *:nth-child(2) {
  margin-left: 15px;
}

.body {
  max-width: 500px;
  margin: 0 auto;
}

.note {
  font-size: 15px;
  margin: 0 auto;
  margin-top: 15px;
  color: var(--gray-m);
  width: 350px;
  text-align: center;
}