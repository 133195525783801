.root {
  height: 64px;
  width: 64px;
}

.wrap {
  position: relative;
  overflow: hidden;
}

.image {
  box-sizing: border-box;
  border-radius: 15px;
  max-height: 100%;
  max-width: 100%;
}

.placeholder {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f5f5f5;
  height: 100%;
  width: 100%;
}

.placeholder.svg {
  color: var(--gray-d);
  padding: 0 30%;
  height: 100%;
  width: 100%;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.025);
}