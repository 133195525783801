@value 800, 1000 from 'vars';

.root {
  margin: 0 auto;
}

.wrap {
  height: calc(100vh - var(--navbar-height));
  min-height: 640px;
  padding: 0 20px 40px;
  max-width: 1280px;
  margin: 0 auto;
}

@media 800 {
  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.main {
  max-width: 400px;
  margin: 0 auto;
}

@media 800 {
  .main {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    max-width: unset;
  }
}

.animation {
  position: relative;
  margin: 40px 0;
  padding-top: 67.5%;
}

.animation.ready {

}

@media 800 {
  .animation {
    padding-top: unset;
    padding-left: 20px;
  }
}

@media 1000 {
  .animation {
    padding: 0 0 0 40px;
    width: 100%;
    max-width: 640px;
  }
}

.video {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

@media 800 {
  .video {
    position: relative;
  }
}

.ready .video {
  opacity: 1;
  animation-name: fade;
  animation-duration: 300ms;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
}

.video::-webkit-media-controls-enclosure {
  display: none;
}

.video::-webkit-media-controls {
  visibility: hidden;
}

.info {
  max-width: 320px;
  margin: 0 auto;
}

@media 800 {
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media 1000 {
  .info {
    max-width: 430px;
    margin: 0;
  }
}

.title {
  font-size: 30px;
  color: var(--black-d);
  font-family: var(--font-bold);
  margin-bottom: 30px;
}

@media 1000 {
  .title {
    font-size: 40px;
  }
}

.subtitle {
  font-size: 20px;
  color: var(--black-l);
  margin-bottom: 50px;
}

.btnset {
  display: flex;
  flex-direction: column;
}

.btn {
  width: 100%;
}

.btnset .btn:first-child {
  margin-bottom: 10px;
}

@media 800 {
  .btnset {
    flex-direction: row;
  }

  .btn {
    width: 150px;
  }

  .btnset .btn:first-child {
    margin: 0 20px 0 0;
  }

}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}