.track {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.track .active {
  background-color: var(--gray);
  cursor: pointer;
}

.track .inactive {
  background-color: var(--gray-xl);
  cursor: pointer;
}

.thumb {
  width: 16px;
  height: 16px;
  border: none;
  background-color: var(--black-l);
}