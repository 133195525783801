.root {

}

@media (min-width: 600px) {
  .root {
    padding: 0 25px;
  }
}

.header {
  text-align: left;
  margin-bottom: 25px;
}

.copy {
  margin-bottom: 20px;
}

.section {
  margin-bottom: 20px;
}

.label {
  font-family: var(--font-semibold);
  color: var(--black);
  font-size: 14px;
  margin-bottom: 10px;
}

.rate-section {
  composes: section;

  margin-bottom: 40px;
}

.rate-label {
  composes: label;
  margin-bottom: 0;
}

.rate-header {
  display: flex;
  align-items: center;

  margin-bottom: 20px;
}

.rate-info {
  display: none;
}

@media (min-width: 900px) {
  .rate-info {
    display: block;
  }
}

.rate-value {
  width: 45px;
  margin: 0 5px 0 10px;
  color: var(--pri-02);
  font-family: var(--font-semibold);
}

.reset {
  margin-top: 10px;
  text-align: right;
  color: var(--gray);
  cursor: pointer;
  font-size: 14px;
}

.reset:hover {
  text-decoration: underline;
}

.copy {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.link {
  border:2px solid var(--gray-l);
  border-radius: 3px;
  width: calc(100% - 16px);
  padding: 8px;

  font-size: 14px;
  color: var(--black);
  margin-bottom: 5px;
}

.link::selection {
  background: transparent;
}

@media (min-width: 450px) {
  .copy {
    flex-direction: row;
  }

  .link {
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.rate-bar {
  width: 100%;
  height: 40px;
}

@media (min-width: 450px) {
  .rate-bar {
    width: 300px;
    height: 40px;
  }
}