.root {
  position: absolute;
  top: 0;
  right: 15px;
}

.btn {
  height: 35px;
  font-size: 14px;
  padding: 5px 10px;
}

.btn + .btn {
  margin-left: 10px;
}

.approve {
  composes: btn;
  color: var(--pri-03);
  border-color: var(--pri-03);
  background-color: var(--pri-01);
}

.approve:hover {
  background-color: var(--greenish-white);
}

.reject {
  composes: btn;
  color: var(--pri-04);
  border-color: var(--pri-04);
  background-color: var(--pri-01);
}

.reject:hover {
  background-color: var(--reddish-white);
}