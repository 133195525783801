.question {
  margin-bottom: 20px;
}

.question:last-of-type {
  border-bottom: 1px solid var(--gray-xl);
}

.short-input {

}

@media (min-width: 500px) {
  .short-input {
    width: 80%;
    max-width: 600px;
  }
}

.offset-left {
  composes: offset-left from '@/scenes/surveys/style.css';
}

.question-text {
  color: var(--gray-d);
}