.root {

}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  min-height: 30px;
}

.info {
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
  padding-bottom: 10px;
}

.back {
  position: absolute;
  top: 15px;
  left: 15px;
}

.subheader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 25px;
  max-width: 300px;
  border-bottom: 1px solid var(--sec-01);
}

.subheader .h2 {
  margin-top: 0;
}

.day {
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-semibold);
}

.h2 {
  font-size: 19px;
  font-family: var(--font-bold);
  color: var(--black-l);
  margin: 10px 0;
}

.arrow {
  width: 28px;
  height: 28px;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
}

.propose {
  font-size: 14px;
  color: var(--pri-02);
  font-family: var(--font-reg);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-top: 1px solid var(--gray-l);
  background-color: var(--gray-xl);
}

.scroll {
  overflow: scroll;
  height: calc(100% - 85px);
  padding: 15px;
}

.confirm {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 15px 15px;
  background-color: var(--gray-xl);
}

.btn {
  position: relative;
  height: 55px;
  width: 100%;
}

.btn:disabled,
.btn:hover {
  border: 2px solid var(--pri-03);
  background-color: var(--pri-03);
  color: var(--pri-01);
}

.indicator {
  background-color: var(--pri-01);
}