.root {

}

.table {

}

.wrap {
  background-color: var(--gray-xl);
  font-family: var(--font-bold);
  color: var(--black-l);
  border-top: 1px solid var(--sec-01);
  border-bottom: 1px solid var(--sec-01);
}

.header {
  display: flex;
  align-items: center;
  font-family: var(--font-bold);
  padding: 10px 5px;
}

.th {
  display: flex;
  font-size: 14px;
}

.th span:nth-child(2) {
  margin-left: 5px;
  line-height: 14px;
  height: 14px;
}

.body {
  font-size: 14px;
}

.row {
  padding: 5px 0;
  height: 30px;
  border-bottom: 1px solid var(--sec-01);
}

.row:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 5px;
}

.marked:hover,
.marked {
  background-color: var(--blue-xl);
}

.tags {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tag {
  background-color: var(--gray-xl);
  border-radius: 3px;
  padding: 5px 10px;
  color: var(--black-l);
  font-size: 14px;
}

.pagination {
  padding: 0 10px;
}