.root {
  display: inline-block;
  position: relative;
  width: 100%;
  outline: none;
}

.underline {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: 2px;
  box-sizing: border-box;
  margin: auto;
  opacity: 0;
}

.shrink {
  composes: underline;
  transform: scaleX(0);
  transition: opacity 120ms ease-out,
              transform 120ms ease-out,
              width 0s linear 180ms;
}

.grow {
  composes: underline;
  opacity: 1;
  width: 100%;
  transition: width 180ms ease-out,
              opacity 120ms ease-in,
              transform 120ms ease-in;
}

.focused {
  composes: grow;
  composes: underline;
  background-color: var(--pri-02);
}

.unfocused {
  composes: shrink;
  composes: underline;
  background-color: var(--pri-02);
  transform: scaleX(0);
}

.blurred {
  composes: grow;
  composes: underline;
  background-color: var(--gray-l);
}

.unblurred {
  composes: shrink;
  composes: underline;
  background-color: var(--gray-l);
  opacity: 0.8;
  width: 100%;
  transform: scaleX(1);
}

.readonly {
  opacity: 1;
  width: 100%;
  composes: underline;
  background-color: var(--gray-l);
}