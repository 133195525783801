.root {

}

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffedea;
  padding: 2px 4px;
  border-radius: 4px;
}

.main {
  color: #ff0804;
  font-size: 12px;
}

.verification {
  display: flex;
  align-items: center;
  stroke-width: 1.5;
  font-size: 13px;
  border-radius: 50%;
  --verified: var(--blue-m);
  --unverified: #979797;
}

.verified {
  composes: verification;
  border: 2px solid var(--verified);
  stroke: var(--verified);
  color: var(--verified);
}

.unverified {
  composes: verification;
  border: 2px solid var(--unverified);
  stroke: var(--unverified);
  color: var(--unverified);
}

.offplatform {
  composes: verification;
  border: 2px solid var(--unverified);
  stroke: var(--unverified);
  color: var(--unverified);
}