.icon {
  width: 18px;
  height: 18px;
}

@media (min-width: 800px) {
  .icon {
    width: 20px;
    height: 20px;
  }
}

.pencil {
  composes: icon;
  stroke: var(--pri-02);
  stroke-width: 2;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--pri-02);
  border-radius: 50%;
  height: 34px;
  width: 34px;
}

.btn:hover {
  background-color: var(--pri-02);
}

.btn:hover svg {
  stroke: var(--pri-01);
}

.btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btns > button {
  margin-left: 15px;
}