@value 800, 1100 from 'vars';

.root {
}

@media 1100 {
  .root {
    border-top: 1px solid var(--gray-xl);
  }
}

.wrap {
}

.main {
  background-color: var(--blue-xl);
  padding: 70px 0;
}

@media 800 {
  .main {
    background-image: url('static/images/website/q2q/bg-dots-blue-top-right-l.png');
    background-position: right;
    background-size: 35%;
    background-repeat: no-repeat;
  }
}

@media 1100 {
  .main {
    position: relative;
    background-color: unset;
    background-image: unset;
    max-width: 1240px;
    margin: 0 auto;
  }
}

.header {
  font-size: 28px;
  font-family: var(--font-bold);
  color: var(--black-d);
  text-align: center;
  margin: 0 20px 40px;
}

@media 800 {
  .header {
    font-size: var(--font-size-header);
    max-width: 490px;
    margin: 40px auto;
  }
}

@media 1100 {
  .header {
    max-width: 100%;
  }
}

.items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

@media 800 {
  .items {
    flex-direction: row;
    justify-content: space-between;
    max-width: 700px;
    margin: 0 auto;
  }
}

@media 1100 {
  .items {
    max-width: 1000px;
    z-index: 3;
  }
}

.item {
  background: var(--pri-01);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  overflow: hidden;
  width: 300px;
  margin-bottom: 50px;
}

@media 800 {
  .item {
    width: 200px;
    margin-bottom: 0;
  }
}

@media 1100 {
  .item {
    width: 300px;
  }
}

.img {
  width: 300px;
}

@media 800 {
  .img {
    width: 200px;
  }
}

@media 1100 {
  .img {
    width: 300px;
  }
}

.info {
  padding: 18px 25px 20px;
}

@media 800 {
  .info {
    height: 165px;
  }
}


.text {
  font-family: var(--font-semibold);
  font-size: 18px;
}

.subtext {
  margin-top: 10px;
  color: var(--gray-d);
}

.thumb {

}

@media 1100 {
  .con {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: var(--blue-xl);
    border-top-left-radius: 29px;
    border-bottom-left-radius: 29px;
    overflow: hidden;
  }
}

@media 1100 {
  .bg {
    position: absolute;
    width: 100%;
    top: 5%;
    height: 95%;
    background-image: url('static/images/website/q2q/bg-dots-blue-top-right-l.png');
    background-position: center right;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 2;
  }
}