.root {
  /* margin: 80px 0 0 0; */
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: var(--gray-d);
  font-size: 22px;
  text-align: center;
  width: 330px;
}

.desc {
  color: var(--gray-d);
  font-size: 16px;
  font-family: var(--font-reg);
  text-align: center;
  margin: 0 0 10px 0;
  width: 300px;
  margin-bottom: 30px;
}

.connect {
  width: 150px;
  font-size: 14px;
  border: none;
}

.p {
  max-width: 80%;
  color: var(--gray-d);
  font-size: 14px;
  font-family: var(--font-reg);
}