.row {
  margin-bottom: 25px;
}

.link {
  color: var(--hyperlink);
}

.link:hover {
  text-decoration: underline;
}

.title {
  margin-bottom: 12px;
}

.input {
  line-height: 25px;
  letter-spacing: normal;
  margin-bottom: -3px;
}

.focusable {
  bottom: -3px
}

.deemphasis {
  color: var(--gray);
}

.time {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 35px;
}