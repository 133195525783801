.modal {
  width: 900px;
}

.row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.content {
  border-radius: 4px;
  border: 1px solid var(--gray-l);
  padding: 10px;
  margin-bottom: 20px;
  background-color: var(--gray-xl);

  max-height: 400px;
  overflow-y: auto;
}

.label {
  font-family: var(--font-semibold);
}

.range {
  display: flex;
  align-items: center;
  gap: 10px;
}

.time {
  border-radius: 4px;
  border: 1px solid var(--gray-l);
  padding: 8px;

  box-sizing: border-box;
  width: 70px;
  display: flex;
  justify-content: flex-end;
}

.link {
  color: var(--hyperlink);
  text-decoration: underline;
}
