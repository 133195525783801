.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: var(--black);
  font-family: var(--font-bold);
  margin: 0 0 4px 0;
}

.content {
  display: flex;
  height: 245px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}

.error {
  color: var(--sec-04);
  font-size: 14px;
  font-family: var(--font-semibold);
}

.spinner {
  -webkit-animation: rotation 1s infinite linear;
  -moz-animation: rotation 1s infinite linear;
  -o-animation: rotation 1s infinite linear;
  animation: rotation 1s infinite linear;
  border: 8px solid rgba(0,0,0,.2);
  border-radius: 100%;
}

.spinner:before {
   content: "";
   display: block;
   position: absolute;
   left: -8px;
   top: -8px;
   height: 100%;
   width: 100%;
   border-top: 8px solid var(--pri-02);
   border-left: 8px solid transparent;
   border-bottom: 8px solid transparent;
   border-right: 8px solid transparent;
   border-radius: 100%;
}

@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
  }

.blur-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  box-sizing: border-box;
}

.blur-label {
  color: var(--gray-d);
  font-size: 14px;
}

.blur-switch {
  margin-right: 10px;
}

.preview-track {
  max-height: 200px;
}