.section {
  margin-bottom: 20px;
}

.title {
  font-size: 18px;
  font-family: var(--font-bold);
  margin-bottom: 10px;
}

.actions {
  display: flex;
  justify-content: space-evenly;
}