.connecting {
  color: var(--gray-d);
  font-family: var(--font-bold);
}

.waiting {
  color: var(--gray-d);
  font-family: var(--font-bold);
}

.duration {
  color: var(--black);
  font-family: var(--font-bold);
}