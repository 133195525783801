
.content {
  display: flex;
  align-items: center;
}

.q {
  flex-shrink: 0;
  color: var(--pri-02);
  font-family: var(--font-bold);
  font-size: 20px;
  width: 55px;
}

.p {
  padding-right: 10px;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  width: 50px;
  height: 50px;
}

.indicator {
  display: none;
}

@media (min-width: 900px) {
  .indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 7px;
    background-color: var(--pri-02);
  }
}

.item {
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  border-bottom: 1px solid var(--gray-xl);
  position: relative;
}

@media (min-width: 900px) {
  .item.active {
    background-color: var(--blue-l);
    border-bottom: 1px solid transparent;
  }
}

.see-more.active {
  background-color: var(--blue-l);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--gray-d);
}

@media (min-width: 900px) {
  .active .icon {
    color: var(--hyperlink);
  }
}

.inactive .icon:hover {
  color: var(--hyperlink);
  background-color: var(--blue-l);
}