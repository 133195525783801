.root {
  border-right: 1px solid var(--gray-l);
  overflow-y: auto;
}

.item {

}

.item:last-child {
  /* border-bottom: none; */
  /* border-bottom-left-radius: 8px; */
}