.root {
  height: 300px;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
}

.header {
  font-size: 18px;
  font-family: var(--font-bold);
}

.subheader {
  font-size: 16px;
  margin-top: 10px;
}

.informational {
  margin-bottom: 30px;
}

.btn-content {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 65px;
  justify-content: space-between;
}