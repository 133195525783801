.root {
  min-height: 500px;
}

.items {
  max-height: 600px;
  overflow-y: auto;
}

.item {
  width: calc(100% - 30px);
  margin-bottom: 10px;
}

.placeholder {
  color: var(--gray);
  text-align: center;
}

.header {
  margin-bottom: 15px;
}

.title {
  font-family: var(--font-semibold);
}

.subtitle {
  font-size: 15px;
  color: var(--gray-d);
}

.item-content {
  width: 100%;
}

.disabled {
  background-color: var(--gray-xl);
}

.open-ended-input {
  margin-top: 5px;
}

.open-ended-input textarea {
  background-color: unset;
}

.editor img {
  max-height: 150px;
}