.root {

}

.wrap {

}

.main {

}

.footer {
  display: flex;
  align-items: center;
  padding: 40px 0 0;
}

.btn {
  min-width: 140px;
}

.row {
  margin-bottom: 15px;
}

.label {
  font-family: var(--font-bold);
  color: var(--font-black-l);
  margin-bottom: 15px;
}

.option {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 40px;
  padding-bottom: 20px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
}

.section {
  margin-bottom: 15px;
}

.col {
  composes: section;
  width: calc(50% - 25px);
  margin-right: 25px;
}

.full {
  composes: section;
  width: 100%;
}

.toolbar {
  margin-bottom: 15px;
}

.validation {
  color: var(--pri-04);
  margin-top: 20px;
}

.scroll {
  max-height: 430px;
  overflow-y: scroll;
}

.flag {
  color: var(--pri-04);
  font-family: var(--font-semibold);
  font-size: 15px;
}