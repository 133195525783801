.root {
  margin: 0 auto;
}

.dropdown {
  composes: visible from 'css/display.css';
}

.item ~ .item {
  border-top: 1px solid var(--gray-l);
}

.item:last-child {
  padding-bottom: 10px;
}