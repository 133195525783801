.root {
  padding: 0 20px 0 0;
}

@media (min-width: 800px) {
  .root {
    padding: 0;
  }
}

.header {
  display: flex;
  text-align: center;
  margin-bottom: 25px;
}

.header .label {
  display: none;
}

@media (min-width: 800px) {
  .header .label {
    display: initial;
  }
}

.header .value {
  display: none;
}

@media (min-width: 800px) {
  .header .value {
    display: initial;
  }
}

.row {
  word-break: break-word;
  padding: 5px 0;
  margin-bottom: 35px;
}

@media (min-width: 800px) {
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}

.content, .header-content {
  width: calc(100% - 3px);
}

@media (min-width: 800px) {
  .content, .header-content {
    max-width: 400px;
  }
}

.label {
  color: var(--black-l);
  font-family: var(--font-semibold);
  margin-bottom: 10px;
}

@media (min-width: 800px) {
  .label {
    width: 150px;
    margin-right: 25px;
    margin-bottom: 0;
    font-family: var(--font-reg);
  }

  .no-slider .label {
    width: unset;
    min-width: 150px;
    display: flex;
    flex-grow: 1;
    max-width: 400px;
  }
}

.value {
  display: flex;
  align-items: center;
  margin: 5px 0 15px 0;
}

.value-input {
  width: 65px;
}

.value-header {
  margin: 5px 0 15px 0;
  /* width: 65px; */
}

@media (min-width: 800px) {
  .value {
    margin: 0 20px 0 0;
  }
  .value-input {
    width: 75px;
  }
  .value-header {
    margin: 0 20px 0 0;
    width: 75px;
  }
}

.value-input input, .value-input input::placeholder {
  text-align: right;
}

.pct {
  color: var(--gray-d);
  width: 55px;
  margin-left: 20px;
}

.validation {
  color: var(--pri-04);
}

.footer {
  composes: row;

}

.extended-footer {

} 

@media (min-width: 800px) {
  .footer {
    width: 500px;
  }
  
  .extended-footer {
    width: 575px;
  } 
  .footer {
    /* justify-content: space-between; */
  }
}

.footer .label {
  color: var(--black-l);
  font-family: var(--font-semibold);
}

.total {
  width: 75px;
  display: flex;
  justify-content: flex-end;
}

.total-row {
  display: flex;
  align-items: center;
}

.open-ended {
  margin-top: 5px;
}