.root {

}

.tab {

}

@media (min-width: 800px) {
  .tab {
    width: 600px;
  }
}

.card-border {
  border: 1px solid var(--sec-01);
  border-radius: 5px;
}


.card {
  padding: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--gray-xl);
}

.card:last-of-type {
  border-bottom: none;
}

.tags {
  composes: tags from 'static/css/tags.css';
}

.tag {
  composes: tag from 'static/css/tags.css';
}

.fullname {
  font-family: var(--font-semibold);
  color: var(--black);
  margin: 5px 0;
  font-size: 18px;
}

.subtext {
  font-family: var(--font-reg);
}

.btns {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

@media (min-width: 800px) {
  .btns {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;
  }
}

.btn {
  font-family: var(--font-bold);
  font-size: 16px;
  text-align: center;
  padding: 10px 20px;
  border-radius: 25px;
  border: 2px solid var(--pri-02);
  transition: background-color 0.1s ease-in;
  cursor: pointer;
  min-width: 103px;
}

.btn:hover {
  border: 2px solid var(--sec-02);
}

.fill {
  composes: btn;
  background-color: var(--pri-02);
  color: var(--pri-01);
}

.fill:hover {
  background-color: var(--sec-02);
}

.nofill {
  composes: btn;
  background-color: var(--pri-01);
  color: var(--pri-02);
}

.nofill:hover {
  background-color: var(--blue-l);
}

.link:hover {
  text-decoration: underline;
  color: var(--hyperlink);
}

.cancel {
  composes: nofill from 'css/button.css';
}

.confirm {
  composes: fill from 'css/button.css';
}