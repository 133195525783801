.root {
  position: relative;
}

.name-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.name-input {
  max-width: 80px;
}

.entries {
  display: flex;
  gap: 10px;
}

.context-menu {
  position: absolute;
  top: 0;
  right: 0;
}