.root {
}

.result {
  padding: 10px 18px;
}

.result:hover {
  background-color: var(--gray-xl);
}

.details {
  overflow: hidden;
}

.name {
  font-size: 14px;
  font-family: var(--font-semibold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.type {
  font-size: 12px;
  font-family: var(--font-semibold);
  color: var(--gray-d);
}

.icon {
  margin-right: 10px;
  flex-shrink: 0;
}

.highlighted {
  background-color: var(--gray-xl);
}

.return {
  position: absolute;
  top: 16px;
  right: 10px;
  color: var(--gray-l);
}