.root {
  display: block;
  font-size: 14px;
  overflow: hidden;
  line-height: 1.8em;
  max-height: 1.6em;
  /* margin-right: -1em; */
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: var(--font-reg);
  color: var(--black-l);
}

.label {
  display: inline-block;
  font-family: var(--font-semibold);
  margin-right: 6px;
}

.label:after {
  display: inline-block;
  content: ':';
}