.root {
  position: sticky;
  top: calc(var(--navbar-height) + var(--height-breadcrumbs) + var(--height-tabbar) + var(--sticky-space)); /* breadcrumbs + tabbar + media */
  z-index: 2;
}

.wrap {
  background-color: var(--pri-01);
  padding: 10px 0;
  border-bottom: 1px solid var(--gray-xl);
}

.main {

}

.btns {
  display: flex;
  gap: 10px;
}

.btn {
  border-radius: 4px;
}