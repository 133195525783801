.root {
  padding: 0 10px;
}

@media (min-width: 900px) {
  .root {
    padding: 0 50px;
  }
}

.wrap {
  width: 100%;
}

@media (min-width: 900px) {
  .wrap {
    width: 550px;
  }
  .modal {
    width: auto;
  }
}

.header {
  text-align: left;
}