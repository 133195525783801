.root {
  padding: 0 15px;
}

.row {
  padding: 15px 0;
}

.body {
  min-height: 175px;
}

.autocomplete {
  width: 100%;
}

.btn {
  composes: brick from 'css/button.css';
  border-radius: 4px;
}

.label {
  font-family: var(--font-bold);
  color: var(--black-l);
  margin-bottom: 10px;
}