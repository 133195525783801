.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 10px;
}

.icon {
  margin: 0 0 8px 0;
}

.spacer {
  flex-grow: 1;
}

.disabled {
  cursor: not-allowed;
}