.root {
  --wrap-padding: 15px;
  --sb-width: 250px;
  --content-width: 650px;
  --content-margin-lr: calc(70px * 2);
  --vertical-margin: 25px;

  margin: 0 auto;
}

.wrap {

}

.menu {
  width: 100%;
}

@media (min-width: 850px) {
  .root {
    max-width: 1280px;
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    padding: 0 var(--wrap-padding);
    margin: var(--vertical-margin) 0;
  }

  .content-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    /* margin-left: var(--sb-width); */
  }

  .content {
    min-height: calc(100vh - var(--navbar-height) - var(--vertical-margin) - var(--vertical-margin));
    max-width: 800px;
  }

  .menu {
    /* position: sticky; */
    /* top: calc(var(--vertical-margin) + var(--navbar-height)); */
    width: var(--sb-width);
    min-width: var(--sb-width);
  }
}