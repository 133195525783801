@value 800, 1100 from 'vars';

.root {

}

@media 800 {
  .root {
    margin-bottom: 30px;
    padding: 20px;
  }
}

@media 1000 {
  .root {
    padding: 20px 40px;
  }
}

.wrap {
  background-color: var(--blue-l);
  margin: 0 auto;
  border-radius: 20px;
  padding: 30px 0;
  max-width: 320px;
}

@media 800 {
  .wrap {
    padding: 30px;
    max-width: var(--max-width);
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media 800 {
  .main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.graphic {
  margin-bottom: 30px;
}

@media 800 {
  .graphic {
    margin-bottom: 0;
  }
}

.img {
  border-radius: 20px;
  width: 260px;
}

.info {

}

@media 800 {
  .info {
    margin-left: 30px;
  }
}

@media 1100 {
  .info {
    margin-left: 40px;
  }
}

.title {
  font-size: var(--font-size-heading-2);
  font-family: var(--font-bold);
  color: var(--black);
  margin-bottom: 20px;
  max-width: 310px;
  text-align: center;
}

@media 800 {
  .title {
    margin-bottom: 30px;
    text-align: left;
    max-width: 100%;
  }
}

.link {
  justify-content: center;
}

@media 800 {
  .link {
    justify-content: flex-start;
  }
}