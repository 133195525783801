.edit-trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.editing-container {
  display: flex;
  align-items: center;
}

.edit-icon {
  visibility: hidden;
  margin-left: 5px;
  width: 14px;
  height: 14px;
  color: var(--pri-02);
}

.edit-trigger:hover .edit-icon {
  visibility: visible;
}

.enabled {
  cursor: pointer;
  color: var(--pri-03);
}

.cancel {
  cursor: pointer;
  color: var(--pri-04);
}

.input-element {
  background-color: inherit;
}