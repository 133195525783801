.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.user-select {
  flex-grow: 1;
  min-width: 0;
}

.role-select {
  width: 125px;
}

.user-select-menu {
  z-index: 99 !important;
}