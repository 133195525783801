.root {
  padding: 12px;
  font-size: 13px;
  color: var(--black-l);
  width: 250px;
}

.name {
  font-family: var(--font-semibold);
  margin-bottom: 5px;
  width: 90%;
}

.type {
  font-style: italic;
  margin-bottom: 10px;
}

.description {
  word-break: break-word;
  white-space: pre-wrap;
}