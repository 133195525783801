.contain {
  width: 80px;
  flex: 0 0;
}

.stretch {
  width: 80px;
  flex: 1 1;
}

.header {
  font-size: 14px;
  color: var(--black-l);
  font-family: var(--font-semibold);
}

.cell {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 13px;
  min-height: 35px;
  color: var(--black-l);
}