.root {

}

.wrap {

}

.main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: var(--sec-02);
  font-family: var(--font-semibold);
  font-size: 13px;
  background-color: var(--blue-l);
  border-radius: 20px;
  padding: 4px 0;
  width: 80px;
}

.main .icon {
  width: 19px;
  color: var(--sec-02);
}

.main.completed .status {
  background-color: var(--greenish-white);
  color: var(--pri-03);
}

.main.completed .icon,
.main.completed {
  color: var(--pri-03);
}

.main.visibility .status {
  background-color: var(--blue-l);
  color: var(--sec-05);
  border-radius: 4px;
}