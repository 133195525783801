.root {
  padding: 10px;
  --height-header: 45px;
  --width-panel: 100%;
  --min-width-panel: 400px;
  --max-width-panel: 400px;
}

.root.popup {
  padding: 0;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  height: 100%;
}

.notes {

}

.header {
  display: flex;
  align-items: center;
  background-color: var(--gray-xl);
  min-height: var(--height-header);
  max-height: var(--height-header);
}

.win {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}

.title {
  color: var(--black);
  font-size: 18px;
  font-family: var(--font-bold);
}

.btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  width: 36px;
  height: 30px;
}

.btn:hover {
  background-color: var(--gray-l);
}