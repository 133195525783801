.root {
  display: inline-flex;
  border: 1.5px solid var(--sec-01);
  border-radius: 20px;
  color: var(--gray-d);
  cursor: default;
  font-size: 12px;
  font-family: var(--font-semibold);
  transition: background-color 200ms ease-in-out,
              border 140ms ease-in,
              border-color 140ms ease-in,
              color 140ms ease-in;
  line-height: 19px;
}

.clickable {
  cursor: pointer;
}

.clickable:hover:enabled {
  background-color: var(--blue-l);
  border-color: var(--pri-02);
  color: var(--pri-02);
}

.basic {
  background-color: var(--pri-01);
}

.selected {
  background-color: var(--blue-l);
  border-color: var(--pri-02);
  color: var(--pri-02);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.readonly {}