.root {
  text-align: center;

  font-family: var(--font-reg);
  color: var(--gray-d);
  padding: 10px 0 25px 0;

  transition: .1s linear;
}

.icon {
  width: 130px;
  height: 130px;
  stroke-width: .5;
  color: var(--gray-l);
  transition: .1s linear;
}

.icon-wrap {
  padding: 15px;
  border-radius: 50%;
  transition: .1s linear;
}

.title {
  margin-top: 15px;
  font-family: var(--font-semibold);
}

.text {
  margin-top: 40px;
}

.dragging {

}

.dragging .title, .dragging .text {
  color: var(--pri-02);
}

.dragging .icon {
  color: var(--pri-02);
  transform: scale(1.05);
}

.dragging .icon-wrap {
  /* background-color: var(--blue-l); */
  transform: scale(1.05);
}

.dragging.root {
  background-color: var(--blue-xl);

  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%230A68EFFF' stroke-width='4' stroke-dasharray='6%2c 10' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 3px;
}
