.root {
  position: relative;
  width: 100px;
  height: 100px;
}

.spinner {
  transform-origin: center;
  animation-name: cycle;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  fill: transparent;
  stroke: #5C73AD;
  stroke-width: 7px;
  stroke-linecap: round;
  filter: url(#shadow);
}

.lightbulb {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bulb {
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 1.2s;
}

@keyframes cycle {
  0% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -105;
  }
  50% {
    stroke-dasharray: 80 10;
    stroke-dashoffset: -160;
  }
  100% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -300;
  }
}

@keyframes fade {
  0%, to {
    opacity: .7;
    transform: scale(.75);
  }
  40%, 50% {
    opacity: 1;
    transform: scale(1);
  }
}