.root {

}

.wrap {
  padding: 20px 0;
}

.main {

}

.header {
  position: relative;
}

.title {
  display: inline-block;
  width: 180px;
  font-size: 16px;
  line-height: 45px;
  min-height: 45px;
  text-align: center;
  font-family: var(--font-bold);
  color: var(--pri-02);
}

.indicator {
  position: absolute;
  height: 4px;
  background-color: var(--pri-02);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 1;
  bottom: 0;
  width: 180px;
}