.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.badge {
  color: var(--pri-01);
  border-radius: 3px;
  padding: 4px 8px;
  font-size: 13px;
  font-family: var(--font-semibold);
}

.joined {
  composes: badge;
  background-color: var(--pri-03);
}

.invited {
  composes: badge;
  background-color: var(--pri-02);
}

.inreview {
  composes: badge;
  background-color: var(--pri-02);
}

.rejected {
  composes: badge;
  background-color: var(--gray-m);
}

.bookmarked {
  composes: badge;
  background-color: var(--pri-05);
}

.inactive {
  composes: badge;
  background-color: var(--gray-d);
}

.staging {
  composes: badge;
  color: var(--black-l);
  background-color: #ffca2c;
}