.root {
  margin-top: 10px;
}

.header {
  font-size: 18px;
  font-family: var(--font-bold);
  margin: 0 0 8px;
}


.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 14px;
  align-items: center;
}

.setting-text {
  display: flex;
  align-items: center;
}

.help-icon {
  margin-left: 5px;
}