.root {
  --rmr-color-border: transparent;
  --rmr-color-outline: transparent;
  --rmr-space-3: 0;
  height: 100%;
  --max-width: 775px;
  --padding-x: 20px;
}

.wrap {
  padding: var(--padding-x) var(--padding-x) 40px;
  height: 100%;
}

.main {
  max-width: var(--max-width);
  margin: 0 auto;
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}

.root h1.title {
  font-family: var(--font-bold);
  font-size: 40px;
  color: var(--black);
}

.icon {
  padding: 5px;
}

.anchor {
  margin-left: 20px;
}

.menu {
  margin-top: 0;
  box-shadow: 0px 2px 6px 1px rgb(60 64 67 / 15%);
}

.item {
  cursor: pointer;
  color: var(--black-l);
  padding: 10px 20px;
  white-space: nowrap;
  height: 20px;
  background-color: var(--pri-01);
}

.item:hover {
  color: var(--pri-02);
  background-color: var(--blue-xl);
}