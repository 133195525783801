.bucket {
  border-bottom: 1px solid var(--gray-l);
  display: flex;
  padding: 14px 0;
  justify-content: space-between;
  width: 100%;
}

.bucket:last-child {
  border-bottom: none;
}

.right {
  display: flex;
}

.name {
  color: var(--black);
  font-size: 14px;
  margin: 0 0 10px 0;
}

.description {
  color: var(--gray);
  font-size: 14px;
}