.root {
  padding: 0 15px;
}

.row {
  padding: 15px 0;
}

.body {
  min-height: 175px;
}

.autocomplete {
  width: 100%;
}

.footer {
  text-align: center;
  padding: 15px 0;
}

.btn {
  composes: brick from 'css/button.css';
}

.field {
  border-radius: 2px;
  border: 2px solid var(--gray-l);
  max-width: 100%;
}

.field:focus-within {
  border-color: var(--pri-02);
}

.textarea {
  border: none;
  resize: none;
  padding: 5px;
  outline: none;
  color: var(--gray-d);
  font-size: 18px;
  font-family: var(--font-reg);
  width: calc(100% - 10px);
}

.label {
  font-family: var(--font-bold);
  color: var(--black-l);
  margin-bottom: 10px;
}

.ranking {
  width: 200px;
}