.icon-microphone,
.icon-microphone-off {
  composes: icon from './Shared.css';
}

.icon-microphone-off {
  composes: icon-off from './Shared.css';
}

@media (min-width: 800px) {
  .icon-microphone:hover {
    background-color: var(--gray-l);
  }

  .icon-microphone-off:hover {
    background-color: var(--gray-xl);
  }
}

.icon-microphone svg,
.icon-microphone-off svg {
  height: 32px;
  width: 32px;
}

.disabled {
  composes: disabled from './Shared.css';
}