@value 800 from 'vars';
@value 1100 from 'vars';

.root {

}

.wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media 800 {
  .wrap {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.main {

}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav {
  display: flex;
  align-items: center;
}

.title {
  font-family: var(--font-bold);
  color: var(--black-d);
  font-size: 32px;
  margin-bottom: 15px;
  text-align: center;
}

.subtitle {
  max-width: 600px;
  margin-bottom: 5px;
  text-align: center;
}

.btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn {
  padding: 0px 10px;
  height: 35px;
  text-transform: uppercase;
}

.signup {
  composes: btn;
  width: 160px;
}

.login {
  font-family: var(--font-semibold);
  color: #4a4a4a;
  margin-right: 10px;
}

.login:hover {
  color: var(--pri-02)
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 0 10px;
  background-color:  #304659;
  color: var(--pri-01);
}

@media 800 {
  .footer {
    padding: 0 60px;
  }
}

.links {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

@media 800 {
  .links {
    gap: 40px;
  }
}

.subscript {
  font-family: var(--font-semibold);
  color: var(--pri-01);
}

a.subscript {
  text-transform: uppercase;
}