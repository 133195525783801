.root {

}

.right {
  display: flex;
  align-items: center;
}

.see-all {
  display: none;
  margin-right: 10px;
  border: none;
}

@media (min-width: 800px) {
  .see-all {
    display: block;
  }
}

.carousel {
  overflow-x: hidden;
}

.items {
  display: flex;
  align-items: center;
  transition: transform .2s ease-out;
}

.post {
  margin-right: 20px;
}

.post:last-of-type {
  margin-right: 0;
}