.footer {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  width: 350px;
  margin: 0 auto
}

.note {
  font-size: 14px;
  width: 400px;
  margin: 0 auto;
  margin-top: 25px;
}

.id {
  font-family: var(--font-bold);
}