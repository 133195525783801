@value 800 from 'vars';

.root {

}

.wrap {

}

.main {
  margin: 40px 0;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.option {
  display: flex;
  align-items: center;
  gap: 5px;
}

.label {

}

.cancel {
  font-family: var(--font-bold);
  color: var(--black-l);
  cursor: pointer;
}

.btn {
  width: 150px;
}

.divider {
  width: 100%;
  background-color: var(--sec-01);
  height: 1px;
  margin: 20px 0;
}

@media 800 {
  .divider {
    max-width: 450px;
  }
}