.root {}

.dropdown-container {
  display: flex;
  justify-content: space-between;
}

.dropdown {
  min-width: 225px;
  max-width: 250px;
}

.children {
  margin-top: 10px;
}