.item {
  margin-bottom: 25px;
}

.row {
  display: flex;
  margin-bottom: 15px;
}

.field {
  --border-width: 2px;
  --padding-horizontal: 15px;
  width: 100%;
}

.phone {
  width: 250px;
}

.label {
  font-size: 16px;
}

.sms {
  display: flex;
  justify-content: space-between;
}

.consent {
  font-size: 15px;
  margin-left: 10px;
}

.select {
  width: 250px;
}

.research {
  width: 100%;
}

.tzall {
  color: var(--gray);
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
}

.footer {
  margin: 60px 0;
}