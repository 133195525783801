.root {
  display: flex;
  align-items: center;
}

.btn {
  display: block;
  font-size: 14px;
  width: 145px;
  font-family: var(--font-reg);
}

.loader {
  margin-left: 5px;
}