.result {
  box-sizing: border-box;
  display: flex;
  padding: 6px 18px;
}

.circle {
  width: 28px;
  height: 28px; 
  margin-top: 2px;
  margin-right: 10px;
}

.description {
  box-sizing: border-box;
  margin-top: -4px;
  width: 100%;
}