.root {
  align-items: center;
  color: #4c4c4c;
  padding: 12px 14px;
  background-color: var(--gray-xl);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  opacity: 0;
  transition: opacity 1000ms ease;
}

.show {
  opacity: 1;
}

.message {
  margin-bottom: 18px;
  font-size: 14px;
}

.link {
  display: block;
  margin-top: 10px;
  text-decoration: underline;
}

.dismiss {
  display: block;
  background-color: var(--gray-xl);
  border: 1px solid #4c4c4c;
  /* border-radius: 15px; */
  color: #4c4c4c;
  font-family: var(--font-bold);
  padding: 5px 20px;
  width: 100%;
  transition: 500ms ease;
  font-size: 14px;
}

.dismiss:hover {
  color: #e5e5e5;
  background-color: #4c4c4c;
}

@media (min-width: 800px) {
  .root {
    display: flex;
    justify-content: space-between;

    width: 625px;
    padding: 5px;
    padding-left: 10px;
  }
  
  .message {
    max-width: 100%;
    margin-bottom: 0;
  }

  .link {
    display: inline;
    margin: 0;
  }
}