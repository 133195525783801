.row {
  margin: 0 0 30px 0;
}

.row:last-child {
  margin: 0;
}

.header {
  display: flex;
  padding: 0 3px 0 2px;
  justify-content: space-between;
}

.option-label {
  font-size: 18px;
  font-family: var(--font-bold);
}

.select {
  cursor: pointer;
}

.desktop,
.phone {
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktop svg {
  margin: 0 8px 0 0;
}

.phone svg {
  margin: 0 8px 0 0;
  height: 20px;
  width: 20px;
}

.option-disabled {
  color: var(--gray-d);
  padding: 0 2px;
  margin: 4px 0 0 0;
}