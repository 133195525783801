.title {
  color: var(--gray-d);
  font-family: var(--font-bold);
  font-size: 24px;
  text-align: center;
}

.root {
  padding: 25px;
}

.preview {
  border-radius: 50%;
}