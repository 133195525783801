.occurrences-cell {
  display: flex;
  gap: 10px;
  align-items: center;
}

.occurrences-cell.active-cell {
  justify-content: space-between;
  flex-grow: 1;
}

.view-occurrences {
  cursor: pointer;
  text-decoration: underline;
  color: var(--pri-02);
}

.clear-active {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  cursor: pointer;
  background-color: var(--blue-m);
  color: var(--pri-02);

  display: flex;
  justify-content: center;
  align-items: center;
}

.hover-show {
  visibility: hidden;
}

.tr:hover .hover-show {
  visibility: visible;
}

.term-cell {
  display: flex;
  align-items: center;
  gap: 5px;
}

.typo {
  display: inline-block;
  position: relative;
  background: url(data:image/gif;base64,R0lGODdhBAADAPEAANv///8AAP///wAAACwAAAAABAADAEACBZQjmIAFADs=) bottom repeat-x;
  color: var(--pri-04);
}

.head-tr {
  border-top: none !important;
}

.body {
  max-height: 500px;
  height: auto;
}

.active-row {
  background-color: var(--blue-l);
}

.derived {
  font-style: italic;
}