.root {

}

.node {

  display: inline-flex;
  align-items: center;

  cursor: pointer;
  margin-bottom: 2px;
  color: var(--gray-d);
}

.selected {

}

.label {
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.label-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.node:hover .label, .node.selected .label {
  background-color: var(--blue-xl);
  color: var(--pri-02);
}

.node.selected .label {
  font-family: var(--font-semibold);
}

.icon {
  margin-right: 5px;
  min-width: 18px;
  height: 18px;
}

.node.selected .icon, .node:hover .icon {
  color: var(--pri-02);
}

.expandable {
 
}

.chevron-up {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid var(--gray-d);
  margin-right: 10px;
  /* margin-left: 5px; */
}

.node.selected .chevron-up, .node:hover .chevron-up {
  border-left: 6px solid var(--pri-02);
}

.chevron-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid var(--gray-d);
  margin-right: 6px;
}

.node.selected .chevron-down, .node:hover .chevron-down {
  border-top: 6px solid var(--pri-02);
}

.empty {
  width: 16px;

}

.children {
  margin-left: 25px;
}