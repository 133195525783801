.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  margin-top: 10px;
  border-bottom: 1px solid var(--gray-xl);
}

.table td {
  padding: 5px;
}

.table thead {
  background-color: var(--gray-xl);
  font-family: var(--font-semibold);
}

.odd-meeting {
  background-color: var(--gray-xl);
}

.even-meeting {
  background-color: none;
}