.row {
  display: flex;
  align-items: center;
  width: 100%;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.left {
  display: flex;
  align-items: flex-start;
  width: calc(100% - 25px);
}

.ordinal {
  font-family: var(--font-semibold);
  color: var(--gray-d);
  margin-right: 10px;
}

.remove {
  cursor: pointer;
  margin-left: 5px;
  color: var(--gray-d);
  width: 20px;
  min-width: 20px;
}

.text-content {
  width: 100%;
}

.open-ended-input {
  margin-top: 5px;
}

.editor img {
  max-height: 150px;
}