.root {
  padding: 15px 0 150px 0;
}

.wrap {
  width: 800px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
}

.section {
  font-size: 15px;
  margin-bottom: 15px;
}

.section-33 {
  composes: section;
  width: 33%;
}

.section-50 {
  composes: section;
  width: 50%;
}

.section-100 {
  composes: section;
  width: 100%;
}

.section-title {
  font-size: 18px;
  color: var(--black);
  font-family: var(--font-semibold);
  line-height: 34px;
}

.section-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 15px 0;
  border-bottom: 1px solid var(--gray-l);
  max-width: 800px;
}

.label {
  display: flex;
  font-family: var(--font-bold);
  padding-right: 20px;
  margin-bottom: 15px;
  color: var(--black);
  font-size: 15px;
  justify-content: space-between;
}

.value {
  padding-right: 20px;
}

.form-error {
  color: var(--pri-04);
  font-family: var(--font-semibold);
}

.input-error {
  background-color: var(--pri-04);
}

.textarea-error,
.textarea-error:focus {
  border-color: var(--pri-04);
}

.input {
  font-size: 15px;
  line-height: 26px;
  letter-spacing: normal;
}

.input-value {
  font-size: 15px;
  line-height: 26px;
}

.textarea {
  color: var(--black);
  font-size: 15px;
  line-height: 26px;
  padding: 2px 6px;
}

.textarea-value {
  line-height: 1em;
  white-space: pre-wrap;
  word-break: break-word;
}

.select {
  width: 100%;
  line-height: normal;
  font-family: var(--font-reg);
}

.tagger {
  padding-right: 20px;
}

.sector-tagger {
  composes: tagger;
  margin-top: 10px;
}

.tags, .tags-form {
  composes: tags from 'static/css/tags.css';
  padding-top: 0;
  margin-top: 6px;
}

.tag,
.tags-form .tag {
  margin-bottom: 5px;
  margin-right: 5px;
}

.none {
  color: var(--gray-d);
  font-style: italic;
}

.link:hover {
  color: var(--hyperlink);
  text-decoration: underline;
}

.toolbar {
  margin-bottom: 10px;
}

.title {
  display: flex;
  gap: 10px;
  align-items: center;
}

:global(.ant-picker).picker {
  width: 100%;
  font-family: var(--font-reg);
}

:global(.ant-picker-large).picker {
  padding: 4px;
}

:global(.ant-picker).picker :global(.ant-picker-input)>input,
:global(.ant-picker).picker :global(.ant-picker-input)>input:focus,
:global(.ant-picker).picker :global(.ant-picker-input)>input:hover,
:global(.ant-picker).picker:hover,
:global(.ant-picker.ant-picker-focused) {
  font-family: var(--font-reg);
  font-size: 14px;
  border-color: var(--pri-02);
  border-radius: 4px;
  box-shadow: none;
}

@keyframes :global(antSlideDownIn) {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes :global(antSlideDownOut) {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}