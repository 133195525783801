.root {
  min-width: 150px;
}

.row-name {
  font-family: var(--font-semibold);
  margin-bottom: 5px;
}

.avg {
  margin-bottom: 10px;
}

.option {
  
}