.root {

}

.wrap {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.main {

}

.header {
  font-size: 16px;
  font-family: var(--font-semibold);
  color: var(--black);
  line-height: 20px;
}

.divider {
  padding-bottom: 5px;
  border-bottom: 1px solid var(--sec-01);
  margin-bottom: 15px;
}

.back {
  margin-bottom: 15px;
}