@value 1200 from 'vars';

.root {
  max-height: 887px;
}

.content {
  min-height: 325px;
  overflow-y: auto;
  max-height: 827px;
}