@value 800, 1100 from 'vars';

.root {
  --font-size-heading-xl: 40px;

  --font-size-heading-1: 34px;
  --font-size-heading-2: 30px;

  --font-size-subtitle-1: 20px;
  --font-size-subtitle-2: 18px;

  --max-width: 1280px;

  --height-fullpage: calc(100vh - var(--navbar-height));

  --section-separator: 1px solid var(--gray-xl);
}

.multi {
  position: relative;
}

.bg {
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

@media 800 {
  .bg {
    background-image: url('static/images/website/careers/bg-dots-blue-right.png');
    background-repeat: no-repeat;
    background-position: 100% 340px;
    background-size: 440px;
  }
}

@media 1100 {
  .bg {
    background-size: 480px;
  }
}