@value 1100 from 'vars';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

@media 1100 {
  .root {
  }
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.searchbar {
  padding-top: 25px;
}

@media 1100 {
  .searchbar {
    display: none;
  }
}

.action {
  display: none;
}

@media 1100 {
  .action {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.filters {

}

@media 1100 {
  .filters {
    display: none;
  }
}

.count {
  display: flex;
  color: var(--black-l);
  font-family: var(--font-bold);
  font-size: 16px;
  line-height: 16px;
  width: 110px;
}

.export {
  display: none;
}

@media 1100 {
  .export {
    display: initial;
  }
}