.info {
  position: relative;
  padding: 20px 0;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  padding: 5px 0;
  border-bottom: 2px solid var(--gray-l);
}

@media (min-width: 800px) {
  .title {
    font-size: 24px;
  }
}

.subtitle {
  font-family: var(--font-01);
  font-size: 18px;
  color: var(--acc-01);
}

.edit {
  position: absolute;
  right: 0px;
  top: -40px;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  --button-size: 35px;
  --reset-color: var(--gray);
  --save-color: var(--pri-03);
  --edit-color: var(--pri-02);
}

.edit-btn {
  border: 2px solid var(--edit-color);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-top: 5px;
}

@media (min-width: 800px) {
  .edit-btn {
    border-radius: var(--button-size);
    height: var(--button-size);
    width: var(--button-size);
    margin-top: 0;
  }
}

.edit-btn:hover {
  background-color: var(--edit-color);
}

.edit-btn:hover .edit-SVG {
  stroke: var(--pri-01);
}

.edit-SVG {
  width: 18px;
  height: 18px;
  stroke: var(--edit-color);
  stroke-width: 2;
  margin-top: 2px;
  margin-left: 1px;
}

@media (min-width: 800px) {
  .edit-SVG {
    width: 24px;
    height: 24px;
  }
}

.reset-btn {
  height: var(--button-size);
  width: var(--button-size);
  border-radius: var(--button-size);
  border: 2px solid var(--reset-color);
}

.reset-btn:hover {
  background-color: var(--reset-color);
}

.reset-SVG {
  color: var(--reset-color);
  font-size: 18px;
  font-family: var(--font-bold);
}

.reset-btn:hover .reset-SVG {
  color: var(--pri-01);
}

.save-btn {
  height: var(--button-size);
  width: var(--button-size);
  border-radius: var(--button-size);
  border: 2px solid var(--save-color);
}

.save-btn:hover {
  background-color: var(--save-color);
}

.save-SVG {
  stroke: var(--save-color);
  margin-top: 4px;
  margin-left: 1px;
}

.save-btn:hover .save-SVG {
  stroke: var(--pri-01);
}

.input-container {
  padding: 0.1em;
  border-bottom: 2px solid var(--gray-l);
  width: 250px;
}

.main {
  margin: 20px 0;
}

.switch {
  height: 30px;
  margin-bottom: 25px;
}

.brick {
  composes: brick from 'css/button.css';
}