.root {

}

.dropdown {
  width: 125px;
}

.label {

}