.root {}

.wrap {
  padding: 20px 0 0;
}

.tr {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1;
}

.th {
  flex: 1 1;
}

.empty-msg {
  font-style: italic;
  text-align: center;
  color: var(--gray-m);
  margin-bottom: 20px;
}

.name {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1 1;
}

.value {}

.entity {
  padding-left: 26px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter {
  width: 150px;
}

.filter input {
  background-color: transparent;
}

.header {
  background-color: var(--gray-xl);
  border-top: 1px solid var(--gray-l);
  border-bottom: 1px solid var(--gray-l);
  box-sizing: border-box;
}

.header .row {
  border-bottom: none;
}

.header .cell {
  font-size: 16px;
  font-family: var(--font-reg);
  font-weight: 800;
  color: var(--black);
  padding: 0 10px;
}

.body {}

.body .row {
  border-bottom: 1px solid var(--gray-l);
}

.body .cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  min-height: 40px;
  font-size: 16px;
}

.edit {
  width: 16px;
}

.edit .icon {
  display: none;
}

.name:hover .edit .icon {
  display: block;
}

.icon:hover {
  color: var(--sec-02);
}

.icon {
  width: 16px;
  height: 16px;
  color: var(--pri-02);
  ;
}

.modal {
  display: flex;
  flex-direction: column;
  overflow-y: visible;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  width: 300px;
  margin: 0 auto;
}

.label {
  font-family: var(--font-bold);
}

.field {
  margin-bottom: 20px;
}

.select,
.type {}

.btn {
  width: 140px;
  border-radius: 4px;
  padding: 8px 10px;
}

.footer {
  max-height: 45px;
  min-height: 45px;
}

.hthead {
  top: 245px;
}

.sortable {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}