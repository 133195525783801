.root {
  --left-offset: 75px;
}

.survey {
  max-width: 800px;
}

.detail {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.detail-header {
  font-family: var(--font-semibold);
}

.detail-text {
  margin-left: 5px;
}

.header {

}

@media (min-width: 450px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.employments {

}

.employment {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  max-width: 600px;
}

.employment:last-of-type {
  margin-bottom: 0;
}

.bullet  {
  border-radius: 50%;
  background-color: var(--gray-d);

  width: 7px;
  min-width: 7px;
  height: 7px;

  margin-right: 15px;
  margin-left: 5px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 215px;

  margin: 10px 0;
}

@media (min-width: 450px) {
  .buttons {
    margin: 0;
  }
}