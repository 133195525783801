.header {
  display: flex;
  align-items: baseline;
}

.trash-icon {
  color: var(--pri-04);
  cursor: pointer;
  align-self: center;
  width: 32px;
}

.option-list {
  margin-top: 20px;
  margin-bottom: 20px;
}

.dropdown {
  max-width: 200px;
  margin-left: 20px;
  margin-right: 20px;
}

.add {
  margin-top: 20px;
}

.section-title-input {
  font-family: var(--font-semibold);
}

.checkbox {
  margin-top: 15px;
}