.title {
  font-size: 22px;
  font-family: var(--font-bold);
  margin-bottom: 30px;
  text-align: center;
}

.item {
  position: relative;
  margin-bottom: 30px;
}

.error {
  position: absolute;
  bottom: -20px;
  font-size: 12px;
  color: var(--pri-04);
}

.btn {
  width: 100%;
}