.hover-container {
  padding: 5px;
}

.buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.icon {
  cursor: pointer;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  font-family: var(--font-semibold);
  margin-bottom: 5px;
}

.row {
  margin-bottom: 20px;
}

.dict-row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.update-text {
  font-style: italic;
  text-align: center;
  margin-top: 20px;
}

.confirmation-line {
  margin-bottom: 20px;
}

.bold {
  font-family: var(--font-semibold);
}

.alert {
  max-width: none;
}