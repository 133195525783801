.root {
  padding: 14px;
  width: 200px;
  border-left: 1px solid var(--gray-xl);
}

.title {
  font-family: var(--font-semibold);
  font-size: 15px;
  margin: 0 0 14px 0;
}

.filters {
  display: flex;
  flex-direction: column;
}

.check:not(:last-of-type) {
  margin: 0 0 4px 0;
}