.link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link:hover {
  text-decoration: underline;
  color: var(--hyperlink);
}

.file-icon {
  margin-right: 10px;
  width: 28px;
  min-width: 28px;
}

.name {
  display: flex;
  align-items: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}