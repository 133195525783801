.table {
  display: table;
  width: 100%;
  margin-bottom: 40px;
}

.header {
  display: table-row;
}

.header>div {
  display: table-cell;
  padding-bottom: 10px;

  font-family: var(--font-semibold);
}

.tbody {
  display: table-row-group;
}

.trow {
  display: table-row;
}

.trow>div {
  display: table-cell;
  padding-bottom: 10px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.flex-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.option-col {
  width: 40%;
  padding-right: 20px;
  word-break: break-word;
}