.root {
  display: flex;
  align-items: flex-start;
}

.value {
  word-break: break-word;
  white-space: pre-wrap;
  margin-left: 8px;
}

.capture-sheet-label {
  padding: 5px;
  display: inline-block;
}