.root {
  height: 100%;
}

.header {
  font-size: 18px;
  font-family: var(--font-bold);
  margin: 0 0 10px;
  padding: 0 8px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 34px);
}

.active {
  composes: active from '../style.css';
}

.icon {
  composes: icon from '../style.css';
  height: 40px;
  width: 40px;
}

.icon svg {
  height: 28px;
  width: 28px;
}

.badge {
  composes: badge from '../style.css';
  background-color: var(--pri-04);
}

.top {
  height: 100%;
  max-height: 100%;
  /* overflow: hidden; */
  overflow-y: auto;
  padding: 0 10px;
}

/* .top:hover {
  overflow-y: auto;
} */

.bottom {
  border-top: 1px solid var(--gray-l);
  justify-content: flex-end;
  margin: 10px 0 0 0;
  padding: 8px 6px;
  height: 80px;
}

