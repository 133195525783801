.root {
  margin-bottom: 20px;
}

.header {
  margin-bottom: 10px;
}

.title {
  font-family: var(--font-semibold);
  font-size: 18px;
}