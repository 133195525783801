.modal {
  width: 900px;
}

.body {

}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.cancel {
  margin-right: 15px;
}