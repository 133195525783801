.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.icon {
  color: var(--pri-02);
  cursor: pointer;
  margin-left: 15px;
}

.message {
  composes: icon;
  margin-top: 2px;
}