.version {
  margin-bottom: 15px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actions {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 12px;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

.title {
  display: flex;
  align-items: center;
}

.ordinal {
  font-family: var(--font-semibold);
  cursor: pointer;

  color: var(--hyperlink);
  text-decoration: underline;
}

.generated-on {
  color: var(--gray);
  font-size: 14px;
  margin-left: 10px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 10px;
}