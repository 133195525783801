.root {
  width: 500px;
}

.field {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
  width: 120px;
}

.footer {
  padding: 25px 0 10px;
}

.btn {
  width: 150px;
  height: 40px;
  padding: 0;
}