.root {
  margin-top: 40px;
}

.wrap {
  margin: 0 auto;
  width: 100%;
  max-width: 350px;
  padding: 0 10px;
}

@media(min-width: 600px) {
  .wrap {
    padding: 0 15px;
    max-width: 500px;
  }
}


@media(min-width: 800px) {
  .wrap {
    max-width: 650px;
  }
}

.header {
  min-height: 150px;
}

@media (min-width: 450px){
  .header {
    min-height: 100px;
    margin-bottom: 10px;
  }
}

.title {
  margin-bottom: 20px;
  font-family: var(--font-bold);
  font-size: 24px;
}

@media (min-width: 600px) {
  .title {
    display: flex;
    align-content: center;
  }
}

.subtitle {
  font-family: var(--font-reg);
  font-size: 18px;
  color: var(--gray-d);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 10px;
}

.btn {
  composes: brick from 'css/button.css';
  font-size: 16px;
  width: 130px;
}