.flag {
  margin-left: 10px;
  padding: 8px;
  min-width: 65px;
  height: 40px;
  background-color: var(--pri-01);
}

@media (min-width: 425px) {
  .flag {
    min-width: 80px;
  }
}