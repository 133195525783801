.root {
  --height-workspace-header: 155px;
  --height-workspace-tabbar: 45px;
  --height-workspace-page: calc(100vh - var(--navbar-height));
  --height-workspace-tab: calc(var(--height-file-page) - (var(--height-workspace-header) + var(--height-workspace-tabbar)));
  --gutter-width: 15px;

  height: var(--height-workspace-page);
  max-width: var(--navbar-width);
  width: 100%;
  margin: 0 auto;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 10px;
}