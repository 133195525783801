.root {
  display: flex;
  margin-bottom: 15px;
  height: 50px;
}

.info {
  margin-left: 10px;
}

.name {
  font-size: 14px;
  font-family: var(--font-semibold);
}

.career {
  font-family: var(--font-reg);
  font-size: 12px;
  color: #6C6C6C;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 24px;
}

.skeleton {
  transition: opacity .15s ease-in;
}