.toolbar {
  margin-bottom: 15px;
}

.editor-row {
  display: flex;
  align-items: center;
}

.editor-row> :first-child {
  flex-grow: 1;
}