.root {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: black;
  opacity: .5;
  z-index: 9999;
  cursor: progress;
  top: 0;
  left: 0;
}