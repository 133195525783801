.icon,
.icon-off {
  composes: icon from './Shared.css';
}

.icon-off {
  composes: icon-off from './Shared.css';
}

.icon-content {
  display: flex;
  align-items: center;
}

.icon-text {
  font-family: var(--font-bold);
  font-size: 14px;
  margin-right: 2px;
}