.root {
  display: flex;
  justify-content: center;
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 40px 0;
}

.title {
  text-align: center;
  font-family: var(--font-bold);
  font-size: 30px;
  margin-bottom: 10px;
}

.date {
  color: var(--gray-d);
  margin-bottom: 30px;
}

.text {
  text-align: center;
  margin-bottom: 40px;
}