.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu {
  margin-left: 10px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 5px;
}

.discover {
  margin-right: 20px;
}