.root {

}

.wrap {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  border-bottom: 1px solid var(--gray-l);
}

.title {
  font-size: 18px;
  color: var(--black);
  font-family: var(--font-semibold);
  line-height: 34px;
}

.edit {
  font-family: var(--font-bold);
  color: var(--pri-02);
}