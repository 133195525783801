.root {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease-in;
}

.plus {
  color: var(--pri-02);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: var(--font-bold);
}

.check-disabled {
  color: var(--pri-02);
  cursor: not-allowed;
  display: flex;
  align-items: center;
  font-family: var(--font-bold);
  opacity: 0.5;
}