.footer {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.footer > *:nth-child(2) {
  margin-left: 15px;
}

.body {
  max-width: 500px;
  margin: 0 auto;
}