.root {
  margin: auto;
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.title {
  color: var(--black-l);
  font-family: var(--font-bold);
  font-size: 18px;
  line-height: 20px;
  margin: 0 0 25px 0;
}

.copy {
  color: var(--black-l);
  font-size: 14px;
}

.copy2 {
  composes: copy;
  margin: 30px 0 0 0;
}

.next {
  border-radius: 3px;
  width: 170px;
}

.navigation {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.external {
  color: var(--gray-d);
  font-family: var(--font-semibold);
  font-size: 15px;
  margin: 40px 0 0 0;
}

.external:hover a {
  text-decoration: underline;
}

@media (min-width: 800px) {
  .root {
    max-width: 700px;
  }

  .wrap {
    display: block;
    height: auto;
  }

  .title {
    font-size: 24px;
    line-height: 30px;
  }

  .copy,
  .copy2 {
    font-size: 20px;
    line-height: 24px;
  }

  .navigation {
    display: block;
  }

  .external {
    font-size: 16px;
    margin: 40px 0 0 0;
  }
}

.refer {
  padding: 20px 0;
}

.navigation .copy {
  margin-bottom: 25px;
}

.header {
  font-family: var(--font-semibold);
  font-size: 17px;
  color: var(--black-l);
  margin-bottom: 15px;
}

.footer {
  margin: 60px 0 40px;
}

.link {
  font-family: var(--font-semibold);
  color: var(--hyperlink);
}

.link::after {
  margin-left: 5px;
  content: '>';
}