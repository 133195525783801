.icon {
  transition: opacity 100ms ease;
  cursor: pointer;
  color: var(--black-l);
  margin-left: 10px;
}

.disabled-icon {
  cursor: not-allowed;
}

.tooltip {
  margin-left: 10px;
}

.upload-section {
  text-align: center;
  padding: 20px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.upload-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.upload-input {
  margin-bottom: 10px;
}

.tab {
  width: 50%;
}

.tabs {
  width: 100%;
}

.tab.single {
  width: 100%;
}