@value 800 from 'vars';

.root {
  width: 100%;
}

.card {
  padding: 40px 20px;
  max-width: 470px;
  min-height: 680px;
  box-sizing: border-box;
  box-shadow: 0px 2px 51px -14px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  background: white;

  margin: 0 auto;
}

@media 800 {
  .card {
    padding: 40px;
  }
}