@value 800 from 'vars';

.root {}

.wrap {
  margin: 10px 0;
}

.wrap .card {
  margin-bottom: 10px;
}

.main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px;
}

.col {
  max-width: 600px;
  width: 100%;
}

@media (min-width: 1300px) {
  .col {
    min-width: 600px
  }
}

.search,
.label {
  color: var(--black);
  font-family: var(--font-bold);
  margin-bottom: 8px;
}

.field {
  color: var(--black-l);
  font-family: var(--font-reg);
  white-space: pre-wrap;
  overflow-wrap: break-word;
  line-height: 24px;
  min-height: 24px;
}

.frequency {
  width: 200px;
}

.editable-survey-field {
  composes: field;
  margin-top: 8px;
}

.row {
  margin-bottom: 20px;
}

.filters-row {
  composes: row;
}

.tags {
  composes: tags from 'static/css/tags.css';
  padding: 5px 0 0 0;
  min-height: 41px;
}

.textblock {
  display: flex;
  padding-right: 15px;
}

.input {
  font-family: var(--font-reg);
  font-size: 16px;
}

.fill {
  composes: fill from '@/scenes/projects/style.css';
}

.nofill {
  composes: nofill from '@/scenes/projects/style.css';
}

.cancel {
  composes: nofill from 'css/button.css';
}

.confirm {
  composes: fill from 'css/button.css';
}

.dropdown {
  width: 450px;
}

.description {
  resize: vertical;
  min-height: 90px;
}

.read-only-survey {
  margin-right: 25px;
  margin-top: 20px;
}

@media (min-width: 425px) {
  .read-only-survey {
    margin-right: 65px;
  }
}

.card {

  max-width: 725px;

  position: relative;
}

.overview {
  display: flex;
  justify-content: space-between;
}

@media 800 {
  .goal {
    max-width: 200px;
  }
}

.target> :last-child {
  margin-top: 20px;
}

.target .form {
  height: 24px;
  line-height: 24px;
}

.body {
  width: 100%;
}

.project-filters-header {
  font-size: 20px;
  font-family: var(--font-semibold);
  color: var(--black);
  margin-bottom: 20px;
}

.project-filters-label {
  font-size: 18px;
  font-family: var(--font-reg);
  margin-bottom: 10px;
  color: var(--gray-d);
}

.none-text {
  color: var(--gray);
  line-height: 24px;
}

.autocomplete {
  max-width: 450px;
}

.na {
  color: var(--gray);
}

.none {
  min-height: 19px;
  font-style: italic;
  color: var(--gray);
}