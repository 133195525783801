.root {
  padding: 18px 18px 0 18px;
}

.title {
  font-family: var(--font-semibold);
  font-size: 15px;
  margin: 0 0 8px 0;
}

.profiles {
  display: flex;
}

.profile {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 10px 2px;
  width: 80px;
}

.avatar {
  margin: 0 0 6px 0;
}

.name {
  color: var(--gray-d);
  font-size: 13px;
  font-family: var(--font-semibold);
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  line-height: 20px;
  height: 40px;
  width: 100%;
}

.name:hover {
  color: var(--hyperlink);
  text-decoration: underline;
}

.nothing {
  color: var(--gray);
  font-family: var(--font-semibold);
  font-size: 14px;
}

.find-experts {
  color: var(--hyperlink);
  font-family: var(--font-semibold);
  font-size: 14px;
  margin: 10px 0 0 0;
}