.option {
  margin-bottom: 20px;
  margin-left: 0;
}

.option:last-of-type {
  margin-bottom: 0;
}

.text {
  border: none;
  border-bottom: 1px solid var(--gray-l);
  line-height: 23px;
  font-family: var(--font-reg);
  font-size: 16px;
  width: 100%;
}

.open-ended {

}

.open-ended input {
  margin-top: 5px;
}