.row {
  display: flex;
  margin-bottom: 15px;
}

.label {
  font-size: 16px;
}

.item {
  margin-bottom: 25px;
}

.field {
  --border-width: 2px;
  --padding-horizontal: 15px;
  width: 100%;
}

.input {
  font-family: var(--font-reg);
  font-size: 16px;
  padding: 10px var(--padding-horizontal);
  border: var(--border-width) solid var(--gray-l);
  border-radius: 5px;
  width: calc(100% - ((var(--border-width) * 2) + (var(--padding-horizontal) * 2)));
  resize: none;
  line-height: normal;
}

.input:focus {
  border: var(--border-width) solid var(--pri-02);
}

.footer {
  padding: 60px 0 0;
}