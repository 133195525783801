.root {

}

.add-level {
  cursor: pointer;
  color: var(--pri-02);
  margin-top: 10px;
}

.attribute {
  font-family: var(--font-semibold);
  color: var(--black-l);
}