.root {
  display: none;
}

@media (min-width: 600px) {
  .root {
    display: inherit;
  }
}

.wrapper {
  display: flex;
  align-items: center;
}

.icon {
  fill: #f5f5f5;
  stroke: var(--gray);
  height: 18px;
  width: 18px;
}