.root {
  padding: 15px;
  border-bottom: 1px solid var(--gray-xl);
  color: var(--gray-d);

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.version-data {
  cursor: pointer;
  padding: 10px 20px 10px 60px;
}

.version-data:hover {
  background-color: var(--blue-xl);
}

.offset {
  margin-left: 35px;
}