.input {
  display: none;
}

.label {
  cursor: pointer;
  display: inline-block;
}

.drag-input {
  border: 3px dashed var(--pri-02);
  background-color: var(--blue-l);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  text-align: center;
  align-items: center;

  color: var(--pri-02);
  font-family: var(--font-bold);
}