.root {
  width: 100%;
  height: 35px;
}

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: calc(100% - 25px);
  height: 100%;
}

.line {
  display: inline-flex;
  position: absolute;
  height: 1px;
  border: 0;
  padding: 0;
}

.circle {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  background-color: var(--pri-01);
  height: 6px;
  width: 6px;
  border: 4px solid var(--azure);
  border-radius: 50%;
  padding: 6px;
  touch-action: pan-x;
}

.middle {
  composes: line;
  position: absolute;
  width: calc(100% + 25px);
  z-index: 1;
  border-top: 4px solid var(--azure);
}

.back {
  composes: line;
  position: absolute;
  left: 0px;
  z-index: 0;
  width: calc(100% + 25px);
  border-top: 4px solid var(--sec-01);
}

.price-label {
  display: flex;
  align-items: center;

  margin-bottom: 4px;
}

.label {
  color: var(--black-l);
  font-family: var(--font-semibold);
  font-size: 16px;
}

.price {
  margin-left: 10px;

  color: var(--azure);
  font-family: var(--font-semibold);
}