.root {

}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid var(--gray-xl);
  min-height: 40px;
  padding: 5px 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 0;
  border-bottom: 2px solid var(--gray-xl);
  font-family: var(--font-semibold);
}

.col {
  color: var(--black-l);
}

.row .col:nth-of-type(2) {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 15px;
}

.body {
  min-height: 300px;
  max-height: 400px;
  overflow-y: auto;
}

.footer {
  text-align: center;
  margin-top: 30px;
}

.btn {
  width: 125px;
}

.btn:nth-of-type(2) {
  margin-left: 20px;
}

.autocomplete {
  width: 350px;
  margin-bottom: 25px;
}

@media (min-width: 900px) {
  .autocomplete {
    margin-bottom: 50px;
  }
}

.owner {
  font-family: var(--font-semibold);
  padding: 10px;
}