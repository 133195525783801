.root {
  position: fixed;
  z-index: 4;
  right: 0px;
  bottom: 0px;
  top: 0px;
}

.visible {
  composes: root;
  left: 0px;
  transition: left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.hidden {
  composes: root;
  left: 100vw;
  transition: left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.menu {
  position: fixed;
  top: var(--navbar-height);
  right: 0;
  background-color: var(--pri-01);
  width: 100%;
  max-width: 300px;
  height: calc(100% - var(--navbar-height));
  z-index: 1;
}

.hidden .menu {
  opacity: 0;
  transform: translate(100%, 0);
  transition: transform 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
              opacity 0ms cubic-bezier(0, 0, 0.2, 1) 225ms;
}

.visible .menu {
  opacity: 1;
  transform: translate(0, 0);
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
              opacity 0ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.wrap {
  display: block;
  overflow-y: auto;
  height: 100%;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),
              0px 4px 5px 0px rgba(0,0,0,0.14),
              0px 1px 10px 0px rgba(0,0,0,0.12);
}

.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.22);
  -webkit-tap-highlight-color: transparent;
}

.hidden .overlay {
  opacity: 0;
  left: 100vw;
  transition: opacity 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              left 0ms cubic-bezier(0.4, 0, 0.2, 1) 195ms;
}

.visible .overlay {
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px 25px 80px;
  -webkit-tap-highlight-color: transparent;
}

.item {
  height: 70px;
  padding: 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black-l);
}

.active .item {
  color: var(--pri-02);
}

.box {
  composes: box from './AppBar.css';
  margin-right: 35px;
}

.badge {
  composes: badge from './AppBar.css';
}

.icon {
  width: 35px;
  height: 35px;
}

.name {
  font-family: var(--font-bold);
  font-size: 20px;
}

.drawer {
  display: flex;
  align-items: center;
  height: var(--navbar-height);
}

@media (min-width: 800px) {
  .drawer {
    display: none;
  }
}

.toggle {
  height: 40px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btn {
  position: relative;
  height: 35px;
  width: 35px;
}

.x,
.burger {
  position: absolute;
  bottom: 0;
  right: 0;
  color: var(--gray-d);
}

.open.burger,
.closed.x {
  top: 18px;
  left: 18px;
  height: 0;
  width: 0;
  opacity: 0;
  transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              top 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              left 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.open.x,
.closed.burger {
  top: 0;
  left: 0;
  height: 35px;
  width: 35px;
  opacity: 1;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}