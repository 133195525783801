.content-container > * {
  padding: 15px;
}

.titleContainer {
  cursor: auto;
}

.textContainer {
  display: flex;
}

.expertName {
  font-family: var(--font-semibold);
  font-size: 14px;
  margin-right: 10px;
}

.subtext {
  color: var(--gray-d);
}

.avatar {
  margin-right: 10px;
  height: 50px;
  width: 50px;
}

.anonAvatar {
  cursor: auto;
}

.btns {
  border-top: 1px solid var(--gray-xl);
  display: flex;
  padding: 0px;
}

.btns button {
  width: 100%;
  border-radius: 0px;
  padding: 10px;
  color: var(--pri-02);
  font-family: var(--font-bold);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btns button svg {
  margin-right: 5px;
}

.btns button:not(:last-child) {
  border-right: 1px solid var(--gray-xl);
}

.btns button:hover {
  background: var(--blue-xl);
}