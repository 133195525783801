@value 800, 1000 from 'vars';

.root {
  padding-top: 20px;
  width: 100%;
}

@media 800 {
  .root {
    padding-top: 0;
  }
}

.wrap {
  width: 100%;
  padding: 40px 0 0;
  background-image: url('static/images/website/compliance/dot-grid-square.svg?url');
  background-repeat: no-repeat;
  background-position: -25px 0px;
  margin: 0 auto;
}

@media 800 {
  .wrap {
    background-position: 15px 0;
    max-width: 780px;
  }
}

@media 1000 {
  .wrap {
    background-position: -20px 0;
    max-width: 850px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media 800 {
  .main {
    flex-direction: row;
  }
}

@media 1000 {
  .main {
    justify-content: space-between;
    max-width: 770px;
    margin: 0 auto;
  }
}

.title {
  font-family: var(--font-semibold);
  color: var(--black-d);
  font-size: 20px;
  margin-bottom: 20px;
}

.text {
  color: var(--black-d);
  font-size: 18px;
}

.item {
  background-color: var(--pri-01);
  width: 300px;
  height: 300px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.12);
  border-radius: 8px;
  margin-bottom: 10px;
}

@media 800 {
  .item {
    width: 310px;
    height: 280px;
  }

  .item:nth-child(even) {
    margin-left: 15px;
  }
}

@media 1000 {
  .item {
    width: 370px;
    height: 280px;
    margin-bottom: 25px;
  }

  .item:nth-child(even) {
    margin-left: inherit;
  }
}

.box {
  padding: 20px 30px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 30px;
  border-radius: 8px;
  margin-bottom: 8px;
}
