.root {
}

.title {
  font-family: var(--font-semibold);
  font-size: 15px;
  padding: 18px 18px 10px 18px;
}

.nothing {
  color: var(--gray);
  font-family: var(--font-semibold);
  font-size: 14px;
  padding: 0 18px;
}