.row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 25px;
  --margin-right: 25px;
}

.row .field:not(:last-child) {
  margin-right: var(--margin-right);
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 67px;
  width: calc(300px - (var(--margin-right) / 2));
}

.wide-field {
  composes: field;
  width: 100%;
}

.honoraria {
  composes: field;
  width: 100%;
  min-height: 67px;
  height: auto;
}

.input {
  width: 100%;
}

.label {
  font-size: 16px;
  font-family: var(--font-semibold);
  margin-bottom: 10px;
  color: var(--black);
  line-height: 24px;
}

.field .input .select.disabled,
.disabled {
  color: var(--gray-l);
  -webkit-text-fill-color: var(--gray-l);
}

.footer {
  padding: 60px 0 0;
}

.field .input .select,
.field .input .select:focus {
  border: none;
  border-radius: 0;
  padding-left: 0;
}

.trim {
  margin-bottom: auto;
  min-height: auto;
}

.field .input .select.placeholder {
  color: var(--gray);
}

.netsuite-badge {
  max-width: 400px;
}

.netsuite-project {
  display: flex;
}

.netsuite-clear {
  cursor: pointer;
  margin-left: 10px;
}

.po {
  line-height: 32px;
}

.required {
  color: var(--pri-04);
  margin-left: 5px;
}

.account {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}