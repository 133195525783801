@value 800, 1100 from 'vars';

.root {
  margin: 0 auto;
}

.wrap {
  padding: 0 20px 40px;
  margin: 0 auto;
}

@media 800 {
  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: var(--height-fullpage);
    min-height: 640px;
  }
}

@media 1100 {
  .wrap {
    max-width: var(--max-width);
  }
}

.main {
  max-width: 400px;
  margin: 0 auto;
}

@media 800 {
  .main {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    max-width: var(--max-width);
  }
}

.info {

}

@media 800 {
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 390px;
    margin-top: 20px;
  }
}

@media 1100 {
  .info {
    max-width: 430px;
  }
}

.title {
  font-size: var(--font-size-heading-2);
  color: var(--black-d);
  font-family: var(--font-bold);
  margin-bottom: 45px;
}

.title span {
  background-color: #c5ecd1;
  border-radius: 10px;
  padding: 0 18px 10px;
  margin-left: -18px;
}

@media 800 {
  .title {
    font-size: var(--font-size-heading-1);
    margin-bottom: 30px;
  }

  .title span {
    padding: 0 12px 10px;
    margin-left: -12px;
  }
}

@media 1100 {
  .title {
    font-size: var(--font-size-heading-xl);
    margin-bottom: 45px
  }

  .title span {
    padding: 0 18px 10px;
    margin-left: -18px;
  }
}

.subtitle {
  font-size: var(--font-size-subtitle-1);
  color: var(--black-l);
  margin-bottom: 40px;
}

.graphic {
  margin: 40px auto 50px;
}

@media 800 {
  .graphic {
    margin: 0;
  }
}

.img {
  display: none;
  width: 100%;
  animation-name: fade;
  animation-duration: 300ms;
}

.img.ready {
  display: block;
}

@media 800 {
  .img {
    max-width: 540px;
  }
}

@media 1100 {
  .img {
    max-width: 590px;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}