.root {
  display: flex;
  position: relative;
  padding: 40px 0;
}

.wrap {
  margin: 0 auto;
}

@media (min-width: 1200px) {
  .wrap {
    margin: 0 auto 0 225px;
  }
}

.sidebar {
  display: none;
  width: 220px;
  padding: 0 20px;
}

@media (min-width: 1000px) {
  .sidebar {
    display: inline-block;
  }
}

@media (min-width: 1200px) {
  .sidebar-wrap {
    position: fixed;
  }
}

.main {
  max-width: 750px;
}

@media (min-width: 800px) {
  .main {
    max-width: auto;
    width: 750px;
  }
}

@media (min-width: 1100px) {
  .main {
    width: 850px;
  }
}

.progress {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
}