.root {
  --footer-height: 45px;
  background-color: var(--gray-xl);

  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.sheet {
  height: calc(100% - var(--footer-height));
}

.footer {
  height: var(--footer-height);
  border-top: 1px solid var(--gray);
  box-sizing: border-box;
}

.tabs-scroll {
  width: calc(100% - 45px);
  box-sizing: border-box;
  overflow-x: auto;
  margin-left: 45px;

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tabs-scroll::-webkit-scrollbar {
  display: none;
}

.tabs {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.tab {
  font-size: 14px;
  padding: 8px 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--gray-l);
  border: 1px solid var(--gray);
  border-top: none;

  cursor: pointer;
  margin-left: -4px;

  font-family: var(--font-semibold);

  white-space: nowrap;
}

.tab:last-of-type {
  margin-left: 0px;
}

.tab:hover {
  
}

.tab.active {
  cursor: default;
  background-color: var(--pri-01);
}

.value {
  display: flex;
}