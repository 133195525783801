.root {
  font-family: var(--font-semibold);
  color: var(--gray-d);
}

.dollar:before {
  /* content: '$';
  margin-right: 1px; */
}

.per-hour:after {
  content: '/ hr';
  margin-left: 4px;
}