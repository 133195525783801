.root {
  margin-top: 25px;
}

.left {
  margin-left: 20px;
}

.message {
  color: var(--gray-d);
  margin-bottom: 10px;
}